import { useMemo } from 'react'
import {
    staffRoleActions,
    useRoleData,
    useRoleRates,
    useStaffs,
} from './role-page-store'
import { getColumns } from './role-page-columns'
import { useDataTable } from '@/version2/components/data-table/hooks/use-data-table'
import { DataTable } from '@/version2/components/data-table/data-table'
import { CheckboxWithLabel } from '@/version2/components/ui/checkbox'
import {
    canEditHolidays,
    canEditRoles,
    canEditStaffChargeOutRate,
    canViewStaffChargeOutRate,
    canEditStaffCostRate,
    canEditStaffOvertimeRate,
    canViewStaffOvertimeRate,
    canViewStaffCostRate,
    canViewStaffPayRate,
    canEditStaffPayRate,
} from '@/State/Permissions/HasPermissions'
import SessionStore from '@/State/SessionStore'

interface TopRow {
    id: string
    value?: any
    size?: number
    label?: string
    onChange?: (value: boolean) => void
    permissions?: any
    editable?: boolean
}

export function RolePageTable() {
    const role = useRoleData()
    const roleRates = useRoleRates()
    const staffs = useStaffs()

    const columns = useMemo(
        () => getColumns((column: string) => {}, role),
        [role]
    )
    const topRow: TopRow[] = useMemo(
        () => [
            {
                id: 'role',
                size: 120,
            },
            {
                id: 'avgPayRate',
                value: role?.avgPayRate,
                size: 120,
                label: 'Average From Staff',
                onChange: (value) => {
                    staffRoleActions.changeRoleData('avgPayRate', value)
                },
                permissions: canViewStaffPayRate(SessionStore.user),
                editable:
                    !staffs?.some((staff) => staff?.inheritPayRate) &&
                    canEditRoles(SessionStore.user) &&
                    canEditStaffPayRate(SessionStore.user),
            },
            {
                id: 'avgOvertimeRate',
                value: role?.avgOvertimeRate,
                size: 120,
                label: 'Average From Staff',
                onChange: (value) => {
                    staffRoleActions.changeRoleData('avgOvertimeRate', value)
                },
                permissions: canViewStaffOvertimeRate(SessionStore.user),
                editable:
                    !staffs?.some((staff) => staff?.inheritOvertimeRate) &&
                    canEditRoles(SessionStore.user) &&
                    canEditStaffOvertimeRate(SessionStore.user),
            },
            {
                id: 'avgCostRate',
                value: role?.avgCostRate,
                size: 120,
                label: 'Average From Staff',
                onChange: (value) => {
                    staffRoleActions.changeRoleData('avgCostRate', value)
                },
                permissions: canViewStaffCostRate(SessionStore.user),
                editable:
                    !staffs?.some((staff) => staff?.inheritCostRate) &&
                    canEditRoles(SessionStore.user) &&
                    canEditStaffCostRate(SessionStore.user),
            },
            {
                id: 'avgChargeOutRate',
                value: role?.avgChargeOutRate,
                size: 120,
                label: 'Average From Staff',
                onChange: (value) => {
                    staffRoleActions.changeRoleData('avgChargeOutRate', value)
                },
                permissions: canViewStaffChargeOutRate(SessionStore.user),
                editable:
                    !staffs?.some((staff) => staff?.inheritChargeOutRate) &&
                    canEditRoles(SessionStore.user) &&
                    canEditStaffChargeOutRate(SessionStore.user),
            },
            {
                id: 'button',
            },
        ],
        [role, staffs]
    )

    const renderTopRow = (row: TopRow) => {
        if (row.id === 'button' || row.id === 'role') {
            return null
        }
        return (
            <CheckboxWithLabel
                label={row.label}
                checked={row.value}
                id={row.id}
                onCheckedChange={row.onChange}
                disabled={!row.editable}
            />
        )
    }

    const rowData = useMemo(
        () => roleRates.filter((rate) => !rate?.deletedAt),
        [roleRates]
    )
    const { table } = useDataTable({
        data: rowData,
        columns,
        visibleColumns: columns.map((column: any) => column?.accessorKey),
    })

    return (
        <DataTable table={table} topRows={topRow} renderTopRow={renderTopRow} />
    )
}
