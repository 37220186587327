import { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Column, Row, Table } from '@tanstack/react-table'
import * as cuid from 'cuid'
import * as React from 'react'
import download from 'downloadjs'
import Papa from 'papaparse'
import Formatter from '@/Components/Formatter'
import { trpc } from '@/system/trpc'
import SessionStore from '@/State/SessionStore'
import { useNavigate } from '@tanstack/react-router'
import {
    csvFormatter,
    ExportColumn,
    OrganisationReport,
} from '@/version2/types'
import { DataTableActions } from '@/version2/components/data-table/advanced/data-table-action'
import {
    useOrganisationReport,
    setOrganisationReport,
} from './time-report-store'
import {
    updateCacheItem,
    deleteCacheItem,
    addCacheItem,
} from '@/version2/cache'
import { setPageTitle } from '@/version2/layout/layout-store'
import { orderColumnDefs } from '@/version2/components/data-table/helpers/order-columns'
import { useSaveToast } from '@2/components/hooks/save-toast'

interface TimeReportActionsProps<TData>
    extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
    exportColumns?: ExportColumn[]
}

export function TimeReportActions<TData>({
    table,
    exportColumns = [],
}: TimeReportActionsProps<TData>) {
    const [dialogContent, setDialogContent] = useState<string | null>(null)
    const [nameReport, setNameReport] = useState<string | null>(null)
    const organisationReport = useOrganisationReport()
    const navigate = useNavigate()
    const checkDisableButton =
        !organisationReport?.id &&
        !SessionStore.organisation?.defaultTimeReportId

    const handleOpenDialog = (content: string) => {
        setNameReport(null)
        setDialogContent(content)
    }

    const showSaveAsToast = useSaveToast({
        saveId: 'timesheet-report-save-as',
        onSave: async () => {
            const newId = cuid()
            const result = await trpc.timeReport.saveReport.mutate({
                id: newId,
                name: nameReport,
                filters: organisationReport.filters,
                type: 'timesheet',
                groupBy: organisationReport.groupBy,
                columns: organisationReport.columns,
                options: organisationReport.options,
            })
            addCacheItem('organisationReports', {
                id: newId,
                name: nameReport,
                type: 'timesheet',
            })
            setNameReport(null)
            setDialogContent(null)
            setOrganisationReport({
                ...organisationReport,
                ...result,
            })
            navigate({
                search: {
                    report: newId,
                },
            })
        },
        loadingMessage: 'Saving new report...',
        successMessage: 'New report saved successfully',
        errorMessage: 'Failed to save new report',
    })

    // Save existing report
    const showSaveToast = useSaveToast({
        saveId: 'timesheet-report-save',
        onSave: async () => {
            const result = await trpc.timeReport.saveReport.mutate({
                id: organisationReport.id,
                organisationId: organisationReport.organisationId,
                groupBy: organisationReport.groupBy,
                filters: organisationReport.filters,
                columns: organisationReport.columns,
                options: organisationReport.options,
            })
            setDialogContent(null)
            setOrganisationReport({
                ...organisationReport,
                ...result,
            })
            return result
        },
        loadingMessage: 'Saving report...',
        successMessage: 'Report saved successfully',
        errorMessage: 'Failed to save report',
    })

    // Rename operation
    const showRenameToast = useSaveToast({
        saveId: 'timesheet-report-rename',
        onSave: async () => {
            if (!nameReport || nameReport === organisationReport.name) return
            const result = await trpc.timeReport.saveReport.mutate({
                id: organisationReport.id,
                organisationId: organisationReport.organisationId,
                name: nameReport,
            })
            updateCacheItem('organisationReports', organisationReport.id, {
                name: nameReport,
                type: 'timesheet',
            })
            setPageTitle(nameReport)
            setNameReport(null)
            setDialogContent(null)
            setOrganisationReport({
                ...organisationReport,
                ...result,
            })
            return result
        },
        loadingMessage: 'Renaming report...',
        successMessage: 'Report renamed successfully',
        errorMessage: 'Failed to rename report',
    })

    // Delete operation
    const showDeleteToast = useSaveToast({
        saveId: 'timesheet-report-delete',
        onSave: async () => {
            const result = await trpc.timeReport.saveReport.mutate({
                id: organisationReport.id,
                organisationId: organisationReport.organisationId,
                deletedAt: new Date(),
            })
            deleteCacheItem('organisationReports', organisationReport.id)
            setDialogContent(null)
            navigate({ search: {} })
            return result
        },
        loadingMessage: 'Deleting report...',
        successMessage: 'Report deleted successfully',
        errorMessage: 'Failed to delete report',
    })

    // Set Default operation
    const showSaveDefaultToast = useSaveToast({
        saveId: 'timesheet-report-set-default',
        onSave: async () => {
            return await trpc.timeReport.setDefaultReport.mutate({
                id: organisationReport.id,
            })
        },
        loadingMessage: 'Setting as default...',
        successMessage: 'Set as default report',
        errorMessage: 'Failed to set as default',
    })
    const handleSetDefault = async () => {
        showSaveDefaultToast()
    }

    const handleDialogSubmit = async (dialogContent: string) => {
        switch (dialogContent) {
            case 'Save as':
                showSaveAsToast()
                break
            case 'Save':
                showSaveToast()
                break
            case 'Rename Report':
                showRenameToast()
                break
            case 'Delete Report':
                showDeleteToast()
                break
            default:
                break
        }
        setDialogContent(null)
    }

    const handleExportToCSV = (type: string) => {
        const level = organisationReport.groupBy.indexOf(type)
        const data = table
            .getExpandedRowModel()
            .flatRows.filter((row) =>
                level >= 0
                    ? row.depth == level
                    : row.depth == organisationReport.groupBy.length
            )
        const listColumnsExport = []
        listColumnsExport.push(
            ...organisationReport.groupBy,
            ...organisationReport.columns.filter(
                (column) => !organisationReport.groupBy.includes(column)
            )
        )
        const columnsCSV = orderColumnDefs(
            table
                .getAllColumns()
                .filter(
                    (c) =>
                        !['expand', 'select', 'label'].includes(c.id) &&
                        listColumnsExport.includes(c.id)
                ),
            organisationReport.columns,
            organisationReport.groupBy,
            []
        )
        const csv = []
        csv.push(columnsCSV.map((c) => c?.columnDef?.meta?.title))
        csv.push(
            ...data.map((r) =>
                columnsCSV.map((c) => {
                    return csvFormatter(r, c)
                })
            )
        )

        download(Papa.unparse(csv), `Default timesheet.csv`, 'text/csv')
    }

    return (
        <>
            <DataTableActions
                handleExportToCSV={handleExportToCSV}
                handleDialogSubmit={handleDialogSubmit}
                handleOpenDialog={handleOpenDialog}
                handleSetDefault={handleSetDefault}
                dialogContent={dialogContent}
                setDialogContent={setDialogContent}
                setNameReport={setNameReport}
                nameReport={nameReport}
                organisationReport={organisationReport}
                checkDisableButton={checkDisableButton}
                exportColumns={exportColumns}
            />
        </>
    )
}
