import React from 'react'
import { observer } from 'mobx-react'
import { addMonths, format, subMonths } from 'date-fns'
import {
    getTimeBeforeMonth,
    getTimeInMonth,
    setTimeInMonth,
    getPhaseExpenseProgressToDate,
    setPhaseRevenueProgressForMonth,
} from '../../Utils/forecastHelpersOld'
import _ from 'lodash'
import HoursProgressSlider from './HoursProgressSlider'
import RevenueRowCollection from '../../State/Collections/RevenueRowCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ResourceRowCollection from '../../State/Collections/ResourceRowCollection'
import RevenueForecastStore from '../../Pages/RevenueForecastPage/RevenueForecastStore'
import { get } from 'underscore'

const getProjectPhaseRow = (matchers, collection) => {
    const rowId = matchers.phaseId
        ? `('${matchers.status}', '${matchers.projectId}', '${matchers.phaseId}')`
        : `('${matchers.status}', '${matchers.projectId}')`
    const row =
        collection.rowsById[rowId] ||
        collection.addRow(
            {
                id: rowId,
                projectId: matchers.projectId,
                ...(matchers.phaseId ? { phaseId: matchers.phaseId } : {}),
                groups: matchers.phaseId ? ['project', 'phase'] : ['project'],
            },
            { trackUpdates: false }
        )
    row.fee ||= matchers.phaseId ? row?.phase?.fee : row?.project?.fee
    return row
}

export default observer(({ matchers, updateRevenue, month, editable }) => {
    const monthId = format(month, 'yyyy-MM')
    const project = ProjectCollection.projectsById[matchers.projectId]
    const phase = PhaseCollection.phasesById[matchers.phaseId]
    const resourceRow = getProjectPhaseRow(matchers, ResourceRowCollection)
    const maxHoursBudget = Math.max(
        resourceRow?.hoursBudget,
        resourceRow?.getHoursToDateInMonth(
            format(subMonths(month, 1), 'yyyy-MM')
        )
    )
    const prevHours = resourceRow?.getHoursToDateInMonth(
        format(subMonths(month, 1), 'yyyy-MM')
    )
    const selectedHours = resourceRow?.getHoursInMonth(format(month, 'yyyy-MM'))
    return (
        <div
            key={monthId + project.id + (phase?.id || '')}
            style={{ marginTop: '0.9em' }}
        >
            <div>{phase?.title || project?.title}</div>
            <HoursProgressSlider
                total={resourceRow?.hoursBudget}
                maxAmount={maxHoursBudget}
                prevAmount={prevHours}
                amount={selectedHours}
                height={phase ? 4 : 6}
                editable={editable}
                onChange={async (v) => {
                    resourceRow?.setHoursInMonth(format(month, 'yyyy-MM'), v)
                    if (updateRevenue) {
                        // if (
                        //     resourceRow.childPhaseIds.length !==
                        //     resourceRow.childPhases.length
                        // ) {
                        //     await fetchData({
                        //         collection: 'phases',
                        //         fields: [
                        //             'id',
                        //             'projectId',
                        //             'jobNumber',
                        //             'name',
                        //             'fee',
                        //         ],
                        //         filters: [
                        //             `id in ${qf(resourceRow.childPhaseIds)}`,
                        //         ],
                        //     })
                        // }
                        resourceRow?.childPhaseIds?.forEach((phId) => {
                            const phResRow = getProjectPhaseRow(
                                {
                                    projectId: resourceRow?.projectId,
                                    phaseId: phId,
                                },
                                ResourceRowCollection
                            )
                            const phRevRow = getProjectPhaseRow(
                                {
                                    projectId: resourceRow?.projectId,
                                    phaseId: phId,
                                },
                                RevenueRowCollection
                            )
                            phRevRow.fee ||=
                                PhaseCollection.phasesById[phId]?.fee || 0
                            phRevRow?.setProgressInMonth(
                                monthId,
                                phResRow?.getProgressInMonth(monthId)
                            )
                        })
                    }
                }}
            />
        </div>
    )
})
