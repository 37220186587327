import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import wait from 'wait'
import LoadingSpinner from '../../Components/LoadingSpinner'
import * as Sentry from '@sentry/react'
import SessionStore from '../../State/SessionStore'
import { makeRequest } from '../../Queries/makeRequest'
import { useNavigate } from '@tanstack/react-router'
import { canViewRoute } from '../../State/Permissions/HasPermissions'

export default observer(({ token, redirect }) => {
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [resetState, setResetState] = useState('idle')
    const [validToken, setValidToken] = useState(null)

    useEffect(() => {
        makeRequest({
            path:
                process.env.REACT_APP_SERVER_URL +
                '/api/v1.5/user/verify-token',
            method: 'post',
            data: { token: token },
        })
            .then((response) => {
                setValidToken(true)
            })
            .catch((error) => {
                Sentry.captureException(error)
                setValidToken(false)
                wait(1000).then(() => {
                    navigate({ to: '/login' })
                })
            })
    }, [token, navigate])

    const handleSubmit = (event) => {
        event.preventDefault()
        setResetState('pending')
        makeRequest({
            path:
                process.env.REACT_APP_SERVER_URL +
                '/api/v1.5/user/reset-password',
            method: 'post',
            data: {
                password: password,
                token: token,
            },
        })
            .then((response) => {
                SessionStore.saveLoginResponse(response)
                navigate({
                    to:
                        redirect ||
                        (canViewRoute(SessionStore.user, 'projectList')
                            ? '/projects'
                            : '/timesheets'),
                })
            })
            .catch((error) => {
                Sentry.captureException(error)
                navigate({ to: '/login' })
            })
    }

    if (validToken === null) {
        return <LoadingSpinner />
    }

    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className="flex flex-col items-center justify-center w-[350px]">
                <header className="flex items-center flex-col justify-center">
                    <div className="logo2 s75 size-[10em] flex items-center justify-center">
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                            alt="Coincraft Logo"
                        />
                    </div>
                    <h1 style={{ fontSize: '3em' }}>
                        {validToken ? 'Reset Password' : 'Link expired...'}
                    </h1>
                </header>
                {validToken && (
                    <div className="body w-full">
                        <h4
                            style={{
                                margin: '0em -2em 1em',
                            }}
                        >
                            Please enter your new password.
                        </h4>
                        <form
                            name="forgottenPasswordForm"
                            className="no-margin"
                            onSubmit={handleSubmit}
                        >
                            <fieldset>
                                <div className="form-group no-margin">
                                    <div className="input-group input-group-lg">
                                        <span className="input-group-addon">
                                            <i className="eicon-user"></i>
                                        </span>
                                        <input
                                            value={password || ''}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            type="password"
                                            className="form-control input-lg h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                            placeholder="Your New Password"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <div className="form-actions mt-5">
                                <button
                                    type="submit"
                                    className="btn btn-block btn-lg btn-primary w-full py-2.5"
                                >
                                    Set Password & Login
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </section>
        </div>
    )
})
