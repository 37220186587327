import _ from 'lodash'
import React from 'react'
import Modal from '../../Components/Modal'
import { observer } from 'mobx-react'
export default observer(({ phase, modalId }) => {
    return (
        <Modal
            modalId={modalId}
            heading="Delete Phase"
            onSave={() => phase.update({ deletedAt: new Date() })}
            saveLabel={`Delete ${phase.title}`}
        >
            <p style={{ padding: '0.5em 1em' }}>
                <b>{phase.title}</b>
                {
                    ' has time recorded against it. Are you sure you want to delete it?'
                }
            </p>
        </Modal>
    )
})
