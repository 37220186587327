import { useEffect, useState } from 'react'
import { queryClient } from '@/App'
import { trpc } from '@/system/trpc'
import {
    useSelectedRow,
    useSelectedMonth,
    useSidebarProject,
    useSidebarResourcing,
    setSidebarProject,
    setSidebarResourcing,
    setSidebarMonth,
} from './revenue-forecast-store'
import { GradientSlider, Slider } from '@/version2/components/ui/slider'
import { cn } from '@/lib/utils'

export const RevenueForecastSidebar = () => {
    const selectedRow = useSelectedRow()
    const selectedMonth = useSelectedMonth()
    const sidebarProject = useSidebarProject()
    const sidebarResourcing = useSidebarResourcing()

    useEffect(() => {
        if (selectedMonth) {
            setSidebarMonth(selectedMonth)
        }
    }, [selectedMonth])

    useEffect(() => {
        async function fetchData() {
            if (!selectedMonth || !selectedRow?.projectId) {
                console.log('Missing required data:', {
                    selectedMonth,
                    selectedRow,
                })
                return
            }

            console.log('Fetching data for:', {
                selectedMonth,
                selectedRow,
            })

            try {
                const projectData = await queryClient.fetchQuery(
                    [
                        'revenueForecast.getProjectDetails',
                        {
                            month: selectedMonth,
                            projectId: selectedRow.projectId,
                        },
                    ],
                    async () => {
                        console.log('Making project details request')
                        const result =
                            await trpc.revenueForecast.getProjectDetails.query({
                                month: selectedMonth,
                                projectId: selectedRow.projectId,
                            })
                        console.log('Project details response:', result)
                        return result
                    }
                )
                setSidebarProject(projectData)

                const resourcingData = await queryClient.fetchQuery(
                    [
                        'revenueForecast.getResourcingData',
                        { month: selectedMonth },
                    ],
                    async () => {
                        console.log('Making resourcing data request')
                        const result =
                            await trpc.revenueForecast.getResourcingData.query({
                                month: selectedMonth,
                            })
                        console.log('Resourcing data response:', result)
                        return result
                    }
                )
                setSidebarResourcing(resourcingData)
            } catch (error) {
                console.error('Error fetching sidebar data:', error)
            }
        }

        fetchData()
    }, [selectedMonth, selectedRow])

    if (!selectedMonth || !selectedRow?.projectId) {
        return (
            <div className="p-4">
                <p className="text-gray-500">
                    Select a row and month to view details
                </p>
            </div>
        )
    }
    const [amount, setAmount] = useState(500)

    return (
        <div className="p-4 space-y-6">
            <GradientSlider
                total={750}
                prevAmount={0}
                amount={amount}
                onChange={setAmount}
                height={4}
                editable={true}
            />
            <div>
                <h3 className="text-lg font-semibold mb-2">Project Details</h3>
                <div className="space-y-2">
                    <p>
                        <span className="font-medium">Title:</span>{' '}
                        {sidebarProject.title}
                    </p>
                    <p>
                        <span className="font-medium">Fee:</span> $
                        {sidebarProject.fee?.toLocaleString()}
                    </p>
                    <p>
                        <span className="font-medium">Budget:</span> $
                        {sidebarProject.expenseBudget?.toLocaleString()}
                    </p>
                    <p>
                        <span className="font-medium">Hours:</span>{' '}
                        {sidebarProject.hoursBudget}
                    </p>
                    <p>
                        <span className="font-medium">Revenue:</span> $
                        {sidebarProject.revenue?.toLocaleString()}
                    </p>
                    <p>
                        <span className="font-medium">Expenses:</span> $
                        {sidebarProject.expense?.toLocaleString()}
                    </p>
                </div>
            </div>
            <div>
                <h3 className="text-lg font-semibold mb-2">Phases</h3>
                <div className="space-y-4">
                    {sidebarProject.phases.map((phase) => (
                        <div
                            key={phase.id}
                            className="border-l-2 border-gray-200 pl-3"
                        >
                            <p className="font-medium">{phase.title}</p>
                            <p>Fee: ${phase.fee?.toLocaleString()}</p>
                            <p>
                                Budget: ${phase.expenseBudget?.toLocaleString()}
                            </p>
                            <p>Hours: {phase.hoursBudget}</p>
                            <p>Status: {phase.status}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h3 className="text-lg font-semibold mb-2">Resourcing</h3>
                <div className="space-y-4">
                    {sidebarResourcing.staff.map((member) => {
                        const allocations =
                            sidebarResourcing.allocations.filter(
                                (a) => a.staffId === member.id
                            )
                        const role = sidebarResourcing.roles.find(
                            (r) => r.id === member.roleId
                        )

                        return (
                            <div
                                key={member.id}
                                className="border-l-2 border-gray-200 pl-3"
                            >
                                <p className="font-medium">{member.name}</p>
                                <p>Role: {role?.name || 'No Role'}</p>
                                <p>Availability: {member.availability}h</p>
                                <p>Cost Rate: ${member.costRate}/h</p>
                                <p>
                                    Allocated:{' '}
                                    {allocations.reduce(
                                        (sum, a) => sum + a.hours,
                                        0
                                    )}
                                    h
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
