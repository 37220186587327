import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import {
    PhaseSelector,
    ProjectSelector,
    TaskSelector,
} from '../../Components/Selector'
import Inputs from '../../Components/Inputs'
import DateValue from '../../Components/Widgets/DateValue'
import RenderOnQueries from '../Layout/RenderOnQueries'
import SessionStore from '../../State/SessionStore'
import Checkbox from '../../Components/Widgets/Checkbox'
import {
    canEditStaffTime,
    canEnterTimeAgainstPhase,
    canEnterTimeAgainstProject,
    canLogTimeAgainstNoPhase,
    canLogTimeAgainstTasks,
    canUseTimeEntryFlag,
} from '../../State/Permissions/HasPermissions'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { qf } from '../../Queries/queryFormatter'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { queryClient } from '../../App'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import TaskCollection from '../../State/Collections/TaskCollection'

export default observer(
    ({
        modalId,
        timeEntries,
        dates,
        staff,
        store,
        heading,
        onSave,
        showAllFlags,
        placeholders,
        selectDefaults = true,
        defaults,
    }) => {
        const [project, setProject] = useState(
            defaults ? defaults.project : timeEntries?.[0]?.project
        )
        const [phase, setPhase] = useState(
            defaults ? defaults.phase : timeEntries?.[0]?.phase
        )
        const [task, setTask] = useState(
            defaults ? defaults.task : timeEntries?.[0]?.task
        )
        const [isBillable, setIsBillable] = useState(
            defaults
                ? defaults.isBillable
                : (timeEntries?.[0]?.isBillable ??
                      timeEntries?.[0]?.task?.isBillable ??
                      timeEntries?.[0]?.project?.costCentre?.isBillable ??
                      true)
        )
        const [isVariation, setIsVariation] = useState(
            defaults
                ? defaults.isVariation
                : (timeEntries?.[0]?.isVariation ??
                      timeEntries?.[0]?.task?.isVariation ??
                      false)
        )
        const [isOvertime, setIsOvertime] = useState(
            defaults
                ? defaults.isOvertime
                : (timeEntries?.[0]?.isOvertime ?? false)
        )
        const [isRemote, setIsRemote] = useState(
            defaults ? defaults.isRemote : (timeEntries?.[0]?.isRemote ?? false)
        )
        const [isFlexi, setIsFlexi] = useState(
            defaults ? defaults.isFlexi : (timeEntries?.[0]?.isFlexi ?? false)
        )
        const [beenInvoiced, setBeenInvoiced] = useState(
            defaults
                ? defaults.beenInvoiced
                : (timeEntries?.[0]?.beenInvoiced ?? false)
        )
        const [isLocked, setIsLocked] = useState(
            defaults ? defaults.isLocked : (timeEntries?.[0]?.isLocked ?? false)
        )
        const getSelectedData = () => {
            return Object.fromEntries(
                [
                    project ? ['costCentreId', project.costCentreId] : null,
                    project ? ['projectId', project.id] : null,
                    phase ? ['phaseId', phase.id] : null,
                    task ? ['taskId', task.id] : null,
                    isBillable != null ? ['isBillable', isBillable] : null,
                    isVariation != null ? ['isVariation', isVariation] : null,
                    isOvertime != null ? ['isOvertime', isOvertime] : null,
                    isRemote != null ? ['isRemote', isRemote] : null,
                    isFlexi != null ? ['isFlexi', isFlexi] : null,
                    beenInvoiced != null
                        ? ['beenInvoiced', beenInvoiced]
                        : null,
                    isLocked != null ? ['isLocked', isLocked] : null,
                ].filter((v) => v)
            )
        }
        return (
            <Modal
                modalId={modalId}
                width={'45em'}
                heading={
                    heading ||
                    (timeEntries
                        ? 'Edit Timesheet Entries'
                        : 'New Timesheet Entry')
                }
                onSave={() => {
                    if (onSave) {
                        return onSave(getSelectedData())
                    }
                    if (timeEntries) {
                        timeEntries.forEach((te) => {
                            te.update(getSelectedData())
                        })
                    } else {
                        const timeEntries = dates.map((d) =>
                            TimeEntryCollection.add(
                                {
                                    date: d,
                                    staffId: staff.id,
                                    numMinutes: 0,
                                    ...getSelectedData(),
                                },
                                { trackUpdates: true }
                            )
                        )
                        store.addExtraTime(timeEntries)
                    }
                }}
                saveLabel={
                    timeEntries ? 'Edit Time Entries' : 'Add Time Entries'
                }
                canSave={() =>
                    showAllFlags ||
                    (project && (canLogTimeAgainstNoPhase() || phase))
                }
            >
                <div
                    className="p-[1em] grid gap-[0.5em] "
                    style={{
                        gridTemplateColumns: '1fr 4fr',
                    }}
                >
                    <div className="text-sm text-[#444]">Project: </div>
                    <div className="max-w-max">
                        <ProjectSelector
                            selectedProject={project}
                            placeholderLabel={placeholders?.project}
                            projectOptions={ProjectCollection.projects.filter(
                                (p) =>
                                    canEnterTimeAgainstProject(
                                        SessionStore.user,
                                        p,
                                        timeEntries ? true : false
                                    )
                            )}
                            onChange={(pr) => {
                                queryClient.invalidateQueries([
                                    `phases-${pr.id}`,
                                ])
                                setProject(pr)
                                setPhase(null)
                                setTask(null)
                                if (selectDefaults) {
                                    setIsBillable(pr?.costCentre?.isBillable)
                                    setIsVariation(false)
                                    setIsOvertime(false)
                                }
                            }}
                            variant="secondary"
                        />
                    </div>

                    <div className="text-sm text-[#444]">Phase: </div>
                    <div className="max-w-max">
                        <RenderOnQueries
                            queryIds={[
                                project
                                    ? {
                                          id: `phases-${project.id}`,
                                          collection: 'phases',
                                          fields: [
                                              'jobNumber',
                                              'name',
                                              'projectId',
                                              'status',
                                              'startDate',
                                              'endDate',
                                              'isRootPhase',
                                              'position',
                                              [
                                                  'budgetedStaffIds',
                                                  'budgetedHours.staffIds',
                                              ],
                                              [
                                                  'monthlyAllocatedStaffIds',
                                                  'monthlyAllocations.staffIds',
                                              ],
                                          ],
                                          filters: [
                                              `projectId == "${project.id}"`,
                                          ],
                                          subQueries: [
                                              {
                                                  collection: 'budgetedHours',
                                                  join: `id == budgetedHours.phaseId`,
                                                  groupBy: ['phaseId'],
                                                  fields: [
                                                      [
                                                          'staffIds',
                                                          'uniq(staffId)',
                                                      ],
                                                  ],
                                                  filters: [`staffId != null`],
                                              },
                                              {
                                                  collection:
                                                      'monthlyAllocations',
                                                  join: `id == monthlyAllocations.phaseId`,
                                                  groupBy: ['phaseId'],
                                                  fields: [
                                                      [
                                                          'staffIds',
                                                          'uniq(staffId)',
                                                      ],
                                                  ],
                                                  filters: [
                                                      `staffId != null`,
                                                      `month >= ${qf(
                                                          format(
                                                              new Date(),
                                                              'yyyy-MM'
                                                          )
                                                      )}`,
                                                      `month <= ${qf(
                                                          format(
                                                              new Date(),
                                                              'yyyy-MM'
                                                          )
                                                      )}`,
                                                  ],
                                              },
                                          ],
                                      }
                                    : null,
                                project
                                    ? {
                                          collection: 'tasks',
                                          fields: [
                                              'name',
                                              'projectId',
                                              'phaseId',
                                              'isDefault',
                                              'isBillable',
                                              'isVariation',
                                          ],
                                          filters: [
                                              `projectId == "${project?.id}"`,
                                          ],
                                      }
                                    : null,
                            ].filter((v) => v)}
                        >
                            <PhaseSelector
                                key={`phase-selector-${
                                    project?.phases?.length
                                        ? project?.id
                                        : 'null'
                                }`}
                                placeholderLabel={placeholders?.phase}
                                selectedPhase={
                                    PhaseCollection.phasesById[phase?.id] ||
                                    phase
                                }
                                onChange={(ph) => {
                                    setPhase(ph)
                                    const task =
                                        ph.defaultTask || ph.tasks[0] || null
                                    if (task) {
                                        setTask(task)
                                        if (selectDefaults) {
                                            setIsBillable(task?.isBillable)
                                            setIsVariation(task?.isVariation)
                                            setIsOvertime(false)
                                        }
                                    }
                                }}
                                phaseOptions={
                                    project?.phases?.filter((ph) =>
                                        canEnterTimeAgainstPhase(
                                            SessionStore.user,
                                            ph
                                        )
                                    ) || (phase ? [phase] : [])
                                }
                                variant="secondary"
                            />
                        </RenderOnQueries>
                    </div>
                    {canLogTimeAgainstTasks() ? (
                        <>
                            <div className="text-sm text-[#444]">Task: </div>
                            <div className="max-w-max">
                                <RenderOnQueries
                                    queryIds={[
                                        project
                                            ? {
                                                  collection: 'tasks',
                                                  fields: [
                                                      'name',
                                                      'projectId',
                                                      'phaseId',
                                                      'isDefault',
                                                      'isBillable',
                                                      'isVariation',
                                                  ],
                                                  filters: [
                                                      `projectId == "${project?.id}"`,
                                                  ],
                                              }
                                            : null,
                                    ].filter((v) => v)}
                                >
                                    <TaskSelector
                                        key={`task-selector-${
                                            project?.phases?.length
                                                ? project?.id
                                                : 'null'
                                        }-${
                                            phase?.tasks?.length
                                                ? phase?.id
                                                : 'null'
                                        }`}
                                        selectedTask={
                                            TaskCollection.tasksById[
                                                task?.id
                                            ] || task
                                        }
                                        placeholderLabel={placeholders?.task}
                                        onChange={(task) => {
                                            setTask(task)
                                            if (selectDefaults) {
                                                setIsBillable(task?.isBillable)
                                                setIsVariation(
                                                    task?.isVariation
                                                )
                                                setIsOvertime(false)
                                            }
                                        }}
                                        taskOptions={
                                            PhaseCollection.phasesById[
                                                phase?.id
                                            ]?.tasks || []
                                        }
                                        variant="secondary"
                                    />
                                </RenderOnQueries>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="flex justify-around mt-[1em] pt-[1em] border-t border-t-[#d9d9d9]">
                    {showAllFlags || canUseTimeEntryFlag('billable') ? (
                        <Checkbox
                            label="Billable"
                            className="is-billable-checkbox"
                            indeterminate={isBillable === null}
                            value={
                                isBillable == null
                                    ? 'indeterminate'
                                    : isBillable
                            }
                            onChange={setIsBillable}
                            variant="secondary"
                        />
                    ) : null}
                    {showAllFlags || canUseTimeEntryFlag('variation') ? (
                        <Checkbox
                            label="Variation"
                            className="is-variation-checkbox"
                            indeterminate={isVariation === null}
                            value={
                                isVariation == null
                                    ? 'indeterminate'
                                    : isVariation
                            }
                            onChange={setIsVariation}
                            variant="secondary"
                        />
                    ) : null}
                    {showAllFlags || canUseTimeEntryFlag('overtime') ? (
                        <Checkbox
                            label="Overtime"
                            className="is-overtime-checkbox"
                            indeterminate={isOvertime === null}
                            value={
                                isOvertime == null
                                    ? 'indeterminate'
                                    : isOvertime
                            }
                            onChange={setIsOvertime}
                            variant="secondary"
                        />
                    ) : null}
                    {canUseTimeEntryFlag('remote') ? (
                        <Checkbox
                            label="Remote"
                            className="is-overtime-checkbox"
                            indeterminate={isRemote === null}
                            value={
                                isRemote == null ? 'indeterminate' : isRemote
                            }
                            onChange={setIsRemote}
                            variant="secondary"
                        />
                    ) : null}
                    {canUseTimeEntryFlag('flex') ? (
                        <Checkbox
                            label="Flexi"
                            className="is-overtime-checkbox"
                            indeterminate={isFlexi === null}
                            value={isFlexi == null ? 'indeterminate' : isFlexi}
                            onChange={setIsFlexi}
                            variant="secondary"
                        />
                    ) : null}
                    {showAllFlags && canEditStaffTime(SessionStore.user) ? (
                        <>
                            <Checkbox
                                label="Invoiced"
                                className="is-invoiced-checkbox"
                                indeterminate={beenInvoiced === null}
                                value={
                                    beenInvoiced == null
                                        ? 'indeterminate'
                                        : beenInvoiced
                                }
                                onChange={setBeenInvoiced}
                                variant="secondary"
                            />
                            <Checkbox
                                label="Locked"
                                className="is-locked-checkbox"
                                indeterminate={isLocked === null}
                                value={
                                    isLocked == null
                                        ? 'indeterminate'
                                        : isLocked
                                }
                                onChange={setIsLocked}
                                variant="secondary"
                            />
                        </>
                    ) : null}
                </div>
            </Modal>
        )
    }
)
