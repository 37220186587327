import React from 'react'
import { NavigationSidebar } from '@2/layout/navigation-sidebar'
import { TopBar } from '@2/layout/topbar'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Sidebar } from '@2/layout/sidebar'
import { useSidebarStack } from '@2/layout/sidebar'
import { router } from '@/App'
import { useEffect } from 'react'
export const Layout = ({ children }) => {
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    const { clearSidebar } = useSidebarStack()
    useEffect(() => {
        // Subscribe to route changes
        const unsub = router.subscribe('onBeforeNavigate', (e) => {
            if (e.pathChanged) {
                clearSidebar()
            }
        })
        // Cleanup
        return () => {
            unsub?.()
        }
    }, [clearSidebar])

    return (
        <>
            <div className="flex overflow-hidden bg-background h-screen print:overflow-visible">
                <div className="flex-none print:hidden">
                    <NavigationSidebar />
                </div>
                <div className="flex-auto flex flex-col overflow-hidden border-l border-border print:overflow-visible">
                    <div className="flex-none w-full">
                        <TopBar />
                    </div>
                    <div
                        ref={parent}
                        className="flex-auto w-full overflow-hidden flex"
                    >
                        {children}
                        <Sidebar />
                    </div>
                </div>
            </div>
        </>
    )
}
