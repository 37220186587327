import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { useState } from 'react'
import Checkbox from '../../Components/Widgets/Checkbox'
import SessionStore from '../../State/SessionStore'
import { ErrorAlert } from '../../widgets'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from '@tanstack/react-router'
import { usingNewLogin } from '../../State/Permissions/HasPermissions'
import { SignUp } from '@clerk/clerk-react'

export default observer((props) => {
    const navigate = useNavigate()
    const [userAlreadyExists, setUserAlreadyExists] = useState(false)
    const [email, setEmail] = useState(props.email || '')
    const [password, setPassword] = useState('')
    const [acceptedToc, setAcceptedToc] = useState(false)
    const [registerState, setRegisterState] = useState('idle')
    const isUsingNewLogin = usingNewLogin()
    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className="flex items-center justify-center flex-col w-[350px]">
                {!isUsingNewLogin ? (
                    <>
                        <header className="flex items-center justify-center flex-col w-full">
                            <div className="logo2 s75 size-[10em] flex items-center justify-center">
                                <img
                                    src={
                                        new URL(
                                            '/public/coincraft_logo_130.png',
                                            import.meta.url
                                        )
                                    }
                                    alt="Coincraft Logo"
                                    style={{
                                        marginBottom: isUsingNewLogin
                                            ? '4rem'
                                            : '0rem',
                                    }}
                                />
                            </div>
                            <h1 style={{ fontSize: '5em' }}>coincraft</h1>
                        </header>
                        <div className="body w-full">
                            <h4
                                style={{
                                    margin: '0em -2em 1em',
                                }}
                            >
                                Please enter your email address and select a
                                password
                            </h4>
                            <form
                                name="registerForm"
                                className="no-margin"
                                noValidate
                            >
                                <fieldset>
                                    <div className="form-group mb-5">
                                        <div className="input-group input-group-lg">
                                            <input
                                                name="email"
                                                type="email"
                                                required={true}
                                                value={email || ''}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                className="form-control input-lg login-widget__email-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                                placeholder="Your Email"
                                            />
                                        </div>
                                        {registerState === 'error' &&
                                        !email.length ? (
                                            <div className="popover error-message-popover bottom">
                                                <div className="arrow"></div>
                                                <div className="popover-content">
                                                    Please enter your e-mail
                                                    address.
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-lg">
                                            <input
                                                type="password"
                                                name="password"
                                                value={password || ''}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                required={true}
                                                className="form-control input-lg login-widget__email-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                                placeholder="Your Password"
                                            />
                                        </div>
                                        {registerState === 'error' &&
                                        !password.length ? (
                                            <div className="popover error-message-popover bottom">
                                                <div className="arrow"></div>
                                                <div className="popover-content">
                                                    Please enter a password for
                                                    your account.
                                                </div>
                                            </div>
                                        ) : null}

                                        {userAlreadyExists ? (
                                            <ErrorAlert>
                                                A user already exists with the
                                                email address you entered. Have
                                                you{' '}
                                                <Link
                                                    to={`forgotten-password?email=${email}`}
                                                    className="forgot"
                                                >
                                                    forgotten your password?
                                                </Link>
                                            </ErrorAlert>
                                        ) : null}
                                    </div>
                                    <div
                                        className="control-group"
                                        style={{ textAlign: 'left' }}
                                    >
                                        <div className="controls form-group">
                                            <Checkbox
                                                className="login-widget__toc-checkbox"
                                                label={
                                                    <span className="text-[#666]">
                                                        {
                                                            'I agree to the Coincraft '
                                                        }
                                                        <a
                                                            href="http://www.coincraft.co/terms-of-service"
                                                            target="_blank"
                                                        >
                                                            Terms of Service
                                                        </a>
                                                    </span>
                                                }
                                                value={acceptedToc}
                                                onChange={(v) =>
                                                    setAcceptedToc(v)
                                                }
                                            />
                                        </div>
                                        {registerState === 'error' &&
                                        !acceptedToc ? (
                                            <div className="popover error-message-popover bottom">
                                                <div
                                                    className="arrow"
                                                    style={{ left: '10%' }}
                                                ></div>{' '}
                                                {/* Align with the check box -->*/}
                                                <div className="popover-content">
                                                    Sorry, you need to accept
                                                    our Terms of Service before
                                                    we can create your account.
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </fieldset>
                            </form>
                            <div className="form-actions flex flex-col w-full mt-5">
                                <button
                                    onClick={() => {
                                        if (
                                            !email.length ||
                                            !password.length ||
                                            !acceptedToc
                                        ) {
                                            setRegisterState('error')
                                        } else {
                                            setRegisterState('pending')
                                            SessionStore.register(
                                                email,
                                                password
                                            )
                                                .then(() => {
                                                    setRegisterState('success')
                                                    navigate({
                                                        to: '/register-details',
                                                    })
                                                })
                                                .catch(function (error) {
                                                    Sentry.captureException(
                                                        error
                                                    )
                                                    setRegisterState('error')
                                                })
                                        }
                                    }}
                                    className="btn btn-block btn-lg btn-info login-widget__create-account-button py-2.5"
                                    disabled={registerState === 'pending'}
                                >
                                    <i
                                        className={classNames(
                                            'fa fa-fw',
                                            registerState === 'pending'
                                                ? 'fa-spinner fa-spin'
                                                : 'fa-plus'
                                        )}
                                    />{' '}
                                    {registerState === 'pending'
                                        ? 'Creating Account...'
                                        : 'Create a New Account'}
                                </button>
                                <div className="forgot py-4 mt-3.5">
                                    <Link
                                        to={`/login?email=${email}`}
                                        className="forgot underline text-[1.2em] text-[#808080] text-center"
                                    >
                                        Log Into Your Existing Account
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <SignUp signInUrl="/login" forceRedirectUrl={'/sign-up'} />
                )}
            </section>
        </div>
    )
})
