import { createFileRoute, redirect } from '@tanstack/react-router'
import LoginPage from '../../Pages/LoginPage/LoginPage'
import ErrorComponent from '../../Components/ErrorComponent'
import LoadingSpinner from '../../Components/LoadingSpinner'

export const Route = createFileRoute('/_public/login')({
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})

function Wrapper() {
    return (
        <>
            <LoginPage {...Route.useParams()} {...Route.useSearch()} />
        </>
    )
}
