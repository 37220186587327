import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'
import SessionStore, { settingDefaults } from '../../../State/SessionStore'

export const AutoUpdateSchema = z.object({
    action: z.string(),
    adjustOnLoad: z.boolean(),
    budget: z.string(),
    start: z.string(),
    end: z.string(),
})

export const SettingSchema = z.object({
    sortPhasesBy: z.string(),
    allowNoPhase: z.boolean(),
    useTasks: z.boolean(),
    allowAfterPhaseEnd: z.boolean(),
    timeEntryAllocations: z.array(z.string()),
    timeEntryStatus: z.array(z.string()),
    timeEntryFlags: z.array(z.string()),
    autoPopulate: z.array(z.string()),
    updateHoursFromRevenue: z.boolean(),
    updateRevenueFromHours: z.boolean(),
    autoUpdateRevenue: AutoUpdateSchema,
    autoUpdateHours: AutoUpdateSchema,
    reportInvoiceDateType: z.string(),
    savingInvoices: z.array(z.string()),
})

export type SettingData = z.infer<typeof SettingSchema>
export type AutoUpdateData = z.infer<typeof AutoUpdateSchema>

type SettingsContext = {
    settings: SettingData
}

const initialContext: SettingsContext = {
    settings: {
        ...settingDefaults,
    },
}

export const settingsStore = createQueryStore<
    SettingsContext,
    { settings: SettingData }
>(
    {
        context: initialContext,
        on: {
            saveSettingData: {
                settings: (_, event: { data: SettingData }) => event.data,
            },
            changeSettings: {
                settings: (
                    context,
                    event: { data: { value: any; key: keyof SettingData } }
                ) => ({
                    ...context.settings,
                    [event.data.key]: event.data.value,
                }),
            },
        },
    },
    {
        queryKey: ['settings'],
        queryFn: async () => {
            const result = await trpc.setting.getSettings.query()
            return {
                settings: result.settings,
            }
        },
        mutationFn: (data) =>
            trpc.setting.saveSettings.mutate({
                settings: data.settings,
            }),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'settings-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to settings?',
        loadingMessage: 'Saving settings...',
        successMessage: 'Settings saved successfully',
        errorMessage: 'Failed to save settings',
    }
)

// Export everything from the store
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = settingsStore

// Action creators
export const settingActions = {
    saveSettingData: (data: SettingData) =>
        settingsStore.store.send({ type: 'saveSettingData', data }),

    changeSettings: <K extends keyof SettingData>(
        value: SettingData[K],
        key: K
    ) => {
        const data = { value, key }
        settingsStore.store.send({ type: 'changeSettings', data })
    },
}

// Custom selectors
export const useSetting = () =>
    useSelector(settingsStore.store, (state) => state.context.settings)

// Non-reactive getter
export const getSettings = () => settingsStore.getState().context.settings
