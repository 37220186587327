import { useMemo } from 'react'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import {
    useData,
    useDateRange,
    useOverheadExpenses,
    useStaffExpenses,
} from './revenue-forecast-store'
import { format, eachMonthOfInterval, parse } from '@2/utils/date-fns'
import { FormatCurrency } from '@/Utils/Localisation/CurrencyFormatter'
import { Row, ColumnDef, Column } from '@tanstack/react-table'
import { sortingDateFn, sortingTextFn } from '@/version2/types'
import { cn } from '@/version2/lib/utils'

interface TableRow {
    label: string
    total: number
    [key: string]: any
}

export const RevenueForecastProfitTable = () => {
    const generateColumnDef = createGenerateColumnDef<TableRow>({
        onHideColumn: () => null,
    })

    const storeData = useData()
    const dateRange = useDateRange()
    const overheadExpenses = useOverheadExpenses()
    const staffExpenses = useStaffExpenses()

    const months = useMemo(
        () =>
            dateRange[0] && dateRange[1]
                ? eachMonthOfInterval({
                      start: parse(dateRange[0], 'yyyy-MM-dd', new Date()),
                      end: parse(dateRange[1], 'yyyy-MM-dd', new Date()),
                  })
                : [],
        [dateRange]
    )

    const calculateMonthlyRevenue = (month: Date) => {
        const monthKey = format(month, 'yyyy-MM')
        let total = 0
        storeData.forEach((statusGroup) => {
            const projected = statusGroup[`${monthKey}-P`] || 0
            const actual = statusGroup[`${monthKey}-A`] || 0
            const currentMonthKey = format(new Date(), 'yyyy-MM')
            if (monthKey < currentMonthKey) {
                total += actual
            } else {
                total += projected
            }
        })
        return total
    }

    const calculateMonthlyExpenses = (month: Date) => {
        const monthKey = format(month, 'yyyy-MM')
        let overhead = 0
        let staff = 0

        if (Array.isArray(overheadExpenses)) {
            overhead = overheadExpenses.reduce((sum, expense) => {
                const value = expense[monthKey]
                const numValue =
                    typeof value === 'string'
                        ? parseFloat(value)
                        : (value as number)
                return sum + (numValue || 0)
            }, 0)
        }

        if (Array.isArray(staffExpenses)) {
            staff = staffExpenses.reduce((sum, expense) => {
                const value = expense[monthKey]
                const numValue =
                    typeof value === 'string'
                        ? parseFloat(value)
                        : (value as number)
                return sum + (numValue || 0)
            }, 0)
        }

        return overhead + staff
    }

    const data = useMemo(() => {
        const rows: TableRow[] = [
            {
                label: 'Profit',
                total: 0,
            },
            {
                label: 'Profit Margin',
                total: 0,
            },
        ]

        let totalRevenue = 0
        let totalExpenses = 0

        months.forEach((month) => {
            const monthKey = format(month, 'yyyy-MM')
            const revenue = calculateMonthlyRevenue(month)
            const expenses = calculateMonthlyExpenses(month)
            const profit = revenue - expenses
            const profitMargin = revenue > 0 ? (profit / revenue) * 100 : 0

            totalRevenue += revenue
            totalExpenses += expenses

            rows[0][monthKey] = profit
            rows[1][monthKey] = profitMargin
        })

        const totalProfit = totalRevenue - totalExpenses
        const totalProfitMargin =
            totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0

        rows[0].total = totalProfit
        rows[1].total = totalProfitMargin

        return rows
    }, [months, storeData, overheadExpenses, staffExpenses])

    const columns = useMemo<ColumnDef<TableRow, any>[]>(
        () => [
            generateColumnDef({
                key: 'label',
                title: '',
                type: 'string',
                size: 190,
            }),
            generateColumnDef({
                key: 'total',
                title: 'Total',
                type: 'string',
                size: 120,
                customValue: (row) => {
                    if (row.label === 'Profit') {
                        return FormatCurrency(row.total, {
                            decimals: 0,
                        })
                    }
                    return `${row.total.toFixed(1)}%`
                },
                cellClassName: (row) => {
                    if (!row || !row.original.total) return ''
                    const total = row.original.total
                    if (total < 0) return 'text-red-500'
                    return ''
                },
            }),
            ...months.map((month) =>
                generateColumnDef({
                    key: format(month, 'yyyy-MM'),
                    title: format(month, 'MMM yy'),
                    type: 'string',
                    size: 120,
                    customValue: (row) => {
                        const monthKey = format(month, 'yyyy-MM')
                        const monthValue = row[monthKey]
                        if (row.label === 'Profit') {
                            return FormatCurrency(monthValue, { decimals: 0 })
                        }
                        return `${monthValue.toFixed(1)}%`
                    },
                    cellClassName: (row) => {
                        const monthKey = format(month, 'yyyy-MM')
                        if (!row || !row.original?.[monthKey]) return ''
                        const value = row.original[monthKey]
                        const isPastMonth =
                            format(month, 'yyyy-MM') <
                            format(new Date(), 'yyyy-MM')
                        return cn(
                            isPastMonth && 'bg-diagonal-pattern',
                            value < 0 && 'text-red-500'
                        )
                    },
                })
            ),
        ],
        [months, generateColumnDef]
    )

    const visibleColumns = useMemo(
        () => columns.map((col) => (col as any).accessorKey || col.id),
        [columns]
    )

    const { table } = useDataTable({
        data,
        columns,
        visibleColumns,
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
    })

    return (
        <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4 pl-4">Profit</h2>
            <DataTable table={table} showTotals={false} className="w-full" />
        </div>
    )
}
