import { FormLabel, FormRow, FormSection } from '../../../forms'
import LayoutStore from '../../../State/LayoutStore'
// import InviteStaffModal from './InviteStaffModal'
import {
    canEditStaff,
    canViewCostCentres,
    canViewRoles,
    usingNewLogin,
} from '../../../State/Permissions/HasPermissions'
import { Label } from '../../../Components/ui/label'
import { RadioGroup, RadioGroupItem } from '../../../Components/ui/radio-group'
// import InviteStaffMemberButton from './InviteStaffMemberButton'
import DataStore from '../../../State/DataStore'
import { staffActions, useStaffRoles, useStaffMember } from './staff-page-store'
import SessionStore from '../../../State/SessionStore'
import { Input } from '@/version2/components/ui/input'
import { Checkbox, CheckboxWithLabel } from '@/version2/components/ui/checkbox'
import InviteStaffMemberButton from '../../../Pages/StaffPage/InviteStaffMemberButton'
import { SelectWithLabel } from '@/version2/components/ui/select'
import GenericCombobox from '@/version2/components/ui/combobox'
import { generateFilterOptions } from '@/version2/components/data-table/helpers/filters'
import { CostCentreSelector } from '@/version2/components/selector'

export function StaffDetailTab() {
    const labelWidth = '10em'
    const staffMember = useStaffMember()
    const roles = useStaffRoles()
    const isEditable = canEditStaff(SessionStore.user)
    return (
        <div className="form-horizontal">
            <FormSection>
                <FormRow className="flex">
                    <FormLabel style={{ width: labelWidth }}>
                        First name:
                    </FormLabel>
                    <Input
                        className="staff-member-component__first-name-input input-base ml-[5em]"
                        type="text"
                        value={staffMember?.firstName || ''}
                        disabled={!isEditable}
                        onChange={(e) =>
                            staffActions.changeStaffData(
                                'firstName',
                                e.target.value
                            )
                        }
                    />
                </FormRow>

                <FormRow className="flex">
                    <FormLabel style={{ width: labelWidth }}>
                        Last name:
                    </FormLabel>
                    <Input
                        className="staff-member-component__last-name-input input-base ml-[5em]"
                        type="text"
                        value={staffMember.lastName || ''}
                        disabled={!isEditable}
                        onChange={(e) =>
                            staffActions.changeStaffData(
                                'lastName',
                                e.target.value
                            )
                        }
                    />
                </FormRow>

                <FormRow>
                    <CheckboxWithLabel
                        className="staff-member-component__has-login-input"
                        checked={staffMember.hasLogin}
                        label="Provide Staff Member with Login?"
                        disabled={!isEditable}
                        onCheckedChange={(v) =>
                            staffActions.changeStaffData('hasLogin', v)
                        }
                        id="has-login"
                    />
                </FormRow>

                <FormRow
                    style={{
                        opacity: staffMember.hasLogin ? 1 : 0.5,
                    }}
                    className="flex items-center"
                >
                    <FormLabel style={{ width: labelWidth }}>Email:</FormLabel>
                    <Input
                        className="staff-member-component__email-input input-base ml-[5em]"
                        type="email"
                        value={staffMember.email || ''}
                        disabled={!isEditable || !staffMember.hasLogin}
                        onChange={(e) =>
                            staffActions.changeStaffData(
                                'email',
                                e.target.value
                            )
                        }
                    />
                    {isEditable &&
                    staffMember.hasLogin &&
                    staffMember.email &&
                    !staffMember.isArchived ? (
                        <>
                            {/* {usingNewLogin() ? ( */}
                            <InviteStaffMemberButton staff={staffMember} />
                            {/* ) : (
                                    <button
                                        className="btn btn-sm btn-default"
                                        style={{ marginLeft: '1em' }}
                                        onClick={async () => {
                                            await DataStore.startSave()
                                            LayoutStore.openModal(
                                                <InviteStaffModal
                                                    modalId={'invite-staff'}
                                                    staff={staff}
                                                />
                                            )
                                        }}
                                    >
                                        Get invite...
                                    </button>
                                )} */}
                        </>
                    ) : null}
                </FormRow>

                <FormRow>
                    <FormLabel style={{ width: labelWidth }}>
                        Staff type:
                    </FormLabel>
                    <RadioGroup
                        className="text-base flex gap-8 ml-6"
                        defaultValue="employee"
                        onValueChange={(v) =>
                            staffActions.changeStaffData('staffType', v)
                        }
                        value={staffMember.staffType}
                    >
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="employee" id="employee" />
                            <Label htmlFor="employee">Employee</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="contactor" id="contactor" />
                            <Label htmlFor="contactor">Contactor</Label>
                        </div>
                    </RadioGroup>
                </FormRow>

                {canViewRoles(SessionStore.user) ? (
                    <FormSection className="flex flex-col">
                        <FormLabel style={{ width: labelWidth }}>
                            Staff Role:
                        </FormLabel>
                        <GenericCombobox
                            options={roles.map((role) => ({
                                ...role,
                                value: role.id,
                                label: role.name,
                            }))}
                            value={staffMember.roleId}
                            editable={isEditable}
                            placeholder="Select role..."
                            onChange={(value) =>
                                staffActions.changeStaffData('roleId', value)
                            }
                        />
                    </FormSection>
                ) : null}

                <FormRow
                    style={{
                        maxWidth: 'max-content',
                    }}
                >
                    <FormLabel style={{ width: labelWidth }}>Status:</FormLabel>
                    <SelectWithLabel
                        options={[
                            {
                                value: 'false',
                                label: 'Active',
                                key: 'active',
                            },
                            {
                                value: 'true',
                                label: 'Archived',
                                key: 'archived',
                            },
                        ]}
                        editable={isEditable}
                        value={staffMember?.isArchived ? 'true' : 'false'}
                        onValueChange={(v) => {
                            staffActions.changeStaffData(
                                'isArchived',
                                v === 'true'
                            )
                        }}
                    />
                </FormRow>

                {canViewCostCentres(SessionStore.user) ? (
                    <FormRow
                        style={{
                            maxWidth: 'max-content',
                        }}
                        className="flex flex-col"
                    >
                        <FormLabel style={{ width: labelWidth }}>
                            Cost Centre:
                        </FormLabel>
                        {/* <GenericCombobox
                            options={
                                generateFilterOptions('costCentre').options
                            }
                            editable={isEditable}
                            placeholder="Select role..."
                            value={staffMember.costCentreId}
                            onChange={(value) =>
                                staffActions.changeStaffData(
                                    'costCentreId',
                                    value
                                )
                            }
                        /> */}
                        <CostCentreSelector
                            value={staffMember.costCentreId}
                            onChange={(value) =>
                                staffActions.changeStaffData(
                                    'costCentreId',
                                    value
                                )
                            }
                            disabled={!isEditable}
                        />
                    </FormRow>
                ) : null}
            </FormSection>
        </div>
    )
}
