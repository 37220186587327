import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import SessionStore from '../../State/SessionStore'
import { ErrorAlert } from '../../widgets'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from '@tanstack/react-router'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import { usingNewLogin } from '../../State/Permissions/HasPermissions'
import { SignIn } from '@clerk/clerk-react'

export default observer((props) => {
    const navigate = useNavigate()
    const [loginState, setLoginState] = useState('idle')
    const [email, setEmail] = useState(props.email || '')
    const [password, setPassword] = useState('')
    const isUsingNewLogin = usingNewLogin()

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoginState('pending')
        try {
            await SessionStore.clearCache()
            await SessionStore.login({ email, password })
            setLoginState('success')
            navigate({
                to: canViewRoute(SessionStore.user, 'projectList')
                    ? '/projects'
                    : '/timesheets',
            })
        } catch (error) {
            Sentry.captureException(error)
            setLoginState('error')
        }
    }

    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className="flex items-center justify-center flex-col w-[350px]">
                {!isUsingNewLogin ? (
                    <>
                        <header className="flex items-center justify-center flex-col w-full">
                            <div className="logo2 s75 size-[10em] flex items-center justify-center">
                                <img
                                    src={
                                        new URL(
                                            '/public/coincraft_logo_130.png',
                                            import.meta.url
                                        )
                                    }
                                    alt="Coincraft Logo"
                                />
                            </div>
                            <h1 style={{ fontSize: '5em' }}>coincraft</h1>
                        </header>
                        <div className="body w-full">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-5">
                                    <div className="input-group input-group-lg">
                                        <input
                                            value={email || ''}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            type="email"
                                            className="form-control input-lg login-widget__email-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                            placeholder="Your Email"
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-5">
                                    <div className="input-group input-group-lg">
                                        <input
                                            type="password"
                                            value={password || ''}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            className="form-control input-lg login-widget__password-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                            placeholder="Your Password"
                                        />
                                    </div>
                                </div>
                                <div className="form-actions flex flex-col w-full mt-5">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-lg btn-primary w-full flex justify-center py-2.5 mt-1.5"
                                        disabled={loginState === 'pending'}
                                    >
                                        <i
                                            className={classNames(
                                                'fa fa-fw mr-2.5 w-5',
                                                loginState === 'pending'
                                                    ? 'fa-spinner fa-spin'
                                                    : 'fa-user'
                                            )}
                                        />{' '}
                                        Sign In
                                    </button>
                                </div>
                            </form>
                            {loginState === 'error' && (
                                <ErrorAlert>
                                    {`Sorry, we couldn't log you in with those details. Please try again.`}
                                </ErrorAlert>
                            )}
                            <div className="form-actions">
                                <button
                                    style={{ marginTop: '0.5em' }}
                                    onClick={() =>
                                        navigate({
                                            to: `/register?email=${email}`,
                                        })
                                    }
                                    className="btn btn-block btn-lg btn-info login-widget__to-register w-full flex justify-center mt-1.5 py-2.5"
                                >
                                    <i
                                        className={
                                            'fa fa-fw fa-plus mr-2.5 w-5'
                                        }
                                    />{' '}
                                    Create a New Account
                                </button>
                                <div className="forgot py-4 mt-3.5">
                                    <Link
                                        to={`/forgotten-password?email=${email}`}
                                        className="forgot underline  text-[1.2em] text-[#808080] text-center"
                                    >
                                        Forgotten Your Password?
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <SignIn
                        signUpUrl={'/register'}
                        forceRedirectUrl={'/sign-in'}
                    />
                )}
            </section>
        </div>
    )
})
