export const getLabel = {
    projects: (project) =>
        project.jobNumber
            ? `${project.jobNumber}: ${project.name}`
            : project.name || 'No Name',
    phases: (phase) =>
        phase.jobNumber
            ? `${phase.jobNumber}: ${phase.name}`
            : phase.name || 'No Name',
    tasks: (task) => task.name || 'No Name',
    contacts: (contact) =>
        contact.organisationName && contact.firstName && contact.lastName
            ? `${contact.firstName} ${contact.lastName} (${contact.organisationName})`
            : contact.firstName && contact.lastName
              ? `${contact.firstName} ${contact.lastName}`
              : contact.organisationName
                ? contact.organisationName
                : 'No Name',
    invoices: (invoice) => invoice.ref || 'No Name',
    organisations: (organisation) => organisation.name || 'No Name',
    suppliers: (supplier) => supplier?.name || 'No Name',
    roles: (role) => role.name || 'No Name',
    costCentres: (costCentre) => costCentre.name || 'No Name',
    permission: (permission) => permission.name || 'No Name',
    organisationReports: (report) => report.name || 'No Name',
    resourceScheduleReports: (report) => report.name || 'No Name',
    revenueForecastReports: (report) => report.name || 'No Name',
    staff: (staffMember) =>
        `${staffMember.firstName} ${staffMember.lastName}` || 'No Name',
    organisationSubscriptions: () => null || 'No Name',
}
