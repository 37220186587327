import cuid from 'cuid'
import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import ContactCollection from '../Collections/ContactCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import InvoiceLineItemCollection from '../Collections/InvoiceLineItemCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from './Model'
import PhaseCollection from '../Collections/PhaseCollection'
import sortPhases from '../../Utils/sortPhases'

class InvoiceModel extends Model {
    oldId = null
    @observable ref = null

    @observable dueDate = null
    @observable startDate = null
    @observable endDate = null
    @observable issueDate = null

    @observable projectId = null
    @observable contactId = null

    @observable taxRatePercent = null
    @observable accountingSystemId = null
    @observable accountingSystemInvoiceId = null
    @observable cachedData = null

    @observable projectStatus = null

    @observable totalAmount = 0
    @observable totalTax = 0
    @observable agreedAmount = 0
    @observable agreedTax = 0
    @observable variationAmount = 0
    @observable variationTax = 0
    @observable reimbursementAmount = 0
    @observable reimbursementTax = 0

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = InvoiceCollection
        this.init(data, options)
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get phases() {
        const phases = this.phaseIds
            .map(
                (id) =>
                    PhaseCollection.phasesById[id] ||
                    this.cachedData.phases[id] ||
                    this.project.rootPhase
            )
            .sort(sortPhases)
        return [...new Set(phases)]
    }

    @computed
    get phaseIds() {
        return Object.keys(this.lineItemsByPhaseId)
    }

    @computed
    get expenses() {
        return this.project.expenses
    }

    @computed
    get contact() {
        return ContactCollection.contactsById[this.contactId]
    }

    @computed
    get lineItems() {
        return InvoiceLineItemCollection.lineItemsByInvoiceId[this.id] || []
    }

    @computed
    get timeEntries() {
        return this.lineItems.map((li) => li.timeEntries).flat() || []
    }

    @computed
    get lineItemsByPhaseId() {
        let lineItemsByPhaseId = { [this.project.rootPhase.id]: [] }
        this.project.phases.forEach((ph) => {
            lineItemsByPhaseId[ph.id] = []
        })
        this.lineItems.forEach((li) => {
            const id = Boolean(
                PhaseCollection.phasesById[li.phaseId] ||
                    this.cachedData.phases[li.phaseId]
            )
                ? li.phaseId
                : this.project.rootPhase.id
            if (!lineItemsByPhaseId[id]) lineItemsByPhaseId[id] = []
            lineItemsByPhaseId[id].push(li)
        })
        return lineItemsByPhaseId
    }

    @computed
    get lineItemsByExpenseId() {
        let lineItemsByExpenseId = {}
        this.expenses.forEach((ex) => {
            lineItemsByExpenseId[ex.id] = []
        })
        this.lineItems.forEach((li) =>
            lineItemsByExpenseId[li.expenseId].push(li)
        )
        return lineItemsByExpenseId
    }

    @computed
    get amount() {
        return _.sum(this.lineItems.map((li) => li.amount))
    }

    @computed
    get tax() {
        return _.sum(this.lineItems.map((li) => li.tax))
    }

    @computed
    get phasesWithRevenue() {
        return [
            ...new Set(
                this.lineItems.filter((li) => li.amount).map((li) => li.phase)
            ),
        ]
    }

    @action.bound
    updateCachedData() {
        this.update({
            cachedData: {
                project: {
                    name: this.project.title,
                    jobNumber: this.project.jobNumber,
                },
                phases: Object.fromEntries(
                    this.phases.map((ph) => [
                        ph.id,
                        {
                            name: ph.title,
                            jobNumber: ph.jobNumber,
                            fee: ph.fee,
                            //loaded on InvoicePage
                            previousBilled: ph.previousBilled || 0,
                        },
                    ])
                ),
                expenses: Object.fromEntries(
                    this.expenses.map((e) => [
                        e.id,
                        {
                            name: e.name,
                            cost: e.cost,
                            //loaded on InvoicePage
                            previousBilled: e.previousBilled || 0,
                        },
                    ])
                ),
            },
        })
        this.lineItems.forEach((li) => {
            if (
                li.lineItemType === 'progress' &&
                li.billingType === 'agreedFee'
            ) {
                li.update({
                    unitQuantity: li.phase.fee
                        ? li.amount / (li.phase.fee || 0)
                        : 0,
                    unitCost: li.phase.fee || 0,
                })
            }
        })
    }
}

export default InvoiceModel
