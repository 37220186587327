import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'
import { v4 as uuid } from 'uuid'

// Utility functions for parsing
const parseNumberOrUndefined = (
    value: string | number | undefined
): number | undefined => {
    if (value === undefined) return undefined
    if (typeof value === 'number') return value
    const parsed = parseFloat(value)
    return isNaN(parsed) ? undefined : parsed
}

const parseDateOrUndefined = (
    value: string | Date | undefined
): Date | string | undefined => {
    if (value === undefined) return undefined
    if (value instanceof Date) return value
    if (typeof value === 'string') return value // Keep string dates as-is
    const parsed = new Date(value)
    return isNaN(parsed.getTime()) ? undefined : parsed
}

// Schema Definitions
export const StaffRateSchema = z.object({
    id: z.string(),
    organisationId: z.string().optional(),
    staffId: z.string().optional(),
    date: z
        .union([z.coerce.date(), z.string(), z.undefined()])
        .transform(parseDateOrUndefined),
    payRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    overtimeRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    costRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    chargeOutRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    weeklyAvailability: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    deletedAt: z
        .union([z.coerce.date(), z.string(), z.undefined()])
        .transform(parseDateOrUndefined),
})

export const RoleRateSchema = z.object({
    id: z.string(),
    organisationId: z.string().optional(),
    roleId: z.string().optional(),
    date: z
        .union([z.date(), z.string(), z.undefined()])
        .transform(parseDateOrUndefined),
    payRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    overtimeRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    costRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
    chargeOutRate: z
        .union([z.number(), z.string(), z.undefined()])
        .transform(parseNumberOrUndefined),
})

export const RoleSchema = z.object({
    id: z.string(),
    organisationId: z.string().optional(),
    name: z.string(),
    isArchived: z.boolean().optional(),
    rates: z.array(RoleRateSchema).optional(),
    avgPayRate: z.boolean().optional().default(false),
    avgOvertimeRate: z.boolean().optional().default(false),
    avgCostRate: z.boolean().optional().default(false),
    avgChargeOutRate: z.boolean().optional().default(false),
})

export const StaffSchema = z.object({
    id: z.string(),
    organisationId: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    staffType: z.string().optional(),
    hasLogin: z.boolean().optional(),
    isArchived: z.boolean().optional(),
    costCentreId: z.string().optional(),
    roleId: z.string().optional().nullable(),
    billingLevelOverride: z.string().optional().nullable(),
    permissionsId: z.string().optional().nullable(),
    permissions: z.any().optional().nullable(),
    inheritPayRate: z.boolean().optional(),
    inheritOvertimeRate: z.boolean().optional(),
    inheritCostRate: z.boolean().optional(),
    inheritChargeOutRate: z.boolean().optional(),
    rates: z.array(StaffRateSchema).optional(),
    role: RoleSchema.optional(),
    clerkId: z.string().optional().nullable(),
    clerkOrgId: z.string().optional().nullable(),
})

// Permission Schemas
export const ProjectPermissionsSchema = z
    .object({
        projectPermissions: z.string().optional(),
        projectFees: z.string().optional(),
        projectExpenseBudgets: z.string().optional(),
        projectHoursBudgets: z.string().optional(),
        projectStaffBudgets: z.string().optional(),
        projectDates: z.string().optional(),
        projectPhasesTasks: z.string().optional(),
        projectExpenses: z.string().optional(),
        projectForecastedRevenue: z.string().optional(),
        projectForecastedHours: z.string().optional(),
        projectInvoices: z.string().optional(),
        projectRates: z.string().optional(),
        projectNotes: z.string().optional(),
        projectTimesheets: z.boolean().optional(),
    })
    .optional()

export const FilterSchema = z
    .object({
        type: z.string().optional(),
        values: z.array(z.string()).optional(),
    })
    .optional()

export const ProjectSchema = z
    .object({
        filter: FilterSchema.optional(),
        permissions: ProjectPermissionsSchema.optional(),
        id: z.string().optional(),
    })
    .optional()

export const PermissionsSchema = z
    .object({
        canViewRevenueForecast: z.boolean().optional(),
        canViewStaffExpenses: z.boolean().optional(),
        canViewOperationalExpenses: z.boolean().optional(),
        canViewResourceSchedule: z.boolean().optional(),
        canViewWeeklyPlanner: z.boolean().optional(),
        projects: z.array(ProjectSchema).optional(),
        staffPermissions: z.string().optional(),
        rolePermissions: z.string().optional(),
        editStaffTime: z.boolean().optional(),
        payRate: z.string().optional(),
        overtimeRate: z.string().optional(),
        costRate: z.string().optional(),
        chargeOutRate: z.string().optional(),
        contacts: z.string().optional(),
        costCentres: z.string().optional(),
        operationalExpenses: z.string().optional(),
        holidays: z.string().optional(),
        invoiceSettings: z.string().optional(),
        organisationSettings: z.string().optional(),
        billing: z.string().optional(),
    })
    .optional()
    .nullable()
// Store Types
interface StaffData {
    staffMember: z.infer<typeof StaffSchema> | null
    roles: z.infer<typeof RoleSchema>[]
    staffRates: z.infer<typeof StaffRateSchema>[]
    permissions: z.infer<typeof PermissionsSchema> | null
}

const DEFAULT_RATE: Partial<z.infer<typeof StaffRateSchema>> = {
    date: new Date(),
    payRate: 0,
    overtimeRate: 0,
    costRate: 0,
    chargeOutRate: 0,
}

// Store Creation
export const staffStore = createQueryStore<StaffData, StaffData>(
    {
        context: {
            staffMember: null,
            roles: [],
            staffRates: [],
            permissions: null,
        },
        on: {
            setData: {
                staffMember: (_, { data }: { data: StaffData }) =>
                    data.staffMember,
                roles: (_, { data }: { data: StaffData }) => data.roles,
                staffRates: (_, { data }: { data: StaffData }) =>
                    data.staffRates,
                permissions: (_, { data }: { data: StaffData }) =>
                    data.permissions,
            },
            changeStaffData: {
                staffMember: (
                    context,
                    {
                        key,
                        value,
                    }: { key: keyof z.infer<typeof StaffSchema>; value: any }
                ) =>
                    context.staffMember
                        ? { ...context.staffMember, [key]: value }
                        : null,
            },
            addNewRates: {
                staffRates: (
                    context,
                    {
                        initial,
                    }: { initial: Partial<z.infer<typeof StaffRateSchema>> }
                ) => [
                    ...context.staffRates,
                    {
                        ...DEFAULT_RATE,
                        id: uuid(),
                        organisationId: context.staffMember?.organisationId,
                        staffId: context.staffMember?.id,
                        roleId: context.staffMember?.roleId,
                        ...initial,
                    } as z.infer<typeof StaffRateSchema>,
                ],
            },
            changeRateData: {
                staffRates: (
                    context,
                    {
                        id,
                        key,
                        value,
                    }: {
                        id: string
                        key: keyof z.infer<typeof StaffRateSchema>
                        value: any
                    }
                ) =>
                    context.staffRates.map((rate) =>
                        rate.id === id ? { ...rate, [key]: value } : rate
                    ),
            },
            changePermissions: {
                permissions: (
                    _,
                    {
                        permissions,
                    }: { permissions: z.infer<typeof PermissionsSchema> }
                ) => permissions,
            },
            deleteRate: {
                staffRates: (context, { id }: { id: string }) => {
                    const index = context.staffRates.findIndex(
                        (rate) => rate.id === id
                    )
                    if (index === -1) return context.staffRates

                    const updatedRates = [...context.staffRates]
                    updatedRates[index] = {
                        ...updatedRates[index],
                        deletedAt: new Date(),
                    }
                    return updatedRates
                },
            },
        },
    },
    {
        queryKey: ['staff-member'],
        queryFn: async ({ id }) => {
            const result = await trpc.staff.getStaffMember.query({ id })
            return {
                staffMember: result.staffMember
                    ? StaffSchema.parse(result.staffMember)
                    : null,
                roles: result.roles.map((role) => RoleSchema.parse(role)),
                staffRates: result.staffRates.map((rate) =>
                    StaffRateSchema.parse(rate)
                ),
                permissions: result.permission
                    ? PermissionsSchema.parse(result.permission)
                    : null,
            }
        },
        mutationFn: async (data) => {
            const result = await trpc.staff.updateStaffMember.mutate({
                ...data.staffMember,
                rates: data.staffRates,
                permissions: data.staffMember.permissionsId
                    ? data.staffMember.permissions
                    : data.permissions,
            })
            return result
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'staff-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to staff member?',
        loadingMessage: 'Saving staff member...',
        successMessage: 'Staff member saved successfully',
        errorMessage: 'Failed to save staff member',
    }
)

// Selectors
export const useStaffMember = () =>
    useSelector(staffStore.store, (state) => state.context.staffMember)
export const useStaffRoles = () =>
    useSelector(staffStore.store, (state) => state.context.roles)
export const useStaffRates = () =>
    useSelector(staffStore.store, (state) => state.context.staffRates)
export const useStaffPermissions = () =>
    useSelector(staffStore.store, (state) => state.context.permissions)
export const useStaffRole = () =>
    useSelector(staffStore.store, (state) => {
        const { roles, staffMember } = state.context
        return roles.find((role) => role.id === staffMember?.roleId)
    })

// Actions
export const staffActions = {
    setData: (data: StaffData) =>
        staffStore.store.send({ type: 'setData', data }),

    changeStaffData: <K extends keyof z.infer<typeof StaffSchema>>(
        key: K,
        value: z.infer<typeof StaffSchema>[K]
    ) => staffStore.store.send({ type: 'changeStaffData', key, value }),

    addNewRates: (initial?: Partial<z.infer<typeof StaffRateSchema>>) =>
        staffStore.store.send({ type: 'addNewRates', initial }),

    changeRateData: <K extends keyof z.infer<typeof StaffRateSchema>>(
        id: string,
        key: K,
        value: z.infer<typeof StaffRateSchema>[K]
    ) => staffStore.store.send({ type: 'changeRateData', id, key, value }),

    deleteRate: (id: string) =>
        staffStore.store.send({ type: 'deleteRate', id }),

    changePermissions: (permissions: z.infer<typeof PermissionsSchema>) =>
        staffStore.store.send({ type: 'changePermissions', permissions }),
}

// Non-reactive getters
export const getStaffMember = () =>
    staffStore.store.getSnapshot().context.staffMember
export const getStaffRoles = () => staffStore.store.getSnapshot().context.roles
export const getStaffRates = () =>
    staffStore.store.getSnapshot().context.staffRates
export const getStaffPermissions = () =>
    staffStore.store.getSnapshot().context.permissions

// Export store utilities
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = staffStore
