import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'
import { v4 as uuid } from 'uuid'
import { StaffRateInterface } from '../staff-page/staff.interface'

// Schema Definitions
export const RoleSchema = z.object({
    id: z.string(),
    name: z.string(),
    avgPayRate: z.coerce.boolean(),
    avgOvertimeRate: z.coerce.boolean(),
    avgCostRate: z.coerce.boolean(),
    avgChargeOutRate: z.coerce.boolean(),
    isArchived: z.coerce.boolean(),
})

export const RoleRatesSchema = z.object({
    id: z.string().optional(),
    roleId: z.string().optional(),
    date: z.coerce.date(),
    payRate: z.coerce.number().nullable(),
    overtimeRate: z.coerce.number().nullable(),
    costRate: z.coerce.number().nullable(),
    chargeOutRate: z.coerce.number().nullable(),
    deletedAt: z.coerce.date().nullable().optional(),
})

export type Role = z.infer<typeof RoleSchema>
export type RoleRates = z.infer<typeof RoleRatesSchema>

// Store Types
interface StaffRoleData {
    role: Role | null
    roleRates: RoleRates[]
    staffRates: RoleRates[]
    staffs: StaffRateInterface.StaffData[]
}

const DEFAULT_ROLE_RATE: RoleRates = {
    date: new Date(),
    payRate: 0,
    overtimeRate: 0,
    costRate: 0,
    chargeOutRate: 0,
}

// Store Creation
export const staffRoleStore = createQueryStore<StaffRoleData, StaffRoleData>(
    {
        context: {
            role: null,
            roleRates: [],
            staffRates: [],
            staffs: [],
        },
        on: {
            saveStaffRoleData: {
                role: (_, { data }: { data: StaffRoleData }) => data.role,
                roleRates: (_, { data }: { data: StaffRoleData }) =>
                    data.roleRates.map((rate) => ({
                        ...rate,
                        date: new Date(rate.date),
                    })),
                staffRates: (_, { data }: { data: StaffRoleData }) =>
                    data.staffRates.map((rate) => ({
                        ...rate,
                        date: new Date(rate.date),
                    })),
                staffs: (_, { data }: { data: StaffRoleData }) => data.staffs,
            },
            changeRoleData: {
                role: (
                    context,
                    { key, value }: { key: keyof Role; value: any }
                ) => (context.role ? { ...context.role, [key]: value } : null),
            },
            changeRoleRateData: {
                roleRates: (
                    context,
                    {
                        id,
                        key,
                        value,
                    }: { id: string; key: keyof RoleRates; value: any }
                ) => {
                    const index = context.roleRates.findIndex(
                        (rate) => rate.id === id
                    )
                    if (index === -1) return context.roleRates

                    const updatedRates = [...context.roleRates]
                    updatedRates[index] = {
                        ...updatedRates[index],
                        [key]: value,
                    }
                    return updatedRates
                },
            },
            addNewRate: {
                roleRates: (context, { initial = DEFAULT_ROLE_RATE }) => [
                    ...context.roleRates,
                    {
                        ...initial,
                        id: uuid(),
                        roleId: context.role?.id,
                    },
                ],
            },
            deleteRate: {
                roleRates: (context, { id }: { id: string }) => {
                    const index = context.roleRates.findIndex(
                        (rate) => rate.id === id
                    )
                    if (index === -1) return context.roleRates

                    const updatedRates = [...context.roleRates]
                    updatedRates[index] = {
                        ...updatedRates[index],
                        deletedAt: new Date(),
                    }
                    return updatedRates
                },
            },
        },
    },
    {
        queryKey: ['staff-role'],
        queryFn: async ({ id }) => {
            const result = await trpc.role.getRole.query({ id })
            return {
                role: result.role ? RoleSchema.parse(result.role) : null,
                roleRates: result.roleRates.map((rate) =>
                    RoleRatesSchema.parse(rate)
                ),
                staffRates: result.staffRates.map((rate) =>
                    RoleRatesSchema.parse(rate)
                ),
                staffs: result.staffs,
            }
        },
        mutationFn: (data) =>
            trpc.role.saveRole.mutate({
                role: data.role,
                roleRates: data.roleRates,
            }),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'staff-role-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to staff role?',
        loadingMessage: 'Saving staff role...',
        successMessage: 'Staff role saved successfully',
        errorMessage: 'Failed to save staff role',
    }
)

// Selectors
export const useRoleData = () =>
    useSelector(staffRoleStore.store, (state) => state.context.role)
export const useRoleRates = () =>
    useSelector(staffRoleStore.store, (state) => state.context.roleRates)
export const useStaffRates = () =>
    useSelector(staffRoleStore.store, (state) => state.context.staffRates)
export const useStaffs = () =>
    useSelector(staffRoleStore.store, (state) => state.context.staffs)

// Actions
export const staffRoleActions = {
    saveStaffRoleData: (data: StaffRoleData) =>
        staffRoleStore.store.send({ type: 'saveStaffRoleData', data }),

    changeRoleData: <K extends keyof Role>(key: K, value: Role[K]) =>
        staffRoleStore.store.send({ type: 'changeRoleData', key, value }),

    changeRoleRateData: <K extends keyof RoleRates>(
        id: string,
        key: K,
        value: RoleRates[K]
    ) =>
        staffRoleStore.store.send({
            type: 'changeRoleRateData',
            id,
            key,
            value,
        }),

    addNewRate: (initial?: Partial<RoleRates>) =>
        staffRoleStore.store.send({ type: 'addNewRate', initial }),

    deleteRate: (id: string) =>
        staffRoleStore.store.send({ type: 'deleteRate', id }),
}

// Non-reactive getters
export const getRoleData = () => staffRoleStore.store.getSnapshot().context.role
export const getRoleRates = () =>
    staffRoleStore.store.getSnapshot().context.roleRates
export const getStaffRates = () =>
    staffRoleStore.store.getSnapshot().context.staffRates
export const getStaffs = () => staffRoleStore.store.getSnapshot().context.staffs

// Export store utilities
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = staffRoleStore
