import { useEffect, useCallback, useRef } from 'react'
import { toast } from '@vendor/sonner'
import { Button } from '@2/components/ui/button'
import { useBlocker } from '@tanstack/react-router'
import {
    addPendingOperation,
    startSaving,
    savingSucceeded,
    savingFailed,
    addActiveToast,
    removeActiveToast,
    hasPendingOperation,
    generateSaveId,
    useActiveToasts,
    useHasUnsavedChanges,
    getSaveState,
    clearOperation,
    resetSaveStore,
} from '@2/layout/save-store'

// Constants
const SAVE_TIMEOUT_MS = 30000
const MAX_RETRY_ATTEMPTS = 3
const MAX_PENDING_OPERATIONS = 50

export type SaveToastConfig = {
    saveId?: string
    onSave: (...args: any[]) => Promise<any>
    onCancel?: () => void
    requireConfirmation?: boolean
    confirmationMessage?: string
    loadingMessage?: string
    successMessage?: string
    errorMessage?: string
    showCancelButton?: boolean
    onSuccess?: (data: any) => void
    onRetry?: () => void
    metadata?: Record<string, any>
    hasPendingChanges?: boolean
}

// Add timeout handling for save operations
const withTimeout = (
    promise: Promise<any>,
    timeoutMs: number
): Promise<any> => {
    return Promise.race([
        promise,
        new Promise((_, reject) =>
            setTimeout(
                () => reject(new Error('Save operation timed out')),
                timeoutMs
            )
        ),
    ])
}

export const useNavigationBlocker = (
    requireConfirmation: boolean,
    onCancel?: () => void
) => {
    const hasUnsavedChanges = useHasUnsavedChanges()
    const { activeToasts } = getSaveState()

    useBlocker({
        shouldBlockFn: ({ current, next }) => {
            if (!hasUnsavedChanges || !requireConfirmation) return false
            if (
                current.pathname === next.pathname &&
                current.search === next.search
            ) {
                return false
            }

            const shouldProceed = window.confirm(
                'You have unsaved changes. Are you sure you want to leave?'
            )

            if (shouldProceed) {
                toast.dismiss()
                resetSaveStore()
                if (onCancel) onCancel()
            }

            return !shouldProceed
        },
        enableBeforeUnload: () => hasUnsavedChanges,
        withResolver: true,
    })
}

export const useSaveToast = ({
    saveId,
    onSave,
    onCancel,
    requireConfirmation = false,
    confirmationMessage = 'Save changes?',
    loadingMessage = 'Saving changes...',
    successMessage = 'All saved',
    errorMessage = 'Changes have not been saved',
    showCancelButton = true,
    onSuccess,
    onRetry,
    metadata,
    hasPendingChanges,
}: SaveToastConfig) => {
    const activeToasts = useActiveToasts()
    const saveTimeoutRef = useRef<NodeJS.Timeout>()
    const retryCountRef = useRef<number>(0)

    useNavigationBlocker(requireConfirmation, onCancel)

    useEffect(() => {
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current)
            }
        }
    }, [])

    const executeSave = useCallback(
        async (id, ...props) => {
            // Start saving - moves operation from pending to saving state
            startSaving(id)

            return toast.promise(
                withTimeout(onSave(...props), SAVE_TIMEOUT_MS),
                {
                    loading: loadingMessage,
                    success: (data) => {
                        savingSucceeded(id)
                        removeActiveToast(id)
                        retryCountRef.current = 0

                        // Check if there's a pending operation with the same ID
                        const state = getSaveState()
                        const pendingOperation = state.pendingOperations.find(
                            (op) => op.id === id
                        )

                        if (pendingOperation) {
                            // If there is, show a new toast for it
                            setTimeout(() => showSaveConfirmation(), 100)
                        }

                        if (onSuccess) onSuccess(data)
                        return successMessage
                    },
                    error: (error) => {
                        savingFailed(id)
                        retryCountRef.current += 1

                        const isTimeoutError =
                            error.message.includes('timed out')
                        const errorDetail = isTimeoutError
                            ? 'Operation timed out'
                            : error.message
                        const canRetry =
                            retryCountRef.current < MAX_RETRY_ATTEMPTS

                        return (
                            <div className="flex items-center justify-between w-full">
                                <div>
                                    <span className="text-muted-foreground">
                                        {errorMessage}
                                    </span>
                                    <span className="text-sm text-red-500 block">
                                        {errorDetail}
                                    </span>
                                </div>
                                {canRetry && (
                                    <Button
                                        onClick={() => {
                                            toast.dismiss()
                                            if (onRetry) {
                                                onRetry()
                                            } else {
                                                showSaveConfirmation()
                                            }
                                        }}
                                        variant="default"
                                        size="sm"
                                    >
                                        Retry
                                    </Button>
                                )}
                            </div>
                        )
                    },
                }
            )
        },
        [onSave, onSuccess, onRetry, metadata, saveId]
    )

    const showSaveConfirmation = useCallback(
        (...props) => {
            const id = generateSaveId(saveId)

            // Check operation limits first
            const state = getSaveState()
            if (
                state.pendingOperations.length +
                    state.savingOperations.length >=
                MAX_PENDING_OPERATIONS
            ) {
                toast.error(
                    'Too many pending save operations. Please try again later.'
                )
                return
            }

            // Check if this ID is currently saving
            const isCurrentlySaving = state.savingOperations.some(
                (op) => op.id === id
            )

            // Add/update pending operation
            addPendingOperation({
                id,
                timestamp: Date.now(),
                metadata,
                successMessage,
                errorMessage,
                loadingMessage,
            })

            // Only show a new toast if the operation isn't currently saving
            if (!isCurrentlySaving && !activeToasts.has(id)) {
                addActiveToast(id)

                if (!requireConfirmation) {
                    return executeSave(id, ...props)
                }
                toast(
                    <div className="flex w-full items-center">
                        <div className="flex-auto">{confirmationMessage}</div>
                        <div className="flex-none flex gap-2 justify-end">
                            {showCancelButton && (
                                <Button
                                    onClick={() => {
                                        toast.dismiss()
                                        removeActiveToast(id)
                                        clearOperation(id)
                                        if (onCancel) onCancel()
                                    }}
                                    variant="outline"
                                    size="sm"
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    toast.dismiss()
                                    executeSave()
                                }}
                                variant="default"
                                size="sm"
                            >
                                Save
                            </Button>
                        </div>
                    </div>,
                    {
                        duration: Infinity,
                        dismissible: false,
                    }
                )
            }
        },
        [
            executeSave,
            onCancel,
            requireConfirmation,
            confirmationMessage,
            showCancelButton,
            metadata,
            successMessage,
            errorMessage,
            loadingMessage,
            activeToasts,
            saveId,
        ]
    )

    // Handle showing the confirmation based on pending changes
    useEffect(() => {
        if (hasPendingChanges) {
            showSaveConfirmation()
        }
    }, [hasPendingChanges])

    return showSaveConfirmation
}

export default useSaveToast
