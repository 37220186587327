import { useMemo } from 'react'
import {
    canEditStaff,
    canEditStaffChargeOutRate,
    canEditStaffCostRate,
    canEditStaffOvertimeRate,
    canEditStaffPayRate,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffOvertimeRate,
    canViewStaffPayRate,
    canEditRoles,
} from '../../../State/Permissions/HasPermissions'
import SessionStore from '../../../State/SessionStore'
import {
    staffActions,
    useStaffMember,
    useStaffRates,
    useStaffRole,
    useStaffRoles,
} from './staff-page-store'
import { getColumns } from './staff-rates-tab-columns'
import { CheckboxWithLabel } from '@/version2/components/ui/checkbox'
import { useDataTable } from '@/version2/components/data-table/hooks/use-data-table'
import { DataTable } from '@/version2/components/data-table/data-table'
import { Button } from '@/version2/components/ui/button'

interface TopRow {
    id: string
    value?: any
    size?: number
    label?: string
    onChange?: (value: boolean) => void
    permissions?: any
    editable?: boolean
}
export const StaffRateTab = () => {
    const staff = useStaffMember()
    const staffRole = useStaffRole()
    const staffRates = useStaffRates()
    if (!staff) return <div>Staff Member not found.</div>
    const columns = useMemo(() => {
        return getColumns((column: string) => {}, staff)
    }, [staff])
    const topRow: TopRow[] = useMemo(
        () => [
            {
                id: 'role',
                size: 120,
            },
            {
                id: 'avgPayRate',
                value: staff?.inheritPayRate,
                size: 120,
                label: 'Inherit From Role',
                onChange: (value) => {
                    staffActions.changeStaffData('inheritPayRate', value)
                },
                permissions: canViewStaffPayRate(SessionStore.user),
                editable:
                    staff?.roleId != null &&
                    !staffRole?.avgPayRate &&
                    canEditStaffPayRate(SessionStore.user),
            },
            {
                id: 'avgOvertimeRate',
                value: staff?.inheritOvertimeRate,
                size: 120,
                label: 'Inherit From Role',
                onChange: (value) => {
                    staffActions.changeStaffData('inheritOvertimeRate', value)
                },
                permissions: canViewStaffOvertimeRate(SessionStore.user),
                editable:
                    staff.roleId != null &&
                    !staffRole?.avgOvertimeRate &&
                    canEditStaffOvertimeRate(SessionStore.user),
            },
            {
                id: 'avgCostRate',
                value: staff?.inheritCostRate,
                size: 120,
                label: 'Inherit From Role',
                onChange: (value) => {
                    staffActions.changeStaffData('inheritCostRate', value)
                },
                permissions: canViewStaffCostRate(SessionStore.user),
                editable:
                    staff.roleId != null &&
                    !staffRole?.avgCostRate &&
                    canEditStaffCostRate(SessionStore.user),
            },
            {
                id: 'avgChargeOutRate',
                value: staff?.inheritChargeOutRate,
                size: 120,
                label: 'Inherit From Role',
                onChange: (value) => {
                    staffActions.changeStaffData('inheritChargeOutRate', value)
                },
                permissions: canViewStaffChargeOutRate(SessionStore.user),
                editable:
                    staff.roleId != null &&
                    !staffRole?.avgChargeOutRate &&
                    canEditStaffChargeOutRate(SessionStore.user),
            },
            {
                id: 'availability',
                size: 120,
            },
            {
                id: 'button',
            },
        ],
        [staffRole, staff]
    )

    const renderTopRow = (row: TopRow) => {
        if (
            row.id === 'button' ||
            row.id === 'role' ||
            row.id === 'availability'
        ) {
            return null
        }
        return (
            <CheckboxWithLabel
                label={row.label}
                checked={row.value}
                id={row.id}
                onCheckedChange={row.onChange}
                disabled={!row.editable}
            />
        )
    }
    const includeRoleRates =
        staff.inheritPayRate ||
        staff.inheritOvertimeRate ||
        staff.inheritCostRate ||
        staff.inheritChargeOutRate
    const rowData = useMemo(() => {
        const rates = []
        if (includeRoleRates) {
            rates.push(...(staffRole.rates ?? []))
        }
        rates.push(...(staffRates ?? []))
        return rates
            .filter((rate) => !rate.deletedAt)
            .sort((a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime()
            })
    }, [staff, staffRole, staffRates])
    const { table } = useDataTable({
        data: rowData,
        columns,
        visibleColumns: columns.map((column: any) => column?.accessorKey),
    })
    return (
        <div className="w-full">
            <Button
                variant="outline"
                onClick={() => {
                    staffActions.addNewRates()
                }}
                className="mb-4"
            >
                <i className="fa fa-plus mr-1" />
                Add Rate
            </Button>
            <DataTable
                table={table}
                topRows={topRow}
                renderTopRow={renderTopRow}
            />
        </div>
    )
}
