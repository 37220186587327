import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'

type HistoryEntry<T> = {
    state: T
    timestamp: Date
}

type HistoryContext<T> = {
    history: HistoryEntry<T>[]
    currentHistoryIndex: number
    lastSavedIndex: number
    savedAt: Date | null
    updatedAt: Date | null // This needs to be updated whenever state changes
}

type HistoryState<T> = T & HistoryContext<T>

// Helper function to add history functionality to a store configuration
export function withHistory<T extends object>(config: {
    context: T
    on: Record<string, Partial<T>>
}) {
    const initialHistoryContext: HistoryContext<T> = {
        history: [],
        currentHistoryIndex: -1,
        lastSavedIndex: -1,
        savedAt: null,
        updatedAt: null,
    }

    // Merge the initial context with history context
    const initialContext: HistoryState<T> = {
        ...config.context,
        ...initialHistoryContext,
    }

    // Helper function to manage history
    const addToHistory = (
        context: HistoryState<T>,
        newState: T
    ): HistoryEntry<T>[] => {
        const newHistory = context.history.slice(
            0,
            context.currentHistoryIndex + 1
        )
        return [
            ...newHistory,
            {
                state: newState,
                timestamp: new Date(),
            },
        ]
    }

    // Create enhanced event handlers
    const enhancedOn: Record<string, Partial<HistoryState<T>>> = {
        initializeState: {
            ...Object.fromEntries(
                Object.keys(config.context).map((key) => [
                    key,
                    (_: HistoryState<T>, event: { state: T }) =>
                        event.state[key as keyof T],
                ])
            ),
            history: (_, event: { state: T }) => [
                {
                    state: event.state,
                    timestamp: null,
                },
            ],
            currentHistoryIndex: () => 0,
            lastSavedIndex: () => 0,
            savedAt: () => null,
            updatedAt: () => null,
        },
        ...config.on,
        undo: {
            ...Object.fromEntries(
                Object.keys(config.context).map((key) => [
                    key,
                    (context: HistoryState<T>) => {
                        if (context.currentHistoryIndex <= 0)
                            return context[key as keyof T]
                        return context.history[context.currentHistoryIndex - 1]
                            .state[key as keyof T]
                    },
                ])
            ),
            currentHistoryIndex: (context) =>
                Math.max(-1, context.currentHistoryIndex - 1),
            updatedAt: (context) => {
                if (context.currentHistoryIndex <= 0) return null
                return context.history[context.currentHistoryIndex - 1]
                    .timestamp
            },
        },
        redo: {
            ...Object.fromEntries(
                Object.keys(config.context).map((key) => [
                    key,
                    (context: HistoryState<T>) => {
                        if (
                            context.currentHistoryIndex >=
                            context.history.length - 1
                        )
                            return context[key as keyof T]
                        return context.history[context.currentHistoryIndex + 1]
                            .state[key as keyof T]
                    },
                ])
            ),
            currentHistoryIndex: (context) =>
                Math.min(
                    context.history.length - 1,
                    context.currentHistoryIndex + 1
                ),
            updatedAt: (context) => {
                if (context.currentHistoryIndex >= context.history.length - 1)
                    return context.updatedAt
                return context.history[context.currentHistoryIndex + 1]
                    .timestamp
            },
        },
        save: {
            savedAt: () => new Date(),
            lastSavedIndex: (context) => context.currentHistoryIndex,
        },
        revertToLastSave: {
            ...Object.fromEntries(
                Object.keys(config.context).map((key) => [
                    key,
                    (context: HistoryState<T>) => {
                        if (context.lastSavedIndex === -1)
                            return context[key as keyof T]
                        return context.history[context.lastSavedIndex].state[
                            key as keyof T
                        ]
                    },
                ])
            ),
            currentHistoryIndex: (context) => context.lastSavedIndex,
            updatedAt: (context) => {
                if (context.lastSavedIndex === -1) return null
                return context.history[context.lastSavedIndex].timestamp
            },
        },
    }

    // Enhance existing actions with history tracking
    Object.keys(config.on).forEach((actionType) => {
        if (
            [
                'undo',
                'redo',
                'save',
                'revertToLastSave',
                'initializeState',
                'setIsLoading',
            ].includes(actionType)
        )
            return

        const originalHandler = config.on[actionType]
        enhancedOn[actionType] = {
            ...originalHandler,
            history: (context: HistoryState<T>, event: any) => {
                const newState = Object.keys(config.context).reduce(
                    (acc, key) => {
                        const handler = originalHandler[key as keyof T]
                        if (handler) {
                            acc[key as keyof T] = handler(context, event)
                        } else {
                            acc[key as keyof T] = context[key as keyof T]
                        }
                        return acc
                    },
                    {} as T
                )
                return addToHistory(context, newState)
            },
            currentHistoryIndex: (context) => context.history.length,
            updatedAt: () => new Date(),
        }
    })

    return createStore({
        context: initialContext,
        on: enhancedOn,
    })
}

// Hook creators for history functionality
export function createHistoryHooks<T extends object>(
    store: ReturnType<typeof createStore>
) {
    return {
        useCanUndo: () =>
            useSelector(
                store,
                (state) => state.context.currentHistoryIndex > 0
            ),

        useCanRedo: () =>
            useSelector(
                store,
                (state) =>
                    state.context.currentHistoryIndex <
                    state.context.history.length - 1
            ),

        useHasUnsavedChanges: () =>
            useSelector(
                store,
                (state) =>
                    state.context.currentHistoryIndex !==
                    state.context.lastSavedIndex
            ),
        useUpdatedAt: () =>
            useSelector(store, (state) => {
                // Only return updatedAt if it's after the initial state timestamp
                const updatedAt = state.context.updatedAt
                const initialTimestamp = state.context.history[0]?.timestamp
                return updatedAt &&
                    (!initialTimestamp || updatedAt > initialTimestamp)
                    ? updatedAt
                    : null
            }),
        useSavedAt: () => useSelector(store, (state) => state.context.savedAt),
    }
}

// Action creators for history functionality
export function createHistoryActions(store: ReturnType<typeof createStore>) {
    return {
        undo: () => store.send({ type: 'undo' }),
        redo: () => store.send({ type: 'redo' }),
        save: () => store.send({ type: 'save' }),
        revertToLastSave: () => store.send({ type: 'revertToLastSave' }),
        initializeState: (state: T) =>
            store.send({ type: 'initializeState', state }),
    }
}

export function createHistoryGetters(store: ReturnType<typeof createStore>) {
    return {
        getState: () => store.getSnapshot().context,
        getHistory: () => store.getSnapshot().context.history,
        getCurrentHistoryIndex: () =>
            store.getSnapshot().context.currentHistoryIndex,
        getLastSavedIndex: () => store.getSnapshot().context.lastSavedIndex,
        getSavedAt: () => store.getSnapshot().context.savedAt,
        getUpdatedAt: () => store.getSnapshot().context.updatedAt,
    }
}
