import { useState } from 'react'
import { Radio, Switch, Select } from 'antd'
import { capitalCase } from 'change-case'
import { permissionStructure } from '../../../State/Permissions/PermissionStructure'
import {
    canSeeExpenses,
    canSeeFeesAndRevenue,
    canSeeOperationalExpenses,
    canSeeSalaries,
    deleteProjectPermission,
    hasPermissionsForAllProjects,
} from '../../../State/Permissions/HasPermissions'
import { Link, redirect, useNavigate } from '@tanstack/react-router'
import {
    staffActions,
    useStaffMember,
    useStaffPermissions,
} from './staff-page-store'
import * as React from 'react'
import { Button } from '@/version2/components/ui/button'
import { PermissionsSelector, Selector } from '@/version2/components/selector'
import { MultiSelect } from '@/version2/components/ui/mutil-select'
import { useCacheSlice } from '@/version2/cache'
import { StaffInterface } from './staff.interface'
import { permissionPresets } from '../../../State/Permissions/PermissionPresets'
import * as cuid from 'cuid'
import {
    SwitchWithLabel,
    Switch as SwitchV2,
} from '@/version2/components/ui/switch'
import {
    RadioGroup,
    RadioGroupItem,
    RadioGroupItemWithLabel,
} from '@/version2/components/ui/radio-group'
import { Tabs, TabsList, TabsTrigger } from '@/version2/components/ui/tabs'
const { Option, OptGroup } = Select

export const StaffPermissionTab = ({ id }) => {
    const navigate = useNavigate()
    const staff = useStaffMember()
    const permission = useStaffPermissions()
    const listPermissions = useCacheSlice('permissions') ?? []
    const defaultPreset = listPermissions.find(
        (p) => p.id.toString() === staff.permissionsId
    ) ?? { id: 'custom', name: 'Custom' }
    const [presetOption, setPresetOption] = useState(defaultPreset)

    return (
        <div style={{ width: '58em' }}>
            <div style={{ marginBottom: '5em' }}>
                <h2 className="border-b border-[#aaa]">Presets</h2>
                <div>
                    <PermissionRow
                        childClassName="flex"
                        label={'Permission Presets'}
                    >
                        <PermissionsSelector
                            value={presetOption}
                            onChange={(value) => {
                                setPresetOption(value)
                                const permissionSelected = listPermissions.find(
                                    (p) => p.id === value.id
                                )
                                console.log('value', value, permissionSelected)
                                if (permissionSelected) {
                                    staffActions.changeStaffData(
                                        'permissionsId',
                                        value.id
                                    )
                                    staffActions.changePermissions(
                                        permissionSelected.settings as StaffInterface.StaffMemberPermissions
                                    )
                                } else {
                                    staffActions.changeStaffData(
                                        'permissionsId',
                                        null
                                    )
                                    staffActions.changePermissions(
                                        staff.permissions
                                    )
                                }
                            }}
                        />
                        {presetOption.id !== 'custom' ? (
                            <Link to={`/permissions/${presetOption}`}>
                                <Button className="ml-2">Edit...</Button>
                            </Link>
                        ) : (
                            <Button
                                className="ml-2"
                                onClick={() => {
                                    // const permission =
                                    //     PermissionCollection.createPermissionFromStaff(
                                    //         staff
                                    //     )
                                    // navigate({
                                    //     to: `/permissions/${permission.id}`,
                                    // })
                                }}
                            >
                                Create Preset...
                            </Button>
                        )}
                    </PermissionRow>
                    <PermissionRow label={'Financial Presets'}>
                        <div>
                            <SwitchWithLabel
                                label="Project Fees & Revenue"
                                disabled={!!staff.permissionsId}
                                checked={canSeeFeesAndRevenue({
                                    ...staff,
                                    permissions: permission,
                                })}
                                onCheckedChange={(v) => {
                                    staffActions.changePermissions({
                                        ...permission,
                                        canViewRevenueForecast: v,
                                        chargeOutRate: v ? 'view' : 'hidden',
                                        projects: (
                                            permission?.projects || []
                                        ).map((p) => ({
                                            ...p,
                                            permissions: {
                                                ...p.permissions,
                                                projectFees: v
                                                    ? 'view'
                                                    : 'hidden',
                                                projectForecastedRevenue: v
                                                    ? 'view'
                                                    : 'hidden',
                                                projectInvoices: v
                                                    ? 'view'
                                                    : 'hidden',
                                            },
                                        })),
                                    })
                                }}
                            />
                            <SwitchWithLabel
                                label="Staff Cost & Project Expenses"
                                disabled={!!staff.permissionsId}
                                checked={canSeeExpenses({
                                    ...staff,
                                    permissions: permission,
                                })}
                                onCheckedChange={(v) => {
                                    staffActions.changePermissions({
                                        ...permission,
                                        costRate: v ? 'view' : 'hidden',
                                        projects: (
                                            permission.projects || []
                                        ).map((p) => ({
                                            ...p,
                                            permissions: {
                                                ...p.permissions,
                                                projectExpenses: v
                                                    ? 'view'
                                                    : 'hidden',
                                                projectExpenseBudgets: v
                                                    ? 'view'
                                                    : 'hidden',
                                            },
                                        })),
                                    })
                                }}
                            />
                            <SwitchWithLabel
                                label="Staff Salaries"
                                disabled={!!staff.permissionsId}
                                checked={canSeeSalaries({
                                    ...staff,
                                    permissions: permission,
                                })}
                                onCheckedChange={(v) => {
                                    staffActions.changePermissions({
                                        ...permission,
                                        payRate: v ? 'view' : 'hidden',
                                        overtimeRate: v ? 'view' : 'hidden',
                                    })
                                }}
                            />
                            <SwitchWithLabel
                                label="Operational Expenses"
                                disabled={!!staff.permissionsId}
                                checked={canSeeOperationalExpenses({
                                    ...staff,
                                    permissions: permission,
                                })}
                                onCheckedChange={(v) => {
                                    staffActions.changePermissions({
                                        ...permission,
                                        operationalExpenses: v
                                            ? 'view'
                                            : 'hidden',
                                    })
                                }}
                            />
                        </div>
                    </PermissionRow>
                </div>
            </div>
            <StaffPermissionOptions disabled={staff.permissionsId} />
        </div>
    )
}

export const StaffPermissionOptions = ({ disabled }) => {
    const permissions = useStaffPermissions()
    const handleUpdatePermissions = (key, value) => {
        // updateStaffPermission(staff, { [key]: value })
        staffActions.changePermissions({
            ...permissions,
            [key]: value,
        })
    }

    const handleUpdateProjectPermissions = (id, key, value) => {
        // updateProjectPermission(staff, id, { [key]: value })
        staffActions.changePermissions({
            ...permissions,
            projects: permissions.projects.map((p) =>
                p.id === id
                    ? { ...p, permissions: { ...p.permissions, [key]: value } }
                    : p
            ),
        })
    }

    const handleUpdateFilterPermissions = (id, filter) => {
        // updateProjectPermissionFilter(staff, id, filter)
        staffActions.changePermissions({
            ...permissions,
            projects: permissions.projects.map((p) =>
                p.id === id ? { ...p, filter: filter ?? p.filter } : p
            ),
        })
    }

    const addProjectPermission = () => {
        // addProjectPermission(staff)
        staffActions.changePermissions({
            ...permissions,
            projects: [
                ...permissions.projects,
                {
                    id: cuid(),
                    filter: !hasPermissionsForAllProjects(permissions)
                        ? { type: 'all' }
                        : { type: 'project' },
                    permissions: {
                        ...permissionPresets.timesheet.projects[0].permissions,
                    },
                },
            ],
        })
    }
    return permissionStructure.map((group) =>
        group.label !== 'Projects' ? (
            <PermissionGroup
                title={group.label}
                permissions={group.permissions}
                staffPermissions={permissions as any}
                onChange={handleUpdatePermissions}
                disabled={disabled}
            />
        ) : (
            <div>
                {(permissions?.projects || []).map((projectPermissions) => (
                    <ProjectPermissionGroup
                        staffPermissions={permissions}
                        permissions={group.permissions}
                        projectPermissions={projectPermissions}
                        onChange={(key, value) =>
                            handleUpdateProjectPermissions(
                                projectPermissions.id,
                                key,
                                value
                            )
                        }
                        onFilterChange={(value) =>
                            handleUpdateFilterPermissions(
                                projectPermissions.id,
                                value
                            )
                        }
                        onDelete={() =>
                            deleteProjectPermission(projectPermissions.id)
                        }
                        disabled={disabled}
                    />
                ))}
                {!disabled ? (
                    <button
                        className="plus-btn border-[#d9d9d9] mb-10"
                        onClick={() => {
                            addProjectPermission()
                        }}
                    >
                        + Add Project Permissions
                    </button>
                ) : null}
            </div>
        )
    )
}

export const ProjectPermissionGroup = ({
    permissions,
    projectPermissions,
    staffPermissions,
    onChange,
    onFilterChange,
    onDelete,
    disabled,
}) => {
    return (
        <div style={{ marginBottom: '4em' }}>
            <div
                style={{
                    borderBottom: '1px solid #ccc',
                    display: 'flex',
                    zIndex: 10,
                    position: 'relative',
                    gap: '1em',
                }}
            >
                <h3 style={{ margin: 0 }}>{'Projects'}</h3>
                <ProjectPermissionFilter
                    staffPermissions={staffPermissions}
                    filter={projectPermissions.filter}
                    onChange={onFilterChange}
                    disabled={disabled}
                />
                {!disabled ? (
                    <Button variant="outline" onClick={() => onDelete()}>
                        Delete
                    </Button>
                ) : null}
            </div>
            <PermissionGroup
                permissions={permissions}
                staffPermissions={projectPermissions.permissions}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}

const ProjectPermissionFilter = ({
    filter,
    onChange,
    staffPermissions,
    disabled,
}) => {
    const permission = useCacheSlice('permissions') ?? []
    const costCentre = useCacheSlice('costCentres')
    const options = React.useMemo(
        () =>
            [
                !hasPermissionsForAllProjects(staffPermissions) ||
                filter.type === 'all'
                    ? { name: 'All Projects', id: 'all' }
                    : null,
                {
                    name: 'Owned Projects',
                    id: 'owner',
                },
                {
                    name: 'Projects with Cost Centre',
                    id: 'costCentre',
                },
                { name: 'Specific Projects', id: 'project' },
            ].filter((c) => c),
        [staffPermissions, filter.type]
    )

    return (
        <div className=" w-full gap-3">
            <div
                style={{
                    flex: filter.type === 'all' ? '1 1 auto' : '0 0 auto',
                    marginBottom: '0.25em',
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                <Selector
                    value={filter.type}
                    onChange={(filterType) =>
                        onChange({ type: filterType.id, values: [] })
                    }
                    disabled={disabled}
                    getLabel={(value) => {
                        if (value?.name) return value.name
                        const option = options.find((o) => o.id === value)
                        return option ? option.name : 'Select ....'
                    }}
                    getValue={(value) => value?.id}
                    options={options}
                />
            </div>
            {filter.type === 'project' ? (
                <MultiSelect
                    options={permission.map((p) => ({
                        label: p.name,
                        value: p.id.toString(),
                    }))}
                    onValueChange={(values) => {
                        onChange({
                            ...filter,
                            values: values,
                        })
                    }}
                    disabled={disabled}
                    value={(filter.values ?? []).map((v) => v.toString())}
                />
            ) : filter.type === 'costCentre' ? (
                <MultiSelect
                    options={costCentre.map((cc) => ({
                        label: cc.name,
                        value: cc.id.toString(),
                    }))}
                    onValueChange={(values) => {
                        onChange({
                            ...filter,
                            values: values,
                        })
                    }}
                    disabled={disabled}
                    value={(filter.values ?? []).map((v) => v.toString())}
                />
            ) : null}
        </div>
    )
}

export const PermissionGroup = ({
    title,
    permissions,
    staffPermissions,
    onChange,
    disabled,
}: {
    title?: string
    permissions: any[]
    staffPermissions: StaffInterface.StaffMemberPermissions
    onChange: (key: string, value: any) => void
    disabled: boolean
}) => {
    return (
        <div style={{ marginBottom: title ? '4em' : 0 }}>
            {title ? (
                <h3 style={{ borderBottom: '1px solid #ccc' }}>{title}</h3>
            ) : null}
            <div>
                {permissions.map(
                    ({
                        label,
                        key,
                        type,
                        options,
                        display = (permission: any) => true,
                    }) => {
                        if (!display(staffPermissions)) return null
                        const value = staffPermissions?.[key]
                        const localOnChange = (value) => onChange(key, value)
                        return type === 'switch' ? (
                            <PermissionSwitch
                                {...{ label, value, onChange: localOnChange }}
                                disabled={disabled}
                            />
                        ) : (
                            <PermissionRadio
                                {...{
                                    label,
                                    value,
                                    onChange: localOnChange,
                                    options,
                                }}
                                disabled={disabled}
                            />
                        )
                    }
                )}
            </div>
        </div>
    )
}

const PermissionSwitch = ({ label, value = false, onChange, disabled }) => {
    return (
        <PermissionRow label={label}>
            <SwitchV2
                disabled={disabled}
                checked={value}
                onCheckedChange={(v) => onChange(v)}
            />
        </PermissionRow>
    )
}

const PermissionRadio = ({
    label,
    value = 'hidden',
    onChange,
    options = ['hidden', 'view', 'edit', 'admin'],
    disabled,
}) => {
    const styleOverrides = {
        hidden: {},
        view: {},
        edit: {},
        admin: {},
    }
    if (value === 'hidden') {
        styleOverrides.hidden = {
            backgroundColor: '#aaa',
            borderColor: '#aaa',
        }
    }
    return (
        <PermissionRow label={label}>
            <Tabs value={value}>
                <TabsList variant="solid">
                    {options.map((option) => (
                        <TabsTrigger
                            onClick={() => onChange(option)}
                            disabled={disabled}
                            value={option}
                        >
                            {option === 'admin'
                                ? 'Edit, Create, & Delete'
                                : capitalCase(option)}
                        </TabsTrigger>
                    ))}
                </TabsList>
            </Tabs>
        </PermissionRow>
    )
}

export const PermissionRow = ({
    label,
    children,
    className,
    childClassName,
}: {
    label: string
    children: React.ReactNode
    className?: string
    childClassName?: string
}) => {
    return (
        <div
            className={className}
            css={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                columnGap: '5em',
                rowGap: '0.5em',
                borderBottom: '1px solid #e9e9e9',
                alignItems: 'center',
                padding: '0.5em 0',
                ':hover': {
                    backgroundColor: '#f2f2f2',
                },
                zIndex: 1,
                position: 'relative',
            }}
        >
            <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                {label}
            </div>
            <div className={childClassName}>{children}</div>
        </div>
    )
}
