import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { TextArea } from '@2/components/ui/textarea'

export default observer(({ modalId, timeEntry, store }) => {
    const [notes, setNotes] = useState(timeEntry.notes)
    const textareaRef = useRef(null)

    useEffect(() => {
        // Focus on the textarea when the component mounts
        if (textareaRef.current) {
            textareaRef.current.focus()
        }
    }, [])

    return (
        <Modal
            modalId={modalId}
            heading={'Time Entry Notes'}
            onSave={() => {
                timeEntry.update({ notes })
                timeEntry.attach()
            }}
            saveLabel={'Save Notes'}
        >
            <div>Note:</div>
            <TextArea
                ref={textareaRef}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                style={{ width: '100%', minHeight: '10em' }}
            />
        </Modal>
    )
})
