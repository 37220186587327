import { NumberParser } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import LocaleCurrency from 'locale-currency'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'
import { evaluate } from 'mathjs'
import { FormatCurrency } from './CurrencyFormatter'

export const ParseCurrency = (
    valueString,
    {
        currency = LocaleCurrency.getCurrency(
            isoLangCodes.findCountryLocales(
                constantCase(SessionStore?.organisationCountry || 'us')
            )[0]
        ),
    } = {}
) => {
    if ([null, undefined, ''].includes(valueString)) return valueString

    try {
        // Check if the string contains mathematical operators
        if (/[+\-*/()^]/.test(valueString)) {
            // Get the current locale based on organisation country
            const locale = isoLangCodes.findCountryLocales(
                constantCase(SessionStore?.organisationCountry || 'us')
            )[0]

            // Create currency parser for the current locale
            const parser = new NumberParser(locale, {
                style: 'currency',
                currency: currency || 'USD',
            })

            // Regular expression to match currency values and operators
            // This improved regex handles currency symbols/codes at both start and end of numbers
            const regex =
                /(\p{Sc}?\s*\d[\d.,]*(?:\s*[a-zA-Z]{1,3})?)|([-+*/()^])/gu

            // Process the string to replace localized currency values with standard format
            let standardizedExpression = ''
            let lastIndex = 0
            let match

            const expression = String(valueString)

            // Use regex to find all currency values and operators
            while ((match = regex.exec(expression)) !== null) {
                // If there's text between the last match and this one, add it to result
                if (match.index > lastIndex) {
                    standardizedExpression += expression.substring(
                        lastIndex,
                        match.index
                    )
                }

                if (match[1]) {
                    // This is a currency value - parse it according to locale
                    try {
                        const parsedValue = parser.parse(match[1])
                        standardizedExpression += parsedValue
                    } catch (e) {
                        // If parsing fails, try removing all currency characters and try again
                        try {
                            // Remove both currency symbols and currency codes (2-3 letter codes like EUR, USD, kn, etc.)
                            const valueWithoutCurrency = match[1]
                                .replace(/\p{Sc}/gu, '') // Remove currency symbols
                                .replace(/\s*[a-zA-Z]{1,3}\s*$/g, '') // Remove currency codes at the end
                                .trim()
                            const parsedValue =
                                parser.parse(valueWithoutCurrency)
                            standardizedExpression += parsedValue
                        } catch (innerError) {
                            // Last resort: clean the string and try to get a numeric value out of it
                            try {
                                // Keep only digits, decimal point, and comma
                                let cleanValue = match[1].replace(
                                    /[^\d.,]/g,
                                    ''
                                )

                                // Handle different decimal separators
                                // In Croatian locale, comma is decimal separator and period is thousands separator
                                if (locale.startsWith('hr')) {
                                    cleanValue = cleanValue
                                        .replace(/\./g, '') // Remove thousands separators (periods)
                                        .replace(/,/g, '.') // Replace decimal comma with decimal point
                                } else if (/,\d{1,2}$/.test(cleanValue)) {
                                    // If there's a comma followed by 1-2 digits at the end, likely a decimal comma
                                    cleanValue = cleanValue.replace(
                                        /,(\d{1,2})$/,
                                        '.$1'
                                    )
                                }

                                // Convert to number and back to string to ensure it's a valid number
                                standardizedExpression += parseFloat(cleanValue)
                            } catch (finalError) {
                                // If all parsing fails, just remove non-numeric characters
                                standardizedExpression += match[1].replace(
                                    /[^\d.]/g,
                                    ''
                                )
                            }
                        }
                    }
                } else if (match[2]) {
                    // This is an operator - keep it as is
                    standardizedExpression += match[2]
                }

                lastIndex = regex.lastIndex
            }

            // Add any remaining text after the last match
            if (lastIndex < expression.length) {
                standardizedExpression += expression.substring(lastIndex)
            }

            // Evaluate the standardized expression
            return evaluate(standardizedExpression)
        } else {
            // If there are no operators, just parse the currency value normally
            const parser = new NumberParser(
                isoLangCodes.findCountryLocales(
                    constantCase(SessionStore?.organisationCountry || 'us')
                )[0],
                {
                    style: 'currency',
                    currency: currency || 'USD',
                }
            )
            // Try direct parsing first
            try {
                return parser.parse(String(valueString))
            } catch (e) {
                // If direct parsing fails, try removing currency codes
                try {
                    const valueWithoutCurrency = String(valueString)
                        .replace(/\p{Sc}/gu, '')
                        .replace(/\s*[a-zA-Z]{1,3}\s*$/g, '')
                        .trim()
                    return parser.parse(valueWithoutCurrency)
                } catch (innerError) {
                    // Return the original string as a fallback
                    return valueString
                }
            }
        }
    } catch (e) {
        // If all else fails, return the original string
        return valueString
    }
}
