import { createFileRoute } from '@tanstack/react-router'
import ActivateAccountPage from '../../Pages/LoginPage/ActivateAccountPage'
import ErrorComponent from '../../Components/ErrorComponent'

export const Route = createFileRoute('/_public/activate-account')({
    component: Wrapper,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})

function Wrapper() {
    return <ActivateAccountPage {...Route.useParams()} {...Route.useSearch()} />
}
