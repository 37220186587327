import { observable, computed, action, makeObservable } from 'mobx'
import { differenceInMinutes, format, startOfDay, subSeconds } from 'date-fns'
import localforage from 'localforage'
import superjson from 'superjson'
import SessionStore from '../../State/SessionStore'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import DataStore from '../../State/DataStore'
import tuple from 'immutable-tuple'
import {
    canEnterTimeAgainstPhase,
    canEnterTimeAgainstProject,
} from '../../State/Permissions/HasPermissions'
import sortPhases from '../../Utils/sortPhases'

class timerStore {
    @observable timerState = 'idle'
    @observable saveState = 'idle'
    @observable selectedValues = {
        projectId: null,
        costCentreId: null,
        phaseId: null,
        taskId: null,
        staffId: null,
        isBillable: true,
        isVariation: false,
        isOvertime: false,
        isLocked: false,
        remote: false,
        flexi: false,
    }
    @observable budgetType = 'phase'
    @observable prevSeconds = 0
    @observable startTime = null
    @observable currentTime = null
    @observable open = false
    @observable initialized = false
    timerInterval = null
    constructor() {
        makeObservable(this)
        this.init()
    }
    @action.bound
    init() {
        // make conditional so it doesn't reset
        if (!this.selectedValues.projectId) this.setDefaults()
        // if (!this.timerInterval) this.startTimer();
    }
    @action.bound
    tick() {
        this.currentTime = new Date()
    }
    @action.bound
    startTimer() {
        clearInterval(this.timerInterval)
        this.startTime = new Date()
        this.timerInterval = setInterval(this.tick, 1000)
        this.timerState = 'running'
        this.storeLocal()
    }
    @action.bound
    pauseTimer() {
        this.prevSeconds = this.seconds
        // this.startTime = null;
        this.currentTime = null
        clearInterval(this.timerInterval)
        this.timerState = 'idle'
        this.storeLocal()
    }
    @action.bound
    resetTimer() {
        this.pauseTimer()
        this.prevSeconds = 0
        this.storeLocal()
    }
    @computed
    get currentSeconds() {
        return this.startTime && this.currentTime
            ? (this.currentTime - this.startTime) / 1000
            : 0
    }
    @computed
    get seconds() {
        //TODO slow down time
        return this.prevSeconds + this.currentSeconds
    }
    @computed
    get timeDisplay() {
        return new Date(this.seconds * 1000).toISOString().substr(12, 7)
    }
    @action.bound
    toggleOpen(val) {
        if (val || !this.open) {
            this.init()
        }
        this.open = val || !this.open
    }
    @action.bound
    setDefaults(selectedValues = {}) {
        const setDefaults = () => {
            const project = ProjectCollection.projects
                ?.sort((a, b) => a.title?.localeCompare?.(b.title))
                .filter((p) =>
                    canEnterTimeAgainstProject(SessionStore.user, p)
                )[0]
            const phase = project?.phases
                ?.sort(sortPhases)
                .filter((p) =>
                    canEnterTimeAgainstPhase(SessionStore.user, p)
                )[0]
            const task = phase?.tasks?.sort((a, b) =>
                a.name?.localeCompare?.(b.name)
            )[0]
            this.selectedValues = {
                projectId: project?.id,
                costCentreId: project?.costCentreId,
                phaseId: phase?.id,
                taskId: task?.id,
                staffId: SessionStore.userId,
                isBillable: task?.isBillable ?? true,
                isVariation: task?.isVariation ?? false,
                isOvertime: false,
                isLocked: false,
                remote: false,
                flexi: false,
                ...selectedValues,
            }
            // budgetStore.getBudget(
            //     new Date(), //weekStart
            //     new Date(), //selectedDate
            //     this.mode,
            //     this.selectedValues.staffId,
            //     this.selectedValues.projectId,
            //     this.selectedValues.phaseId
            // )
            this.storeLocal()
            this.initialized = true
        }
        localforage
            .getItem('timer')
            .then((v) => {
                v = v ? superjson.parse(v) : null
                if (
                    !v?.selectedValues?.projectId ||
                    v?.selectedValues?.staffId !== SessionStore.userId
                ) {
                    setDefaults()
                } else {
                    this.timerState = v.timerState
                    this.selectedValues = v.selectedValues
                    this.budgetType = v.budgetType
                    this.prevSeconds = v.prevSeconds
                    this.startTime = v.startTime
                    if (this.timerState !== 'idle') {
                        this.currentTime = Date.now()
                        clearInterval(this.timerInterval)
                        this.timerInterval = setInterval(this.tick, 1000)
                        this.timerState = 'running'
                    }
                    // budgetStore.getBudget(
                    //     new Date(), //weekStart
                    //     new Date(), //selectedDate
                    //     this.mode,
                    //     this.selectedValues.staffId,
                    //     this.selectedValues.projectId,
                    //     this.selectedValues.phaseId
                    // )
                    this.initialized = true
                }
            })
            .catch((e) => {
                console.error(e)
                setDefaults()
            })
    }
    @action.bound
    updateSelectedValues(data) {
        this.selectedValues = {
            ...this.selectedValues,
            ...data,
        }
        if ('projectId' in data) {
            const project = ProjectCollection.projectsById[data.projectId]
            const phase = project?.phases
                ?.sort(sortPhases)
                .filter((p) =>
                    canEnterTimeAgainstPhase(SessionStore.user, p)
                )[0]
            const task = phase?.defaultTask || phase?.tasks[0]
            this.selectedValues = {
                ...this.selectedValues,
                costCentreId: project.costCentreId,
                phaseId: phase?.id,
                taskId: task?.id,
                isBillable: task?.isBillable ?? true,
                isVariation: task?.isVariation ?? false,
            }
        }
        if ('phaseId' in data) {
            const phase = PhaseCollection.phasesById[data.phaseId]
            const task = phase?.defaultTask || phase?.tasks[0]
            this.selectedValues = {
                ...this.selectedValues,
                taskId: task.id,
                isBillable: task?.isBillable ?? true,
                isVariation: task?.isVariation ?? false,
            }
        }
        // budgetStore.getBudget(
        //     new Date(), //weekStart
        //     new Date(), //selectedDate
        //     this.mode,
        //     this.selectedValues.staffId,
        //     this.selectedValues.projectId,
        //     this.selectedValues.phaseId
        // )
        this.storeLocal()
    }
    @action.bound
    changeBudgetType(newType) {
        this.budgetType = newType
        this.storeLocal()
    }
    @computed
    get budget() {
        // TODO
        return {
            budget: 0,
            use: 0,
            percent: 0,
        }
    }
    @action.bound
    saveTimeEntry() {
        this.pauseTimer()
        this.saveState = 'saving'
        const timeEntryData = {
            costCentreId: this.selectedValues.costCentreId,
            projectId: this.selectedValues.projectId,
            phaseId: this.selectedValues.phaseId,
            taskId: this.selectedValues.taskId,
            staffId: SessionStore.userId,
            date: this.startTime || new Date(),
            numMinutes: Math.round(this.seconds / 60) || 0,
            startMinutes: Math.max(
                Math.round(
                    differenceInMinutes(
                        subSeconds(this.startTime, this.prevSeconds),
                        startOfDay(this.startTime)
                    )
                ),
                0
            ),
            notes: this.selectedValues.notes,
            isBillable: this.selectedValues.isBillable ?? true,
            isVariation: this.selectedValues.isVariation ?? false,
            isOvertime: this.selectedValues.isOvertime ?? false,
            remote: this.selectedValues.remote ?? false,
            flexi: this.selectedValues.flexi ?? false,
            isLocked: this.selectedValues.isLocked ?? false,
        }
        const te = TimeEntryCollection.add(timeEntryData, {
            trackUpdates: true,
        })
        DataStore.saveModel(te).then(() => {
            this.saveState = 'saved'
            this.resetTimer()
            setTimeout(() => (this.saveState = 'idle'), 500)
        })
    }
    @action.bound
    storeLocal() {
        localforage.setItem(
            'timer',
            superjson.stringify({
                timerState: this.timerState,
                selectedValues: {
                    ...this.selectedValues,
                    staffId: SessionStore.userId,
                },
                budgetType: this.budgetType,
                prevSeconds: this.prevSeconds,
                startTime: this.startTime,
            })
        )
    }
    @computed
    get interfaceDisabled() {
        return !this.initialized || this.saveState !== 'idle'
    }
    @computed
    get timeEntries() {
        return (
            TimeEntryCollection.timeEntriesByStaffIdDate[
                tuple(SessionStore.userId, startOfDay(new Date()).getTime())
            ] || []
        )
    }
}

export default new timerStore()
