import React, { useState } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import SessionStore from '../../State/SessionStore'
import TimerIcon from '../../Components/Timer/TimerIcon'
import { observer } from 'mobx-react'
import {
    canCreateProjects,
    canCreateStaff,
    canViewBilling,
    canViewInvoiceSettings,
    canViewRoute,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import { Link, useNavigate } from '@tanstack/react-router'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import LayoutStore from '../../State/LayoutStore'
import InvoiceDescriptionTemplateModal from '../InvoiceListPage/InvoiceDescriptionTemplateModal'
import InvoiceSettingsModal from '../InvoiceListPage/InvoiceSettingsModal'
import NewInvoiceModal from '../InvoiceListPage/NewInvoiceModal'
import {
    UserButton,
    OrganizationSwitcher,
    OrganizationProfile,
} from '@clerk/clerk-react'

const menuItems = [
    {
        label: 'Forecasts',
        children: [
            {
                label: 'Revenue Forecast',
                route: '/revenue-forecast',
                permissions: (user) => canViewRoute(user, 'revenueForecast'),
            },
            {
                label: 'Revenue Forecast 2',
                route: '/revenue-forecast2',
                permissions: (user) => canViewRoute(user, 'revenueForecast2'),
            },
            {
                label: 'Resource Schedule',
                route: '/resource-schedule',
                permissions: (user) => canViewRoute(user, 'resourceSchedule'),
            },
            {
                label: 'Weekly Planning',
                route: '/weekly-planning',
                permissions: (user) => canViewRoute(user, 'weeklyPlanning'),
            },
        ],
    },
    {
        label: 'Projects',
        children: [
            {
                label: 'Project List',
                route: '/projects',
                permissions: (user) => canViewRoute(user, 'projectList'),
            },
            {
                label: '+ Create Project',
                onClick: () => {
                    const project = ProjectCollection.createProject()
                    return `/projects/${project.id}`
                },
                permissions: (user) => canViewRoute(user, 'createProject'),
            },
        ],
    },
    {
        label: 'Staff',
        children: [
            {
                label: 'Staff List',
                route: '/staff',
                permissions: (user) => canViewRoute(user, 'staffList'),
            },
            {
                label: 'Staff Roles',
                route: '/staff-roles',
                permissions: (user) => canViewRoute(user, 'staffRoleList'),
            },
            {
                label: 'Permissions',
                route: '/permissions',
                permissions: (user) => canViewRoute(user, 'staff-permissions'),
            },
            {
                label: '+ Create Staff',
                onClick: () => {
                    const staff = StaffCollection.createStaff()
                    return `/staff/${staff.id}`
                },
                permissions: (user) => canViewRoute(user, 'createStaff'),
            },
        ],
    },
    {
        label: 'Invoices',
        children: [
            {
                label: 'Invoice List',
                route: '/invoices',
                permissions: (user) => canViewRoute(user, 'invoiceList'),
            },
            {
                label: 'Invoice Templates',
                onClick: () => {
                    LayoutStore.openModal(
                        <InvoiceDescriptionTemplateModal
                            modalId={'invoice-templates'}
                        />
                    )
                },
                permissions: (user) => canViewRoute(user, 'invoiceTemplates'),
            },
            {
                label: 'Invoice Settings',
                onClick: () => {
                    LayoutStore.openModal(
                        <InvoiceSettingsModal modalId={'invoice-settings'} />
                    )
                },
                permissions: (user) => canViewRoute(user, 'invoiceSettings'),
            },
            {
                label: '+ Create Invoice',
                onClick: () => {
                    LayoutStore.openModal(
                        <NewInvoiceModal modalId={'new-invoice'} />
                    )
                },
                permissions: (user) => canViewRoute(user, 'createInvoice'),
            },
        ],
    },
    {
        label: 'Timesheets',
        children: [
            {
                label: 'Timesheets',
                route: '/timesheets',
                permissions: (user) => canViewRoute(user, 'timesheets'),
            },
            {
                label: 'Calendar',
                route: '/timesheets/calendar',
                permissions: (user) => canViewRoute(user, 'timesheetCalendar'),
            },
            {
                label: 'Timesheet Reports',
                route: '/timesheets/report',
                permissions: (user) => canViewRoute(user, 'timesheetReports'),
            },
        ],
    },
    {
        label: 'Expenses',
        children: [
            {
                label: 'Track Expenses',
                route: '/track-expenses',
                permissions: (user) => canViewRoute(user, 'trackExpenses'),
            },
            {
                label: 'Expense Reports',
                route: '/expense-reports',
                permissions: (user) => canViewRoute(user, 'expenseReports'),
            },
            {
                label: 'Suppliers',
                route: '/suppliers',
                permissions: (user) => canViewRoute(user, 'suppliers'),
            },
        ],
    },
    {
        label: 'Organisation',
        children: [
            {
                label: 'Overhead Expenses',
                route: '/overhead-expenses',
                permissions: (user) => canViewRoute(user, 'overheadExpenses'),
            },
            {
                label: 'Cost Centres',
                route: '/cost-centres',
                permissions: (user) => canViewRoute(user, 'costCentres'),
            },
            {
                label: 'Holidays',
                route: '/organisation-holidays',
                permissions: (user) =>
                    canViewRoute(user, 'organisationHolidays'),
            },
            {
                label: 'Contacts',
                route: '/contacts',
                permissions: (user) => canViewRoute(user, 'contacts'),
            },
            {
                label: 'Settings',
                route: '/settings',
                permissions: (user) => canViewRoute(user, 'settings'),
            },
        ],
    },
]

const menuStructure = () => {
    const menu = []
    menuItems.forEach((item) => {
        const children = item.children.filter((child) =>
            child.permissions(SessionStore.user)
        )
        if (children.length > 0) {
            menu.push([item.label, children])
        }
    })
    return menu
}

export default observer(() => {
    const [hoveredItem, setHoveredItem] = useState(null)
    const [pointerDownTs, setPointerDownTs] = useState(0)
    return (
        <header className="coincraft-header">
            <div className="header-bar dont-print text-444 bg-gold justify-between w-full lg:flex-row flex-col flex items-center shadow-[rgba(0,0,0,0.2)_0px_4px_8px] m-0 px-8 py-0 text-sm">
                <div className="navigation">
                    {menuStructure().map(([menu, pages], i) => {
                        return (
                            <MenuItem
                                key={i}
                                menu={menu}
                                pages={pages}
                                i={i}
                                setHoveredItem={setHoveredItem}
                                hoveredItem={hoveredItem}
                            />
                        )
                    })}
                </div>
                <div className="account-info text-right items-center flex">
                    <div className="user-info items-center flex">
                        <TimerIcon />
                        {!usingNewLogin() ? (
                            <div className="inline-block text-[0.95em] py-[0.25em] px-[0.5em]">
                                {SessionStore.user?.fullName}
                            </div>
                        ) : null}
                        {canViewBilling(SessionStore.user) ? (
                            <div
                                className="billing-btn"
                                style={{
                                    marginRight: '1em',
                                    marginLeft: '1em',
                                }}
                            >
                                <Link to={'/billing'}>Billing</Link>
                            </div>
                        ) : null}
                        <div className="inline-flex text-[0.95em] ">
                            {!usingNewLogin() ? (
                                <a onClick={() => SessionStore.logout()}>
                                    Sign Out &nbsp;
                                    <i className="fa fa-sign-out"></i>
                                </a>
                            ) : (
                                <>
                                    <UserButton showName={true} />
                                    {/* <OrganizationSwitcher
                                        hidePersonal={true}
                                        hideSlug={true}
                                    /> */}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
})

const MenuItem = observer(({ menu, pages, i, hoveredItem, setHoveredItem }) => {
    const navigate = useNavigate()
    const ref = React.useRef()
    const [isPointerUp, setIsPointerUp] = React.useState(false)
    return (
        <div
            key={menu + i}
            ref={ref}
            className="inline-block relative whitespace-nowrap hover:bg-highlighted focus:bg-highlighted"
            data-menu-item-id={menu}
            onPointerOut={(e) => {
                if (isPointerUp) {
                    setIsPointerUp(false)
                    return
                }
                if (!ref.current.contains(e.relatedTarget)) {
                    setHoveredItem(null)
                }
            }}
            onPointerUp={() => {
                setIsPointerUp(true)
            }}
        >
            <div
                onPointerOver={(e) => {
                    if (menu !== hoveredItem) {
                        setHoveredItem(menu)
                    } else {
                        setHoveredItem(null)
                    }
                }}
                className={classNames(
                    'nav-button',
                    'cursor-pointer font-semibold inline-block px-6 py-4',
                    menu === hoveredItem && 'bg-highlighted'
                )}
            >
                {menu}
            </div>
            <div
                className={classNames(
                    'nav-dropdown z-[1001] bg-[rgb(255,222,102)] absolute shadow-[rgba(0,0,0,0.2)_0px_8px_16px] left-0 top-full',
                    menu === hoveredItem ? 'inline-block' : 'hidden'
                )}
            >
                {pages.map((page) => {
                    return page.onClick ? (
                        <button
                            key={page.id}
                            className={classNames(
                                'nav-dropdown-item',
                                'cursor-pointer w-full block pl-6 pr-10 py-4 border-t-[rgb(255,233,153)] border-b-[rgb(255,211,51)] border-t border-solid border-b hover:bg-[linear-gradient(hsla(47,100%,80%,1),hsla(47,100%,90%,1))]',
                                {
                                    highlighted: false,
                                }
                            )}
                            onClick={() => {
                                // [Refactoring Needed] this workaround to done because redirect is not working.
                                // Will look for better solution later.
                                const url = page.onClick()
                                if (url) navigate({ to: url })
                                setHoveredItem(null)
                            }}
                        >
                            {page.label}
                        </button>
                    ) : (
                        <Link
                            key={page.id}
                            className={classNames(
                                'nav-dropdown-item',
                                'cursor-pointer block pl-6 pr-10 py-4 border-t-[rgb(255,233,153)] border-b-[rgb(255,211,51)] border-t border-solid border-b hover:bg-[linear-gradient(hsla(47,100%,80%,1),hsla(47,100%,90%,1))]',
                                {
                                    highlighted: false,
                                }
                            )}
                            to={page.route}
                            onClick={() => {
                                setHoveredItem(null)
                            }}
                        >
                            {page.label}
                        </Link>
                    )
                })}
            </div>
        </div>
    )
})
