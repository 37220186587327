import { useState } from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'
import { cn } from '@2/lib/utils'
import { Button } from '@2/components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@2/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'

export type ComboboxOption = {
    value: string
    label: string
}

export type GenericComboboxProps<
    TOption extends ComboboxOption = ComboboxOption,
> = {
    options: TOption[]
    placeholder?: string
    emptyMessage?: string
    searchPlaceholder?: string
    className?: string
    value?: string
    onChange?: (value: string, option: TOption) => void
    initialValue?: string
    editable?: boolean
}

export function GenericCombobox<
    TOption extends ComboboxOption = ComboboxOption,
>(props: GenericComboboxProps<TOption>) {
    const {
        options,
        placeholder = 'Select an option...',
        emptyMessage = 'No option found.',
        searchPlaceholder = 'Search...',
        className = '',
        onChange,
        value: valueFromProps,
        initialValue,
        editable,
    } = props
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(initialValue)

    const innerValue = props.hasOwnProperty('value') ? valueFromProps : value

    const handleSelect = (currentValue: string, option: TOption) => {
        setValue(currentValue === value ? '' : currentValue)
        setOpen(false)
        if (onChange) {
            onChange(currentValue === value ? '' : currentValue, option)
        }
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    disabled={!editable}
                    className={`w-[200px] justify-between ${className}`}
                >
                    {innerValue
                        ? options.find((option) => option.value === innerValue)
                              ?.label
                        : placeholder}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
                <Command value={innerValue}>
                    <CommandInput placeholder={searchPlaceholder} />
                    <CommandList>
                        <CommandEmpty>{emptyMessage}</CommandEmpty>
                        <CommandGroup>
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={(current) =>
                                        handleSelect(current, option)
                                    }
                                    disabled={!editable}
                                >
                                    <Check
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            value === option.value
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default GenericCombobox
