import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import TimeEntryAggregateCollection from '../State/Aggregates/TimeEntryAggregateCollection'
import tuple from 'immutable-tuple'
import { FormatNumber } from '../Utils/Localisation/NumberFormatter'
import { FormatPercent } from '../Utils/Localisation/PercentFormatter'
import RenderOnQueries from '../Pages/Layout/RenderOnQueries'
import {
    addDays,
    endOfISOWeek,
    endOfWeek,
    format,
    formatISO,
    startOfISOWeek,
} from 'date-fns'
import { qf } from '../Queries/queryFormatter'
import FetchStore from '../Queries/FetchStore'
import ProjectCollection from '../State/Collections/ProjectCollection'
import { Progress } from './ui/Progress'
import { Selector } from './Selector'

const budgetDataRequirements = ({
    projects,
    phase,
    staff,
    role,
    startDate,
    endDate,
    extraHours,
}) => {
    return [
        {
            id:
                'budgetTimeEntries' +
                projects.map((p) => p.id).join(',') +
                (endDate ? format(endDate, 'yyyy-MM-dd') : 'null'),
            collection: 'timeEntries',
            fields: [
                ['numMinutes', 'sum(numMinutes)'],
                'month',
                'projectId',
                'phaseId',
                'staffId',
                'roleId',
            ],
            filters: [
                `projectId in ${qf(projects.map((p) => p.id))}`,
                `(date <= ${qf(endDate)} or (date <= ${qf(addDays(endOfWeek(endDate), 1))} and staffId != ${qf(staff.id)}))`,
            ],
            groupBy: ['projectId', 'phaseId', 'staffId', 'roleId', 'month'],
        },
        {
            id:
                'weeklyBudgetTimeEntries' +
                projects.map((p) => p.id).join(',') +
                (endDate ? format(endDate, 'yyyy-MM-dd') : 'null'),
            collection: 'timeEntries',
            fields: [
                ['numMinutes', 'sum(numMinutes)'],
                'week',
                'projectId',
                'phaseId',
                'staffId',
                'roleId',
            ],
            filters: [
                `projectId in ${qf(projects.map((p) => p.id))}`,
                `(date >= ${qf(addDays(endDate, 1))} and date <= ${qf(addDays(endOfWeek(endDate), 1))} and staffId != ${qf(staff.id)})`,
            ],
            groupBy: ['projectId', 'phaseId', 'staffId', 'roleId', 'week'],
        },
        {
            id: 'budgetPhases' + projects.map((p) => p.id).join(','),
            collection: 'phases',
            fields: ['projectId', 'hoursBudget'],
            filters: [`projectId in ${qf(projects.map((p) => p.id))}`],
        },
        {
            id: 'budgetProjects' + projects.map((p) => p.id).join(','),
            collection: 'budgetedHours',
            filters: [
                `projectId in ${qf(projects.map((p) => p.id))}`,
                `hours > 0`,
            ],
            fields: ['projectId', 'phaseId', 'staffId', 'roleId', 'hours'],
        },
        {
            id:
                'budgetAllocations' +
                projects.map((p) => p.id).join(',') +
                (startDate ? format(startDate, 'yyyy-MM-dd') : 'null') +
                (endDate ? format(endDate, 'yyyy-MM-dd') : 'null'),
            collection: 'monthlyAllocations',
            fields: [
                'projectId',
                'phaseId',
                'numMinutes',
                'staffId',
                'roleId',
                'month',
            ],
            filters: [
                `projectId in ${qf(projects.map((p) => p.id))}`,
                `month == ${qf(format(addDays(endDate, 1), 'yyyy-MM'))}`,
                'numMinutes > 0',
            ],
        },
        {
            id:
                'weeklyBudgetAllocations' +
                projects.map((p) => p.id).join(',') +
                (endDate ? format(endDate, 'yyyy-MM-dd') : 'null'),
            collection: 'weeklyAllocations',
            fields: [
                'projectId',
                'phaseId',
                'numMinutes',
                'staffId',
                'roleId',
                'week',
            ],
            filters: [
                `projectId in ${qf(projects.map((p) => p.id))}`,
                `week == ${qf(format(addDays(endDate, 1), 'RRRR-II'))}`,
                'numMinutes > 0',
            ],
        },
    ]
}

const budgetLookup = {
    projectBudgetForTeam: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                undefined,
                undefined,
                undefined,
                undefined,
                endDate
            ) + extraHours,
        denominator: project?.hoursBudget || 0,
    }),
    projectBudgetForRole: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                undefined,
                role?.id || staff?.role?.id,
                undefined,
                undefined,
                endDate
            ) + extraHours,
        denominator: _.sum(
            project?.budgets
                ?.filter(
                    (b) =>
                        !b.staff &&
                        (b.role === role || b.role === staff.role) &&
                        !b.deletedAt
                )
                .map((b) => b.hours)
        ),
    }),
    projectBudgetForStaff: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                undefined,
                role?.id || staff?.role?.id,
                staff?.id,
                undefined,
                endDate
            ) + extraHours,
        denominator: _.sum(
            project?.budgets
                ?.filter((b) => b.staff === staff)
                .map((b) => b.hours)
        ),
    }),
    phaseBudgetForTeam: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                phase?.id,
                undefined,
                undefined,
                undefined,
                endDate
            ) + extraHours,
        denominator: phase?.hoursBudget || 0,
    }),
    phaseBudgetForRole: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                phase?.id,
                role?.id || staff?.role?.id,
                undefined,
                undefined,
                endDate
            ) + extraHours,
        denominator: _.sum(
            phase?.budgets
                ?.filter(
                    (b) =>
                        !b.staff &&
                        (b.role === role || b.role === staff.role) &&
                        !b.deletedAt
                )
                .map((b) => b.hours)
        ),
    }),
    phaseBudgetForStaff: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                phase?.id,
                role?.id || staff?.role?.id,
                staff?.id,
                undefined,
                endDate
            ) + extraHours,
        denominator: _.sum(
            phase?.budgets?.filter((b) => b.staff === staff).map((b) => b.hours)
        ),
    }),
    monthlyProjectBudgetForTeam: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                undefined,
                undefined,
                undefined,
                startDate,
                endDate
            ) + extraHours,
        denominator: _.sum(
            project?.allocations
                ?.filter(
                    (b) => b.month == format(addDays(endDate, 1), 'yyyy-MM')
                )
                .map((a) => a.hours)
        ),
    }),
    monthlyProjectBudgetForRole: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                undefined,
                role?.id || staff?.role?.id,
                undefined,
                startDate,
                endDate
            ) + extraHours,
        denominator: _.sum(
            project?.allocations
                ?.filter(
                    (b) =>
                        !b.staff &&
                        (b.role === role || b.role === staff.role) &&
                        !b.deletedAt &&
                        b.month == format(addDays(endDate, 1), 'yyyy-MM')
                )
                .map((a) => a.hours)
        ),
    }),
    monthlyProjectBudgetForStaff: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                undefined,
                role?.id || staff?.role?.id,
                staff?.id,
                startDate,
                endDate
            ) + extraHours,
        denominator: _.sum(
            project?.allocations
                ?.filter(
                    (b) =>
                        b.staff === staff &&
                        b.month == format(addDays(endDate, 1), 'yyyy-MM')
                )
                .map((a) => a.hours)
        ),
    }),
    monthlyPhaseBudgetForTeam: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                phase?.id,
                undefined,
                undefined,
                startDate,
                endDate
            ) + extraHours,
        denominator: _.sum(
            phase.allocations
                ?.filter(
                    (b) => b.month == format(addDays(endDate, 1), 'yyyy-MM')
                )
                .map((a) => a.hours)
        ),
    }),
    monthlyPhaseBudgetForRole: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                phase?.id,
                role?.id || staff?.role?.id,
                undefined,
                startDate,
                endDate
            ) + extraHours,
        denominator: _.sum(
            phase.allocations
                ?.filter(
                    (b) =>
                        !b.staff &&
                        (b.role === role || b.role === staff.role) &&
                        !b.deletedAt &&
                        b.month == format(addDays(endDate, 1), 'yyyy-MM')
                )
                .map((a) => a.hours)
        ),
    }),
    monthlyPhaseBudgetForStaff: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => ({
        numerator:
            getAggregateHoursInDateRange(
                projects.map((p) => p.id),
                project?.id,
                phase?.id,
                role?.id || staff?.role?.id,
                staff?.id,
                startDate,
                endDate
            ) + extraHours,
        denominator: _.sum(
            phase.allocations
                ?.filter(
                    (b) =>
                        b.staff === staff &&
                        b.month == format(addDays(endDate, 1), 'yyyy-MM')
                )
                .map((a) => a.hours)
        ),
    }),
    weeklyPhaseBudgetForTeam: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => {
        startDate = startOfISOWeek(startDate)
        return {
            numerator:
                getWeeklyAggregateHoursInDateRange(
                    projects.map((p) => p.id),
                    project?.id,
                    phase?.id,
                    undefined,
                    undefined,
                    startDate,
                    endDate
                ) + extraHours,
            denominator: _.sum(
                phase.weeklyAllocations
                    ?.filter(
                        (b) =>
                            b.week ==
                            format(addDays(endOfISOWeek(endDate), 1), 'RRRR-II')
                    )
                    .map((a) => a.hours)
            ),
        }
    },
    weeklyPhaseBudgetForRole: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => {
        startDate = startOfISOWeek(startDate)
        return {
            numerator:
                getWeeklyAggregateHoursInDateRange(
                    projects.map((p) => p.id),
                    project?.id,
                    phase?.id,
                    role?.id || staff?.role?.id,
                    undefined,
                    startDate,
                    endDate
                ) + extraHours,
            denominator: _.sum(
                phase.weeklyAllocations
                    ?.filter(
                        (b) =>
                            !b.staff &&
                            (b.role === role || b.role === staff.role) &&
                            !b.deletedAt &&
                            b.week ==
                                format(
                                    addDays(endOfISOWeek(endDate), 1),
                                    'RRRR-II'
                                )
                    )
                    .map((a) => a.hours)
            ),
        }
    },
    weeklyPhaseBudgetForStaff: ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours,
    }) => {
        startDate = startOfISOWeek(startDate)
        return {
            numerator:
                getWeeklyAggregateHoursInDateRange(
                    projects.map((p) => p.id),
                    project?.id,
                    phase?.id,
                    role?.id || staff?.role?.id,
                    staff?.id,
                    startDate,
                    endDate
                ) + extraHours,
            denominator: _.sum(
                phase.weeklyAllocations
                    ?.filter(
                        (b) =>
                            b.staff === staff &&
                            b.week ==
                                format(
                                    addDays(endOfISOWeek(endDate), 1),
                                    'RRRR-II'
                                )
                    )
                    .map((a) => a.hours)
            ),
        }
    },
}

const getAggregateHoursInDateRange = (
    projectIds,
    projectId,
    phaseId,
    roleId,
    staffId,
    startDate,
    endDate
) => {
    return _.sum(
        (
            FetchStore.getResponse(
                'budgetTimeEntries' +
                    projectIds.join(',') +
                    (endDate ? format(endDate, 'yyyy-MM-dd') : 'null')
            )?.timeEntries.timeEntriesByModelTuple[
                tuple(projectId, phaseId, roleId, staffId)
            ] || []
        )
            ?.filter(
                (t) =>
                    (!endDate || t.date <= endDate) &&
                    (!startDate || t.date >= startDate)
            )
            .map((t) => t.hours)
    )
}

const getWeeklyAggregateHoursInDateRange = (
    projectIds,
    projectId,
    phaseId,
    roleId,
    staffId,
    startDate,
    endDate
) => {
    return _.sum(
        (
            FetchStore.getResponse(
                'weeklyBudgetTimeEntries' +
                    projectIds.join(',') +
                    (endDate ? format(endDate, 'yyyy-MM-dd') : 'null')
            )?.timeEntries.timeEntriesByModelTuple[
                tuple(projectId, phaseId, roleId, staffId)
            ] || []
        )
            // ?.filter(
            //     (t) =>
            //         (!endDate || t.date <= endDate) &&
            //         (!startDate || t.date >= startDate)
            // )
            .map((t) => t.hours)
    )
}

export const budgetNames = {
    projectBudgetForTeam: 'Project Budget for Team',
    projectBudgetForRole: 'Project Budget for Role',
    projectBudgetForStaff: 'Project Budget for Staff Member',
    monthlyProjectBudgetForTeam: 'Monthly Project Budget for Team',
    monthlyProjectBudgetForRole: 'Monthly Project Budget for Role',
    monthlyProjectBudgetForStaff: 'Monthly Project Budget for Staff Member',
    phaseBudgetForTeam: 'Phase Budget for Team',
    phaseBudgetForRole: 'Phase Budget for Role',
    phaseBudgetForStaff: 'Phase Budget for Staff Member',
    monthlyPhaseBudgetForTeam: 'Monthly Phase Budget for Team',
    monthlyPhaseBudgetForRole: 'Monthly Phase Budget for Role',
    monthlyPhaseBudgetForStaff: 'Monthly Phase Budget for Staff Member',
    weeklyPhaseBudgetForTeam: 'Weekly Phase Budget for Team',
    weeklyPhaseBudgetForRole: 'Weekly Phase Budget for Role',
    weeklyPhaseBudgetForStaff: 'Weekly Phase Budget for Staff Member',
}

export default observer(
    ({
        project,
        projects,
        phase,
        staff,
        role,
        startDate,
        endDate,
        budget,
        setBudget,
        extraHours = 0,
        textStyles = {},
        barStyles = {},
        dropdownStyles = {},
        containerStyles = {},
        dropdownAbove = false,
        dropdownClassName,
        variant,
        ...props
    }) => {
        projects = projects || [project]
        let budgetOptions = [
            project && !phase ? 'projectBudgetForTeam' : null,
            project &&
            !phase &&
            (role || staff.role) &&
            project?.budgets?.filter(
                (b) =>
                    !b.staff &&
                    (b.role === role || b.role === staff.role) &&
                    !b.deletedAt &&
                    b.hours > 0
            )?.length
                ? 'projectBudgetForRole'
                : null,
            project &&
            !phase &&
            staff &&
            project?.budgets?.filter((b) => b.staff === staff && b.hours > 0)
                ?.length
                ? 'projectBudgetForStaff'
                : null,
            phase ? 'phaseBudgetForTeam' : null,
            phase &&
            (role || staff?.role) &&
            phase?.budgets?.filter(
                (b) =>
                    !b.staff &&
                    (b.role === role || b.role === staff.role) &&
                    !b.deletedAt &&
                    b.hours > 0
            )?.length
                ? 'phaseBudgetForRole'
                : null,
            phase &&
            staff &&
            phase?.budgets?.filter((b) => b.staff === staff && b.hours > 0)
                ?.length
                ? 'phaseBudgetForStaff'
                : null,
            project && !phase && project.allocations?.length
                ? 'monthlyProjectBudgetForTeam'
                : null,
            project &&
            !phase &&
            (role || staff?.role) &&
            project?.allocations?.filter(
                (b) =>
                    !b.staff &&
                    (b.role === role || b.role === staff.role) &&
                    !b.deletedAt &&
                    b.hours > 0
            )?.length
                ? 'monthlyProjectBudgetForRole'
                : null,
            project &&
            !phase &&
            staff &&
            project?.allocations?.filter(
                (b) => b.staff === staff && b.numMinutes > 0
            )?.length
                ? 'monthlyProjectBudgetForStaff'
                : null,
            phase && phase.monthlyAllocations?.length
                ? 'monthlyPhaseBudgetForTeam'
                : null,
            phase &&
            (role || staff?.role) &&
            phase?.monthlyAllocations?.filter(
                (b) =>
                    !b.staff &&
                    (b.role === role || b.role === staff.role) &&
                    !b.deletedAt &&
                    b.numMinutes > 0
            )?.length
                ? 'monthlyPhaseBudgetForRole'
                : null,
            phase &&
            staff &&
            phase?.monthlyAllocations?.filter(
                (b) => b.staff === staff && b.numMinutes > 0
            )?.length
                ? 'monthlyPhaseBudgetForStaff'
                : null,
            phase && phase.weeklyAllocations?.length
                ? 'weeklyPhaseBudgetForTeam'
                : null,
            phase &&
            (role || staff?.role) &&
            phase?.weeklyAllocations?.filter(
                (b) =>
                    !b.staff &&
                    (b.role === role || b.role === staff.role) &&
                    !b.deletedAt &&
                    b.numMinutes > 0
            )?.length
                ? 'weeklyPhaseBudgetForRole'
                : null,
            phase &&
            staff &&
            phase?.weeklyAllocations?.filter(
                (b) => b.staff === staff && b.numMinutes > 0
            )?.length
                ? 'weeklyPhaseBudgetForStaff'
                : null,
        ]?.filter((o) => o)
        const [selectedBudget, setSelectedBudget] = useState(budget)
        return (
            <div style={containerStyles} {...props}>
                {dropdownAbove && (
                    <Selector
                        style={{
                            zIndex: 100,
                            width: '100%',
                            fontSize: '1rem',
                            ...dropdownStyles,
                        }}
                        selectedOption={
                            selectedBudget ||
                            budgetOptions[budgetOptions?.length - 1] ||
                            'projectBudgetForTeam'
                        }
                        onChange={(v) => {
                            setSelectedBudget(v)
                            setBudget && setBudget(v)
                        }}
                        className={`budget-selector ${dropdownClassName || ''}`}
                        options={budgetOptions}
                        optionLabel={(bOpt) => {
                            return budgetNames[bOpt]
                        }}
                        variant="secondary"
                    />
                )}
                <RenderOnQueries
                    queryIds={budgetDataRequirements({
                        projects: projects || [project],
                        phase,
                        staff,
                        role,
                        startDate,
                        endDate,
                        extraHours,
                    })}
                >
                    <BudgetProgressBar
                        {...{
                            projects,
                            project,
                            phase,
                            staff,
                            role,
                            startDate,
                            endDate,
                            extraHours,
                            selectedBudget:
                                selectedBudget ||
                                budgetOptions[budgetOptions?.length - 1] ||
                                'projectBudgetForTeam',
                            barStyles,
                            textStyles,
                            variant: variant,
                        }}
                    />
                </RenderOnQueries>

                {!dropdownAbove && (
                    <Selector
                        style={{
                            zIndex: 1000,
                            width: '100%',
                            ...dropdownStyles,
                        }}
                        selectedOption={
                            selectedBudget ||
                            budgetOptions[budgetOptions?.length - 1] ||
                            'projectBudgetForTeam'
                        }
                        onChange={(v) => {
                            setSelectedBudget(v)
                            setBudget && setBudget(v)
                        }}
                        className={'budget-selector'}
                        options={budgetOptions}
                        optionLabel={(bOpt) => {
                            return budgetNames[bOpt]
                        }}
                        variant="secondary"
                    />
                )}
            </div>
        )
    }
)

const BudgetProgressBar = observer(
    ({
        projects,
        project,
        phase,
        staff,
        role,
        startDate,
        endDate,
        extraHours = 0,
        selectedBudget,
        barStyles,
        textStyles,
        variant,
    }) => {
        const budgetUse = budgetLookup[selectedBudget]({
            projects,
            project,
            phase,
            staff,
            role,
            startDate,
            endDate,
            extraHours,
        })
        const budgetProgress = budgetUse.numerator / budgetUse.denominator
        let progressGradient = { '0%': '#108ee9' }
        progressGradient[`${Math.round((0.5 / budgetProgress) * 100)}%`] =
            '#87d068'
        if (budgetProgress > 0.8) {
            progressGradient[`${Math.round((0.8 / budgetProgress) * 100)}%`] =
                '#ffc200'
        }
        if (budgetProgress > 1) {
            progressGradient[`${Math.round((1 / budgetProgress) * 100)}%`] =
                '#ff5800'
        }
        function makeCSSGradient(colorStops) {
            // Start the gradient string with the direction
            let gradient = 'linear-gradient(to right'

            // Add each color stop to the gradient string
            for (const position in colorStops) {
                gradient += `, ${colorStops[position]} ${position}`
            }

            // Close the gradient string
            gradient += ')'

            return gradient
        }
        return (
            <>
                <div
                    className="text-[#666] text-[13px] px-0 pb-2"
                    style={{
                        ...textStyles,
                    }}
                >
                    {`${FormatNumber(budgetUse.numerator)} / ${FormatNumber(
                        budgetUse.denominator
                    )}${
                        isFinite(budgetProgress)
                            ? ` (${FormatPercent(budgetProgress)})`
                            : ''
                    }`}
                </div>
                <Progress
                    value={budgetProgress * 100}
                    // showInfo={false}
                    fill={makeCSSGradient(progressGradient)}
                    // trailColor="#dddddd"
                    // style={{ padding: '0 0.75rem', ...barStyles }}
                />
                {variant === 'secondary' ? <div className="mb-1" /> : null}
            </>
        )
    }
)
