import localforage from 'localforage'
import { observable, computed, action, makeObservable, reaction } from 'mobx'
import AdjustForecastsModal from '../Pages/ProjectPage/AdjustForecastsModal'
import { makeRequest } from '../Queries/makeRequest'
import {
    CollectionLookup,
    collections,
    autoSaveCollections,
} from './CollectionLookup'
import PhaseCollection from './Collections/PhaseCollection'
import SessionStore from './SessionStore'
import React from 'react'
import LayoutStore from './LayoutStore'
import BudgetCollection from './Collections/BudgetCollection'
import cuid from 'cuid'
import ProjectExpenseCollection from './Collections/ProjectExpenseCollection'
import DateRangeDataStore from './DateRangeDataStore'
import BatchDataStore from './BatchDataStore'
import * as Sentry from '@sentry/react'
import {
    alwaysUpdateHours,
    alwaysUpdateRevenue,
    neverAdjustForecasts,
    shouldOpenUpdateForecastModal,
    shouldOpenUpdateForecastModalForResources,
    shouldOpenUpdateForecastModalForRevenue,
} from './Permissions/HasPermissions'
import debounce from 'lodash/debounce'
import InvoiceLineItemCollection from './Collections/InvoiceLineItemCollection'

class DataStore {
    @observable state = 'uninitialized' // fetching, success, error
    @observable rawData = {}
    @observable saveState = 'idle'
    @observable autosave = false
    @observable lastSaveAttemptAt = Date.now()
    @observable saveFunctions = {}
    @observable funcUpdatedAt = Date.now()
    idsToSave = new Set()
    constructor(props) {
        makeObservable(this)
        reaction(() => this.updatedAt, this.whenNeedsSaving)
        // autosave
        reaction(() => this.updatedAt, this.startAutosave)
        reaction(() => this.funcUpdatedAt, this.startAutosave)
        this.debouncedStartSave = debounce(this.startSave.bind(this), 500)
    }
    @action.bound
    addSaveFunction(id, f) {
        this.saveFunctions[id] = f
        this.saveState = 'needsSaving'
        this.funcUpdatedAt = Date.now()
    }
    @action.bound
    setAutosave(autosave) {
        this.autosave = autosave
    }
    @action.bound
    async startAutosave() {
        if (this.autosave) {
            this.debouncedStartSave()
        }
    }
    @action.bound
    whenNeedsSaving() {
        if (this.needsSaving) {
            this.saveState = 'needsSaving'
        }
    }
    @computed
    get needsSaving() {
        return (
            autoSaveCollections.some((c) => c.needsSaving) ||
            autoSaveCollections.some(
                (c) => c.updatedAt > this.lastSaveAttemptAt
            ) ||
            DateRangeDataStore.needsSaving ||
            BatchDataStore.needsSaving ||
            Object.values(this.saveFunctions).length > 0
        )
    }
    @computed
    get updatedAt() {
        return Math.max(...autoSaveCollections.map((c) => c.updatedAt))
    }
    @action.bound
    async retrySave() {
        this.saveState = 'idle'
        await this.startSave()
    }
    @action.bound
    destroyNewItems(idProp, id) {
        if (this.saveState !== 'saving') {
            autoSaveCollections.forEach((c) => {
                c.newUnsavedModels.forEach((m) => {
                    if (m.id === id || m[idProp] === id) {
                        if (c.collection === 'invoiceLineItems') {
                            m.timeEntries.forEach((te) => {
                                te.update({ beenInvoiced: false })
                            })
                            m.expenseItems.forEach((ei) => {
                                ei.update({ beenInvoiced: false })
                            })
                        }
                        c.delete(m.id)
                    }
                })
            })
        }
        if (!this.needsSaving) {
            this.saveState = 'idle'
        }
    }
    @action.bound
    async startSave() {
        if (
            this.needsSaving &&
            this.saveState !== 'saving' //&&
            //this.saveState !== 'error'
        ) {
            this.saveState = 'saving'
            const savedAt = Date.now()
            const saveData = {}
            const modals = {}
            const freshModels = []
            const failedModels = []
            autoSaveCollections.forEach((c) => {
                if (c.needsSaving) {
                    freshModels.push(
                        ...c.modelsToSave.filter((m) => !m.failedToSave)
                    )
                    failedModels.push(
                        ...c.modelsToSave.filter((m) => m.failedToSave)
                    )
                }
            })
            const trySavingFailedModels = !freshModels.length
            let modelsInBatch = trySavingFailedModels
                ? failedModels
                : freshModels
            modelsInBatch.forEach((m) => {
                saveData[m.collection.collection] ??= []
                saveData[m.collection.collection].push(this.serializeModel(m))
            })
            try {
                const { data: newIds } = await makeRequest({
                    path: `api/v1.5/save`,
                    method: 'post',
                    data: {
                        ...saveData,
                    },
                })
                if (trySavingFailedModels) {
                    modelsInBatch.forEach((m) => {
                        m.failedToSave = false
                    })
                }
                modelsInBatch = []
                Object.entries(newIds).forEach(([collection, idLookup]) => {
                    const c = CollectionLookup[collection]
                    Object.values(idLookup).forEach((id) => {
                        c.update(
                            id,
                            {},
                            {
                                trackUpdates: false,
                                savedAt: savedAt,
                            }
                        )
                    })
                })
                this.saveState = 'success'
                this.idsToSave = new Set()

                openAdjustForecastModal(saveData, modals)

                Object.values(modals).forEach((modal) => {
                    LayoutStore.openModal(modal)
                })
                const saveFunctions = Object.entries(this.saveFunctions)
                for (const [id, f] of Object.entries(this.saveFunctions)) {
                    try {
                        const test = await f()
                        delete this.saveFunctions[id]
                    } catch (e) {
                        console.error(
                            `Error saving function with id ${id}: ${e}`
                        )
                        throw e
                    }
                }
                if (this.needsSaving) {
                    this.startSave()
                } else {
                    setTimeout(() => {
                        if (this.saveState === 'success')
                            this.saveState = 'idle'
                    }, 1000)
                }
            } catch (e) {
                modelsInBatch.forEach((m) => {
                    m.failedToSave = true
                })
                Sentry.captureException(e)
                console.error(e.message)
                this.saveState = 'error'
            }
            await BatchDataStore.startSave()
            await DateRangeDataStore.startSave()
            SessionStore.checkIfUpdateIsAvailable()
        }
    }
    @action.bound
    serializeModel(model) {
        // if (!SessionStore.canEditModel(model))
        //     throw new Error('Cannot Edit Model')
        let data = model.serializeUpdates()
        // if (
        //     data.createdAt &&
        //     !SessionStore.canCreateInCollection(model.collection.collection)
        // )
        //     throw new Error('Cannot Create Model')
        // if (
        //     data.deletedAt &&
        //     !SessionStore.canDeleteInCollection(model.collection.collection)
        // )
        //     throw new Error('Cannot Delete Model')
        Object.keys(data).forEach((field) => {
            if (
                ['id', 'id', 'updatedAt', 'deletedAt', 'createdAt'].includes(
                    field
                )
            )
                return
            // if (!SessionStore.canEditField(model, field)) {
            //     delete data[field]
            // }
        })
        return data
    }
    @action.bound
    async saveModel(model) {
        this.saveState = 'saving'
        const savedAt = Date.now()
        const saveData = {}
        try {
            saveData[model.collection.collection] = [this.serializeModel(model)]
            const { data: newIds } = await makeRequest({
                path: `api/v1.5/save`,
                method: 'post',
                data: saveData,
            })
            !model.detached &&
                Object.entries(newIds).forEach(([collection, idLookup]) => {
                    const c = CollectionLookup[collection]
                    Object.values(idLookup).forEach((id) => {
                        c.update(
                            id,
                            {},
                            {
                                trackUpdates: false,
                                savedAt: savedAt,
                            }
                        )
                    })
                })
            this.saveState = 'success'
            if (this.needsSaving) {
                this.startSave()
            } else {
                setTimeout(() => {
                    if (this.saveState === 'success') this.saveState = 'idle'
                }, 1000)
            }
        } catch (e) {
            Sentry.captureException(e)
            console.error(e.message)
            this.saveState = 'error'
        }
    }
}

export default new DataStore()

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

const openAdjustForecastModal = (saveData, modals) => {
    if (neverAdjustForecasts()) return
    const adjustedDatesPhases =
        (saveData.phases?.length &&
            saveData.phases.filter((ph) => ph.startDate || ph.endDate)) ||
        []
    const adjustRevenueTargetPhaseIds =
        (saveData.phases?.length &&
            saveData.phases
                .filter(
                    (ph) => ph.fee != null || adjustedDatesPhases.includes(ph)
                )
                .map((ph) => ph.id)) ||
        []
    const adjustInvoiceLineItemsPhaseIds =
        (saveData.invoiceLineItems?.length &&
            saveData.invoiceLineItems
                .filter(
                    (i) =>
                        i?.unitQuantity != null ||
                        i?.unitCost != null ||
                        i?.billingType
                )
                .map(
                    (i) =>
                        InvoiceLineItemCollection.invoiceLineItemsById[i.id]
                            ?.phaseId
                )) ||
        []
    const adjustChangeLogPhaseIds =
        (saveData.changeLogItems?.length &&
            saveData.changeLogItems?.map((c) => c.phaseId)) ||
        []
    const adjustAllocationBudgetPhaseIds = [
        ...new Set([
            ...adjustedDatesPhases.map((ph) => ph.id),
            ...((saveData.budgetedHours?.length &&
                saveData.budgetedHours.map(
                    (b) => BudgetCollection.budgetsById[b.id].phaseId
                )) ||
                []),
        ]),
    ]
    const adjustAllocationProjectExpensePhaseIds =
        (saveData.projectExpenses?.length &&
            saveData.projectExpenses
                .filter((e) => e.cost != null || e.startDate || e.endDate)
                .map(
                    (e) =>
                        ProjectExpenseCollection.projectExpensesById[e.id]
                            .phaseId
                )) ||
        []
    const adjustPhaseIds = [
        ...adjustRevenueTargetPhaseIds,
        ...adjustInvoiceLineItemsPhaseIds,
        ...adjustChangeLogPhaseIds,
    ]
    if (
        adjustPhaseIds.length ||
        adjustAllocationBudgetPhaseIds?.length ||
        adjustAllocationProjectExpensePhaseIds?.length
    ) {
        if (
            (shouldOpenUpdateForecastModalForRevenue() &&
                (adjustRevenueTargetPhaseIds?.length ||
                    adjustInvoiceLineItemsPhaseIds?.length ||
                    adjustAllocationProjectExpensePhaseIds?.length ||
                    adjustChangeLogPhaseIds?.length)) ||
            (shouldOpenUpdateForecastModalForResources() &&
                adjustAllocationBudgetPhaseIds?.length)
        ) {
            const modalId = 'adjustFee' + cuid()
            modals[modalId] = (
                <AdjustForecastsModal
                    phaseIds={adjustPhaseIds}
                    budgetPhaseIds={adjustAllocationBudgetPhaseIds}
                    expensePhaseIds={adjustAllocationProjectExpensePhaseIds}
                    revenue={Boolean(
                        adjustPhaseIds?.length ||
                            adjustAllocationProjectExpensePhaseIds?.length
                    )}
                    hours={Boolean(adjustAllocationBudgetPhaseIds?.length)}
                    modalId={modalId}
                />
            )
        }
        if (alwaysUpdateRevenue() && adjustRevenueTargetPhaseIds?.length) {
            makeRequest({
                path:
                    process.env.REACT_APP_NODE_SERVER_URL +
                    '/auto-adjust/revenue',
                method: 'POST',
                data: {
                    organisationId: SessionStore.organisation.id,
                    userId: SessionStore.user?.id,
                    phaseIds: adjustPhaseIds,
                    budgetType:
                        SessionStore.organisation.settings?.autoUpdateRevenue
                            ?.budget || 'remaining',
                    startDateType:
                        SessionStore.organisation.settings?.autoUpdateRevenue
                            ?.start || 'now',
                    endDateType:
                        SessionStore.organisation.settings?.autoUpdateRevenue
                            ?.end || 'endDate',
                    nowTs: Date.now(),
                },
            })
        }
        if (
            alwaysUpdateRevenue() &&
            adjustAllocationProjectExpensePhaseIds?.length
        ) {
            makeRequest({
                path:
                    process.env.REACT_APP_NODE_SERVER_URL +
                    '/auto-adjust/expenses',
                method: 'POST',
                data: {
                    organisationId: SessionStore.organisation.id,
                    userId: SessionStore.user?.id,
                    phaseIds: adjustAllocationProjectExpensePhaseIds,
                    budgetType:
                        SessionStore.organisation.settings?.autoUpdateRevenue
                            ?.budget || 'remaining',
                    startDateType:
                        SessionStore.organisation.settings?.autoUpdateRevenue
                            ?.start || 'now',
                    endDateType:
                        SessionStore.organisation.settings?.autoUpdateRevenue
                            ?.end || 'endDate',
                    nowTs: Date.now(),
                },
            })
        }
        if (alwaysUpdateHours() && adjustAllocationBudgetPhaseIds?.length) {
            makeRequest({
                path:
                    process.env.REACT_APP_NODE_SERVER_URL +
                    '/auto-adjust/resources',
                method: 'POST',
                data: {
                    organisationId: SessionStore.organisation.id,
                    userId: SessionStore.user?.id,
                    phaseIds: adjustAllocationBudgetPhaseIds,
                    budgetType:
                        SessionStore.organisation.settings?.autoUpdateHours
                            ?.budget || 'remaining',
                    startDateType:
                        SessionStore.organisation.settings?.autoUpdateHours
                            ?.start || 'now',
                    endDateType:
                        SessionStore.organisation.settings?.autoUpdateHours
                            ?.end || 'endDate',
                    nowTs: Date.now(),
                },
            })
        }
    }
}
