import { useMemo } from 'react'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    ChartLegend,
    ChartLegendContent,
} from '@2/components/ui/chart'
import {
    useData,
    useDateRange,
    useOverheadExpenses,
    useStaffExpenses,
} from './revenue-forecast-store'
import { format, parse, eachMonthOfInterval } from '@2/utils/date-fns'
import { FormatCurrency } from '@/Utils/Localisation/CurrencyFormatter'
import { cn } from '@/version2/lib/utils'
export const RevenueForecastChart = () => {
    const projectData = useData()
    const dateRange = useDateRange()
    const overheadExpenses = useOverheadExpenses()
    const staffExpenses = useStaffExpenses()

    const chartData = useMemo(() => {
        if (!projectData?.length || !dateRange[0] || !dateRange[1]) return []

        const months = eachMonthOfInterval({
            start: parse(dateRange[0], 'yyyy-MM-dd', new Date()),
            end: parse(dateRange[1], 'yyyy-MM-dd', new Date()),
        })
        const thisMonthKey = format(new Date(), 'yyyy-MM')
        return months.map((month) => {
            const monthKey = format(month, 'yyyy-MM')
            const monthLabel = format(month, 'MMM yy')

            const totalRevenue = projectData.reduce((total, statusGroup) => {
                const projected = (statusGroup[`${monthKey}-P`] as number) || 0
                const actual = (statusGroup[`${monthKey}-A`] as number) || 0

                if (monthKey < thisMonthKey) {
                    return total + actual
                } else {
                    return total + projected
                }
                return total + Math.max(actual, projected)
            }, 0)

            const totalExpenses =
                (
                    (overheadExpenses || []) as { [key: string]: number }[]
                ).reduce((total, overheadExpense) => {
                    return total + (overheadExpense[`${monthKey}`] || 0)
                }, 0) +
                ((staffExpenses || []) as { [key: string]: number }[]).reduce(
                    (total, staffExpense) => {
                        return total + (staffExpense[`${monthKey}`] || 0)
                    },
                    0
                )

            return {
                name: monthLabel,
                revenue: totalRevenue,
                expenses: totalExpenses,
            }
        })
    }, [projectData, dateRange])

    const chartConfig = {
        revenue: {
            label: 'Revenue',
            color: 'hsl(var(--primary))',
        },
        expenses: {
            label: 'Expenses',
            color: 'hsl(var(--destructive))',
        },
    }

    return (
        <ChartContainer config={chartConfig} className="h-[300px] w-full">
            <AreaChart
                data={chartData}
                margin={{ top: 10, right: 30, left: 20, bottom: 20 }}
            >
                <defs>
                    <filter
                        id="dotShadow"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                    >
                        <feDropShadow
                            dx="0"
                            dy="1"
                            stdDeviation="2"
                            floodOpacity="0.3"
                        />
                    </filter>
                    <linearGradient
                        id="gradientRevenue"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="hsl(var(--primary))"
                            stopOpacity={0.75}
                        />
                        <stop
                            offset="95%"
                            stopColor="hsl(var(--primary))"
                            stopOpacity={0.3}
                        />
                    </linearGradient>
                    <linearGradient
                        id="gradientExpenses"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="hsl(var(--destructive))"
                            stopOpacity={0.75}
                        />
                        <stop
                            offset="95%"
                            stopColor="hsl(var(--destructive))"
                            stopOpacity={0.1}
                        />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey="name"
                    tickLine={false}
                    tickMargin={20}
                    fontSize="0.8rem"
                    axisLine={false}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickMargin={20}
                    fontSize="0.8rem"
                    tickFormatter={(value) =>
                        new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            notation: 'compact',
                        }).format(value)
                    }
                />
                <CartesianGrid
                    strokeOpacity={0.25}
                    strokeWidth={0.5}
                    stroke="hsl(var(--card-foreground))"
                />
                <ChartTooltip
                    content={({ payload, className }) => {
                        const revenue = payload[0]?.payload?.revenue || 0
                        const expenses = payload[0]?.payload?.expenses || 0
                        const profit = revenue - expenses
                        const profitMargin =
                            revenue > 0 ? (profit / revenue) * 100 : 0
                        return (
                            <div
                                className={cn(
                                    'grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl',
                                    className
                                )}
                            >
                                <div>
                                    <div className="font-medium mb-1">
                                        {payload[0]?.payload?.name}
                                    </div>
                                    <div
                                        className={cn(
                                            profit < 0 && 'text-red-500'
                                        )}
                                    >
                                        <span className="mr-2">Profit:</span>
                                        <span>
                                            {FormatCurrency(profit, {
                                                decimals: 0,
                                            })}{' '}
                                            ({profitMargin.toFixed(1)}
                                            %)
                                        </span>
                                    </div>
                                    <div>
                                        <div className="flex items-center">
                                            <div
                                                className="h-2 w-2 shrink-0 rounded-[2px] mr-2"
                                                style={{
                                                    backgroundColor:
                                                        'hsl(var(--primary))',
                                                }}
                                            />
                                            <span className="mr-2">
                                                Revenue:
                                            </span>
                                            <span>
                                                {FormatCurrency(revenue, {
                                                    decimals: 0,
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center">
                                            <div
                                                className="h-2 w-2 shrink-0 rounded-[2px] mr-2"
                                                style={{
                                                    backgroundColor:
                                                        'hsl(var(--destructive))',
                                                }}
                                            />
                                            <span className="mr-2">
                                                Expenses:
                                            </span>
                                            <span>
                                                {FormatCurrency(expenses, {
                                                    decimals: 0,
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />
                <ChartLegend content={<ChartLegendContent />} />
                {/* Revenue Areas - White stroke underneath */}
                {/* <Area
                    type="monotone"
                    dataKey="revenue"
                    stroke="#fbfaf9"
                    strokeOpacity={0.25}
                    fill="url(#gradientRevenue)"
                    fillOpacity={1}
                    strokeWidth={6}
                    dot={false}
                /> */}
                {/* Revenue Areas - Colored stroke on top */}
                <Area
                    type="monotone"
                    dataKey="revenue"
                    stroke="hsl(var(--primary))"
                    // fill="none"
                    fill="url(#gradientRevenue)"
                    strokeWidth={2}
                    activeDot={{
                        stroke: '#fbfaf9',
                        strokeWidth: 1,
                        r: 4,
                        filter: 'url(#dotShadow)',
                    }}
                />

                {/* Expenses Areas - White stroke underneath */}
                {/* <Area
                    type="monotone"
                    dataKey="expenses"
                    stroke="#fbfaf9"
                    strokeOpacity={0.25}
                    fill="url(#gradientExpenses)"
                    fillOpacity={1}
                    strokeWidth={6}
                    dot={false}
                /> */}
                {/* Expenses Areas - Colored stroke on top */}
                <Area
                    type="monotone"
                    dataKey="expenses"
                    stroke="hsl(var(--destructive))"
                    // fill="none"
                    fill="url(#gradientExpenses)"
                    strokeWidth={2}
                    activeDot={{
                        stroke: '#fbfaf9',
                        strokeWidth: 1,
                        r: 4,
                        filter: 'url(#dotShadow)',
                    }}
                />
            </AreaChart>
        </ChartContainer>
    )
}

export default RevenueForecastChart
