import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import uuid from 'uuid'
import { ProjectInterface } from './project-page.interface'
import { createQueryStore } from '@/version2/utils/query-store'

type StoreContext = Omit<ProjectInterface.ProjectDetail, 'project'> & {
    isPendingChanges: boolean
    project?: ProjectInterface.Project
}
// Initial state
export const initialContext: StoreContext = {
    isPendingChanges: false,
    budgetedHours: [],
    changeLogItems: [],
    invoiceLineItems: [],
    invoices: [],
    monthlyAllocations: [],
    phases: [],
    projectExpenses: [],
    projectNotes: [],
    projectRates: [],
    revenueTargets: [],
    tasks: [],
    weeklyAllocations: [],
}

export const initialRate = {
    date: new Date(),
    payRate: 0,
    overtimeRate: 0,
    costRate: 0,
    chargeOutRate: 0,
    isStaffRate: true,
}

// Create the store
export const projectStore = createStore({
    context: initialContext,
    on: {
        setData: {
            budgetedHours: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.budgetedHours,
            changeLogItems: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.changeLogItems,
            invoiceLineItems: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.invoiceLineItems,
            invoices: (_, event: { data: ProjectInterface.ProjectDetail }) =>
                event.data.invoices,
            monthlyAllocations: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.monthlyAllocations,
            phases: (_, event: { data: ProjectInterface.ProjectDetail }) =>
                event.data.phases,
            projectExpenses: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.projectExpenses,
            projectNotes: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.projectNotes,
            projectRates: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.projectRates,
            revenueTargets: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.revenueTargets,
            tasks: (_, event: { data: ProjectInterface.ProjectDetail }) =>
                event.data.tasks,
            weeklyAllocations: (
                _,
                event: { data: ProjectInterface.ProjectDetail }
            ) => event.data.weeklyAllocations,
        },
        updatePendingChanges: {
            isPendingChanges: (_, event: { isPendingChanges: boolean }) =>
                event.isPendingChanges,
        },
        reset: {
            ...initialContext,
        },
    },
})
export const staffStore = createQueryStore<StoreContext, StoreContext>(
    {
        context: initialContext,
        on: {
            setData: {
                budgetedHours: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.budgetedHours,
                changeLogItems: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.changeLogItems,
                invoiceLineItems: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.invoiceLineItems,
                invoices: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.invoices,
                monthlyAllocations: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.monthlyAllocations,
                phases: (_, event: { data: ProjectInterface.ProjectDetail }) =>
                    event.data.phases,
                projectExpenses: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.projectExpenses,
                projectNotes: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.projectNotes,
                projectRates: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.projectRates,
                revenueTargets: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.revenueTargets,
                tasks: (_, event: { data: ProjectInterface.ProjectDetail }) =>
                    event.data.tasks,
                weeklyAllocations: (
                    _,
                    event: { data: ProjectInterface.ProjectDetail }
                ) => event.data.weeklyAllocations,
            },
            updatePendingChanges: {
                isPendingChanges: (_, event: { isPendingChanges: boolean }) =>
                    event.isPendingChanges,
            },
            reset: {
                ...initialContext,
            },
        },
    },
    {
        queryKey: ['project-detail'],
        queryFn: async ({ id }) => {
            //
        },
        mutationFn: async (data) => {
            const result = true
            return result
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'project-detail',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to project detail?',
        loadingMessage: 'Saving project detail...',
        successMessage: 'Project detail saved successfully',
        errorMessage: 'Failed to save project detail',
    }
)

// Action creators
const setData = (data: ProjectInterface.ProjectDetail) =>
    projectStore.send({
        type: 'setData',
        data,
    })
const updatePendingChanges = (isPendingChanges: boolean) =>
    projectStore.send({
        type: 'updatePendingChanges',
        isPendingChanges,
    })
const reset = () =>
    projectStore.send({
        type: 'reset',
    })

export const projectActions = {
    setData,
    updatePendingChanges,
    reset,
}

// Selector hooks
export const useProjectDetail = () => {
    return useSelector(projectStore, (state) => state.context)
}
export const useBudgetedHours = () => {
    return useSelector(projectStore, (state) => state.context.budgetedHours)
}
export const useChangeLogItems = () => {
    return useSelector(projectStore, (state) => state.context.changeLogItems)
}
export const useInvoiceLineItems = () => {
    return useSelector(projectStore, (state) => state.context.invoiceLineItems)
}
export const useInvoices = () => {
    return useSelector(projectStore, (state) => state.context.invoices)
}
export const useMonthlyAllocations = () => {
    return useSelector(
        projectStore,
        (state) => state.context.monthlyAllocations
    )
}
export const usePhases = () => {
    return useSelector(projectStore, (state) => state.context.phases)
}
export const useProjectExpenses = () => {
    return useSelector(projectStore, (state) => state.context.projectExpenses)
}
export const useProjectNotes = () => {
    return useSelector(projectStore, (state) => state.context.projectNotes)
}
export const useProjectRates = () => {
    return useSelector(projectStore, (state) => state.context.projectRates)
}
export const useRevenueTargets = () => {
    return useSelector(projectStore, (state) => state.context.revenueTargets)
}
export const useTasks = () => {
    return useSelector(projectStore, (state) => state.context.tasks)
}
export const useWeeklyAllocations = () => {
    return useSelector(projectStore, (state) => state.context.weeklyAllocations)
}
export const usePendingChanges = () => {
    return useSelector(projectStore, (state) => state.context.isPendingChanges)
}

// Getter for non-reactive access
export const getProjectStoreContext = () => projectStore.getSnapshot().context
export const getProjectData = () => projectStore.getSnapshot().context.project
export const getBudgetedHours = () =>
    projectStore.getSnapshot().context.budgetedHours
export const getChangeLogItems = () =>
    projectStore.getSnapshot().context.changeLogItems
export const getInvoiceLineItems = () =>
    projectStore.getSnapshot().context.invoiceLineItems
export const getInvoices = () => projectStore.getSnapshot().context.invoices
export const getMonthlyAllocations = () =>
    projectStore.getSnapshot().context.monthlyAllocations
export const getPhases = () => projectStore.getSnapshot().context.phases
export const getProjectExpenses = () =>
    projectStore.getSnapshot().context.projectExpenses
export const getProjectNotes = () =>
    projectStore.getSnapshot().context.projectNotes
export const getProjectRates = () =>
    projectStore.getSnapshot().context.projectRates
export const getRevenueTargets = () =>
    projectStore.getSnapshot().context.revenueTargets
export const getTasks = () => projectStore.getSnapshot().context.tasks
export const getWeeklyAllocations = () =>
    projectStore.getSnapshot().context.weeklyAllocations

// Export store utilities
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = staffStore
