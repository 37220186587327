import React from 'react'
import CreateReactClass from 'create-react-class'
import { mergeProps } from './utils.js'

export var FormRow = CreateReactClass({
    render: function () {
        let props = mergeProps(
            {
                style: { padding: '0.5em' },
            },
            this.props
        )
        return <div {...props}>{this.props.children}</div>
    },
})

export var FormSection = CreateReactClass({
    render: function () {
        let props = mergeProps(
            {
                style: {
                    margin: '1em 0',
                    color: '#333',
                },
            },
            this.props
        )
        return <div {...props}>{this.props.children}</div>
    },
})

export var FormLabel = CreateReactClass({
    render: function () {
        let props = mergeProps(
            {
                style: {
                    fontWeight: 'normal',
                    verticalAlign: 'top',
                },
            },
            this.props
        )
        return <label {...props}>{this.props.children}</label>
    },
})

export var FormLabelInline = CreateReactClass({
    render: function () {
        let props = mergeProps(
            {
                style: {
                    display: 'inline-block',
                    fontWeight: 'normal',
                    verticalAlign: 'top',
                },
            },
            this.props
        )
        return <label {...props}>{this.props.children}</label>
    },
})

export var FormHeader = CreateReactClass({
    render: function () {
        let props = mergeProps(
            {
                style: {
                    borderBottom: 'solid 1px #eee',
                    padding: '.5em 1em',
                    fontSize: '1.7em',
                },
            },
            this.props
        )
        return <h3 {...props}>{this.props.children}</h3>
    },
})
