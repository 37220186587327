import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import wait from 'wait'
import LoadingSpinner from '../../Components/LoadingSpinner'
import * as Sentry from '@sentry/react'
import SessionStore from '../../State/SessionStore'
import { makeRequest } from '../../Queries/makeRequest'
import { useNavigate } from '@tanstack/react-router'
import { canViewRoute } from '../../State/Permissions/HasPermissions'

export default observer(({ token, redirect }) => {
    const navigate = useNavigate()
    const [validToken, setValidToken] = useState(null)
    useEffect(() => {
        makeRequest({
            path:
                process.env.REACT_APP_SERVER_URL +
                '/api/v1.5/user/verify-token',
            method: 'post',
            data: {
                token: token,
            },
        })
            .then((response) => {
                setValidToken(true)
                makeRequest({
                    path:
                        process.env.REACT_APP_SERVER_URL +
                        '/api/v1.5/user/activate-account',
                    method: 'post',
                    data: {
                        token: token,
                    },
                })
                    .then((response) => {
                        SessionStore.saveLoginResponse(response)
                        wait(500).then(() => {
                            navigate({
                                to:
                                    redirect ||
                                    (canViewRoute(
                                        SessionStore.user,
                                        'projectList'
                                    )
                                        ? '/projects'
                                        : '/timesheets'),
                            })
                        })
                    })
                    .catch((error) => {
                        wait(2000).then(() => {
                            navigate({ to: '/login' })
                        })
                    })
            })
            .catch((error) => {
                Sentry.captureException(error)
                setValidToken(false)
                wait(2000).then(() => {
                    navigate({ to: '/login' })
                })
            })
    }, [])
    if (validToken === null) {
        return <LoadingSpinner />
    }
    return (
        <div
            className="flex flex-align-items-center flex-justify-content-center"
            style={{
                textAlign: 'center',
                alignSelf: 'center',
            }}
        >
            <section className={'login-widget'}>
                <header className="text-align-center">
                    <div
                        style={{ width: '10em', height: '10em' }}
                        className="logo2 s75"
                    >
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                        />
                    </div>
                    <h1 style={{ fontSize: '3em' }}>
                        {validToken ? 'Account Activated!' : 'Token Expired'}
                    </h1>
                </header>
                <div className="body">
                    <h4
                        style={{
                            margin: '0em -2em 1em',
                            fontSize: '1.2em',
                        }}
                    >
                        Redirecting...
                    </h4>
                </div>
            </section>
        </div>
    )
})
