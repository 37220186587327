import React, { useState, useCallback, useEffect } from 'react'
import { X } from 'lucide-react'
import { cn } from '@/lib/utils'

let listeners = new Set()
let items = []

const emitChange = () => {
    listeners.forEach((listener) => listener(items))
}

export const useSidebarStack = () => {
    const [sidebarItems, setSidebarItems] = useState(items)

    useEffect(() => {
        listeners.add(setSidebarItems)
        return () => listeners.delete(setSidebarItems)
    }, [])

    const addToSidebar = useCallback((content, options = {}) => {
        const id = Math.random().toString(36).substring(7)
        items = [{ id, content, ...options }, ...items]
        emitChange()
        return id
    }, [])

    const removeFromSidebar = useCallback((id) => {
        items = items.filter((item) => item.id !== id)
        emitChange()
        return id
    }, [])

    const clearSidebar = useCallback(() => {
        items = []
        emitChange()
    }, [])

    return {
        addToSidebar,
        removeFromSidebar,
        clearSidebar,
        hasSidebarItems: items.length > 0,
    }
}

const ANIMATION_DURATION = 300 // ms

// A single sidebar panel that slides in on mount & out on close
function SidebarPanel({
    item,
    offset,
    isFrontPanel,
    removeFromSidebar,
    totalWidth,
    zIndex,
}: {
    item: any
    offset: number
    isFrontPanel: boolean
    removeFromSidebar: (id: string) => void
    totalWidth: number
    zIndex: number
}) {
    // Track whether this panel is "entering" and "exiting"
    const [mounted, setMounted] = useState(false) // for slide-in
    const [exiting, setExiting] = useState(false) // for slide-out

    // Trigger the "slide in" animation on first mount
    useEffect(() => {
        // Small delay so that the starting position is applied
        // before the transition kicks in
        const t = setTimeout(() => setMounted(true), 10)
        return () => clearTimeout(t)
    }, [])

    // When user closes the panel
    const handleClose = () => {
        setExiting(true)
        // Wait for the exit transition before removing from the store
        setTimeout(() => {
            removeFromSidebar(item.id)
        }, ANIMATION_DURATION)
    }

    return (
        <div
            className={cn(
                'absolute inset-0 bg-background flex flex-col h-full',
                'transition-all duration-300 ease-in-out',
                !isFrontPanel && 'border-l border-border'
            )}
            style={{
                // If not mounted (entering) or currently exiting,
                // shift the panel offscreen (to the right).
                // Otherwise, keep it at `offset`.
                transform: `translateX(${
                    mounted && !exiting ? offset : offset + totalWidth
                }px)`,
                width: `${totalWidth - offset}px`,
                boxShadow: !isFrontPanel
                    ? '-4px 0 8px -4px rgba(0, 0, 0, 0.1)'
                    : 'none',
                zIndex,
            }}
        >
            <div className="flex-1 overflow-auto relative">
                {!item.hideClose && (
                    <button
                        onClick={handleClose}
                        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 z-10"
                    >
                        <X size={16} />
                    </button>
                )}
                <div className="p-4">{item.content}</div>
            </div>
            <div className="h-28 w-full print:hidden flex-none" />
        </div>
    )
}

export const Sidebar = () => {
    const [sidebarItems, setSidebarItems] = useState([])
    const { removeFromSidebar } = useSidebarStack()

    useEffect(() => {
        // Listen for changes to the global sidebar stack
        const handleItems = (newItems: any[]) => setSidebarItems(newItems)
        listeners.add(handleItems)
        return () => {
            listeners.delete(handleItems)
        }
    }, [])

    if (sidebarItems.length === 0) return null

    // Calculate extra width based on number of visible panels
    const visiblePanels = Math.min(sidebarItems.length, 3)
    const extraWidth = (visiblePanels - 1) * 12
    const totalWidth = 320 + extraWidth

    return (
        <div
            className={cn(
                'flex-none border-l border-border',
                'transition-all duration-300 ease-in-out'
            )}
            style={{ width: `${totalWidth}px` }}
        >
            <div className="relative h-full">
                {sidebarItems.slice(0, 3).map((item, index, array) => {
                    const offset = (array.length - 1 - index) * 12
                    const isFrontPanel = index === array.length - 1
                    const zIndex = array.length - index

                    return (
                        <SidebarPanel
                            key={item.id}
                            item={item}
                            offset={offset}
                            isFrontPanel={isFrontPanel}
                            removeFromSidebar={removeFromSidebar}
                            totalWidth={totalWidth}
                            zIndex={zIndex}
                        />
                    )
                })}
            </div>
        </div>
    )
}
