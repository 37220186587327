import { createFileRoute } from '@tanstack/react-router'
import RegisterPage from '../../Pages/LoginPage/RegisterPage'
import ErrorComponent from '../../Components/ErrorComponent'

export const Route = createFileRoute('/_public/register')({
    component: Wrapper,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})

function Wrapper() {
    return (
        <>
            <RegisterPage {...Route.useParams()} {...Route.useSearch()} />
        </>
    )
}
