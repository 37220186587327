import { useMemo } from 'react'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { useStaffExpenses, useDateRange } from './revenue-forecast-store'
import { format, eachMonthOfInterval, parse } from '@2/utils/date-fns'
import { sortingDateFn, sortingTextFn } from '@/version2/types'
import { cn } from '@/version2/lib/utils'

interface TableRow {
    id: string
    name: string
    total: number
    [key: string]: any
}

export const RevenueForecastStaffTable = () => {
    const generateColumnDef = createGenerateColumnDef<TableRow>({
        onHideColumn: () => null,
    })

    const staffExpenses = useStaffExpenses()
    const dateRange = useDateRange()

    const months = useMemo(
        () =>
            dateRange[0] && dateRange[1]
                ? eachMonthOfInterval({
                      start: parse(dateRange[0], 'yyyy-MM-dd', new Date()),
                      end: parse(dateRange[1], 'yyyy-MM-dd', new Date()),
                  })
                : [],
        [dateRange]
    )

    const tableData = useMemo(() => {
        if (
            !staffExpenses ||
            !Array.isArray(staffExpenses) ||
            !staffExpenses.length
        )
            return []

        return staffExpenses
            .map((staff) => {
                if (!staff || !staff.id || !staff.name) return null

                return {
                    id: staff.id,
                    name: staff.name,
                    label: staff.name,
                    total: Object.entries(staff)
                        .filter(([key]) => key.match(/^\d{4}-\d{2}$/))
                        .reduce((sum, [_, value]) => {
                            const numValue =
                                typeof value === 'string'
                                    ? parseFloat(value)
                                    : (value as number)
                            return sum + (numValue || 0)
                        }, 0),
                    ...months.reduce(
                        (acc, month) => {
                            const monthKey = format(month, 'yyyy-MM')
                            const value = staff[monthKey]
                            acc[monthKey] =
                                typeof value === 'string'
                                    ? parseFloat(value)
                                    : (value as number) || 0
                            return acc
                        },
                        {} as Record<string, number>
                    ),
                }
            })
            .filter(Boolean)
            .sort((a, b) => a.name.localeCompare(b.name)) as TableRow[]
    }, [staffExpenses, months])

    const columns = useMemo(
        () => [
            generateColumnDef({
                key: 'name',
                title: 'Staff Member',
                type: 'text',
                size: 200,
            }),
            generateColumnDef({
                key: 'total',
                title: 'Total',
                type: 'currency',
                size: 120,
                aggregationFn: 'sum',
            }),
            ...months.map((month) =>
                generateColumnDef({
                    key: format(month, 'yyyy-MM'),
                    title: format(month, 'MMM yy'),
                    type: 'currency',
                    aggregationFn: 'sum',
                    size: 120,
                    cellClassName: (row) => {
                        const isPastMonth =
                            format(month, 'yyyy-MM') <
                            format(new Date(), 'yyyy-MM')
                        return cn(isPastMonth && 'bg-diagonal-pattern')
                    },
                })
            ),
        ],
        [months]
    )

    const visibleColumns = useMemo(
        () => [
            'name',
            'total',
            ...months.map((month) => format(month, 'yyyy-MM')),
        ],
        [months]
    )

    const { table } = useDataTable({
        data: tableData,
        columns,
        visibleColumns,
        groups: {
            enabled: false,
            columns: [],
            leafRowLabel: 'Staff Member',
        },
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
    })

    if (!tableData.length) return null

    return (
        <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4 pl-4">Staff Expenses</h2>
            <DataTable table={table} showTotals={true} className="w-full" />
        </div>
    )
}

export default RevenueForecastStaffTable
