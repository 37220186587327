import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import CashflowChart from '../../Components/Widgets/CashflowChart'
import ResourceScheduleStore, { groupProp } from './ResourceScheduleStore'
import { useMediaQuery } from '@react-hook/media-query'
import useResizeObserver from '@react-hook/resize-observer'
import RenderOnQueries from '../Layout/RenderOnQueries'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { ResourceScheduleReportAllocationColumns } from '../../reports/ResourceSchedule/ResourceScheduleReportAllocationColumns'
import { format } from 'date-fns'
import SessionStore from '../../State/SessionStore'
import { queryClient } from '../../App'
import UpdateReportModal from '../../Components/UpdateReportModal'
import ProjectForecastsStore from '../../Pages/ProjectPage/forecasts/ProjectForecastsStore'
import sortPhases from '../../Utils/sortPhases'

const useSize = (target) => {
    const [size, setSize] = React.useState()

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

const spacerColumn = {
    label: '',
    width: 3,
    type: 'text',
    value: (r) => '',
    onChange: (r) => (v) => null,
}

export default observer((params) => {
    useEffect(() => {
        ResourceScheduleStore.setSearchParams(params)
    }, [params])
    if (!SessionStore.settings.autoUpdateHours.adjustOnLoad) {
        return <SchedulePage />
    } else {
        return (
            <RenderOnQueries
                queryIds={[
                    {
                        id: 'auto-adjust-hours',
                        path:
                            process.env.REACT_APP_NODE_SERVER_URL +
                            '/auto-adjust/resources',
                        method: 'POST',
                        data: {
                            organisationId: SessionStore.organisation.id,
                            userId: SessionStore.user?.id,
                            budgetType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateHours?.budget || 'remaining',
                            startDateType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateHours?.start || 'now',
                            endDateType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateHours?.end || 'endDate',
                            nowTs: Date.now(),
                        },
                    },
                ]}
                loading={<LoadingSpinner />}
            >
                <SchedulePage />
            </RenderOnQueries>
        )
    }
})

const SchedulePage = observer(() => {
    const DataStore = require('../../State/DataStore').default
    useEffect(() => {
        DataStore.setAutosave(true)
        return () => {
            DataStore.setAutosave(false)
        }
    }, [])
    const isPrinting = useMediaQuery('print')
    const [store, setStore] = useState(ResourceScheduleStore)
    const target = React.useRef(null)
    const size = useSize(target)
    const widthRatio = size?.width / (store?.resourceTableStore?.width * 15)
    return (
        <div
            style={{
                padding: '0 2em',
                position: 'relative',
                // width: '100%',
            }}
        >
            <div ref={target} style={{ width: '100%', display: 'flex' }} />
            <RenderOnQueries
                loading={<LoadingSpinner />}
                queryIds={[
                    {
                        id:
                            `resourceTable` +
                            store.report.queryKey +
                            store.periodType,
                        baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                        path: `/resource-schedule/table`,
                        method: 'POST',
                        staleTime: 1000,
                        cacheTime: 1000,
                        data: {
                            organisationId: SessionStore.organisationId,
                            userId: SessionStore.user?.id,
                            filters: store.report.filters,
                            level: 0,
                            parentData: {},
                            dateRange: [
                                format(store.startDate, 'yyyy-MM-dd'),
                                format(store.endDate, 'yyyy-MM-dd'),
                            ],
                            periodType: store.periodType,
                        },
                    },
                ]}
            >
                <UpdateReportModal report={store.report} />
                <ResourceSchedule
                    store={store}
                    widthRatio={widthRatio}
                    isPrinting={isPrinting}
                />
            </RenderOnQueries>
        </div>
    )
})

const ResourceSchedule = observer(({ store, widthRatio, isPrinting }) => {
    const queryData = queryClient.getQueryData([
        `resourceTable` + store.report.queryKey + store.periodType,
    ])?.data
    useEffect(() => {
        store.init(queryData)
    }, [store.report.queryKey, store.periodType, queryData])
    return (
        <>
            <ResourceChart
                store={store}
                widthRatio={widthRatio}
                isPrinting={isPrinting}
            />
            <ResourceTable store={store} />
        </>
    )
})

const ResourceChart = observer(({ store, widthRatio, isPrinting }) => {
    const periods = [...Array(12)].map((v, i) =>
        store.addPeriods(store.startDate, i)
    )
    const periodAvailability = periods.map((m) =>
        store.getAvailabilityInPeriod('', format(m, store.periodFormat()))
    )
    const activePeriodAllocations = periods.map((m) => {
        return _.sum(
            store.queryData.map((r) =>
                store.getRowActiveHoursInPeriod(
                    r,
                    format(m, store.periodFormat())
                )
            )
        )
    })
    const prospectivePeriodAllocations = periods.map((m) => {
        return _.sum(
            store.queryData.map((r) =>
                store.getRowProspectiveHoursInPeriod(
                    r,
                    format(m, store.periodFormat())
                )
            )
        )
    })
    return (
        <>
            <CashflowChart
                revenue={periodAvailability.map((v) => v || 0)}
                expenses={activePeriodAllocations.map((v) => v || 0)}
                prospectiveExpenses={prospectivePeriodAllocations.map(
                    (v) => v || 0
                )}
                totalWidth={(18 * 2 + 8 * 11.5) * 10}
                graphWidth={8 * 11 * 10}
                height={22 * 10}
                sizeRatio={isPrinting ? widthRatio : 1}
            />
        </>
    )
})

const ResourceTable = observer(({ store, keys = [], rows } = {}) => {
    const level = keys.length
    const groups = store.report.filters.groups
    const isLastLevel = level === groups.length - 1
    const rowColumns = ResourceScheduleReportAllocationColumns(
        store,
        keys,
        ProjectForecastsStore
    )
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    const group = groups[level]
    return (
        <Table
            key={'ttt' + store.report.queryKey + level + parent?.id}
            tableStore={!level ? store.resourceTableStore : null}
            expandAll={store.expandAll}
            style={
                !level
                    ? { margin: '2em 0' }
                    : {
                          boxShadow: 'none',
                          background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                          fontSize: '1.2rem',
                      }
            }
            columns={[
                isLastLevel ? spacerColumn : null,
                rowColumns.title(level, {}),
                rowColumns.budgetUse(level, {}),
                ...[...Array(12)].map((v, i) => {
                    return rowColumns.hours(
                        store.addPeriods(store.startDate, i),
                        level,
                        {}
                    )
                }),
            ].filter((c) => c)}
            rows={[...(rows || store.queryData)].sort(
                group === 'phase'
                    ? sortPhases
                    : (a, b) => (a.title || '').localeCompare(b.title || '')
            )}
            // onExpand={(row) => {
            //     store.expandAllocationRow(filters)
            // }}
            // onCollapse={(row) => {
            //     store.collapseAllocationRow(filters)
            // }}
            getChildComponent={
                !isLastLevel
                    ? (r) => {
                          return (
                              <ResourceTable
                                  keys={[...keys, r.key]}
                                  store={store}
                                  rows={r.children}
                              />
                          )
                      }
                    : null
            }
            showHeader={!level}
            showTotals={!level}
        />
    )
})
