import { observable, computed, action, makeObservable, toJS } from 'mobx'
import Collection from './Collection'
import InvoiceModel from '../Models/InvoiceModel'
import { addDays } from 'date-fns'
import SessionStore from '../SessionStore'
import _ from 'lodash'
import InvoiceLineItemCollection from './InvoiceLineItemCollection'
import { canViewExpenseTracking } from '../Permissions/HasPermissions'

// can't put this at the top for some reason - circular deps?
const Formula = require('../../formulas/Formula').default

export function getInvoiceNumber(project) {
    const thisYear = new Date().getFullYear()
    const invoiceRefFormula = new Formula({
        ref: 'inv0',
        prop: 'description',
        formula: (
            SessionStore.organisation.settings.invoice.invoiceCode ||
            `format(invNum, "0000")`
        )
            .replace(
                '$project.invNum',
                SessionStore.organisation.settings.invoice.invNumProj[
                    project.id
                ] || 0
            )
            .replace(
                'invNumYear',
                SessionStore.organisation.settings.invoice.invNumYear[
                    String(thisYear)
                ] || 0
            )
            .replace(
                'invNum',
                SessionStore.organisation.settings.invoice.invNum || 0
            )
            .replace(/\$project/g, `#"pr${project.id}"`),
    })
    return invoiceRefFormula.value
}

export function getNewInvoiceNumber(project) {
    const thisYear = new Date().getFullYear()
    const invCode =
        SessionStore.organisation.settings.invoice.invoiceCode ||
        'format(invNum, "0000")'
    const updateInvNumProj = invCode.includes('$project.invNum')
    const updateInvNumYear = invCode.includes('invNumYear')
    if (!SessionStore.organisation.settings.invoice?.invNum) {
        SessionStore.organisation.updateInvoiceSettings({
            invNum: SessionStore.organisation.invNum || 0,
        })
    }
    if (
        updateInvNumProj &&
        !SessionStore.organisation.settings.invoice?.invNumProj?.[project.id]
    ) {
        SessionStore.organisation.updateInvoiceSettings({
            invNumProj: {
                ...(SessionStore.organisation.settings.invoice?.invNumProj ||
                    {}),
                [project.id]: SessionStore.organisation.invNumProj || 0,
            },
        })
    }
    if (
        updateInvNumYear &&
        !SessionStore.organisation.settings.invoice?.invNumYear?.[
            String(thisYear)
        ]
    ) {
        SessionStore.organisation.updateInvoiceSettings({
            invNumYear: {
                ...(SessionStore.organisation.settings.invoice?.invNumYear ||
                    {}),
                [String(thisYear)]: SessionStore.organisation.invNumYear || 0,
            },
        })
    }
    SessionStore.organisation.incrementInvoiceNumber(project.id)

    return getInvoiceNumber(project)
}

class InvoiceCollection extends Collection {
    constructor() {
        super({ collection: 'invoices', modelClass: InvoiceModel })
        this.addLookup('invoicesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('invoicesByContactId', 'manyByKey', {
            key: (t) => t.contactId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('invoicesByOldId', 'uniqueByKey', {
            key: (t) => t.oldId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get invoicesById() {
        return this.modelsById
    }
    get invoices() {
        return this.models.filter((i) => !i.deletedAt)
    }
    @action.bound
    addInvoice(data) {
        this.add(data)
    }
    @action.bound
    addInvoices(data) {
        this.addMany(data)
    }
    @action.bound
    createInvoice(project, startDate, endDate) {
        const invoiceRef = getNewInvoiceNumber(project)
        const invoice = this.add(
            {
                ref: invoiceRef,
                projectId: project.id,
                contactId: project?.contactId,
                startDate,
                endDate,
                issueDate: new Date(),
                dueDate: addDays(
                    new Date(),
                    SessionStore.organisation.settings.invoice
                        .numDaysBetweenIssueDateAndDueDate
                ),
                taxRatePercent:
                    SessionStore.organisation.accountingSystemSettings?.settings
                        ?.taxOnTaxType?.rate ?? 10,
                accountingSystemId: SessionStore.organisation.accountingSystem,
                cachedData: {
                    project: {
                        name: project.name,
                        jobNumber: project.jobNumber,
                    },
                    phases: Object.fromEntries(
                        project.phases.map((ph) => [
                            ph.id,
                            {
                                name: ph.name,
                                jobNumber: ph.jobNumber,
                                fee: ph.fee,
                                previousBilled: ph.previouslyBilled,
                            },
                        ])
                    ),
                    expenses: Object.fromEntries(
                        project.expenses.map((e) => [
                            e.id,
                            {
                                name: e.name,
                                cost: e.cost,
                                previousBilled: e.previouslyBilled,
                            },
                        ])
                    ),
                },
            },
            { trackUpdates: true }
        )
        project.phases
            .filter((ph) => !ph?.isRootPhase)
            .forEach((ph) => {
                const ili = InvoiceLineItemCollection.add(
                    {
                        invoiceId: invoice.id,
                        projectId: project.id,
                        contactId: invoice.contactId,
                        phaseId: ph.id,
                        billingType: 'agreedFee',
                        lineItemType: 'progress',
                        unitCost: ph.fee,
                        description: '[phase] ([phaseprogress] completion)',
                        isTaxed: true,
                    },
                    { trackUpdates: true }
                )
            })
        project.expenses.forEach((pe) => {
            if (!canViewExpenseTracking()) {
                if (
                    pe.billable &&
                    pe.endDate &&
                    pe.endDate <= endDate &&
                    pe.cost - pe.previouslyBilled > 0
                ) {
                    InvoiceLineItemCollection.add(
                        {
                            invoiceId: invoice.id,
                            projectId: project.id,
                            contactId: invoice.contactId,
                            phaseId: pe.phaseId || project.rootPhase.id,
                            billingType: 'reimbursement',
                            lineItemType: 'expense',
                            description: pe.name,
                            unitQuantity: 1,
                            unitCost: pe.cost - pe.previouslyBilled,
                            expenseId: pe.id,
                            isTaxed: true,
                        },
                        { trackUpdates: true }
                    )
                }
            } else if (pe.billable && pe.expenseItems.length > 0) {
                pe.expenseItems.forEach((pei) => {
                    InvoiceLineItemCollection.add(
                        {
                            invoiceId: invoice.id,
                            projectId: project.id,
                            contactId: invoice.contactId,
                            phaseId: pe.phaseId || project.rootPhase.id,
                            billingType: 'reimbursement',
                            lineItemType: 'expense',
                            description: pe.name,
                            unitQuantity: 1,
                            unitCost: pei.cost,
                            expenseId: pe.id,
                            expenseItemIds: [pei.id],
                            isTaxed: true,
                        },
                        { trackUpdates: true }
                    )
                    pei.update({ beenInvoiced: true })
                })
            }
        })
        return invoice
    }
}

export default new InvoiceCollection()
export const InvoiceCollectionClass = InvoiceCollection
