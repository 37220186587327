import * as React from 'react'

import { cn } from '@2/lib/utils'

const TextArea = React.forwardRef<
    HTMLTextAreaElement,
    React.ComponentProps<'textarea'>
>(({ className, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

    // Combine the forwarded ref with our local ref
    React.useImperativeHandle(
        ref,
        () => textareaRef.current as HTMLTextAreaElement
    )

    // Function to adjust the height of the textarea
    const adjustHeight = React.useCallback(() => {
        const textarea = textareaRef.current
        if (!textarea) return

        // Reset height to auto to get the correct scrollHeight
        textarea.style.height = 'auto'

        // Set the height based on content
        if (textarea.value.trim() === '') {
            // Set to 1 line height when empty
            textarea.style.height = '2rem' // approximately 1 line of text
        } else {
            // Set the height to match the content
            textarea.style.height = `${textarea.scrollHeight}px`
        }
    }, [])

    // Adjust height when content changes
    React.useEffect(() => {
        const textarea = textareaRef.current
        if (!textarea) return

        // Initial height adjustment
        adjustHeight()

        // Add event listeners
        textarea.addEventListener('input', adjustHeight)

        return () => {
            textarea.removeEventListener('input', adjustHeight)
        }
    }, [adjustHeight])

    return (
        <textarea
            className={cn(
                'flex w-full rounded-md border border-input bg-transparent px-3 py-2 text-base shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm overflow-hidden resize-none',
                className
            )}
            ref={textareaRef}
            {...props}
        />
    )
})
TextArea.displayName = 'Textarea'

export { TextArea }
