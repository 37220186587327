import { createFileRoute } from '@tanstack/react-router'
import RegisterDetailsPage from '../../Pages/LoginPage/RegisterDetailsPage'
import ErrorComponent from '../../Components/ErrorComponent'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export const Route = createFileRoute('/_public/register-details')({
    loader: async ({ location, context }) => {
        if (context.organization) {
            window.location.href = canViewRoute(
                SessionStore.user,
                'projectList'
            )
                ? '/projects'
                : '/timesheets'
        }
    },
    component: RegisterDetailsPage,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})
