import { createFileRoute, redirect } from '@tanstack/react-router'
import LoadingSpinner from '../Components/LoadingSpinner'
import SessionStore from '../State/SessionStore'
import { usingNewLogin } from '../State/Permissions/HasPermissions'
export const Route = createFileRoute('/')({
    beforeLoad: async ({ context }) => {
        // Use the context updated in the root route
        if (usingNewLogin() && !context.loaded) return
        if (!SessionStore.initialized || !SessionStore.authenticated) {
            throw redirect({ to: '/login' })
        } else {
            throw redirect({ to: '/projects' })
        }
    },
    pendingComponent: LoadingSpinner,
    component: LayoutComponent,
})

function LayoutComponent() {
    return <LoadingSpinner />
}
