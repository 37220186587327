import * as React from 'react'
import { useSearch, useNavigate } from '@tanstack/react-router'
import type { DataTableFilterField } from '@2/types'
import {
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    getExpandedRowModel,
    type ColumnDef,
    type ColumnFiltersState,
    type SortingState,
    type VisibilityState,
    aggregationFns,
    Row,
} from '@tanstack/react-table'

import { useDebounce } from '@2/hooks/use-debounce'
import { useTableSelection } from './use-table-selection'
import { createSelectableColumn } from '../columns/select-column'
import { createExpansionColumn } from '../columns/expand-column'
import { createLabelColumn } from '../columns/label-column'

interface UseDataTableProps<TData, TValue> {
    data: TData[]
    columns: ColumnDef<TData, TValue>[]
    visibleColumns: string[]
    // pageCount?: number
    // defaultPerPage?: number
    defaultSort?: SortingState
    filterFields?: DataTableFilterField<TData>[]
    enableAdvancedFilter?: boolean
    sortingFns?: any
    defaultColumn?: any
    selectable?: {
        enabled: boolean
        enableMultipleSelection?: boolean
        enableRowSelection?: (row: Row<TData>) => boolean
    }
    groups?: {
        enabled: boolean
        columns: string[]
        labelColumn?: ColumnDef<TData, any> // Allow overriding which column is used for the group label
        leafRowLabel?: string
    }
}

// const schema = z.object({
//     page: z.coerce.number().default(1),
//     per_page: z.coerce.number().optional(),
//     sort: z.string().optional(),
// })

export function useDataTable<TData, TValue>({
    data,
    columns,
    visibleColumns,
    // pageCount,
    // defaultPerPage,
    defaultSort,
    filterFields = [],
    enableAdvancedFilter = false,
    sortingFns,
    selectable,
    groups,
}: UseDataTableProps<TData, TValue>) {
    const navigate = useNavigate()
    const search = useSearch({ strict: false })

    const defaultColumnVisibility = columns.reduce((acc, column: any) => {
        acc[column.accessorKey] = visibleColumns.includes(column.accessorKey)
        return acc
    }, {} as VisibilityState)

    // Search params
    // const parsedSearch = schema.parse(search)

    // const page = parsedSearch.page
    // const perPage = parsedSearch.per_page ?? defaultPerPage
    // const [column, order] = sort?.split('.') ?? []

    // Memoize computation of searchableColumns and filterableColumns
    const { searchableColumns, filterableColumns } = React.useMemo(() => {
        return {
            searchableColumns: filterFields.filter(
                (field) => !field.options && field.isDefaultFilter === true
            ),
            filterableColumns: filterFields.filter(
                (field) => field.options && field.isDefaultFilter === true
            ),
        }
    }, [filterFields])

    // Create query string
    const createQueryString = React.useCallback(
        (params: Record<string, string | number | null>) => {
            const newSearch = { ...search }

            for (const [key, value] of Object.entries(params)) {
                if (value === null) {
                    delete newSearch[key]
                } else {
                    newSearch[key] = String(value)
                }
            }

            return newSearch
        },
        [search]
    )

    // Initial column filters
    const initialColumnFilters: ColumnFiltersState = React.useMemo(() => {
        return Object.entries(search).reduce<ColumnFiltersState>(
            (filters, [key, value]) => {
                const filterableColumn = filterableColumns.find(
                    (column) => column.value === key
                )
                const searchableColumn = searchableColumns.find(
                    (column) => column.value === key
                )

                if (filterableColumn) {
                    filters.push({
                        id: key,
                        value: value.split('.'),
                    })
                } else if (searchableColumn) {
                    filters.push({
                        id: key,
                        value: [value],
                    })
                }

                return filters
            },
            []
        )
    }, [filterableColumns, searchableColumns, search])

    // Table states
    const [rowSelection, setRowSelection] = React.useState({})

    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>(defaultColumnVisibility)
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>(initialColumnFilters)

    // Handle server-side pagination
    // const [{ pageIndex, pageSize }, setPagination] =
    //     React.useState<PaginationState>({
    //         pageIndex: page - 1,
    //         pageSize: perPage,
    //     })

    // const pagination = React.useMemo(
    //     () => ({
    //         pageIndex,
    //         pageSize,
    //     }),
    //     [pageIndex, pageSize]
    // )

    // Handle server-side sorting
    const [sorting, setSorting] = React.useState<SortingState>(defaultSort)

    // React.useEffect(() => {
    //     navigate({
    //         search: createQueryString({
    //             page: pageIndex + 1,
    //             per_page: pageSize,
    //             sort: sorting[0]?.id
    //                 ? `${sorting[0]?.id}.${sorting[0]?.desc ? 'desc' : 'asc'}`
    //                 : null,
    //         }),
    //         replace: true,
    //     })

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pageIndex, pageSize, sorting])

    // Handle server-side filtering
    const debouncedSearchableColumnFilters = JSON.parse(
        useDebounce(
            JSON.stringify(
                columnFilters.filter((filter) => {
                    return searchableColumns.find(
                        (column) => column.value === filter.id
                    )
                })
            ),
            500
        )
    ) as ColumnFiltersState

    const filterableColumnFilters = columnFilters.filter((filter) => {
        return filterableColumns.find((column) => column.value === filter.id)
    })

    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        // Opt out when advanced filter is enabled, because it contains additional params
        if (enableAdvancedFilter) return

        // Prevent resetting the page on initial render
        if (!mounted) {
            setMounted(true)
            return
        }

        // Initialize new params
        const newParamsObject = {}

        // Handle debounced searchable column filters
        for (const column of debouncedSearchableColumnFilters) {
            if (typeof column.value === 'string') {
                Object.assign(newParamsObject, {
                    [column.id]:
                        typeof column.value === 'string' ? column.value : null,
                })
            }
        }

        // Handle filterable column filters
        for (const column of filterableColumnFilters) {
            if (
                typeof column.value === 'object' &&
                Array.isArray(column.value)
            ) {
                Object.assign(newParamsObject, {
                    [column.id]: column.value.join('.'),
                })
            }
        }

        // Remove deleted values
        for (const key in search) {
            if (
                (searchableColumns.find((column) => column.value === key) &&
                    !debouncedSearchableColumnFilters.find(
                        (column) => column.id === key
                    )) ||
                (filterableColumns.find((column) => column.value === key) &&
                    !filterableColumnFilters.find(
                        (column) => column.id === key
                    ))
            ) {
                Object.assign(newParamsObject, { [key]: null })
            }
        }

        // After cumulating all the changes, push new params
        navigate({
            search: createQueryString(newParamsObject),
            replace: true,
        })

        // table.setPageIndex(0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(debouncedSearchableColumnFilters),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(filterableColumnFilters),
    ])

    const customAggregationFns = {
        progressBar: (
            columnId: string,
            leafRows: Row<TData>[],
            childRows: Row<TData>[]
        ) => {
            let numerator: number = 0
            let denominator: number = 0
            childRows.forEach((row) => {
                const value = row.getValue<number>(columnId)
                numerator += value?.numerator ?? 0
                denominator += value?.denominator ?? 0
            })

            return { numerator, denominator }
        },
        titleDefault: (
            columnId: string,
            leafRows: Row<TData>[],
            childRows: Row<TData>[]
        ) => {
            return 'Total'
        },
        nullData: null,
    }

    let tableColumns: ColumnDef<TData, any>[] = [...columns]

    if (groups?.enabled) {
        // Add label column if grouping is enabled
        const labelColumn =
            groups.labelColumn ||
            createLabelColumn<TData>({
                groupByColumns: groups.columns,
                columns,
            })

        // Add expansion column for grouped data
        const expansionColumn = createExpansionColumn<TData>({
            groupByColumns: [...groups.columns, groups.leafRowLabel],
            columns,
        })

        tableColumns = [expansionColumn, labelColumn, ...tableColumns]
    }

    if (selectable?.enabled) {
        const selectColumn = createSelectableColumn<TData>({
            enableMultipleSelection: selectable.enableMultipleSelection,
            enableRowSelection: selectable.enableRowSelection,
        })
        tableColumns = [selectColumn, ...tableColumns]
    }

    const table = useReactTable({
        data,
        columns: tableColumns,
        // pageCount: pageCount ?? -1,
        state: {
            // pagination,
            sorting,
            columnVisibility: defaultColumnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection:
            selectable?.enabled && selectable?.enableRowSelection,
        getSubRows: (row: any) => row.children,
        onRowSelectionChange: setRowSelection,
        // onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        sortingFns: sortingFns,
        manualPagination: true,
        aggregationFns: {
            ...aggregationFns, // Built-in aggregation functions
            ...customAggregationFns, // Your custom aggregation functions
        },
        // manualSorting: true,
        // manualFiltering: true,
        enableGlobalFilter: true,
    })

    return { table }
}
