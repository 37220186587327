import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'

export interface SaveOperation {
    id: string
    timestamp: number
    metadata?: Record<string, any>
    successMessage?: string
    errorMessage?: string
    loadingMessage?: string
}

interface SaveStoreContext {
    pendingOperations: SaveOperation[]
    savingOperations: SaveOperation[]
    activeToasts: Set<string>
}

// Initial state
const initialContext: SaveStoreContext = {
    pendingOperations: [],
    savingOperations: [],
    activeToasts: new Set(),
}

// Create the store
export const saveStore = createStore({
    context: initialContext,
    on: {
        addPendingOperation: {
            pendingOperations: (state, event: { operation: SaveOperation }) => {
                // replace any existing pending operation with same ID
                const filtered = state.pendingOperations.filter(
                    (op) => op.id !== event.operation.id
                )
                return [...filtered, event.operation]
            },
        },
        startSaving: {
            pendingOperations: (state, event: { id: string }) =>
                state.pendingOperations.filter((op) => op.id !== event.id),
            savingOperations: (state, event: { id: string }) => [
                ...state.savingOperations,
                state.pendingOperations.find((op) => op.id === event.id)!,
            ],
        },
        savingSucceeded: {
            savingOperations: (state, event: { id: string }) => {
                const newSavingOperations = state.savingOperations.filter(
                    (op) => op.id !== event.id
                )
                return newSavingOperations
            },
        },
        savingFailed: {
            savingOperations: (state, event: { id: string }) =>
                state.savingOperations.filter((op) => op.id !== event.id),
            pendingOperations: (state, event: { id: string }) => {
                const failedOperation = state.savingOperations.find(
                    (op) => op.id === event.id
                )
                if (!failedOperation) return state.pendingOperations

                // Only add back to pending if there isn't already an operation with the same id
                const hasPendingWithSameId = state.pendingOperations.some(
                    (op) => op.id === event.id
                )
                if (hasPendingWithSameId) return state.pendingOperations

                return [...state.pendingOperations, failedOperation]
            },
        },
        addActiveToast: {
            activeToasts: (state, event: { id: string }) =>
                new Set([...Array.from(state.activeToasts), event.id]),
        },
        removeActiveToast: {
            activeToasts: (state, event: { id: string }) => {
                const newSet = new Set(state.activeToasts)
                newSet.delete(event.id)
                return newSet
            },
        },
        clearOperation: {
            pendingOperations: (state, event: { id: string }) =>
                state.pendingOperations.filter((op) => op.id !== event.id),
            savingOperations: (state, event: { id: string }) =>
                state.savingOperations.filter((op) => op.id !== event.id),
        },
        reset: {
            pendingOperations: () => [],
            savingOperations: () => [],
            activeToasts: () => new Set(),
        },
    },
})

// Action creators
export const addPendingOperation = (operation: SaveOperation) =>
    saveStore.send({ type: 'addPendingOperation', operation })

export const startSaving = (id: string) =>
    saveStore.send({ type: 'startSaving', id })

export const savingSucceeded = (id: string) =>
    saveStore.send({ type: 'savingSucceeded', id })

export const savingFailed = (id: string) =>
    saveStore.send({ type: 'savingFailed', id })

export const addActiveToast = (id: string) =>
    saveStore.send({ type: 'addActiveToast', id })

export const removeActiveToast = (id: string) =>
    saveStore.send({ type: 'removeActiveToast', id })

export const clearOperation = (id: string) =>
    saveStore.send({ type: 'clearOperation', id })

export const resetSaveStore = () => saveStore.send({ type: 'reset' })

// Selector hooks
export const usePendingOperations = () =>
    useSelector(saveStore, (state) => state.context.pendingOperations)

export const useSavingOperations = () =>
    useSelector(saveStore, (state) => state.context.savingOperations)

export const useActiveToasts = () =>
    useSelector(saveStore, (state) => state.context.activeToasts)

export const useHasUnsavedChanges = () =>
    useSelector(saveStore, (state) => {
        return (
            state.context.pendingOperations.length > 0 ||
            state.context.savingOperations.length > 0
        )
    })

// Getter functions
export const getSaveState = () => saveStore.getSnapshot().context

// Helper functions
export const hasPendingOperation = (id: string) => {
    const state = getSaveState()
    return (
        state.pendingOperations.some((op) => op.id === id) ||
        state.savingOperations.some((op) => op.id === id)
    )
}

export const generateSaveId = (saveId?: string) => {
    return saveId || Math.random().toString(36).substring(2, 9)
}
