'use client'

import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { cn } from '@/lib/utils'

interface SliderProps
    extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> {
    rootClassName?: string
    trackClassName?: string
    rangeClassName?: string
    thumbClassName?: string
    markClassName?: string
    marks?: number[]
}

const Slider = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    SliderProps
>(
    (
        {
            className,
            rootClassName,
            trackClassName,
            rangeClassName,
            thumbClassName,
            markClassName,
            marks = [],
            style,
            ...props
        },
        ref
    ) => {
        console.log('style', style)
        return (
            <SliderPrimitive.Root
                ref={ref}
                className={cn(
                    'relative flex w-full touch-none select-none items-center',
                    rootClassName,
                    className
                )}
                {...props}
            >
                <SliderPrimitive.Track
                    className={cn(
                        'relative h-2 w-full grow overflow-hidden rounded-full bg-secondary',
                        trackClassName
                    )}
                >
                    <SliderPrimitive.Range
                        className={cn(
                            'absolute h-full bg-primary slider-range',
                            rangeClassName
                        )}
                        style={style} // Pass through any custom styles
                    />
                </SliderPrimitive.Track>

                {marks.length > 0 &&
                    marks.map((mark) => (
                        <span
                            key={mark}
                            className={cn(
                                'absolute top-1/2 -translate-x-1/2 -translate-y-1/2 w-1.5 h-1.5 rounded-full bg-primary',
                                markClassName
                            )}
                            style={{
                                left: `${(mark / (props.max || 100)) * 100}%`,
                            }}
                        />
                    ))}

                <SliderPrimitive.Thumb
                    className={cn(
                        'block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                        thumbClassName
                    )}
                />
            </SliderPrimitive.Root>
        )
    }
)

Slider.displayName = SliderPrimitive.Root.displayName

interface GradientSliderProps {
    total: number
    prevAmount: number
    amount: number
    height?: number
    editable?: boolean
    onChange?: (value: number) => void
    className?: string
}

const calculateProgressValues = (
    total: number,
    prevAmount: number,
    amount: number
) => {
    const maxAmount = Math.max(prevAmount + amount, total)
    const maxProgress = maxAmount / (total || 1)
    const prevProgress = prevAmount / (total || 1)
    const currentProgress = (prevAmount + amount) / (total || 1)

    return { maxAmount, maxProgress, prevProgress, currentProgress }
}

const getGradientColors = (currentProgress: number) => {
    let progressGradient: Record<string, string> = { '0%': '#108ee9' }
    let finalColour = '#108ee9'

    progressGradient[`${Math.round((0.5 / currentProgress) * 100)}%`] =
        '#87d068'

    if (currentProgress > 0.4) {
        finalColour = '#87d068'
    }
    if (currentProgress > 0.8) {
        progressGradient[`${Math.round((0.8 / currentProgress) * 100)}%`] =
            '#ffc200'
        finalColour = '#ffc200'
    }
    if (currentProgress > 1) {
        progressGradient[`${Math.round((1 / currentProgress) * 100)}%`] =
            '#ff5800'
        finalColour = '#ff5800'
    }

    return { progressGradient, finalColour }
}

const getRangeStyle = (
    progressGradient: Record<string, string>,
    total: number,
    height: number
) => ({
    background: total
        ? `linear-gradient(to right, ${Object.entries(progressGradient)
              .map(([key, value]) => `${value} ${key}`)
              .join(', ')})`
        : '#f5f5f5',
    height: `${height}px`,
})

const GradientSlider = React.forwardRef<HTMLDivElement, GradientSliderProps>(
    (
        {
            className,
            total,
            prevAmount,
            amount,
            height = 4,
            editable = true,
            onChange,
            ...props
        },
        ref
    ) => {
        const [sliderFocused, setSliderFocused] = React.useState(false)

        const { maxAmount, maxProgress, prevProgress, currentProgress } =
            calculateProgressValues(total, prevAmount, amount)
        const { progressGradient, finalColour } =
            getGradientColors(currentProgress)

        const handleValueChange = (v: number[]) => {
            if (!sliderFocused || !total || !onChange) return
            const value = v[0]

            if (value === Math.round(prevProgress * 100)) {
                return onChange(0)
            }
            if (value === Math.round(maxProgress * 100)) {
                return onChange(maxAmount - prevAmount)
            }
            return onChange(
                Math.max(value / 100, prevProgress) * total - prevAmount
            )
        }

        return (
            <Slider
                ref={ref}
                className={className}
                value={total ? [currentProgress * 100] : []}
                max={total ? Math.max(maxProgress, currentProgress) * 100 : 0}
                onValueChange={handleValueChange}
                rangeClassName={cn(
                    'absolute h-full',
                    total ? '' : 'bg-[#f5f5f5]'
                )}
                style={{
                    background: getRangeStyle(progressGradient, total, height)
                        .background,
                }}
                thumbClassName={cn(
                    'block h-5 w-5 rounded-full border-2 bg-background',
                    `border-[${finalColour}]`
                )}
                marks={total ? [prevProgress * 100] : []}
                disabled={!editable || !total}
                onFocus={() => setSliderFocused(true)}
                onBlur={() => setSliderFocused(false)}
                {...props}
            />
        )
    }
)

GradientSlider.displayName = 'GradientSlider'

export { Slider, GradientSlider }
