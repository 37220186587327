import {
    createFileRoute,
    redirect,
    useLoaderData,
} from '@tanstack/react-router'
import AuthCallbackPage from '../../Pages/AuthCallbackPage/AuthCallbackPage'
import LoadingSpinner from '../../Components/LoadingSpinner'
import SessionStore from '../../State/SessionStore'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import { clerk } from '../../App'
import { jwtDecode } from 'jwt-decode'

export const Route = createFileRoute('/_public/sign-in')({
    loader: async ({ location, context }) => {
        if (usingNewLogin() && !context.loaded) {
            return
        }
        const encodedToken = await clerk?.session?.getToken?.({
            template: 'coincraft',
        })
        const token = jwtDecode(encodedToken)
        if (!token?.userId || !token?.organisationId) {
            window.location.href = '/sign-up'
            return
        }
        try {
            await SessionStore.clearCache()
            await SessionStore.login({ token })
            window.location.href = canViewRoute(
                SessionStore.user,
                'projectList'
            )
                ? '/projects'
                : '/timesheets'
        } catch (error) {
            console.log('error', error)
            throw error?.response?.data?.message || 'An error occurred'
        }
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    errorComponent: ({ error, reset }) => {
        return <Wrapper error={error} />
    },
})

function Wrapper({ error }) {
    return (
        <>
            <AuthCallbackPage
                {...Route.useParams()}
                {...Route.useSearch()}
                error={error}
            />
        </>
    )
}
