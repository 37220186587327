import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'

export const OrganisationHolidaySchema = z.object({
    id: z.string(),
    name: z.string().max(200),
    startDate: z.date(),
    endDate: z.date(),
    deletedAt: z.date().nullable().optional(),
})

export type OrganisationHoliday = z.infer<typeof OrganisationHolidaySchema>

type HolidayContext = {
    holidays: OrganisationHoliday[]
}

const initialContext: HolidayContext = {
    holidays: [],
}

export const holidayStore = createQueryStore<
    HolidayContext,
    { holidays: OrganisationHoliday[] }
>(
    {
        context: initialContext,
        on: {
            setHolidays: {
                holidays: (_, event: { holidays: OrganisationHoliday[] }) =>
                    event.holidays,
            },
            addHoliday: {
                holidays: (
                    context,
                    event: { holiday: OrganisationHoliday }
                ) => [...context.holidays, event.holiday],
            },
            updateHoliday: {
                holidays: (
                    context,
                    event: { id: string; holiday: Partial<OrganisationHoliday> }
                ) => {
                    const index = context.holidays.findIndex(
                        (h) => h.id === event.id
                    )
                    if (index === -1) return context.holidays

                    const updatedHolidays = [...context.holidays]
                    updatedHolidays[index] = {
                        ...updatedHolidays[index],
                        ...event.holiday,
                    }
                    return updatedHolidays
                },
            },
            deleteHoliday: {
                holidays: (context, event: { id: string }) => {
                    const index = context.holidays.findIndex(
                        (h) => h.id === event.id
                    )
                    if (index === -1) return context.holidays

                    const updatedHolidays = [...context.holidays]
                    updatedHolidays[index] = {
                        ...updatedHolidays[index],
                        deletedAt: new Date(),
                    }
                    return updatedHolidays
                },
            },
        },
    },
    {
        queryKey: ['organisation-holidays'],
        queryFn: async () => {
            const result =
                await trpc.organisationHolidays.getOrganisationHolidays.query()
            return {
                holidays: result.organisationHolidays.map((holiday) => ({
                    ...holiday,
                    startDate: new Date(holiday.startDate),
                    endDate: new Date(holiday.endDate),
                })),
            }
        },
        mutationFn: (data) =>
            trpc.organisationHolidays.saveOrganisationHolidays.mutate({
                organisationHolidays: data.holidays,
            }),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'organisation-holidays-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to organisation holidays?',
        loadingMessage: 'Saving holidays...',
        successMessage: 'Holidays saved successfully',
        errorMessage: 'Failed to save holidays',
    }
)

// Export everything from the store
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = holidayStore

// Action creators
export const setHolidays = (holidays: OrganisationHoliday[]) =>
    holidayStore.store.send({ type: 'setHolidays', holidays })

export const addHoliday = (holiday: OrganisationHoliday) =>
    holidayStore.store.send({ type: 'addHoliday', holiday })

export const updateHoliday = (
    id: string,
    holiday: Partial<OrganisationHoliday>
) => holidayStore.store.send({ type: 'updateHoliday', id, holiday })

export const deleteHoliday = (id: string) =>
    holidayStore.store.send({ type: 'deleteHoliday', id })

// Custom selectors
export const useHolidays = () =>
    useSelector(holidayStore.store, (state) => state.context.holidays)

export const useActiveHolidays = () => {
    const holidays = useHolidays()
    return useMemo(() => holidays.filter((h) => !h?.deletedAt), [holidays])
}
