import { type ColumnDef } from '@tanstack/react-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import SessionStore from '@/State/SessionStore'
import {
    canEditHolidays,
    canEditRoles,
    canEditStaffChargeOutRate,
    canViewStaffChargeOutRate,
    canEditStaffCostRate,
    canEditStaffOvertimeRate,
    canViewStaffOvertimeRate,
    canViewStaffCostRate,
    canViewStaffPayRate,
    canEditStaffPayRate,
} from '@/State/Permissions/HasPermissions'
import { Role, RoleRates, staffRoleActions } from './role-page-store'
import { RoleUtils } from '@/version2/utils/role-utils'

export function getColumns(
    onHideColumn: (column: string) => void,
    role: Role
): ColumnDef<RoleRates>[] {
    const generateColumnDef = createGenerateColumnDef<RoleRates>({
        onHideColumn,
    })
    const wideSize = 120
    return [
        generateColumnDef({
            key: 'date',
            type: 'date',
            title: 'Starting From',
            size: wideSize,
            permissions: (r) => canViewStaffPayRate(SessionStore.user),
            editable:
                canEditRoles(SessionStore.user) &&
                canEditStaffPayRate(SessionStore.user),
            onChange: (row, val) => {
                staffRoleActions.changeRoleRateData(
                    row.original.id,
                    'date',
                    val
                )
            },
        }),
        generateColumnDef({
            key: 'payRate',
            type: 'currency',
            title: 'Pay Rate',
            size: wideSize,
            permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
            editable:
                !role?.avgPayRate &&
                canEditRoles(SessionStore.user) &&
                canEditStaffOvertimeRate(SessionStore.user),
            customValue: (r: RoleRates) =>
                role?.avgPayRate
                    ? RoleUtils.getRoleRateAtDate('pay', r?.date)
                    : r?.payRate,
            onChange: (row, val) => {
                staffRoleActions.changeRoleRateData(
                    row.original.id,
                    'payRate',
                    val
                )
            },
        }),
        generateColumnDef({
            key: 'overtimeRate',
            type: 'currency',
            title: 'Overtime Rate',
            size: wideSize,
            permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
            editable:
                !role?.avgOvertimeRate &&
                canEditRoles(SessionStore.user) &&
                canEditStaffOvertimeRate(SessionStore.user),
            customValue: (r: RoleRates) =>
                role?.avgOvertimeRate
                    ? RoleUtils.getRoleRateAtDate('overtime', r?.date)
                    : r?.overtimeRate,
            onChange: (row, val) => {
                staffRoleActions.changeRoleRateData(
                    row.original.id,
                    'overtimeRate',
                    val
                )
            },
        }),
        generateColumnDef({
            key: 'costRate',
            type: 'currency',
            title: 'Cost Rate',
            size: wideSize,
            permissions: (r) => canViewStaffCostRate(SessionStore.user),
            editable:
                !role?.avgCostRate &&
                canEditRoles(SessionStore.user) &&
                canEditStaffCostRate(SessionStore.user),
            customValue: (r: RoleRates) =>
                role?.avgCostRate
                    ? RoleUtils.getRoleRateAtDate('cost', r?.date)
                    : r?.costRate,
            onChange: (row, val) => {
                staffRoleActions.changeRoleRateData(
                    row.original.id,
                    'costRate',
                    val
                )
            },
        }),
        generateColumnDef({
            key: 'chargeOutRate',
            type: 'currency',
            title: 'Charge Out Rate',
            size: wideSize,
            permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
            editable:
                !role?.avgChargeOutRate &&
                canEditRoles(SessionStore.user) &&
                canEditStaffChargeOutRate(SessionStore.user),
            customValue: (r: RoleRates) =>
                role?.avgChargeOutRate
                    ? RoleUtils.getRoleRateAtDate('chargeOut', r?.date)
                    : r?.chargeOutRate,
            onChange: (row, val) => {
                staffRoleActions.changeRoleRateData(
                    row.original.id,
                    'chargeOutRate',
                    val
                )
            },
        }),
        generateColumnDef({
            key: 'deleteButton',
            title: 'Action',
            type: 'button',
            editable: canEditRoles(SessionStore.user),
            permissions: () => canEditRoles(SessionStore.user),
            cell: () => {
                return <i className="fa fa-times" />
            },
            onClick: (row) => {
                console.log(row.original.id)
                staffRoleActions.deleteRate(row.original.id)
            },
        }),
    ]
}
