/* @flow */
import moment from 'moment'
import _ from 'underscore'
import React from 'react'
import CreateReactClass from 'create-react-class'
import classNames from 'classnames'
import Immutable from 'immutable'
// import d3 from 'd3'

import PropTypes from 'prop-types'
import cuid from 'cuid'

export function objectToQueryString(obj) {
    var str = []
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            var val = obj[p]
            if (val == null) {
                val = ''
            }
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(val))
        }
    return str.join('&')
}

export var ToggleButton = CreateReactClass({
    /**
     * `selectedName` should be managed by the parent (via `onClick`) and be set
     * to the `name` of the selected `ToggleButton` of the group in question.
     * When a `ToggleButton` is clicked, the `onClick` handler is called with the
     * value of the  `name` property being the only argument.
     */
    propTypes: {
        name: PropTypes.string.isRequired,
        selectedName: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    },

    getInitialState: function () {
        return {}
    },

    render: function () {
        return (
            <label
                className={classNames({
                    btn: true,
                    'btn-primary': true,
                    active: this.props.selectedName === this.props.name,
                })}
                onClick={this.handleClick}
            >
                <input type="radio" name="options" autoComplete="off" />
                {this.props.label}
            </label>
        )
    },

    handleClick: function () {
        this.props.onClick(this.props.name)
    },
})

export var Dropdown = CreateReactClass({
    propTypes: {
        items: PropTypes.array.isRequired,
        renderMenuItem: PropTypes.func.isRequired,
        renderSelectedItem: PropTypes.func.isRequired,
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        style: PropTypes.object,
    },

    getDefaultProps: function () {
        return {
            style: {},
        }
    },

    render: function () {
        var self = this

        if (this.props.style.paddingLeft == null) {
            this.props.style.paddingLeft = 0
        }

        return (
            <span className="btn" style={this.props.style}>
                <span className="dropdown">
                    <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="true"
                    >
                        {self.props.renderSelectedItem(self.props.value)}
                        <span className="caret"></span>
                    </button>
                    <ul
                        className="dropdown-menu"
                        role="menu"
                        aria-labelledby="dropdownMenu1"
                    >
                        {self.props.items.map(function (item, index) {
                            return (
                                <li role="presentation" key={index}>
                                    <a
                                        href="js/void"
                                        onClick={function () {
                                            self.props.onChange(item)
                                        }}
                                        style={{ color: 'black' }}
                                    >
                                        {self.props.renderMenuItem(item)}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </span>
            </span>
        )
    },
})

export var ErrorPopover = CreateReactClass({
    propTypes: {
        // "top" and "left" are also doable but we just haven't supported those with css yet.
        orientation: PropTypes.oneOf(['bottom', 'right', 'block']),
        message: PropTypes.any.isRequired,
        arrowProps: PropTypes.object,
    },

    getDefaultProps: function () {
        return {
            arrowProps: {},
        }
    },

    render: function () {
        let { orientation, message, arrowProps, className, ...props } =
            this.props
        return (
            <div
                className={classNames(
                    'popover',
                    'error-message-popover',
                    orientation,
                    className
                )}
                {...props}
            >
                {orientation != null ? (
                    <div className="arrow" {...arrowProps}></div>
                ) : null}
                <div className="popover-content">{message}</div>
            </div>
        )
    },
})

export function parseMoment(d) {
    if (d == null) {
        return null
    } else {
        return moment(d, 'YYYY-MM-DD')
    }
}

export function parseDateTime(s) {
    if (s.indexOf('+') >= 0) {
        return moment(s, 'YYYY-MM-DDTHH:mm:ssZ')
    } else {
        return moment(s, 'YYYY-MM-DDTHH:mm:ss')
    }
}

export function formatMinutes(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60)
    let minutes = totalMinutes % 60
    return hours + ':' + (minutes < 10 ? '0' : '') + minutes
}

export function parseTime(timeText) {
    /**
     * Returns `null` if `timeText` isn't in a recognised time format.
     */
    let hours, minutes

    if (timeText == null) {
        return 0
    }

    if (timeText.length > 0) {
        if (timeText.match(/^\d{1,2}$/)) {
            // If they enter just a number then that's a number of hours.
            hours = parseInt(timeText)
            minutes = 0
        } else if (timeText.match(/^(\d{0,2})[.,](\d{1,2})?$/)) {
            // Regular "hh.mm" time.
            let parts = [RegExp.$1, RegExp.$2]

            hours = parts[0] !== '' ? parseInt(parts[0]) : 0

            minutes = parts[1] !== '' ? parseInt(parts[1]) : 0
            if (minutes < 10 && parts[1].length === 1) {
                minutes *= 10
            }
            minutes = (minutes / 100) * 60
        } else if (timeText.match(/^(\d{0,10})[:](\d{1,10})?$/)) {
            // Regular "hh:mm" or "hh.mm" time.
            let parts = [RegExp.$1, RegExp.$2]

            hours = parts[0] !== '' ? parseInt(parts[0]) : 0

            // We decided that eg. "3:3" should be interpreted as "3:30".  Also "3:"
            // should be "3:00". But obviously "3:06" should not be "3:60" etc.
            //
            // So:
            // "3:" -> 3:00
            // "3:0" -> 3:00
            // "3:00" -> 3:00
            // "3:01" -> 3:01
            // "3:1" -> 3:10
            // "3:5" -> 3:50
            // "3:6" -> 3:06
            // "3:9" -> 3:09

            minutes = parts[1] !== '' ? parseInt(parts[1]) : 0
            if (minutes < 6 && parts[1].length === 1) {
                minutes *= 10
            }
        } else {
            return 0
        }
    } else {
        return 0
    }

    return hours * 60 + minutes
}

export const StateLink = class {
    constructor(options) {
        this.component = options.component
        this.stateVar = options.stateVar
    }

    handleFieldChangeValue(fieldName, value) {
        this.component.state[this.stateVar][fieldName] = value
        var newState = {}
        newState[this.stateVar] = this.component.state[this.stateVar]
        this.component.setState(newState)
        this.component._validate()
    }

    handleFieldChange(fieldName, event) {
        this.handleFieldChangeValue(fieldName, event.target.value)
    }

    getHandleFunc(fieldName) {
        var self = this
        return function (event) {
            self.handleFieldChange(fieldName, event)
        }
    }
}

export function linkMouseOverToState(component, variable) {
    function updateState(val) {
        var s = {}
        s[variable] = val
        component.setState(s)
    }

    return {
        onMouseOver: function () {
            updateState(true)
        },
        onMouseOut: function () {
            updateState(false)
        },
    }
}

export function generateUUID(prefix = '') {
    // http://stackoverflow.com/a/8809472/223486
    var d = new Date().getTime()
    var uuid = String(prefix) + '-' + cuid()
    // + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //   var r = (d + Math.random()*16)%16 | 0;
    //   d = Math.floor(d/16);
    //   return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    // });
    return uuid
}

// export function formatCurrency(num) {
//     let format = formatCurrency.format
//     if (format == null) {
//         format = formatCurrency.format = d3.format('0,000')
//     }
//     return format(Math.round(num))
// }

// export function formatCurrencyWithCents(num) {
//     let format = formatCurrencyWithCents.format
//     if (format == null) {
//         format = formatCurrencyWithCents.format = d3.format(',.2f')
//     }
//     return format(num)
// }

/**
 * Convention here: `n` at the end of a name of a formatting function means "to
 * `n` decimal places" Ie. `formatPercentage0` will output a value rounded to
 * zero decimal places (ie. passed to `Math.round`).
 *
 * Also, the `ifNull` value will be used if any non-numeric value is passed
 * (including `NaN`, `Infinity` and `-Infinity`).
 */

export function formatPercentage0(num, { ifNull = null } = {}) {
    if (!isNumber(num)) {
        return ifNull
    } else {
        return Math.round(num) + '%'
    }
}

export function formatPercentage2(num, { ifNull = null } = {}) {
    if (!isNumber(num)) {
        return ifNull
    } else {
        return Math.round(num * 100) / 100 + '%'
    }
}

export function formatNumber0(num, { ifNull = null } = {}) {
    if (!isNumber(num)) {
        return ifNull
    }
    return Math.round(num)
}

export function formatNumberN(num, n, { ifNull = null } = {}) {
    /**
     * >>> formatNumber1(2)
     * "2"
     *
     * >>> formatNumber1(2.011111)
     * "2.0"
     */
    if (!isNumber(num)) {
        return ifNull
    }
    let f = num.toFixed(n)
    let shouldChop = true

    let ix = f.indexOf('.')
    if (ix !== -1) {
        for (let i = ix + 1; i < f.length; i++) {
            if (f[i] !== '0') {
                shouldChop = false
                break
            }
        }
    }

    if (shouldChop) {
        f = f.substr(0, f.length - n - 1)
    }
    return f
}

export function formatNumber1(num, { ifNull = null } = {}) {
    /**
     * >>> formatNumber1(2)
     * "2"
     *
     * >>> formatNumber1(2.011111)
     * "2.0"
     */
    return formatNumberN(num, 1, { ifNull: ifNull })
}

export function formatNumber2(num, { ifNull = null } = {}) {
    /**
     * >>> formatNumber2(2)
     * "2"
     *
     * >>> formatNumber2(2.011111)
     * "2.01"
     */
    return formatNumberN(num, 2, { ifNull: ifNull })
}

export function formatNumberMaxN(num, n, { ifNull = null } = {}) {
    if (!isNumber(num)) {
        return ifNull
    }

    let p = Math.pow(10, n)
    return Math.round(num * p) / p
}

export function formatHours0(num, { ifNull = null } = {}) {
    return formatNumber0(num, { ifNull: ifNull }) + 'h'
}

export function pluralise(value, singular, plural = null) {
    return `${value} ${
        value === 1 ? singular : plural != null ? plural : singular + 's'
    }`
}

export function transformEventToSvgCoords(event, svg) {
    /**
     * `event`: a React event (I think)
     * `svg`: an SVG DOM node.
     */

    let p
    if (svg.createSVGPoint) {
        var point = svg.createSVGPoint()
        point.x = event.clientX
        point.y = event.clientY
        point = point.matrixTransform(svg.getScreenCTM().inverse())
        p = [point.x, point.y]
    } else {
        let rect = svg.getBoundingClientRect()
        p = [
            event.clientX - rect.left - svg.clientLeft,
            event.clientY - rect.top - svg.clientTop,
        ]
    }

    // TODO-merge figure out when clipping is/isn't appropriate (check scrolling
    // / panning).
    var clip = true
    if (clip) {
        let rect = svg.getBoundingClientRect()
        p[0] = middleOfThree(0, p[0], rect.width)
        p[1] = middleOfThree(0, p[1], rect.height)
    }
    return p
}

function getEventCoordsRelativeToElement(event, element) {
    if (element.tagName === 'svg') {
        return transformEventToSvgCoords(event, element)
    } else {
        var rect = element.getBoundingClientRect()
        return [event.clientX - rect.left, event.clientY - rect.top]
    }
}

export function mod(n, m) {
    /**
     * `mod` function that works sensibly for negative numbers (Javascript `%` operator doesn't).
     * http://stackoverflow.com/questions/4467539/javascript-modulo-not-behaving
     */
    return ((n % m) + m) % m
}

export function logCalls(f) {
    return function (...args) {
        let r = f(...args)
        /* eslint-disable no-console */
        console.log('called', f.name, args, r)
        /* eslint-enable no-console */
        return r
    }
}

export function rangeIntersection([start1, end1], [start2, end2]) {
    /**
     * If there is no intersection, return `null`.
     * If the intersection has no beginning, the intersection start is `null`.
     * If the intersection has no end, the intersection end is `null`.
     */
    if (
        (start2 != null && end1 != null && start2 > end1) ||
        (start1 != null && end2 != null && start1 > end2)
    ) {
        return null
    }

    function normalise(a) {
        if (a === Infinity || a === -Infinity) {
            return null
        } else {
            return a
        }
    }

    return [
        normalise(
            Math.max(
                start1 != null ? start1 : -Infinity,
                start2 != null ? start2 : -Infinity
            )
        ),
        normalise(
            Math.min(
                end1 != null ? end1 : Infinity,
                end2 != null ? end2 : Infinity
            )
        ),
    ]
}

export function getAllSubintervals(
    list1,
    list2,
    keyFunc1,
    keyFunc2,
    combineFunc
) {
    let i = 0,
        j = 0,
        l1 = list1.length,
        l2 = list2.length
    let r = []
    while (i < l1 && j < l2) {
        let key1 = keyFunc1(list1[i])
        let key2 = keyFunc2(list2[j])

        if (key1 < key2) {
            r.push(combineFunc(key1, list1[i], list2[Math.max(j - 1, 0)]))
        } else if (key1 == key2) {
            r.push(combineFunc(key2, list1[i], list2[j]))
        } else {
            r.push(combineFunc(key2, list1[Math.max(i - 1, 0)], list2[j]))
        }

        if (key1 <= key2) {
            i++
        }
        if (key1 >= key2) {
            j++
        }
    }
    while (i < l1) {
        r.push(
            combineFunc(keyFunc1(list1[i]), list1[i], list2[Math.max(j - 1, 0)])
        )
        i++
    }
    while (j < l2) {
        r.push(
            combineFunc(keyFunc2(list2[j]), list1[Math.max(i - 1, 0)], list2[j])
        )
        j++
    }
    return r
}

export function mergeProps(...propsDicts) {
    function mergeProps1(props1, props2) {
        let {
            style: style1,
            className: className1,
            ...props1Rest
        } = props1 || {}
        let {
            style: style2,
            className: className2,
            ...props2Rest
        } = props2 || {}

        return {
            style: { ...style1, ...style2 },
            className: classNames(className1, className2),
            ...props1Rest,
            ...props2Rest,
        }
    }

    let result = propsDicts.reduce(mergeProps1, {})
    if (result.className === '') {
        delete result.className
    }
    if (_.isEmpty(result.style)) {
        delete result.style
    }
    return result
}

export function mergeDicts(d1, d2, mergeFunc) {
    let r = {}
    _.each(d1, function (v, k) {
        r[k] = mergeFunc(k, v, d2[k])
    })
    _.each(d2, function (v, k) {
        if (!d1.hasOwnProperty(k)) {
            r[k] = mergeFunc(k, undefined, v)
        }
    })
    return r
}

export function mergeMaps(d1, d2, mergeFunc) {
    /**
     * Analogous to `mergeDicts` but works with `Immutable.Map` objects.  Note
     * that (in addition to having different argument order both in the arguments
     * passed to `mergeMaps` and the arguments `mergeMaps` passes to
     * `mergeFunc`), this function is semantically different to
     * `Immutable.Map.mergeWith` in that `mergeFunc` is always called for every
     * value in either map, whereas `mergeWith` only calls its callback for
     * values that exist in both maps, making it impossible to catch them.
     */
    return d1.withMutations(function (d1) {
        d1.forEach(function (v, k) {
            d1.set(k, mergeFunc(k, v, d2.get(k)))
        })
        d2.forEach(function (v, k) {
            if (!d1.has(k)) {
                d1.set(k, mergeFunc(k, undefined, v))
            }
        })
    })
}

export function prefixFlex(flexProp) {
    /**
     * >>> prefixFlex("1 1 auto")
     * {flex: "1 1 auto", WebkitFlex: "1 1 auto"}
     */
    return {
        flex: flexProp,
        WebkitFlex: flexProp,
    }
}

export function hoverState() {
    /**
   * Mixin.
   *
   * Usage:

      let MyComponentWithHover = CreateReactClass({
        mixins: [hoverState()],

        render: function() {
          return (
            <div
                {...this.getHoverHandlers()}
                style={{backgroundColor: this.state.isHovered ? 'red' : 'blue'}}
              I am blue if hovered and red otherwise.
            </div>
          );
        }
      });
    */

    return {
        getInitialState: function () {
            return { isHovered: false }
        },

        getHoverHandlers: function () {
            return {
                onMouseEnter: this.handleMouseEnter,
                onMouseLeave: this.handleMouseLeave,
            }
        },

        handleMouseEnter: function () {
            this.setState({ isHovered: true })
        },

        handleMouseLeave: function () {
            this.setState({ isHovered: false })
        },
    }
}

// https://github.com/jashkenas/underscore/blob/master/underscore.js#L806
// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
//
// Note that we copied this function from the underscore github repo because
// the underscore version in npm doesn't support the `cancel` method.
export function throttle(func, wait, options) {
    var timeout, context, args, result
    var previous = 0
    if (!options) options = {}

    var later = function () {
        previous = options.leading === false ? 0 : _.now()
        timeout = null
        result = func.apply(context, args)
        if (!timeout) context = args = null
    }

    var throttled = function () {
        var now = _.now()
        if (!previous && options.leading === false) previous = now
        var remaining = wait - (now - previous)
        context = this
        args = arguments
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout)
                timeout = null
            }
            previous = now
            result = func.apply(context, args)
            if (!timeout) context = args = null
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining)
        }
        return result
    }

    throttled.cancel = function () {
        clearTimeout(timeout)
        previous = 0
        timeout = context = args = null
    }

    return throttled
}

//TODO this is mostly copied from cashFlowChart.js; factor it.
export const DragController = class {
    constructor({
        getSvgNodeFunc,
        callback,
        mouseUpCallback = null,
        throttleMs = null,
    } = {}) {
        this.getSvgNodeFunc = getSvgNodeFunc
        this.callback = callback
        this.mouseUpCallback = mouseUpCallback
        this.throttleMs = throttleMs
    }

    getMouseEventProperties(getArgs) {
        if (!_.isFunction(getArgs)) {
            getArgs = function () {
                return getArgs
            }
        }

        let self = this
        return {
            onMouseDown: function (e) {
                self.getArgs = getArgs
                self.handleMouseDown(e)
            },
        }
    }

    getEventCoordsRelativeToElement(e) {
        return getEventCoordsRelativeToElement(e, this.getSvgNodeFunc())
    }

    handleMouseDown(e) {
        let self = this

        // Only care about left click.
        if (e.button !== 0) {
            return
        }

        var position = this.getEventCoordsRelativeToElement(e)

        this.mouseDown = true
        this.mousePosition = { x: position[0], y: position[1] }

        this.throttled = throttle(function (e) {
            self.handleMouseMove(e)
        }, this.throttleMs)
        var $body = window.$('body')

        // If the user moves the mouse quickly, it will easily be able to move
        // outside the slider area, but we don't want that to stop the scroll
        // events from happening.  So attach the mousemove event to the `body`
        // element, making sure to clear it on mouseup.
        $body.one('mouseup', function (e) {
            $body.off('mousemove', self.throttled)
            self.handleMouseUp(e)
        })
        $body.on('mousemove', self.throttled)

        e.preventDefault()
        e.stopPropagation()
    }

    handleMouseMove(e, args) {
        if (this.mouseDown) {
            let position = this.getEventCoordsRelativeToElement(e)
            let newPosition = { x: position[0], y: position[1] }
            let args = this.getArgs()

            this.callback(newPosition, this.mousePosition, args)

            this.mousePosition = newPosition
        }
        e.preventDefault()
        e.stopPropagation()
    }

    handleMouseUp(e) {
        if (this.throttled) {
            this.throttled.cancel()
        }
        this.mouseDown = false
        e.preventDefault()
        e.stopPropagation()

        if (this.mouseUpCallback != null) {
            this.mouseUpCallback()
        }
    }
}

export const FpsCounter = class {
    constructor(secondCallback) {
        this.fpsTimestamp = +new Date()
        this.frameCount = 0
        this.fps = 0

        this.secondCallback = secondCallback
    }

    frame() {
        this.frameCount++
        var newTimestamp = +new Date()
        if (newTimestamp - this.fpsTimestamp >= 1000) {
            this.fps = this.frameCount
            this.secondCallback(this.fps)
            this.fpsTimestamp = newTimestamp
            this.frameCount = 0
        }
    }
}

export function everyNth(arr, n, callback) {
    for (var i = 0, l = arr.length; i < l; i += n) {
        callback(arr[i], i)
    }
}

export function mapEveryNth(arr, n, callback) {
    var newArr = []
    everyNth(arr, n, function (r, i) {
        newArr.push(callback(r, i))
    })
    return newArr
}

export function makeAreaPath(data, xFunc, y1Func, y2Func) {
    /**
     * Similar to d3.svg.area(), but optimised for having long horizontal line segments.
     *
     * So where d3.svg.area() might give you something like:
     *  L100,50
     *  L101,50
     *  L102,50
     *  L103,50
     *  ...
     *  L110,50
     *
     * makeAreaPath will give you:
     * L100,50
     * L110,50
     *
     * This may or may not be a worthwhile performance optimisation.
     */
    var points = []

    function addPoint(x, y) {
        if (
            points.length >= 2 &&
            y === points[points.length - 1][1] &&
            y === points[points.length - 2][1]
        ) {
            points[points.length - 1][0] = x
        } else {
            points.push([x, y])
        }
    }

    data.forEach(function (m, i) {
        addPoint(xFunc(m, i), y1Func(m, i))
    })
    for (var i = data.length - 1; i >= 0; i--) {
        var m = data[i]
        addPoint(xFunc(m, i), y2Func(m, i))
    }

    return points
        .map(function (p, i) {
            return `${i === 0 ? 'M' : 'L'} ${p[0]},${p[1]}`
        })
        .join('')
}

export function sum(arr) {
    let s = 0
    for (let item of arr) {
        s += item
    }
    return s
}

export function middleOfThree(a, b, c) {
    if (b < a) {
        return a
    }
    if (b > c) {
        return c
    }
    return b
}

export function smallestAndBiggest(arr) {
    var smallest = null,
        biggest = null
    arr.forEach(function (i) {
        if (smallest == null || i < smallest) {
            smallest = i
        }
        if (biggest == null || i > biggest) {
            biggest = i
        }
    })
    return [smallest, biggest]
}

export function abbreviateNumber(n) {
    /**
     * 1 -> 1
     * 12 -> 12
     * 123 -> 123
     * 1234 -> 1234
     * 12345 -> 12.3k
     * 123456 -> 123k
     * 1234567 -> 1.23m
     * 12345678 -> 12.3m
     * 123456789 -> 123m
     * 1234567890 -> 1.23b
     * 12345678901 -> 12.3b
     * 123456789012 -> 123b
     * 1234567890123 -> 1234b
     * 12345678901234 -> 12345b
     * ...
     */
    const suffixes = ['k', 'm', 'b']

    if (n < 0) {
        return '-' + abbreviateNumber(-n)
    }

    var s = Math.round(n).toString()
    var l = s.length

    if (l <= 4) {
        return s
    } else if (l > suffixes.length * 3 + 3) {
        return (
            s.substr(0, l - suffixes.length * 3) + suffixes[suffixes.length - 1]
        )
    }

    var decimalPointIndex = l % 3 || null
    var suffix = suffixes[Math.floor((s.length - 4) / 3)]

    let s1
    if (decimalPointIndex != null) {
        s1 =
            s.substr(0, decimalPointIndex) +
            '.' +
            s.substring(decimalPointIndex, 3)
    } else {
        s1 = s.substr(0, 3)
    }
    return s1 + suffix
}

/*
export const LinearScale = class {
  constructor({minX = null, maxX = null, minY = null, maxY = null} = {}) {
    this.minX = minX;
    this.maxX = maxX;
    this.minY = minY;
    this.maxY = maxY;
  }

  xToY(x) {
    return (this.maxY - this.minY) / (this.maxX - this.minX) * (x - this.minX);
  }

  yToX(y) {
    return y * (this.maxX - this.minX) / (this.maxY - this.minY) + this.minX;
  }
}
*/

// export const LinearScale = class {
//     constructor({ minX = null, maxX = null, minY = null, maxY = null } = {}) {
//         this.minX = minX
//         this.maxX = maxX
//         this.minY = minY
//         this.maxY = maxY
//         this.scale = d3.scale.linear().domain([minX, maxX]).range([minY, maxY])
//     }

//     xToY(x) {
//         return this.scale(x)
//     }

//     yToX(y) {
//         return this.scale.invert(y)
//     }
// }

export function scaleInnerRange(
    [innerStart, innerEnd],
    [outerStartBefore, outerEndBefore],
    [outerStartAfter, outerEndAfter]
) {
    /**
     * Before
     *      o-------------------o   (outerStartBefore, outerEndBefore)
     *         o------o             (innerStart, innerEnd)
     *
     * User drags outer range:
     *
     *      o------------------->>>>o   (outerStartAfter, outerEndAfter)
     *         o------o                 (innerStart, innerEnd)
     *
     * How should we transform the inner shape so the overall shape
     * looks the same?:
     *
     *      o-----------------------o   (outerStartAfter, outerEndAfter)
     *         o------>>o               (innerStartAfter, innerEndAfter)
     *
     * We return [innerStartAfter, innerEndAfter]
     */

    if (outerStartBefore === outerEndBefore) {
        return [outerStartAfter, outerEndAfter]
    }

    function transformPoint(val) {
        return (
            outerStartAfter +
            ((val - outerStartBefore) / (outerEndBefore - outerStartBefore)) *
                (outerEndAfter - outerStartAfter)
        )
    }

    return [transformPoint(innerStart), transformPoint(innerEnd)]
}

export function camelCaseToSentence(s) {
    /**
    >>> camelCaseToSentence("onHold")
    "On Hold"

    >>> camelCaseToSentence("potential"))
    "Potential"
  */
    return s.replace(/./g, function (c, i) {
        if (i === 0) {
            return c.toUpperCase()
        } else if (c === c.toUpperCase()) {
            return ' ' + c
        } else {
            return c
        }
    })
}

export function camelCaseToAllCaps(s) {
    return s.replace(
        /./g,
        (c) => (c === c.toUpperCase() ? '_' : '') + c.toUpperCase()
    )
}

export function capitaliseFirst(s) {
    return s[0].toUpperCase() + s.substr(1)
}

/**
 * Still not aware of a satisfactory way to implement a defaultdict structure
 * in Javascript. We can make a class that keeps the dict and the default
 * value, but then it's not equivalent to a dict and you have to do lots of
 * `.dict` accesses which gets especially annoying if you nest them.
 *
 * So have plain functions that operate on dicts so we can use plain dicts.
 *
 * var d = {};
 * defaultDictGet(d, "newValue", 3);
 * >>> d.newValue
 * 3
 *
 * // If you know the value already exists, you don't need to provide `defaultValue`
 * // to `defaultDictModify`.
 * defaultDictModify(d, "newValue", v => v + 2);
 * >>> d.newValue
 * 5
 */
export function defaultDictGet(dct, key, defaultValue) {
    if (dct[key] == null) {
        dct[key] = defaultValue
    }
    return dct[key]
}

export function defaultDictModify(dct, key, modifierFunc, defaultValue) {
    dct[key] = modifierFunc(defaultDictGet(dct, key, defaultValue))
}

export function groupBy(lst, grouperFunc, keyFunc) {
    /**
   * >>> myGroupBy(<objects>, <function>, <function>)
   *

    [
      {
        grouper: grouperFunc(<object1>),
        items: [<all the objects for which grouperFunc(o) === grouperFunc(object1)],
      },
      {
        grouper: grouperFunc(<object2>),
        items: [<all the objects for which grouperFunc(o) === grouperFunc(object2)],
      },
      ...
    ]

    `keyFunc` is just a function that takes a result of calling
    `grouperFunc` on an item in `lst` and turns it into a unique string.

    Note that this function does *not* assume that `lst` is already sorted in
    any particular way.
   */
    var d = {}
    var out = []
    for (let item of lst) {
        var grouper = grouperFunc(item)
        var key = keyFunc(grouper)
        if (d[key] == null) {
            d[key] = { grouper: grouper, items: [] }
            out.push(d[key])
        }
        d[key].items.push(item)
    }

    return out
}

export var VerticalAlign = CreateReactClass({
    propTypes: {
        align: PropTypes.string.isRequired,
    },

    render: function () {
        return (
            <div
                style={{
                    display: 'table',
                    width: '100%',
                    height: '100%',
                    tableLayout: 'fixed',
                    ...this.props.style,
                }}
            >
                <div
                    style={{
                        display: 'table-cell',
                        width: '100%',
                        height: '100%',
                        verticalAlign: this.props.align,
                        ...this.props.innerStyle,
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        )
    },
})

export function compareObjectsWithNames(a, b) {
    /**
     * `a` and `b` are either null or objects that have a `name` property.
     *
     * Use this to sort by name, putting null values at the end.
     */
    if (a == null && b == null) {
        return 0
    }
    if (a == null && b != null) {
        return 1
    }
    if (a != null && b == null) {
        return -1
    }
    return a.name.localeCompare(b.name)
}

export function compareMoments(a, b) {
    return (a != null ? +a.toDate() : 0) - (b != null ? +b.toDate() : 0)
}

export function compareMultiple(...args) {
    /**
   * A comparator function is a function that takes two arguments and returns a
   * negative number if the first should be ordered before the second, zero if
   * they are equal, and a positive number if the first should be ordered after
   * the second.
   *
   * `compareMultiple` takes any number of comparator functions and returns a
   * comparator that tries those functions in sequence until one of them
   * returns nonzero, and then returns that value.
   *
   * eg:

     >>> [[3, 1], [2, 2], [2, 1]].sort(compareMultiple(
           (a, b) => a[0] - b[0],
           (a, b) => a[1] - b[1]
         ))
     [[2, 1], [2, 2], [3, 1]]

     One extra thing is that the comparator functions passed in can return `null`
     to denote "I already know these two objects are equal; don't continue to the
     other comparators" rather than zero, which denotes "I don't yet know how these
     objects should be ordered with respect to one another".
   */
    return function (a, b) {
        for (var i = 0; i < args.length - 1; i++) {
            var val = args[i](a, b)
            if (val == null) {
                return 0
            } else if (val !== 0) {
                return val
            }
        }
        return args[args.length - 1](a, b)
    }
}

export function isNumber(ob) {
    return _.isNumber(ob) && !isNaN(ob) && isFinite(ob)
}

export function isNonEmptyString(ob) {
    return _.isString(ob) && ob !== ''
}

export function isDecimal(val) {
    return val == null || val === '' || _.isNumber(parseFloat(val))
}

export function isInteger(val) {
    return _.isNumber(val) && val % 1 === 0
}

export function stableSort(arr, cmp) {
    var arrWithIndexes = arr.map(function (d, i) {
        return {
            item: d,
            index: i,
        }
    })
    arrWithIndexes.sort(function (a, b) {
        var r = cmp(a.item, b.item)
        if (r !== 0) {
            return r
        } else {
            return a.index - b.index
        }
    })
    return arrWithIndexes.map((d) => d.item)
}

export function trueKeys(ob) {
    /**
     * >>> trueKeys({a: true, b: false})
     * ['a']
     */
    var keys = []
    _.each(ob, function (v, k) {
        if (v === true) {
            keys.push(k)
        }
    })
    return keys
}

export function arrayToObject(arr, keyFunc, valueFunc) {
    let d = {}
    arr.forEach(function (item) {
        d[keyFunc(item)] = valueFunc(item)
    })
    return d
}

export function parsesToInt(s) {
    return s != null && s.match(/^\d+$/) != null
}

export function parsesToFloat(s) {
    /**
     * Is `s` a string that is *just* a float (not just a string that starts with a float
     * and might have crap attached to the end). For this function we do not consider
     * exponential format to be valid, hence check for the 'e' character in the regexp.
     * Negative numbers are accepted though.
     *
     * Adapted from http://stackoverflow.com/a/1830547/223486
     */
    return s != null && s !== '' && !/[\se]/i.test(s) && !isNaN(s)
}

export function firstNonNull(...args) {
    for (var i = 0, l = args.length; i < l; i++) {
        let val = args[i]
        if (val != null) {
            return val
        }
    }
}

export function applyTextFilter(arr, func, filter) {
    if (filter == null || filter === '') {
        return arr
    } else {
        var lower = filter.toLowerCase()
        return arr.filter((item) => func(item).toLowerCase().match(lower))
    }
}

export function logAndReturn(expr, msg = null) {
    /* eslint-disable no-console */
    if (msg != null) {
        console.log(msg, expr)
    } else {
        console.log(expr)
    }
    return expr
    /* eslint-enable no-console */
}

export function isNotBlank(s) {
    return s != null && s !== ''
}

var emailRegexp = /\S+@\S+\.\S+/
export function isEmail(email) {
    return emailRegexp.test(email)
}

export function isNumeric(s) {
    // http://stackoverflow.com/a/1830844/223486
    return !isNaN(parseFloat(s)) && isFinite(s)
}

export function isNumericOrBlank(s) {
    return s == null || s === '' || isNumeric(s)
}

export function deepLookup(ob, props) {
    let o = ob
    for (let p of props) {
        if (o == null) {
            return null
        }
        o = o[p]
    }
    return o
}

export function getMonday(d) {
    return d
        .clone()
        .startOf('day')
        .subtract(d.isoWeekday() - 1, 'days')
}

export function isSameWeek(d1, d2) {
    return getMonday(d1).isSame(getMonday(d2))
}

export function divideTotalAmongItems({ items, newTotal, get, set }) {
    /**
     * `items` is a list of arbitrary objects.
     *
     * Set the "total" of `items` to `newTotal`, where `get` is a function that
     * takes an item in the list and returns its value used to calculate the
     * total, and `set` takes an item and a value and sets the item's value to
     * the new value.
     *
     * If the sum of `items` before the call is positive, the items
     * are each multiplied by the same factor so the proportion remains the same.
     * Otherwise, all the values are set to the same number.
     */
    let oldTotal = sum(items.map(get))
    if (oldTotal > 0) {
        let factor = newTotal / oldTotal
        items.forEach(function (i) {
            set(i, get(i) * factor)
        })
    } else {
        items.forEach(function (i) {
            set(i, newTotal / items.length)
        })
    }
    return items
}

export function caseInsensitiveContains(haystack, needle) {
    return haystack.toUpperCase().indexOf(needle.toUpperCase()) >= 0
}

export function caseInsensitiveStartsWith(haystack, needle) {
    return haystack.toUpperCase().indexOf(needle.toUpperCase()) === 0
}

export function wrapAsReactNode(ob) {
    if (React.isValidElement(ob)) {
        return ob
    } else {
        return <span>{ob}</span>
    }
}

export function* iterDict(dict) {
    for (let k in dict) {
        if (dict.hasOwnProperty(k)) {
            yield [k, dict[k]]
        }
    }
}

export function* enumerate(iterable) {
    /**
     * Behaves like Python's `enumerate` function.
     */
    let i = 0

    for (const x of iterable) {
        yield [i, x]
        i++
    }
}

export function* ifilter(iterable, pred) {
    for (let [i, item] of enumerate(iterable)) {
        if (pred(item, i)) {
            yield item
        }
    }
}

export function* imap(iterable, mapper) {
    for (let [i, item] of enumerate(iterable)) {
        yield mapper(item, i)
    }
}

export function* chain(...iterables) {
    for (let iterable of iterables) {
        yield* iterable
    }
}

export function* izip(...iterables) {
    let iters = iterables.map((i) => i[Symbol.iterator]())

    while (true) {
        const is = iters.map((i) => i.next())

        if (_.any(is, (i) => i.done)) {
            return
        }
        yield is.map((i) => i.value)
    }
}

export function* iterDateRange(m1, m2) {
    let m = m1.clone()
    while (!m.isAfter(m2)) {
        yield m
        m = m.clone().add(1, 'day')
    }
}

export function areIntersectingKeyValuesEqual(d1, d2) {
    /**
     * `d1` and `d2` are `Immutable.Map` instances.
     * Returns `false` if any key exists in both maps but with different values,
     * `true` otherwise.
     */
    for (let [k, v] of d1) {
        if (d2.has(k) && !Immutable.is(d2.get(k), v)) {
            return false
        }
    }
    return true
}

export function* range(a, b) {
    if (b == null) {
        b = a
        a = 0
    }
    for (let i = a; i < b; i++) {
        yield i
    }
}

export function dictFrom(iterable) {
    let d = {}
    for (let [key, val] of iterable) {
        d[key] = val
    }
    return d
}

export function areSameDbObjects(ob1, ob2) {
    if (ob1 == null && ob2 == null) {
        return true
    }
    if (ob1 == null || ob2 == null) {
        return false
    }
    if (ob1.constructor !== ob2.constructor) {
        return false
    }
    if (ob1.id != null && ob2.id === ob1.id) {
        return true
    }
    if (ob1.uuid != null && ob2.uuid === ob1.uuid) {
        return true
    }
    return false
}

export function printTiming(f) {
    /**
     * Decorator for debugging performance.
     */
    return function (...args) {
        const t1 = moment()
        const r = f(...args)
        const t2 = moment()
        const diff = t2.diff(t1)
        if (diff > 500) {
            /* eslint-disable no-console */
            console.log('args', args, 'diff', diff)
            /* eslint-enable no-console */
        }
        return r
    }
}

export function raiseError(error) {
    /**
   * Convenience function you can use in the middle of an expression.

   eg:

   let a = (this.a != null) ? this.a
     : (this.b != null) ? this.b
     : raiseError(new Error("Should have had either a or b");

   */

    throw error
}

export const LeaveHook = {
    /**
     * Mixin that's basically the same as react-router's (deprecated) Lifecycle
     * mixin (at least for the leave hook).
     */
    contextTypes: {
        router: PropTypes.object.isRequired,
    },

    componentDidMount: function () {
        this.context.router.setRouteLeaveHook(
            this.props.route,
            this.routerWillLeave
        )
    },
}

export function printStackTrace() {
    try {
        throw new Error()
    } catch (e) {
        /* eslint-disable no-console */
        console.log(e.stack)
        /* eslint-enable no-console */
    }
}

export function throwError(errorType) {
    /**
     * Throw an exception class and get a correct stack trace.
     *
     * Use
     *
     *    throwError(MyErrorClass)
     *
     * instead of
     *
     *    throw new MyErrorClass();
     */
    let error = new Error()
    let exception = new Proxy(new errorType(), {
        get: function (ob, prop) {
            return error[prop]
        },
    })
    exception.name = errorType.name
    throw exception
}

export function addPercentages(...percentages) {
    let p = parseFloat(percentages[0])
    for (let i = 1; i < percentages.length; i++) {
        p += parseFloat(percentages[i])
    }
    return p + '%'
}

export function subtractPercentages(...percentages) {
    let p = parseFloat(percentages[0])
    for (let i = 1; i < percentages.length; i++) {
        p -= parseFloat(percentages[i])
    }
    return p + '%'
}

export const numberTimeStringToHours = (val) => {
    let [, hours, minutes, ampm] =
        val.match(
            /([1-2][0-4]|0?[1-9])[\s*[:.,]\s*]?([0-5][0-9])?\s*([AaPp][Mm])?/
        ) || []
    hours = parseInt(hours || 0)
    minutes = parseInt(minutes || 0)
    ampm = ampm || ''
    if (ampm.toLowerCase() === 'pm' && hours < 12) {
        hours += 12
    }
    if (ampm.toLowerCase() === 'am' && hours === 12) {
        hours = 0
    }
    return hours + minutes / 60
}

export const numberTimeStringToMinutes = (val) => {
    let [, hours, minutes, ampm] =
        val.match(
            /([1-2][0-4]|0?[1-9])[\s*[:.,]\s*]?([0-5][0-9])?\s*([AaPp][Mm])?/
        ) || []
    hours = parseInt(hours || 0)
    minutes = parseInt(minutes || 0)
    ampm = ampm || ''
    if (ampm.toLowerCase() === 'pm' && hours < 12) {
        hours += 12
    }
    if (ampm.toLowerCase() === 'am' && hours === 12) {
        hours = 0
    }
    return hours * 60 + minutes
}

export const numMinutesToTimeString = (numMins) => {
    const pm = numMins >= 12 * 60
    if (numMins >= 13 * 60) numMins -= 12 * 60
    const hours = Math.floor(numMins / 60)
    const mins = numMins - hours * 60
    return `${hours}:${String(mins).padStart(2, '0')}${pm ? 'pm' : 'am'}`
}
