import cuid from 'cuid'
import {
    addDays,
    addMonths,
    endOfMonth,
    startOfDay,
    startOfMonth,
    subDays,
} from 'date-fns'
import tuple from 'immutable-tuple'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import Checkbox from '../../Components/Widgets/Checkbox'
import DateValue from '../../Components/Widgets/DateValue'
import { FormLabel, FormRow, FormSection } from '../../forms'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import { logRound } from '../../Utils/niceScale'

const delayProject = ({ fromDate, untilDate, splitPhases, phases }) => {
    fromDate = startOfDay(fromDate)
    untilDate = startOfDay(untilDate)
    const diff = untilDate - fromDate
    if (!diff) {
        return
    }
    phases.forEach((ph) => {
        const startDate = ph.startDate && startOfDay(ph.startDate)
        const endDate = ph.endDate && startOfDay(ph.endDate)
        if (startDate >= fromDate) {
            startDate &&
                ph.update({
                    startDate: new Date(startDate.getTime?.() + diff),
                })
            endDate &&
                ph.update({ endDate: new Date(endDate.getTime?.() + diff) })
        } else if (endDate >= fromDate) {
            if (splitPhases) {
                const lengthRatio1 =
                    (fromDate - startDate) / (endDate - startDate)
                const lengthRatio2 = 1 - lengthRatio1
                const ph2 = PhaseCollection.add(
                    {
                        projectId: ph.projectId,
                        name: ph.name + ' (delayed)',
                        jobNumber: ph.jobNumber + '_2',
                        startDate: untilDate,
                        endDate: new Date(endDate.getTime?.() + diff),
                        fee: logRound(ph.fee * lengthRatio2),
                        expenseBudget: logRound(
                            ph.expenseBudget * lengthRatio2
                        ),
                        hoursBudget: logRound(ph.hoursBudget * lengthRatio2),
                    },
                    { trackUpdates: true }
                )
                ph.update({
                    endDate: subDays(fromDate, 1),
                    fee: ph.fee - ph2.fee,
                    expenseBudget: ph.expenseBudget - ph2.expenseBudget,
                    hoursBudget: ph.hoursBudget - ph2.hoursBudget,
                })
                ph.budgets.forEach((phb) => {
                    const phb2 = phb.clone(
                        {
                            phaseId: ph2.id,
                            hours: logRound(phb.hours * lengthRatio2),
                        },
                        { trackUpdates: true }
                    )
                    phb.update({ hours: phb.hours - phb2.hours })
                })
            } else {
                endDate &&
                    ph.update({
                        endDate: new Date(endDate.getTime?.() + diff),
                    })
            }
        }
    })
}

export default observer(({ project, modalId }) => {
    const [fromDate, setFromDate] = useState(new Date())
    const [untilDate, setUntilDate] = useState(new Date())
    const [splitPhases, setSplitPhases] = useState(false)
    const [selectAllPhases, setSelectAllPhases] = useState(true)
    const [selectedPhases, setSelectedPhases] = useState(new Set())
    return (
        <Modal
            modalId={modalId}
            heading="Delay Project"
            onSave={() =>
                delayProject({
                    fromDate,
                    untilDate,
                    splitPhases,
                    phases: selectAllPhases ? project.phases : selectedPhases,
                })
            }
            saveLabel="Delay Project"
        >
            <p style={{ padding: '0.5em 1em' }}>
                Please select the dates you will like to delay{' '}
                <em>{project.title}</em>
                {
                    ' until. This shift all phase dates that occur after selected date forward by the same amount.'
                }
            </p>

            <div style={{ padding: '0 1em' }}>
                <FormSection>
                    <FormRow>
                        <FormLabel
                            style={{ width: '7em' }}
                            className="inline-block"
                        >
                            Delay From:
                        </FormLabel>
                        <DateValue
                            className="start-date-input"
                            style={{
                                width: '16em',
                                display: 'inline-block',
                            }}
                            value={fromDate}
                            onChange={setFromDate}
                        />
                    </FormRow>
                    <FormRow>
                        <FormLabel
                            style={{ width: '7em' }}
                            className="inline-block"
                        >
                            Delay Until:
                        </FormLabel>
                        <DateValue
                            className="until-date-input"
                            style={{
                                width: '16em',
                                display: 'inline-block',
                            }}
                            value={untilDate}
                            onChange={setUntilDate}
                        />
                    </FormRow>
                    <FormRow>
                        <Checkbox
                            className="split-phases-input"
                            value={splitPhases}
                            label="Split phases in two that occur during the delay."
                            onChange={setSplitPhases}
                            variant="secondary"
                        />
                    </FormRow>
                    <FormRow>
                        <Checkbox
                            className="all-phases-input"
                            value={selectAllPhases}
                            label="Apply to all phases."
                            onChange={setSelectAllPhases}
                            variant="secondary"
                        />
                    </FormRow>
                    {!selectAllPhases ? (
                        <FormRow style={{ paddingLeft: '3em' }}>
                            {project.phases.map((ph) => {
                                return (
                                    <div>
                                        <Checkbox
                                            variant="secondary"
                                            value={selectedPhases.has(ph)}
                                            label={ph.title}
                                            onChange={(include, e) => {
                                                if (include) {
                                                    selectedPhases.add(ph)
                                                    setSelectedPhases(
                                                        new Set([
                                                            ...selectedPhases,
                                                        ])
                                                    )
                                                } else {
                                                    selectedPhases.delete(ph)
                                                    setSelectedPhases(
                                                        new Set([
                                                            ...selectedPhases,
                                                        ])
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </FormRow>
                    ) : null}
                </FormSection>
            </div>
        </Modal>
    )
})
