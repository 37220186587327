import React from 'react'
import { observer } from 'mobx-react'
import {
    addMonths,
    differenceInBusinessDays,
    endOfMonth,
    format,
    startOfMonth,
    subMonths,
} from 'date-fns'
import ExpenseProgressSlider from './ExpenseProgressSlider'
import _ from 'lodash'
import RevenueForecastStore from '../../Pages/RevenueForecastPage/RevenueForecastStore'
import {
    getCostBeforeMonth,
    getCostInMonth,
    getHoursBeforeMonth,
    getHoursInMonth,
    setCostInMonth,
    setHoursInMonth,
} from '../../Utils/forecastHelpers'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ResourceRowCollection from '../../State/Collections/ResourceRowCollection'
import RevenueRowCollection from '../../State/Collections/RevenueRowCollection'

export default observer(
    ({
        row,
        month,
        editable,
        onChange,
        onTimeChange,
        costBudget,
        hoursBudget,
        previousCost,
        previousHours,
        currentCost,
        currentHours,
        key,
        label,
    }) => {
        const monthId = format(month, 'yyyy-MM')
        const project = row?.project
        const phase = row?.phase
        // const revenueRow = getProjectPhaseRow(matchers, RevenueRowCollection)
        const maxCostBudget = Math.max(
            costBudget ?? row?.costBudget,
            previousCost ??
                row?.getCostToDateInMonth(
                    format(subMonths(month, 1), 'yyyy-MM')
                )
        )
        hoursBudget = hoursBudget ?? row?.hoursBudget
        const prevTime =
            previousHours ??
            row?.getHoursToDateInMonth(format(subMonths(month, 1), 'yyyy-MM'))
        const selectedTime =
            currentHours ?? row?.getHoursInMonth(format(month, 'yyyy-MM'))
        const prevCost =
            previousCost ??
            row?.getCostToDateInMonth(format(subMonths(month, 1), 'yyyy-MM'))
        const selectedCost =
            currentCost ?? row?.getCostInMonth(format(month, 'yyyy-MM'))
        return (
            <div
                key={key ?? monthId + project?.id + (phase?.id || '')}
                style={{ marginTop: '-0.25em' }}
            >
                <div>{label ?? phase?.title ?? project?.title}</div>
                <ExpenseProgressSlider
                    total={costBudget ?? row?.costBudget}
                    maxAmount={maxCostBudget}
                    prevAmount={prevCost}
                    amount={selectedCost}
                    prevTime={prevTime}
                    time={selectedTime}
                    totalTime={hoursBudget}
                    height={4}
                    editable={editable}
                    onChange={
                        onChange ||
                        ((v) => {
                            row?.setCostInMonth(format(month, 'yyyy-MM'), v)
                        })
                    }
                    onTimeChange={
                        onTimeChange ||
                        ((v) => {
                            row?.setHoursInMonth(format(month, 'yyyy-MM'), v)
                        })
                    }
                />
            </div>
        )
    }
)
