import React, { useState } from 'react'
import { observer } from 'mobx-react'
import UtilisationProgressInput from '../../Components/ForecastSidebar/UtilisationProgressInput'
import ExpenseProgressInput from '../../Components/ForecastSidebar/ExpenseProgressInput'
import {
    getPhaseExpenseProgressToDate,
    getPhaseRevenueProgressToDate,
    setPhaseExpenseProgressForMonth,
    setPhaseRevenueProgressForMonth,
} from '../../Utils/forecastHelpersOld'
import RevenueProgressInput from '../../Components/ForecastSidebar/RevenueProgressInput'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import RenderOnQueries from '../Layout/RenderOnQueries'
import { qf } from '../../Queries/queryFormatter'
import { Checkbox } from 'antd'
import RevenueForecastStore from '../RevenueForecastPage/RevenueForecastStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import {
    getChildResourceRows,
    setHoursInMonth,
    setProjectHoursInMonth,
    setRoleHoursInMonth,
    setStaffHoursInMonth,
} from '../../Utils/forecastHelpers'
import _ from 'lodash'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import {
    canEditRevenueTargets,
    canEditStaffAllocations,
    canViewStaffAllocations,
    shouldUpdateRevenueFromHours,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import HoursProgressInput from '../../Components/ForecastSidebar/HoursProgressInput'
import FetchStore from '../../Queries/FetchStore'
import ResourceRowCollection from '../../State/Collections/ResourceRowCollection'

export default observer(({ store, month, row }) => {
    return (
        <FetchWrapper store={store} month={month} row={row}>
            <SidebarContent store={store} month={month} row={row} />
        </FetchWrapper>
    )
})

const FetchWrapper = ({ row, store, month, children }) => {
    const includeStaff = 'staffId' in row?.matchers
    return (
        <RenderOnQueries
            queryIds={[
                store.resourceRowQuery({
                    level: 1,
                    groups: ['staff', 'project'],
                    dateRange: [startOfMonth(month), endOfMonth(month)],
                    staffRoleId: row?.roleId,
                    ...(includeStaff ? { staffId: row?.staffId } : {}),
                }),
            ]}
        >
            {children}
        </RenderOnQueries>
    )
}

const SidebarContent = ({ store, month, row }) => {
    const includeStaff = 'staffId' in row?.matchers
    const [updateRevenueWithExpenses, setUpdateRevenueWithExpenses] = useState(
        shouldUpdateRevenueFromHours()
    )
    const role = row?.role
    const staff = row?.staff
    const allStaff = staff
        ? [staff]
        : role
          ? role.staff.filter((s) => !s.isArchived)
          : StaffCollection.staffByRoleId[null].filter((s) => !s.isArchived)
    const roleRow = includeStaff ? null : row
    const staffRows = includeStaff
        ? [row]
        : allStaff.map((s) => {
              const staffRowId = 'staffResourceSidebar' + s.id
              const staffRow =
                  ResourceRowCollection.rowsById[staffRowId] ||
                  ResourceRowCollection.add({
                      id: staffRowId,
                      roleId: row?.roleId,
                      staffId: s.id,
                      level: 0,
                      groups: [...row.groups, 'staff'],
                      dateRange: [startOfMonth(month), endOfMonth(month)],
                  })
              return staffRow
          })
    if (roleRow) {
        roleRow.setChildren(staffRows)
        staffRows.forEach((s) => {
            s.setParent(roleRow)
        })
    }
    const staffProjectRows = store.getResourceRows({
        level: 1,
        groups: ['staff', 'project'],
        dateRange: [startOfMonth(month), endOfMonth(month)],
        staffRoleId: row?.roleId,
        ...(includeStaff ? { staffId: row?.staffId } : {}),
    })
    staffRows.forEach((s) => {
        const childResourceRows = staffProjectRows.filter(
            (r) => r.staffId === s.staffId
        )
        s.setChildren(childResourceRows)
        childResourceRows.forEach((r) => {
            r.setParent(s)
        })
    })
    const roleProjectRows = []
    if (roleRow) {
        const rowsByProjectId = _.groupBy(staffProjectRows, 'projectId')
        Object.keys(rowsByProjectId).forEach((projectId) => {
            const projectRows = rowsByProjectId[projectId]
            const roleProjectRowId =
                'projectResourceSidebar' + projectId + roleRow.roleId
            const roleProjectRow =
                ResourceRowCollection.rowsById[roleProjectRowId] ||
                ResourceRowCollection.add({
                    id: roleProjectRowId,
                    roleId: roleRow.roleId,
                    projectId,
                    groups: ['role', 'project'],
                })
            roleProjectRow.setChildren(projectRows)
            roleProjectRow.setParent(roleRow)
            roleProjectRows.push(roleProjectRow)
        })
    }
    if (!canViewStaffAllocations(SessionStore.user)) {
        return (
            <div style={{ padding: '1em' }}>
                You do not have permission to view staff allocations.
            </div>
        )
    }
    return (
        <div key={row.id}>
            {canEditRevenueTargets(SessionStore.user) ? (
                <Checkbox
                    onChange={(e) => {
                        const { checked } = e.target
                        setUpdateRevenueWithExpenses(checked)
                    }}
                    checked={updateRevenueWithExpenses}
                    style={{
                        width: '45%',
                        float: 'right',
                        fontSize: '0.65em',
                    }}
                >
                    Update Revenue With Hours
                </Checkbox>
            ) : null}
            <div style={{ padding: '2em' }}>
                {roleRow ? (
                    <>
                        <h4>Role</h4>
                        <UtilisationProgressInput
                            updateRevenue={updateRevenueWithExpenses}
                            editable={canEditStaffAllocations(
                                SessionStore.user
                            )}
                            key={role?.id}
                            month={month}
                            height={6}
                            row={roleRow}
                            staffRow={roleRow}
                            label={roleRow.role?.name}
                        />
                    </>
                ) : null}
            </div>
            <div style={{ padding: '2em' }}>
                <h4>Staff</h4>
                {staffRows.map((s) => (
                    <UtilisationProgressInput
                        updateRevenue={updateRevenueWithExpenses}
                        editable={canEditStaffAllocations(SessionStore.user)}
                        key={s.id}
                        row={s}
                        staffRow={s}
                        month={month}
                        label={s.staff?.fullName}
                    />
                ))}
            </div>
            <div style={{ padding: '2em' }}>
                <h4>Projects</h4>
                {(roleRow ? roleProjectRows : staffProjectRows)
                    .filter((projRow) =>
                        canViewStaffAllocations(
                            SessionStore.user,
                            projRow.project
                        )
                    )
                    .sort((a, b) => {
                        return a.project.title?.localeCompare?.(b.project.title)
                    })
                    .map((projRow, i) => {
                        return (
                            <UtilisationProgressInput
                                updateRevenue={updateRevenueWithExpenses}
                                editable={canEditStaffAllocations(
                                    SessionStore.user
                                )}
                                key={projRow.id}
                                row={projRow}
                                staffRow={projRow.parent}
                                month={month}
                                label={projRow.project?.title}
                                showOtherHours={false}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
