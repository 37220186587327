import { createFileRoute } from '@tanstack/react-router'
import RevenueForecastPageHeader from '../../Pages/RevenueForecastPage/RevenueForecastPageHeader'
import RevenueForecastPage from '../../Pages/RevenueForecastPage/RevenueForecastPage'
import PageBody from '../../Pages/Layout/PageBody'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import RevenueForecastSidebar from '../../Pages/RevenueForecastPage/RevenueForecastSidebar'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import SessionStore from '../../State/SessionStore'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import { qf } from '../../Queries/queryFormatter'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { getCacheItemById } from '@2/cache'
import { PageBody as V2PageBody } from '@2/layout/page-body'
import { RevenueForecastPage as RevenueForecastPageV2 } from '../../version2/pages/revenue-forecast/revenue-forecast-page'
import { RevenueForecastSidebar as RevenueForecastSidebarV2 } from '../../version2/pages/revenue-forecast/revenue-forecast-sidebar'
import {
    fetchForecastData,
    setData,
    setDateRange,
    setReport,
} from '../../version2/pages/revenue-forecast/revenue-forecast-store'
import { startOfMonth, addMonths, format, subMonths } from 'date-fns'
import { useSidebarStack } from '@2/layout/sidebar'
import { useEffect, useState } from 'react'
import { Sidebar } from '@2/layout/sidebar'
import { trpc } from '@/system/trpc'

export const Route = createFileRoute('/_private/revenue-forecast')({
    beforeLoad: ({ params, search }) => {
        if (!canViewRoute(SessionStore.user, 'revenueForecast', params))
            throw new Error('Permission Denied')
        const report = getCacheItemById('revenueForecastReports', search.report)
        setLayoutDefaults({
            pageTitle: report?.name || 'Revenue Forecast',
            subMenu: 'organisation',
            showSidebar: true,
            sidebarExpanded: false,
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report, startDate } }) => ({
        report,
        startDate,
    }),
})

const getDefaultDateRange = () => {
    const start = subMonths(startOfMonth(new Date()), 3)
    const end = addMonths(start, 11) // 12 months including start month
    return [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')]
}

const loader = async ({ report, startDate }) => {
    const reportId =
        report || SessionStore.organisation?.defaultRevenueForecastReportId
    const collections = [
        reportId
            ? {
                  collection: 'revenueForecastReports',
                  fields: ['name', 'filters'],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'revenueForecastReports',
            fields: ['name'],
        },
        {
            collection: 'overheadExpenses',
            fields: ['name'],
        },
        {
            collection: 'milestones',
            fields: ['label', 'date', 'phaseId', 'projectId'],
        },
    ].filter(Boolean)
    if (migratedToV2()) {
        const dateRange = startDate
            ? [
                  startDate,
                  format(addMonths(new Date(startDate), 11), 'yyyy-MM-dd'),
              ]
            : getDefaultDateRange()

        // First fetch the report if specified
        let filters = {}
        if (report) {
            const reportData =
                await trpc.revenueForecast.getRevenueForecastReport.query({
                    id: report,
                })
            setReport(reportData)
            filters = reportData.filters
        }

        // Load initial data with filters from report
        await fetchForecastData(dateRange, filters)
        setDateRange(dateRange)
    }
    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return (
            <>
                <V2PageBody>
                    <RevenueForecastPageV2 />
                </V2PageBody>
            </>
        )
    }
    return (
        <>
            <RevenueForecastPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <RevenueForecastPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
            <PageSidebar>
                <RevenueForecastSidebar
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageSidebar>
        </>
    )
}
