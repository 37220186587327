import { createFileRoute } from '@tanstack/react-router'
import { endOfWeek, parse, startOfWeek } from 'date-fns'
import WeeklyPlanningPage from '../../Pages/WeeklyPlanningPage/WeeklyPlanningPage'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import { CalendarSidebar } from '../../Components/Calendar/CalendarSidebar'
import PageBody from '../../Pages/Layout/PageBody'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import SessionStore from '../../State/SessionStore'
import { qf } from '../../Queries/queryFormatter'
import fetchData from '../../Queries/fetchData'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import { setLayoutDefaults } from '@2/layout/layout-store'
export const Route = createFileRoute('/_private/weekly-planning')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'weeklyPlanning', params))
            throw new Error('Permission Denied')
        setLayoutDefaults({
            pageTitle: 'Weekly Planning',
            subMenu: 'organisation',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    component: Wrapper,
})

const loader = async ({ date, staffId }) => {
    date = date
        ? parse(date, 'yyyy-MM-dd', new Date())
        : startOfWeek(new Date(), { weekStartsOn: 1 })
    const collections = [
        {
            collection: 'timeEntries',
            fields: [
                'costCentreId',
                'projectId',
                'phaseId',
                'staffId',
                'taskId',
                'startMinutes',
                'numMinutes',
                'date',
                'notes',
                'isVariation',
                'isBillable',
                'isOvertime',
                'isLocked',
                'deletedAt',
            ],
            filters: [
                `staffId == "${staffId || SessionStore.userId}"`,
                `date >= ${qf(startOfWeek(date), { weekStartsOn: 1 })}`,
                `date <= ${qf(endOfWeek(date), { weekStartsOn: 1 })}`,
            ],
        },
        {
            collection: 'dailyAllocations',
            fields: [
                'costCentreId',
                'projectId',
                'phaseId',
                'staffId',
                'taskId',
                'startMinutes',
                'numMinutes',
                'date',
                'notes',
                'isVariation',
                'isBillable',
                'isOvertime',
                'isLocked',
                'deletedAt',
            ],
            filters: [
                `date >= ${qf(startOfWeek(date))}`,
                `date <= ${qf(endOfWeek(date))}`,
            ],
        },
    ].filter(Boolean)

    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    return (
        <>
            <PageBody>
                <WeeklyPlanningPage />
            </PageBody>
            <PageSidebar>
                <CalendarSidebar />
            </PageSidebar>
        </>
    )
}
