import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { queryClient } from '@/App'
import { useMemo } from 'react'

export const GLOBAL_CACHE_KEY = ['globalCache'] as const

// Type definitions
interface CacheData {
    staff: Array<{
        id: number
        firstName: string
        lastName: string
        hasLogin: boolean
        isArchived: boolean
    }>
    roles: Array<{
        id: number
        name: string
        isArchived: boolean
    }>
    contacts: Array<{
        id: number
        firstName: string
        lastName: string
        organisationName: string
    }>
    costCentres: Array<{
        id: number
        name: string
    }>
    invoices: Array<{
        id: number
        ref: string
    }>
    organisationReports: Array<{
        id: number
        name: string
        type: string
    }>
    organisationSubscriptions: Array<any> // Using any since full selection is made
    organisations: Array<any> // Using any since full selection is made
    suppliers: Array<{
        id: number
        name: string
    }>
    resourceScheduleReports: Array<{
        id: number
        name: string
    }>
    revenueForecastReports: Array<{
        id: number
        name: string
    }>
    projects: Array<{
        id: number
        name: string
        jobNumber: string
        status: string
    }>
    permissions: Array<{
        id: number
        name: string
        settings: object
    }>
}

export type FilterFunction<T> = (item: T) => boolean
export type SortFunction<T> = (a: T, b: T) => number

// Create the store
export const globalCacheStore = createStore({
    context: {
        cache: {} as CacheData,
    },
    on: {
        setCache: {
            cache: (_, event: { cache: CacheData }) => {
                queryClient.setQueryData(GLOBAL_CACHE_KEY, event.cache)
                return event.cache
            },
        },
        updateCache: {
            cache: (
                context,
                event: {
                    key: keyof CacheData
                    data: CacheData[keyof CacheData]
                }
            ) => {
                const newCache = {
                    ...context.cache,
                    [event.key]: event.data,
                }
                queryClient.setQueryData(GLOBAL_CACHE_KEY, newCache)
                return newCache
            },
        },
        addItem: {
            cache: (
                context,
                event: {
                    key: keyof CacheData
                    data: CacheData[keyof CacheData][number]
                }
            ) => {
                const newCache = {
                    ...context.cache,
                    [event.key]: [
                        ...(context.cache[event.key] as any[]),
                        event.data,
                    ],
                }
                queryClient.setQueryData(GLOBAL_CACHE_KEY, newCache)
                return newCache
            },
        },
        updateItem: {
            cache: (
                context,
                event: {
                    key: keyof CacheData
                    id: number | string
                    data: Partial<CacheData[keyof CacheData][number]>
                }
            ) => {
                const newCache = {
                    ...context.cache,
                    [event.key]: (context.cache[event.key] as any[])?.map(
                        (item) =>
                            item.id === event.id
                                ? { ...item, ...event.data }
                                : item
                    ),
                }
                queryClient.setQueryData(GLOBAL_CACHE_KEY, newCache)
                return newCache
            },
        },
        deleteItem: {
            cache: (
                context,
                event: { key: keyof CacheData; id: number | string }
            ) => {
                const newCache = {
                    ...context.cache,
                    [event.key]: (context.cache[event.key] as any[])?.filter(
                        (item) => item.id !== event.id
                    ),
                }
                queryClient.setQueryData(GLOBAL_CACHE_KEY, newCache)
                return newCache
            },
        },
        clear: {
            cache: () => {
                queryClient.setQueryData(GLOBAL_CACHE_KEY, {})
                return {} as CacheData
            },
        },
    },
})

// Action creators
export const setCache = (cache: CacheData) =>
    globalCacheStore.send({ type: 'setCache', cache })

export const updateCache = <K extends keyof CacheData>(
    key: K,
    data: CacheData[K]
) => globalCacheStore.send({ type: 'updateCache', key, data })

export const addCacheItem = <K extends keyof CacheData>(
    key: K,
    data: CacheData[K][number]
) => globalCacheStore.send({ type: 'addItem', key, data })

export const updateCacheItem = <K extends keyof CacheData>(
    key: K,
    id: number | string,
    data: Partial<CacheData[K][number]>
) => globalCacheStore.send({ type: 'updateItem', key, id, data })

export const deleteCacheItem = <K extends keyof CacheData>(
    key: K,
    id: number | string
) => globalCacheStore.send({ type: 'deleteItem', key, id })

export const clearCache = () => globalCacheStore.send({ type: 'clear' })

// Selector hooks
export const useCache = () =>
    useSelector(globalCacheStore, (state) => state.context.cache)

export const getCache = () => globalCacheStore.getSnapshot().context.cache

// Action creator for getting item by ID without subscribing
export const getCacheItemById = <K extends keyof CacheData>(
    key: K,
    id: number | string
): CacheData[K][number] | undefined => {
    const items = globalCacheStore.getSnapshot().context.cache[key] as any[]
    return items?.find((item) => item.id === id)
}

// Hook for getting item by ID with subscription (for components)
export const useCacheItemById = <K extends keyof CacheData>(
    key: K,
    id: number | string
) => {
    const items = useSelector(
        globalCacheStore,
        (state) => state.context.cache[key]
    ) as any[]
    return useMemo(() => items?.find((item) => item.id === id), [items, id])
}

export const useCacheSlice = <K extends keyof CacheData>(key: K) =>
    useSelector(globalCacheStore, (state) => state.context.cache[key])

export const getCacheSlice = <K extends keyof CacheData>(
    key: K
): CacheData[K] => globalCacheStore.getSnapshot().context.cache[key]

export const useFilteredCacheItems = <K extends keyof CacheData>(
    key: K,
    filter?: FilterFunction<CacheData[K][number]>,
    sort?: SortFunction<CacheData[K][number]>
) => {
    const items = useSelector(
        globalCacheStore,
        (state) => state.context.cache[key]
    ) as CacheData[K]
    return useMemo(() => {
        let result = [...(items || [])]
        if (filter) result = result.filter(filter)
        if (sort) result.sort(sort)
        return result
    }, [items, filter, sort])
}

export const getFilteredCacheItems = <K extends keyof CacheData>(
    key: K,
    filter?: FilterFunction<CacheData[K][number]>,
    sort?: SortFunction<CacheData[K][number]>
) => {
    let items = globalCacheStore.getSnapshot().context.cache[key]
    if (filter) items = items.filter(filter)
    if (sort) items.sort(sort)
    return items
}

export const useProjectReports = () => {
    return useFilteredCacheItems(
        'organisationReports',
        (report) => report.type === 'project'
    )
}

// Selector for projects by status
export const useProjectsByStatus = () => {
    const activeProjects = getFilteredCacheItems(
        'projects',
        (project) => project.status === 'active'
    )
    const prospectiveProjects = getFilteredCacheItems(
        'projects',
        (project) => project.status === 'prospective'
    )
    const onHoldProjects = getFilteredCacheItems(
        'projects',
        (project) => project.status === 'onHold'
    )
    const archivedProjects = getFilteredCacheItems(
        'projects',
        (project) => project.status === 'archived'
    )

    return useMemo(
        () => ({
            active: activeProjects,
            prospective: prospectiveProjects,
            onHold: onHoldProjects,
            archived: archivedProjects,
        }),
        [activeProjects, prospectiveProjects, onHoldProjects, archivedProjects]
    )
}
