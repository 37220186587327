import Formatter from '@/Components/Formatter'
import CellComponent from '@/Components/CellComponent'
import ProgressBar from '@/Components/Widgets/ProgresBar2'
import { Input } from '@2/components/ui/input'
import { DatePicker } from '@2/components/date-picker'
import { Button } from '../../ui/button'
import { Checkbox } from '../../ui/checkbox'
import { DurationUnit } from '../../duration-unit'
import { SupplierSelector } from '../../selector'
import { TableInput } from '../../ui/table-input'

export type CellType =
    | 'currency'
    | 'percent'
    | 'date'
    | 'number'
    | 'string'
    | 'status'
    | 'boolean'
    | 'progressBar'
    | 'button'
    | 'duration'
    | 'box'
    | 'supplier'
    | 'default'

export function renderCell({
    value,
    row,
    column,
    cell,
    editable = false,
    onClick,
    onChange,
    onFocus,
    className,
    style,
}: {
    value: any
    row: any
    column: any
    cell: (row: any) => any
    editable: boolean
    onClick: (row: any) => void
    onChange: (row: any, value: any) => void
    onFocus: (row: any) => void
    className?: string
    style?: React.CSSProperties
}) {
    const { type, permissions } = column.columnDef?.meta
    if (permissions !== undefined && !permissions(row)) {
        value = null
    }
    if (cell && type !== 'button') {
        return cell(row)
    }
    switch (type) {
        case 'currency':
            return editable ? (
                <TableInput
                    type={type}
                    value={Math.round(value)}
                    onChange={(e) => onChange?.(row, e.target.value)}
                    onClick={() => onClick?.(row)}
                    onFocus={() => onFocus?.(row)}
                    className={className}
                    style={style}
                />
            ) : (
                <div
                    onClick={() => onClick?.(row)}
                    onFocus={() => onFocus?.(row)}
                    className={className}
                    style={style}
                    tabIndex={0}
                >
                    {Formatter.currency(value)}
                </div>
            )
        case 'percent':
            if (editable) {
                return (
                    <TableInput
                        type={type}
                        value={Math.round(value)}
                        onChange={(e) => onChange(row, e.target.value)}
                        onClick={() => onClick(row)}
                    />
                )
            }
            return CellComponent.percent({
                value,
                formattedValue: Formatter.percent(value),
            })
        case 'date': {
            if (editable) {
                return (
                    <DatePicker
                        value={new Date(value)}
                        onChange={(val) => onChange(row, val)}
                    />
                )
            }
            return Formatter.date(value ? new Date(value) : null)
        }
        case 'number': {
            if (editable) {
                return (
                    <TableInput
                        type={type}
                        value={Math.round(value)}
                        onChange={(e) => onChange(row, e.target.value)}
                        onClick={() => onClick(row)}
                    />
                )
            }
            return CellComponent.number({
                value,
                formattedValue: Formatter.number(value),
            })
        }
        case 'status': {
            return Formatter.status(value)
        }
        case 'boolean': {
            if (editable) {
                return (
                    <Checkbox
                        checked={value}
                        onCheckedChange={(checked) => onChange(row, !!checked)}
                    />
                )
            }
            return Formatter.boolean(value)
        }
        case 'progressBar':
            const formatter =
                column.columnDef?.meta?.formatter || Formatter.number
            return (
                <ProgressBar
                    width="100%"
                    height="1.5em"
                    numerator={value?.numerator}
                    denominator={value?.denominator}
                    formatNumber={formatter}
                    radius="4"
                />
            )
        case 'duration':
            return (
                <DurationUnit
                    value={value}
                    onChange={(v) => onChange(row, v)}
                />
            )
        case 'button':
            return (
                <Button
                    onClick={() => onClick(row)}
                    disabled={!editable}
                    variant="outline"
                >
                    {cell(row)}
                </Button>
            )
        case 'supplier':
            if (editable) {
                return (
                    <SupplierSelector
                        value={value}
                        onChange={(v) => onChange(row, v)}
                    />
                )
            }
            return value?.name ?? 'No Supplier'
        case 'box':
            return <>{cell(row)}</>
        default:
            if (editable) {
                return (
                    <Input
                        type={type}
                        value={value}
                        onChange={(e) => onChange(row, e.target.value)}
                        onClick={() => onClick(row)}
                    />
                )
            }
            return value
    }
}
