import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'

export const ContactDetailSchema = z.object({
    id: z.string(),
    firstName: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
    organisationName: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    notes: z.string().nullable().optional(),
})

export type ContactDetail = z.infer<typeof ContactDetailSchema>

export interface ContactData {
    contact: ContactDetail | null
    projects: any[] // Consider adding proper types for projects
    invoices: any[] // Consider adding proper types for invoices
}

type ContactContext = ContactData

const initialContext: ContactContext = {
    contact: null,
    projects: [],
    invoices: [],
}

export const contactStore = createQueryStore<ContactContext, ContactData>(
    {
        context: initialContext,
        on: {
            setData: {
                contact: (_, event: { contact: ContactDetail }) =>
                    event.contact,
                projects: (_, event: { projects: any[] }) =>
                    event.projects ?? [],
                invoices: (_, event: { invoices: any[] }) =>
                    event.invoices ?? [],
            },
            updateContact: {
                contact: (
                    context,
                    event: { value: any; prop: keyof ContactDetail }
                ) =>
                    context.contact
                        ? {
                              ...context.contact,
                              [event.prop]: event.value,
                          }
                        : null,
            },
        },
    },
    {
        queryKey: ['contact'],
        queryFn: async ({ id }) => {
            const result = await trpc.contact.getContact.query({ id })
            return {
                contact: result.contact,
                projects: result.projects ?? [],
                invoices: result.invoices ?? [],
            }
        },
        mutationFn: (data) =>
            trpc.contact.saveContact.mutate({
                contact: data.contact, // Only save contact data, not projects/invoices
            }),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'contact-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to contact?',
        loadingMessage: 'Saving contact...',
        successMessage: 'Contact saved successfully',
        errorMessage: 'Failed to save contact',
    }
)

// Export everything from the store
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = contactStore

// Action creators
export const setData = (data: ContactData) =>
    contactStore.store.send({ type: 'setData', ...data })

export const updateContact = (value: any, prop: keyof ContactDetail) =>
    contactStore.store.send({ type: 'updateContact', value, prop })

// Custom selectors
export const useContact = () =>
    useSelector(contactStore.store, (state) => state.context.contact)

export const useProjects = () =>
    useSelector(contactStore.store, (state) => state.context.projects)

export const useInvoices = () =>
    useSelector(contactStore.store, (state) => state.context.invoices)

// Non-reactive getter
export const getContact = () => contactStore.getState().context.contact
