import { Row, type ColumnDef } from '@tanstack/react-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import SessionStore from '@/State/SessionStore'
import {
    canEditStaffChargeOutRate,
    canViewStaffChargeOutRate,
    canEditStaffCostRate,
    canEditStaffOvertimeRate,
    canViewStaffOvertimeRate,
    canViewStaffCostRate,
    canViewStaffPayRate,
    canEditStaffPayRate,
    canEditStaff,
} from '@/State/Permissions/HasPermissions'
import { staffActions } from './staff-page-store'
import { StaffInterface } from './staff.interface'
import { Button } from '@/version2/components/ui/button'
import { toNumber } from 'lodash'

export function getColumns(
    onHideColumn: (column: string) => void,
    staff: StaffInterface.StaffMember
): ColumnDef<StaffInterface.Rate>[] {
    const generateColumnDef = createGenerateColumnDef<StaffInterface.Rate>({
        onHideColumn,
    })
    const wideSize = 120
    return [
        generateColumnDef({
            key: 'date',
            type: 'date',
            title: 'Starting From',
            size: wideSize,
            editable: (r) => {
                return !r.original.roleId && canEditStaff(SessionStore.user)
            },
            onChange: (row, val) => {
                staffActions.changeRateData(
                    row.original.id,
                    'date',
                    val as Date
                )
            },
        }),
        generateColumnDef({
            key: 'payRate',
            type: 'currency',
            title: 'Pay Rate',
            size: wideSize,
            permissions: (r) => canViewStaffPayRate(SessionStore.user),
            editable: (r: Row<StaffInterface.Rate>) =>
                !r.original.roleId &&
                !staff?.inheritPayRate &&
                canEditStaffPayRate(SessionStore.user),
            customValue: (r: StaffInterface.Rate) =>
                (!r.roleId && !staff?.inheritPayRate) ||
                (r.roleId && staff?.inheritPayRate)
                    ? r.payRate
                    : null,
            onChange: (row, val) => {
                staffActions.changeRateData(
                    row.original.id,
                    'payRate',
                    toNumber(val)
                )
            },
        }),
        generateColumnDef({
            key: 'overtimeRate',
            type: 'currency',
            title: 'Overtime Rate',
            size: wideSize,
            permissions: (r) => canViewStaffOvertimeRate(SessionStore.user),
            editable: (r) =>
                !r.original.roleId &&
                !staff?.inheritOvertimeRate &&
                canEditStaffOvertimeRate(SessionStore.user),
            customValue: (r: StaffInterface.Rate) =>
                (!r.roleId && !staff?.inheritOvertimeRate) ||
                (r.roleId && staff?.inheritOvertimeRate)
                    ? r.overtimeRate
                    : null,
            onChange: (row, val) => {
                staffActions.changeRateData(
                    row.original.id,
                    'overtimeRate',
                    toNumber(val)
                )
            },
        }),
        generateColumnDef({
            key: 'costRate',
            type: 'currency',
            title: 'Cost Rate',
            size: wideSize,
            permissions: (r) => canViewStaffCostRate(SessionStore.user),
            editable: (r) =>
                !staff?.inheritCostRate &&
                !r.original.roleId &&
                canEditStaffCostRate(SessionStore.user),
            customValue: (r: StaffInterface.Rate) =>
                (!r.roleId && !staff?.inheritCostRate) ||
                (r.roleId && staff?.inheritCostRate)
                    ? r.costRate
                    : null,
            onChange: (row, val) => {
                staffActions.changeRateData(
                    row.original.id,
                    'costRate',
                    toNumber(val)
                )
            },
        }),
        generateColumnDef({
            key: 'chargeOutRate',
            type: 'currency',
            title: 'Charge Out Rate',
            size: wideSize,
            permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
            editable: (r) =>
                !staff?.inheritChargeOutRate &&
                !r.original.roleId &&
                canEditStaffChargeOutRate(SessionStore.user),
            customValue: (r: StaffInterface.Rate) =>
                (!r.roleId && !staff?.inheritChargeOutRate) ||
                (r.roleId && staff?.inheritChargeOutRate)
                    ? r.chargeOutRate
                    : null,
            onChange: (row, val) => {
                staffActions.changeRateData(
                    row.original.id,
                    'chargeOutRate',
                    toNumber(val)
                )
            },
        }),
        generateColumnDef({
            key: 'weeklyAvailability',
            type: 'number',
            title: 'Weekly Availability',
            size: wideSize,
            editable: (r) =>
                !r.original.roleId && canEditStaff(SessionStore.user),
            customValue: (r: StaffInterface.Rate) =>
                !r.roleId ? r.weeklyAvailability : null,
            onChange: (row, val) => {
                staffActions.changeRateData(
                    row.original.id,
                    'weeklyAvailability',
                    toNumber(val)
                )
            },
        }),
        generateColumnDef({
            key: 'deleteButton',
            title: 'Action',
            editable: (r) =>
                !r.original.roleId && canEditStaff(SessionStore.user),
            permissions: () => canEditStaff(SessionStore.user),
            cell: (c) => {
                return !c.original.roleId ? (
                    <Button
                        onClick={() => {
                            console.log(c.original.id)
                            staffActions.deleteRate({ id: c.original.id })
                        }}
                        disabled={
                            !!c.original.roleId ||
                            !canEditStaff(SessionStore.user)
                        }
                        variant="outline"
                    >
                        <i className="fa fa-times" />
                    </Button>
                ) : null
            },
        }),
    ]
}
