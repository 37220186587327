import { createFileRoute, notFound } from '@tanstack/react-router'
import PermissionPageHeader from '../../Pages/PermissionPage/PermissionPageHeader'
import PermissionPage from '../../Pages/PermissionPage/PermissionPage'
import PageBody from '../../Pages/Layout/PageBody'
import { idf, qf } from '../../Queries/queryFormatter'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import DataStore from '../../State/DataStore'
import { router } from '../../App'
import PermissionCollection from '../../State/Collections/PermissionCollection'
import { CenterPadPageBody } from '@2/layout/page-body'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { getCacheItemById } from '@2/cache'

export const Route = createFileRoute('/_private/permissions/$id')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'staff-permissions', params))
            throw new Error('Permission Denied')
        const permission = getCacheItemById('permissions', params.id)
        setLayoutDefaults({
            pageTitle: permission?.name || 'Permission',
            subMenu: 'resources',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ params }) => await loader(params.id),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
    onLeave: (match) => {
        const { id } = match.params
        const permission = PermissionCollection.permissionsById[id]
        if (
            !permission?.createdAt &&
            match.pathname !== router.latestLocation.pathname
        ) {
            DataStore.destroyNewItems('permissionId', id)
        }
    },
})

const loader = async (id) => {
    const collections = [
        {
            collection: 'permissions',
            fields: ['name', 'settings'],
            filters: [idf(id)],
        },
        {
            collection: 'staff',
            fields: ['permissionsId', 'permissions'],
        },
    ].filter(Boolean)
    const data = await Promise.all(collections.map(fetchData))
    const permission = PermissionCollection.permissionsById[id]
    if (!permission) {
        throw notFound()
    }
    return data
}

function Wrapper() {
    const { id } = Route.useParams()
    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <PermissionPage permissionId={id} />
            </CenterPadPageBody>
        )
    }
    return (
        <>
            <PermissionPageHeader id={id} />
            <PageBody>
                <PermissionPage permissionId={id} />
            </PageBody>
        </>
    )
}
