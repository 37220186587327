import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import SessionStore from '../../State/SessionStore'
import { countries } from '../../countries'
import * as Sentry from '@sentry/react'
import { makeRequest } from '../../Queries/makeRequest'
import { Selector } from '../../Components/Selector'
import { useNavigate } from '@tanstack/react-router'
import {
    usingNewLogin,
    canViewRoute,
} from '../../State/Permissions/HasPermissions'

export default observer(() => {
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [organisationName, setOrganisationName] = useState('')
    const [countryCode, setCountryCode] = useState(null)
    const [industry, setIndustry] = useState('')
    const [otherIndustry, setOtherIndustry] = useState('')
    const [companySize, setCompanySize] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [referralSource, setReferralSource] = useState('')
    const [otherReferralSource, setOtherReferralSource] = useState('')
    const [submitState, setSubmitState] = useState('idle')
    useEffect(() => {
        fetch('https://cloudflare.com/cdn-cgi/trace')
            .then((response) => response.text())
            .then((data) => {
                // Parse the response which is in format key1=value1\nkey2=value2
                const parsedData = Object.fromEntries(
                    data
                        .trim()
                        .split('\n')
                        .map((line) => line.split('='))
                )
                // Use the loc field which contains the country code
                if (!countryCode && parsedData.loc) {
                    setCountryCode(parsedData.loc.toLowerCase())
                }
            })
    }, [])
    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className="flex items-center justify-center flex-col w-[350px]">
                <header className="flex items-center justify-center flex-col w-full">
                    <div className="logo2 s75 size-[10em] flex items-center justify-center">
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                            alt="Coincraft Logo"
                        />
                    </div>
                    <h1 style={{ fontSize: '5em' }}>coincraft</h1>
                </header>
                <div className="body w-full">
                    <p style={{ marginBottom: '1.5em' }}>
                        We just need to know a few more things so we can finish
                        setting up your account.
                    </p>
                    <form
                        className="no-margin"
                        noValidate
                        style={{ textAlign: 'left' }}
                    >
                        <fieldset>
                            <div className="form-group space-y-6">
                                {!usingNewLogin() ? (
                                    <>
                                        <div className="control-group space-y-2">
                                            <label
                                                className="control-label"
                                                htmlFor="user-details-form__first-name"
                                            >
                                                What's your name?
                                            </label>
                                            <div className="controls form-group flex items-center gap-3.5">
                                                <div className="user_details_form__name-input first w-full">
                                                    <input
                                                        id="user-details-form__first-name"
                                                        value={firstName || ''}
                                                        onChange={(e) => {
                                                            setFirstName(
                                                                e.target.value
                                                            )
                                                        }}
                                                        type="text"
                                                        className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                                        placeholder="First name"
                                                    />
                                                </div>
                                                <div className="user_details_form__name-input w-full">
                                                    <input
                                                        id="user-details-form__last-name"
                                                        value={lastName || ''}
                                                        onChange={(e) => {
                                                            setLastName(
                                                                e.target.value
                                                            )
                                                        }}
                                                        type="text"
                                                        className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                                        placeholder="Last name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="control-group">
                                            {submitState === 'error' &&
                                            (!firstName.length ||
                                                !lastName.length) ? (
                                                <div
                                                    className="popover error-message-popover bottom"
                                                    style={{
                                                        marginBottom: '20px',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {!firstName.length ? (
                                                        <div
                                                            className="arrow"
                                                            style={{
                                                                left: '25%',
                                                                borderBottomColor:
                                                                    'red',
                                                            }}
                                                        />
                                                    ) : null}
                                                    {!lastName.length ? (
                                                        <div
                                                            className="arrow"
                                                            style={{
                                                                left: '75%',
                                                                borderBottomColor:
                                                                    'red',
                                                            }}
                                                        />
                                                    ) : null}
                                                    <div
                                                        className="popover-content"
                                                        style={{ color: 'red' }}
                                                    >
                                                        Please enter your name.
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </>
                                ) : null}
                                <div className="control-group space-y-2">
                                    <label
                                        className="control-label"
                                        htmlFor="user-details-form__organisation-name"
                                    >
                                        What is your organisation called?
                                    </label>
                                    <div className="controls form-group">
                                        <div>
                                            <input
                                                value={organisationName || ''}
                                                onChange={(e) => {
                                                    setOrganisationName(
                                                        e.target.value
                                                    )
                                                }}
                                                type="text"
                                                id="user-details-form__organisation-name"
                                                className="form-control input-lg login-widget__email-input input-base w-full h-10 py-2 focus:outline-none hover:border-[#618fb0]"
                                                placeholder="Organisation name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="control-group">
                                    {submitState === 'error' &&
                                    !organisationName.length ? (
                                        <div
                                            className="popover error-message-popover bottom"
                                            style={{
                                                marginBottom: '20px',
                                                color: 'red',
                                            }}
                                        >
                                            <div
                                                className="arrow"
                                                style={{
                                                    borderBottomColor: 'red',
                                                }}
                                            ></div>
                                            <div
                                                className="popover-content"
                                                style={{ color: 'red' }}
                                            >
                                                Please enter your organisation's
                                                name.
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {!usingNewLogin() ? (
                                    <div className="control-group input-group input-group-lg">
                                        <label
                                            className="control-label"
                                            htmlFor="user-details-form__phone-number"
                                        >
                                            What's your phone number?
                                        </label>
                                        <div className="controls form-group">
                                            <div>
                                                <input
                                                    value={phoneNumber || ''}
                                                    onChange={(e) => {
                                                        setPhoneNumber(
                                                            e.target.value
                                                        )
                                                    }}
                                                    type="tel"
                                                    id="user-details-form__phone-number"
                                                    className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                                    placeholder="Phone number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div
                                    className="control-group space-y-2"
                                    style={{ clear: 'both' }}
                                >
                                    <label
                                        className="control-label"
                                        htmlFor="user-details-form__country"
                                    >
                                        Which country are you from?
                                    </label>
                                    <div className="controls form-group">
                                        <div>
                                            <div className="user-details-form__country">
                                                <CountrySelector
                                                    value={countryCode}
                                                    onChange={(countryCode) => {
                                                        setCountryCode(
                                                            countryCode
                                                        )
                                                    }}
                                                    variant={'secondary'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="control-group"
                                    style={{ clear: 'both' }}
                                >
                                    {submitState === 'error' && !countryCode ? (
                                        <div
                                            className="popover error-message-popover bottom"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <div className="arrow"></div>
                                            <div className="popover-content">
                                                Please enter your country.
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className="control-group mb-6"
                                style={{ clear: 'both' }}
                            >
                                <label
                                    className="control-label mb-2"
                                    htmlFor="user-details-form__industry"
                                >
                                    Industry
                                </label>
                                <div className="controls form-group">
                                    <div>
                                        <select
                                            id="user-details-form__industry"
                                            value={industry}
                                            onChange={(e) => {
                                                setIndustry(e.target.value)
                                                if (
                                                    e.target.value === 'other'
                                                ) {
                                                    setOtherIndustry('')
                                                }
                                            }}
                                            className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                        >
                                            <option value="">
                                                Select your industry
                                            </option>
                                            <option value="architecture">
                                                Architecture
                                            </option>
                                            <option value="engineering">
                                                Engineering
                                            </option>
                                            <option value="marketing">
                                                Marketing
                                            </option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    {industry === 'other' && (
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                id="user-details-form__other-industry"
                                                value={otherIndustry}
                                                onChange={(e) =>
                                                    setOtherIndustry(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Please specify your industry"
                                                className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="control-group mb-6">
                                <label
                                    className="control-label mb-2"
                                    htmlFor="user-details-form__company_size"
                                >
                                    Company Size
                                </label>
                                <div className="controls form-group">
                                    <div>
                                        <select
                                            id="user-details-form__company_size"
                                            value={companySize}
                                            onChange={(e) =>
                                                setCompanySize(e.target.value)
                                            }
                                            className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                        >
                                            <option value="">
                                                Select company size
                                            </option>
                                            <option value="just-me">
                                                Just me
                                            </option>
                                            <option value="1-5">
                                                2-5 staff
                                            </option>
                                            <option value="6-10">
                                                6-10 staff
                                            </option>
                                            <option value="11-25">
                                                11-25 staff
                                            </option>
                                            <option value="26-50">
                                                26-50 staff
                                            </option>
                                            <option value="51-100">
                                                51-100 staff
                                            </option>
                                            <option value="101-200">
                                                101-200 staff
                                            </option>
                                            <option value="201-500">
                                                201-500 staff
                                            </option>
                                            <option value="500+">
                                                500+ staff
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="control-group mb-6"
                                style={{ clear: 'both' }}
                            >
                                <label
                                    className="control-label mb-2"
                                    htmlFor="user-details-form__referral_source"
                                >
                                    How did you hear about us?
                                </label>
                                <div className="controls form-group">
                                    <div>
                                        <select
                                            id="user-details-form__referral_source"
                                            value={referralSource}
                                            onChange={(e) => {
                                                setReferralSource(
                                                    e.target.value
                                                )
                                                if (
                                                    e.target.value === 'other'
                                                ) {
                                                    setOtherReferralSource('')
                                                }
                                            }}
                                            className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                        >
                                            <option value="">
                                                Select an option
                                            </option>
                                            <option value="google_search">
                                                Google/Search Engine
                                            </option>
                                            <option value="social_media">
                                                Social Media (LinkedIn,
                                                Facebook, Twitter, etc.)
                                            </option>
                                            <option value="online_ads">
                                                Online Ads
                                            </option>
                                            <option value="word_of_mouth">
                                                Word of Mouth/Referral from a
                                                Friend
                                            </option>
                                            <option value="review_site">
                                                Review Site (e.g., G2, Capterra)
                                            </option>
                                            <option value="blog_article">
                                                Blog/Article
                                            </option>
                                            <option value="email_newsletter">
                                                Email Newsletter
                                            </option>
                                            <option value="event_webinar">
                                                Event/Webinar
                                            </option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    {referralSource === 'other' && (
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                value={otherReferralSource}
                                                onChange={(e) =>
                                                    setOtherReferralSource(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Please specify"
                                                className="form-control h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <div className="form-actions">
                        <button
                            onClick={() => {
                                if (!organisationName.length || !countryCode) {
                                    setSubmitState('error')
                                } else {
                                    setSubmitState('pending')
                                    if (!usingNewLogin()) {
                                        makeRequest({
                                            path:
                                                process.env
                                                    .REACT_APP_SERVER_URL +
                                                '/api/v1.5/user/register-details',
                                            method: 'post',
                                            data: {
                                                firstName,
                                                lastName,
                                                organisationName,
                                                country: countryCode,
                                                phoneNumber,
                                                industry:
                                                    industry !== 'other'
                                                        ? industry
                                                        : otherIndustry,
                                                companySize,
                                                referralSource:
                                                    referralSource !== 'other'
                                                        ? referralSource
                                                        : otherReferralSource,
                                            },
                                        })
                                            .then(async function (response) {
                                                SessionStore.saveLoginResponse(
                                                    response
                                                )
                                                navigate({
                                                    to: '/projects',
                                                })
                                            })
                                            .catch(function (error) {
                                                Sentry.captureException(error)
                                                console.error(error)
                                                setSubmitState('error')
                                            })
                                    } else {
                                        makeRequest({
                                            path:
                                                process.env
                                                    .REACT_APP_NODE_SERVER_URL +
                                                '/auth/register-org',
                                            method: 'post',
                                            data: {
                                                name: organisationName,
                                                country: countryCode,
                                                industry:
                                                    industry !== 'other'
                                                        ? industry
                                                        : otherIndustry,
                                                companySize,
                                                referralSource:
                                                    referralSource !== 'other'
                                                        ? referralSource
                                                        : otherReferralSource,
                                            },
                                        })
                                            .then(async function (response) {
                                                SessionStore.saveLoginResponse(
                                                    response
                                                )
                                                navigate({ to: '/projects' })
                                            })
                                            .catch(function (error) {
                                                Sentry.captureException(error)
                                                console.error(error)
                                                setSubmitState('error')
                                            })
                                    }
                                }
                            }}
                            className="btn btn-block btn-lg btn-info user_details_form__submit_button w-full"
                        >
                            <span>
                                <i className="fa fa-play"></i>
                            </span>{' '}
                            &nbsp; Start Using Coincraft!
                        </button>
                        <div className="forgot"></div>
                    </div>
                </div>
            </section>
        </div>
    )
})

const CountrySelector = ({ value, onChange, variant }) => {
    return (
        <Selector
            value={value}
            options={countries.map((c) => ({
                label: c.name,
                value: c.code,
            }))}
            searchMatcher={function (country, searchText) {
                return country?.name
                    ?.toLowerCase()
                    .match(searchText.toLowerCase())
            }}
            onChange={(country) => {
                onChange(country)
            }}
            variant={variant}
        />
    )
}
