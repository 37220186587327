export const formatDateISOString = (date: Date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10)
}

export const formatTimeISOString = (date: Date) => {
    return new Date(date).toISOString()
}

// Utility functions for parsing
export const parseNumberOrUndefined = (
    value: string | number | undefined,
    options?: { default?: number }
): number | undefined => {
    if (value === undefined) return options?.default
    if (typeof value === 'number') return value
    const parsed = parseFloat(value)
    return isNaN(parsed) ? options?.default : parsed
}

export const parseDateOrUndefined = (
    value: string | Date | undefined
): Date | string | undefined => {
    if (value === undefined) return undefined
    if (value instanceof Date) return value
    if (typeof value === 'string') return value // Keep string dates as-is
    const parsed = new Date(value)
    return isNaN(parsed.getTime()) ? undefined : parsed
}
