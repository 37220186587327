import React from 'react'
import { observer } from 'mobx-react'
import { format, subMonths } from 'date-fns'
import _ from 'lodash'
import RevenueProgressSlider from './RevenueProgressSlider'

export default observer(
    ({
        row,
        month,
        editable,
        updateCost,
        onChange,
        fee,
        previousRevenue,
        currentRevenue,
        label,
        key,
    }) => {
        const monthId = format(month, 'yyyy-MM')
        const maxFee = Math.max(
            fee ?? row?.totalFee,
            previousRevenue ??
                row?.getRevenueToDateInMonth(
                    format(subMonths(month, 1), 'yyyy-MM')
                )
        )
        const prevRevenue =
            previousRevenue ??
            row?.getRevenueToDateInMonth(format(subMonths(month, 1), 'yyyy-MM'))
        const selectedRevenue =
            currentRevenue ?? row?.getRevenueInMonth(format(month, 'yyyy-MM'))
        return (
            <div key={key ?? monthId + row?.id} style={{ marginTop: '0.9em' }}>
                <div>{label ?? row?.label}</div>
                <RevenueProgressSlider
                    total={fee ?? row?.totalFee}
                    maxAmount={maxFee}
                    prevAmount={prevRevenue}
                    amount={selectedRevenue}
                    height={4}
                    editable={editable}
                    onChange={
                        onChange ??
                        ((v) => {
                            const monthId = format(month, 'yyyy-MM')
                            row?.setRevenueInMonth?.(monthId, v)
                        })
                    }
                />
            </div>
        )
    }
)
