// query-store.ts
import { withHistory } from './store-history'
import { useSelector } from '@xstate/store/react'
import { useSaveToast } from '@/version2/components/hooks/save-toast'
import { useCallback } from 'react'
import {
    createHistoryGetters,
    createHistoryHooks,
    createHistoryActions,
} from './store-history'
import { queryClient } from '@/App'

type QueryConfig<T, P> = {
    queryKey: string[]
    queryFn: () => Promise<T>
    mutationFn: (data: P) => Promise<{ success: boolean }>
    staleTime?: number
    cacheTime?: number
}

type BaseContext = {
    isLoading: boolean
}

type StoreConfig<T> = {
    context: T
    on: Record<string, Partial<T>>
}

type SaveToastConfig = {
    saveId: string
    requireConfirmation?: boolean
    confirmationMessage?: string
    loadingMessage?: string
    successMessage?: string
    errorMessage?: string
}

export function createQueryStore<T extends object, P extends object>(
    storeConfig: StoreConfig<T>,
    queryConfig: QueryConfig<T, P>,
    saveToastConfig: SaveToastConfig
) {
    // Add isLoading to the initial context
    const initialContext = {
        ...storeConfig.context,
        isLoading: false,
    }

    // Create base store with history
    const store = withHistory({
        context: initialContext,
        on: {
            ...storeConfig.on,
            setIsLoading: {
                isLoading: (_, event: { isLoading: boolean }) =>
                    event.isLoading,
            },
        },
    })

    // Create enhanced store with query integration
    const enhancedStore = {
        ...store,
        send: (event: any) => {
            store.send(event)
            if (!['setIsLoading', 'initializeState'].includes(event.type)) {
                // Extract only the original context fields for react-query updates
                const originalContextKeys = Object.keys(storeConfig.context)
                const contextForQuery = Object.fromEntries(
                    Object.entries(store.getSnapshot().context).filter(
                        ([key]) => originalContextKeys.includes(key)
                    )
                )
                queryClient.setQueryData(queryConfig.queryKey, contextForQuery)
            }
        },
    }

    // Create loader function
    const loader = async (...params: any) => {
        store.send({ type: 'setIsLoading', isLoading: true })

        try {
            const result = await queryClient.fetchQuery({
                queryKey: queryConfig.queryKey,
                queryFn: () => queryConfig.queryFn(...params),
                staleTime: queryConfig.staleTime ?? 1000 * 60 * 5,
                cacheTime: queryConfig.cacheTime ?? 1000 * 60 * 30,
            })

            store.send({
                type: 'initializeState',
                state: { ...result, isLoading: false },
            })
            return result
        } catch (error) {
            console.error(
                `Error loading ${queryConfig.queryKey.join('/')}:`,
                error
            )
            throw error
        } finally {
            store.send({ type: 'setIsLoading', isLoading: false })
        }
    }

    // Create custom hooks
    const useStoreData = () =>
        useSelector(enhancedStore, (state) => {
            const originalContextKeys = Object.keys(storeConfig.context)
            return Object.fromEntries(
                Object.entries(state.context).filter(([key]) =>
                    originalContextKeys.includes(key)
                )
            ) as T
        })

    const useIsLoading = () =>
        useSelector(enhancedStore, (state) => state.context.isLoading)

    // Create save hook that integrates with save toast
    const useSaveHandler = () => {
        const { useCanUndo, useCanRedo, useUpdatedAt } =
            createHistoryHooks(enhancedStore)
        const hasUnsavedChanges = useUpdatedAt()?.getTime()
        const { save, revertToLastSave } = createHistoryActions(enhancedStore)

        const handleSave = useCallback(async () => {
            const currentState = enhancedStore.getSnapshot().context
            const originalContextKeys = Object.keys(storeConfig.context)
            const stateForMutation = Object.fromEntries(
                Object.entries(currentState).filter(([key]) =>
                    originalContextKeys.includes(key)
                )
            ) as P
            try {
                const result = await queryConfig.mutationFn(stateForMutation)
                save()
                return result
            } catch (error) {
                console.error('Error saving changes:', error)
                throw error
            }
        }, [])

        const handleCancel = useCallback(() => {
            revertToLastSave()
        }, [])

        useSaveToast({
            saveId: saveToastConfig.saveId,
            onSave: handleSave,
            onCancel: handleCancel,
            requireConfirmation: saveToastConfig.requireConfirmation,
            confirmationMessage: saveToastConfig.confirmationMessage,
            loadingMessage: saveToastConfig.loadingMessage,
            successMessage: saveToastConfig.successMessage,
            errorMessage: saveToastConfig.errorMessage,
            metadata: {
                type: queryConfig.queryKey.join('/'),
                timestamp: Date.now(),
            },
            hasPendingChanges: hasUnsavedChanges,
        })

        return {
            handleSave,
            handleCancel,
            hasUnsavedChanges,
        }
    }

    // Return everything needed to use the store
    return {
        store: enhancedStore,
        loader,
        useStoreData,
        useIsLoading,
        useSaveHandler,
        ...createHistoryHooks(enhancedStore),
        ...createHistoryActions(enhancedStore),
        ...createHistoryGetters(enhancedStore),
    }
}
