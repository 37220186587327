import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import PageHeader from '../Layout/PageHeader'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import LayoutStore from '../../State/LayoutStore'
import ReportHeader, {
    ReportSearch,
} from '../../Components/Reports/ReportHeader'
import TimesheetReportStore from './TimesheetReportStore'
import EditTimeEntryModal from '../TimesheetsPage/EditTimeEntryModal'
import { Selector } from '../../Components/Selector'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import TaskCollection from '../../State/Collections/TaskCollection'
import { format } from 'date-fns'
import HeaderButton from '../../Components/ui/HeaderButton'
import { queryClient } from '../../App'

function getConsistentValue(arr, key) {
    const uniqueValues = new Set(arr.map((obj) => obj[key]))

    return uniqueValues.size === 1 ? [...uniqueValues][0] || null : null
}

export default observer(() => {
    const report = TimesheetReportStore.report
    const selectedRows = [...TimesheetReportStore.selectedRows]
    return (
        <PageHeader
            heading={
                <div>
                    <div>{'Timesheet Reports'}</div>
                    <div className="text-sm font-normal px-2.5 py-1.5">
                        {isFinite(report.dateRange[0])
                            ? `(${format(
                                  report.dateRange[0],
                                  'dd MMMM yyyy'
                              )} - ${format(
                                  report.dateRange[1],
                                  'dd MMMM yyyy'
                              )})`
                            : `(All time prior to: ${format(
                                  new Date(),
                                  'dd MMMM yyyy'
                              )})`}
                    </div>
                </div>
            }
            rightButtons={
                <div className="flex items-center gap-2">
                    <HeaderButton
                        disabled={!TimesheetReportStore.selectedRows.size}
                        onClick={() => {
                            LayoutStore.openModal(
                                <EditTimeEntryModal
                                    modalId={'editEntry'}
                                    timeEntries={selectedRows}
                                    onSave={(data) => {
                                        TimesheetReportStore.batchSaveSelectedRows(
                                            data
                                        )
                                        queryClient.setQueryData(
                                            [
                                                TimesheetReportStore.report
                                                    .queryKey,
                                            ],
                                            (oldData) => ({
                                                ...oldData,
                                                data: TimesheetReportStore.queryData,
                                            })
                                        )
                                    }}
                                    showAllFlags={true}
                                    placeholders={{
                                        project: 'Leave Project Unchanged',
                                        phase: 'Leave Phase Unchanged',
                                        task: 'Leave Task Unchanged',
                                    }}
                                    selectDefaults={false}
                                    defaults={{
                                        project:
                                            ProjectCollection.projectsById[
                                                getConsistentValue(
                                                    selectedRows,
                                                    'projectId'
                                                )
                                            ],
                                        phase: getConsistentValue(
                                            selectedRows,
                                            'phaseId'
                                        )
                                            ? PhaseCollection.phasesById[
                                                  getConsistentValue(
                                                      selectedRows,
                                                      'phaseId'
                                                  )
                                              ] || {
                                                  id: getConsistentValue(
                                                      selectedRows,
                                                      'phaseId'
                                                  ),
                                                  name: getConsistentValue(
                                                      selectedRows,
                                                      'projectPhase'
                                                  ),
                                              }
                                            : null,
                                        task: TaskCollection.tasksById[
                                            getConsistentValue(
                                                selectedRows,
                                                'taskId'
                                            )
                                        ]
                                            ? TaskCollection.tasksById[
                                                  getConsistentValue(
                                                      selectedRows,
                                                      'taskId'
                                                  )
                                              ] || {
                                                  id: getConsistentValue(
                                                      selectedRows,
                                                      'taskId'
                                                  ),
                                                  name: getConsistentValue(
                                                      selectedRows,
                                                      'task'
                                                  ),
                                              }
                                            : null,
                                        isBillable: getConsistentValue(
                                            selectedRows,
                                            'isBillable'
                                        ),
                                        isVariation: getConsistentValue(
                                            selectedRows,
                                            'isVariation'
                                        ),
                                        isOvertime: getConsistentValue(
                                            selectedRows,
                                            'isOvertime'
                                        ),
                                        beenInvoiced: getConsistentValue(
                                            selectedRows,
                                            'beenInvoiced'
                                        ),
                                        isLocked: getConsistentValue(
                                            selectedRows,
                                            'isLocked'
                                        ),
                                    }}
                                />
                            )
                        }}
                        label="Edit Selected Time"
                        icon={<i className="fa fa-table" />}
                    />
                    <div className="export-button-dropdown flex items-center">
                        <HeaderButton
                            label="Export"
                            icon={<i className="fa fa-table" />}
                            onClick={() => {
                                TimesheetReportStore.downloadCSV()
                            }}
                            className="border-r-0 rounded-tr-none rounded-br-none"
                        />
                        <Selector
                            className="border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] rounded-tl-none rounded-bl-none"
                            style={{
                                maxWidth: '9em',
                                margin: 0,
                            }}
                            value={
                                TimesheetReportStore.groupBy[
                                    TimesheetReportStore.exportLevel
                                ] || 'time'
                            }
                            options={[...TimesheetReportStore.groupBy, 'time']}
                            optionLabel={function (group) {
                                return (
                                    <div>
                                        {report.columnOptionsById[group]
                                            ?.label || 'Time Entry'}
                                    </div>
                                )
                            }}
                            onChange={(group) => {
                                const index =
                                    TimesheetReportStore.groupBy.indexOf(group)
                                TimesheetReportStore.setExportLevel(
                                    index >= 0 > 0
                                        ? index
                                        : TimesheetReportStore.groupBy.length
                                )
                            }}
                        />
                    </div>
                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                </div>
            }
            extraContent={
                <>
                    <ReportHeader
                        report={report}
                        reportOptions={
                            ReportCollection.reportsByType['timesheet']
                        }
                        expandClick={() =>
                            TimesheetReportStore.toggleExpandAll()
                        }
                        expandLabel={
                            TimesheetReportStore.expandAll
                                ? '- Collapse All'
                                : '+ Expand All'
                        }
                        selector={
                            <Selector
                                className="py-2 px-4 border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs"
                                style={{ maxWidth: '8em' }}
                                value={
                                    TimesheetReportStore.groupBy[
                                        TimesheetReportStore.expandToLevel
                                    ] || 'time'
                                }
                                options={[
                                    ...TimesheetReportStore.groupBy,
                                    'time',
                                ]}
                                optionLabel={function (group) {
                                    return (
                                        <div>
                                            {report.columnOptionsById[group]
                                                ?.label || 'Time Entry'}
                                        </div>
                                    )
                                }}
                                onChange={(group) => {
                                    const index =
                                        TimesheetReportStore.groupBy.indexOf(
                                            group
                                        )
                                    TimesheetReportStore.setExpandToLevel(
                                        index >= 0 > 0
                                            ? index
                                            : TimesheetReportStore.groupBy
                                                  .length
                                    )
                                }}
                            />
                        }
                    />
                </>
            }
        />
    )
})
