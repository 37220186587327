import { observable, computed, action, makeObservable } from 'mobx'
import {
    format,
    startOfWeek,
    endOfWeek,
    addDays,
    startOfDay,
    isWeekend,
    parse,
    getWeek,
    differenceInDays,
    isSameWeek,
} from 'date-fns'
import cuid from 'cuid'
import tuple from 'immutable-tuple'
import SessionStore from '../../State/SessionStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import TaskCollection from '../../State/Collections/TaskCollection'
import DailyAllocationCollection from '../../State/Collections/DailyAllocationCollection'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import {
    canEditStaffTime,
    canEnterTimeAgainstPhase,
    canEnterTimeAgainstProject,
    canLogTimeAgainstNoPhase,
} from '../../State/Permissions/HasPermissions'
import _ from 'lodash'
import sortPhases from '../../Utils/sortPhases'
import { router } from '../../App'
class calendarState {
    @observable mode = 'time' // or "allocation"
    @observable weekStart = startOfWeek(new Date(), { weekStartsOn: 1 })
    @observable calendarMouseX = 0
    @observable calendarMouseY = 0
    @observable calendarWidth = 100
    @observable calendarHeight = 100
    @observable calendarMinuteEms = 0.1
    @observable selectedValues = {
        costCentreId: null,
        projectId: null,
        phaseId: null,
        taskId: null,
        staffId: null,
        isBillable: true,
        isVariation: false,
        isOvertime: false,
        isLocked: false,
        remote: false,
        flexi: false,
    }
    @observable budgetType = 'phase'
    @observable newItem = null
    @observable selectedItem = null
    @observable newItemModal = false
    @observable editItemModal = false
    @observable isDeletingItem = false
    @observable deletingItem = null
    @observable deleteCountdown = 5
    @observable dragging = false
    @observable draggingChangeMinutes = 0
    deleteTimeout = null
    constructor() {
        makeObservable(this)
    }
    @action.bound
    init(mode, { date, staffId } = {}) {
        this.mode = mode
        this.setDefaults()
        if (date) {
            this.setWeekStart(date)
        }
        if (staffId) {
            this.selectedValues.staffId = staffId
        }
    }
    @action
    setDefaults(selectedValues) {
        const project = ProjectCollection.projects
            ?.sort((a, b) => a.title?.localeCompare?.(b.title))
            .filter((p) => canEnterTimeAgainstProject(SessionStore.user, p))[0]
        const phase = project?.phases
            ?.sort(sortPhases)
            .filter((p) => canEnterTimeAgainstPhase(SessionStore.user, p))[0]
        const task = phase?.defaultTask || phase?.tasks[0]
        this.selectedValues = {
            costCentreId: project?.costCentreId,
            projectId: project?.id,
            phaseId: phase?.id,
            taskId: task?.id,
            staffId: canEditStaffTime(SessionStore.user)
                ? router.latestLocation?.search?.staffId || SessionStore.userId
                : SessionStore.userId,
            isBillable: task?.isBillable ?? true,
            isVariation: task?.isVariation ?? false,
            isOvertime: false,
            isLocked: false,
            remote: false,
            flexi: false,
            numMinutes: 0,
            ...selectedValues,
        }
    }
    @computed
    get weekEnd() {
        return endOfWeek(this.weekStart, { weekStartsOn: 1 })
    }
    @computed
    get weekDays() {
        return [...Array(7).keys()].map((d) => addDays(this.weekStart, d))
    }
    @computed
    get calendarMouseDay() {
        return this.weekDays[
            Math.floor(this.calendarMouseX / (this.calendarWidth / 7))
        ]
    }
    @computed
    get calendarMouseFloatMinutes() {
        return (
            Math.round(
                (this.calendarMouseY / this.calendarHeight) * (24 * 60)
            ) || 0
        )
    }
    @computed
    get calendarMouseHour() {
        return Math.floor(this.calendarMouseFloatMinutes / 60) || 0
    }
    @computed
    get calendarMouseMinute() {
        return this.calendarMouseFloatMinutes - this.calendarMouseHour * 60 || 0
    }
    @action.bound
    adjustDragMinutes(adjustment) {
        this.draggingChangeMinutes = adjustment
    }
    @action.bound
    setDragging(isDragging) {
        this.dragging = isDragging
    }
    @action.bound
    setWeekStart(newWeekStart) {
        this.weekStart = startOfWeek(newWeekStart, { weekStartsOn: 1 })
        this.setDefaults(this.selectedValues)
        router.navigate({
            search: (prev) => ({
                ...prev,
                date: format(this.weekStart, 'yyyy-MM-dd'),
            }),
        })
    }
    @action.bound
    isDayIndexWeekend(dayIndex) {
        return isWeekend(this.weekDays[dayIndex])
    }
    @action.bound
    setCalendarMouseData(x, y, width, height) {
        this.calendarMouseX = x
        this.calendarMouseY = y
        this.calendarHeight = height
        this.calendarWidth = width
    }
    @action.bound
    createNewItem(dragging = false) {
        if (canLogTimeAgainstNoPhase() || this.selectedValues.phaseId) {
            this.newItem = {
                ...this.selectedValues,
                id: cuid(),
                date: this.calendarMouseDay,
                startMinutes: Math.round(this.calendarMouseFloatMinutes),
                numMinutes: 15,
                get staff() {
                    return StaffCollection.staffsById[this.staffId]
                },
                get project() {
                    return ProjectCollection.projectsById[this.projectId]
                },
                get projectPhase() {
                    return PhaseCollection.phasesById[this.phaseId]
                },
                get task() {
                    return TaskCollection.tasksById[this.taskId]
                },
            }
            if (dragging) {
                this.editSelectedItem(this.newItem, true)
            }
        }
    }
    @action.bound
    updateNewItem(newData) {
        for (const [key, val] of Object.entries(newData)) {
            this.newItem[key] = val
        }
    }
    @action.bound
    commitNewItem(newData, dragging = false) {
        this.updateNewItem(newData)
        if (dragging) {
            this.editSelectedItem(this.newItem, true)
        }
        if (this.mode === 'allocation') {
            DailyAllocationCollection.add(this.newItem, { trackUpdates: true })
        } else {
            TimeEntryCollection.add(this.newItem, { trackUpdates: true })
        }
        this.newItem = null
        this.updateSelectedValues({ notes: '' })
    }
    @action.bound
    editSelectedItem(data, dragging = false) {
        // Check if we need to snap to nearby time entries
        if (
            dragging &&
            (data.startMinutes !== undefined || data.numMinutes !== undefined)
        ) {
            const currentItem = this.selectedItem || data
            const staffId = currentItem.staffId
            const date = currentItem.date

            // Get all time entries for this staff member on this date
            const timeEntriesForStaffOnDate =
                TimeEntryCollection.timeEntriesByStaffIdDate[
                    tuple(staffId, date.getTime())
                ] || []

            // Filter out the current entry
            const otherTimeEntries = timeEntriesForStaffOnDate.filter(
                (entry) => entry.id !== currentItem.id
            )
            if (otherTimeEntries.length > 0) {
                const newStartMinutes =
                    data.startMinutes !== undefined
                        ? data.startMinutes
                        : currentItem.startMinutes
                const newNumMinutes =
                    data.numMinutes !== undefined
                        ? data.numMinutes
                        : currentItem.numMinutes
                const newEndMinutes = newStartMinutes + newNumMinutes

                // Check for nearby entries to snap to
                let snappedStartMinutes = Math.round(newStartMinutes / 15) * 15
                let snappedEndMinutes = Math.round(newEndMinutes / 15) * 15

                for (const entry of otherTimeEntries) {
                    const entryStart = entry.startMinutes
                    const entryEnd = entry.startMinutes + entry.numMinutes

                    // Check if start time is within 15 minutes of another entry's end time
                    if (Math.abs(newStartMinutes - entryEnd) <= 15) {
                        // Snap to the minute after the other entry
                        snappedStartMinutes = entryEnd
                    }

                    // Check if end time is within 15 minutes of another entry's start time
                    if (Math.abs(newEndMinutes - entryStart) <= 15) {
                        // Snap to the minute before the other entry
                        snappedEndMinutes = entryStart
                    }
                }

                // Update the data with snapped values
                if (data.startMinutes !== undefined) {
                    data.startMinutes = snappedStartMinutes
                }

                if (data.numMinutes !== undefined) {
                    // Recalculate numMinutes based on snapped end time
                    data.numMinutes = snappedEndMinutes - snappedStartMinutes

                    // Ensure minimum duration of 15 minutes
                    if (data.numMinutes < 15) {
                        data.numMinutes = 15
                    }
                }
            } else {
                if (data.startMinutes !== undefined) {
                    data.startMinutes = Math.round(data.startMinutes / 15) * 15
                }
                if (data.numMinutes !== undefined) {
                    data.numMinutes = Math.round(data.numMinutes / 15) * 15
                }
            }
        }

        this.selectedItem?.update?.(data)
    }
    @action.bound
    selectItem(item) {
        this.deleteCountdown = 0
        this.selectedItem = item
        this.selectedValues = {
            costCentreId: item?.project?.costCentreId,
            projectId: item?.project?.id,
            phaseId: item?.phase?.id,
            taskId: item?.task?.id,
            staffId: item?.staffId,
            isBillable: item?.isBillable,
            isVariation: item?.isVariation,
            isOvertime: item?.isOvertime,
            isLocked: item?.isLocked,
            remote: item?.remote,
            flexi: item?.flexi,
            numMinutes: item?.numMinutes,
            notes: item?.notes,
            date: item?.date,
        }
        this.selectedValues.date = new Date(this.selectedValues.date)
        this.selectedValues.updatedAt = new Date(this.selectedValues.updatedAt)
        //TODO
        // budgetStore.getBudget(
        //     this.weekStart,
        //     this.selectedItem ? this.selectedItem.date : this.weekStart,
        //     this.mode,
        //     this.selectedValues.staffId,
        //     this.selectedValues.projectId,
        //     this.selectedValues.phaseId
        // )
    }
    @action.bound
    deselectItem() {
        if (this.selectedItem) {
            this.deleteCountdown = 0
            this.selectedValues = {
                ...this.selectedValues,
                ...(this.mode === 'allocation' ||
                this.selectedItemType === 'time'
                    ? {
                          isBillable:
                              this.selectedItem?.task?.isBillable ?? true,
                          isVariation:
                              this.selectedItem?.task?.isVariation ?? false,
                          isOvertime: false,
                          isLocked: false,
                          remote: false,
                          flexi: false,
                          notes: '',
                      }
                    : {}),
            }
            this.selectedItem = null
        }
    }
    @action.bound
    updateSelectedValues(data, save = true) {
        this.selectedValues = {
            ...this.selectedValues,
            ...(this.selectedItem
                ? {
                      costCentreId: this.selectedItem?.project?.costCentreId,
                      projectId: this.selectedItem?.project?.id,
                      phaseId: this.selectedItem?.phase?.id,
                      taskId: this.selectedItem?.task?.id,
                      staffId: this.selectedItem?.staffId,
                      isBillable: this.selectedItem?.isBillable,
                      isVariation: this.selectedItem?.isVariation,
                      isOvertime: this.selectedItem?.isOvertime,
                      isLocked: this.selectedItem?.isLocked,
                      remote: this.selectedItem?.remote,
                      flexi: this.selectedItem?.flexi,
                      numMinutes: this.selectedItem?.numMinutes,
                      notes: this.selectedItem?.notes,
                      date: this.selectedItem?.date,
                  }
                : {}),
            ...data,
        }
        if ('projectId' in data) {
            const project = ProjectCollection.projectsById[data.projectId]
            const phase = project?.phases
                ?.sort(sortPhases)
                .filter((p) =>
                    canEnterTimeAgainstPhase(SessionStore.user, p)
                )[0]
            const task = phase?.defaultTask || phase?.tasks[0]
            this.selectedValues = {
                ...this.selectedValues,
                costCentreId: project?.costCentreId,
                projectId: project?.id,
                phaseId: phase?.id,
                taskId: task?.id,
                isBillable: task?.isBillable ?? true,
                isVariation: task?.isVariation ?? false,
            }
        }
        if ('phaseId' in data) {
            const phase = PhaseCollection.phasesById[data.phaseId]
            const task = phase?.defaultTask || phase?.tasks[0]
            this.selectedValues = {
                ...this.selectedValues,
                taskId: task?.id,
                isBillable: task?.isBillable ?? true,
                isVariation: task?.isVariation ?? false,
            }
        }
        if (this.selectedItem && save) {
            this.editSelectedItem(this.selectedValues)
        }
    }
    @action.bound
    updateSelectedStaffId(id) {
        this.setDefaults({ staffId: id })
    }
    @action.bound
    deleteSelectedItem() {
        this.isDeletingItem = true
        this.deleteStartTimestamp = Date.now()
        this.deletingItem = this.selectedItem
        this.deleteCountdown = 5
        this.deleteTimeout = setInterval(
            () => this.deleteItemInterval(this.deletingItem),
            1000
        )
    }
    @action.bound
    deleteItemInterval(item) {
        if (this.deleteCountdown > 0) {
            this.deleteCountdown--
        } else {
            if (this.deletingSelectedItem) {
                this.deselectItem()
            }
            item.update({ deletedAt: new Date() })
            this.isDeletingItem = false
            this.deleteCountdown = 5
            this.deletingItem = null
            clearInterval(this.deleteTimeout)
        }
    }
    @action.bound
    undoDeleteSelectedItem() {
        this.isDeletingItem = false
        this.deleteCountdown = 5
        this.deletingItem = null
        clearInterval(this.deleteTimeout)
    }
    @computed
    get deletingSelectedItem() {
        return this.selectedItem && this.deletingItem === this.selectedItem
    }
    @action.bound
    changeBudgetType(newType) {
        this.budgetType = newType
    }
    @computed
    get dayTotals() {
        const dayTotals = {
            0: { hours: 0, minutes: 0 },
            1: { hours: 0, minutes: 0 },
            2: { hours: 0, minutes: 0 },
            3: { hours: 0, minutes: 0 },
            4: { hours: 0, minutes: 0 },
            5: { hours: 0, minutes: 0 },
            6: { hours: 0, minutes: 0 },
        }
        const maxEndTimes = {
            0: 9 * 60,
            1: 9 * 60,
            2: 9 * 60,
            3: 9 * 60,
            4: 9 * 60,
            5: 9 * 60,
            6: 9 * 60,
        }
        const items =
            this.mode === 'allocation'
                ? DailyAllocationCollection.dailyAllocations
                : TimeEntryCollection.timeEntries
        let models =
            items.filter(
                (te) =>
                    te.staffId === this.selectedValues.staffId &&
                    isSameWeek(te.date, this.weekStart, { weekStartsOn: 1 }) &&
                    !te.deletedAt
            ) || []
        models.forEach((t) => {
            const diffDays = differenceInDays(t.date, this.weekStart)
            if (diffDays >= 0 && diffDays < 7) {
                dayTotals[diffDays].hours += Math.floor(t.numMinutes / 60)
                dayTotals[diffDays].minutes += Math.round(t.numMinutes % 60)
                dayTotals[diffDays].hours += Math.floor(
                    dayTotals[diffDays].minutes / 60
                )
                dayTotals[diffDays].minutes = Math.round(
                    dayTotals[diffDays].minutes % 60
                )
            }
            if (t.startMinutes && t.numMinutes) {
                maxEndTimes[diffDays] = Math.max(
                    maxEndTimes[diffDays],
                    t.startMinutes + t.numMinutes
                )
            }
        })
        models.forEach((t) => {
            const diffDays = differenceInDays(t.date, this.weekStart)
            if (!t.startMinutes && t.numMinutes) {
                t.startMinutes = maxEndTimes[diffDays]
                maxEndTimes[diffDays] += t.numMinutes
            }
        })
        return dayTotals
    }
    getTotalOnDate(date) {
        const items =
            this.mode === 'allocation'
                ? DailyAllocationCollection.dailyAllocations
                : TimeEntryCollection.timeEntries
        let models =
            items.filter(
                (te) =>
                    te.staffId === this.selectedValues.staffId &&
                    te.date.getTime() === date.getTime() &&
                    !te.deletedAt
            ) || []
        return _.sum(models.map((te) => te.numMinutes))
    }
}

export default new calendarState()
