import Formatter from '@/Components/Formatter'
import SessionStore from '@/State/SessionStore'
import { trpc } from '@/system/trpc'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
import {
    updateCacheItem,
    deleteCacheItem,
    addCacheItem,
} from '@/version2/cache'
import { DataTableActions } from '@/version2/components/data-table/advanced/data-table-action'
import useSaveToast from '@/version2/components/hooks/save-toast'
import { setPageTitle } from '@/version2/layout/layout-store'
import { ExportColumn, Fraction, OrganisationReport } from '@/version2/types'
import { useNavigate } from '@tanstack/react-router'
import { Column, Row, Table } from '@tanstack/react-table'
import * as cuid from 'cuid'
import download from 'downloadjs'
import Papa from 'papaparse'
import * as React from 'react'
import { useState } from 'react'
import {
    useOrganisationReport,
    setOrganisationReport,
} from './staff-report-store'

interface StaffReportActionsProps<TData>
    extends React.HTMLAttributes<HTMLDivElement> {
    table: Table<TData>
    exportColumns?: ExportColumn[]
}

export function StaffReportActions<TData>({
    table,
    exportColumns = [],
}: StaffReportActionsProps<TData>) {
    const navigate = useNavigate()
    const [dialogContent, setDialogContent] = useState<string | null>(null)
    const [nameReport, setNameReport] = useState<string | null>(null)

    const organisationReport = useOrganisationReport()
    const checkDisableButton =
        !organisationReport?.id &&
        !SessionStore.organisation?.defaultStaffReportId

    const showSaveAsToast = useSaveToast({
        saveId: 'staff-report-save-as',
        onSave: async () => {
            const newId = cuid()
            const result = await trpc.staffReport.saveReport.mutate({
                id: newId,
                name: nameReport,
                filters: organisationReport.filters,
                type: 'staffMember',
                columns: organisationReport.columns,
                options: organisationReport.options,
            })
            addCacheItem('organisationReports', {
                id: newId,
                name: nameReport,
                type: 'staffMember',
            })
            setNameReport(null)
            setDialogContent(null)
            setOrganisationReport({
                ...organisationReport,
                ...result,
            })
            navigate({
                search: {
                    report: newId,
                },
            })
        },
        loadingMessage: 'Saving new report...',
        successMessage: 'New report saved successfully',
        errorMessage: 'Failed to save new report',
    })

    // Save existing report
    const showSaveToast = useSaveToast({
        saveId: 'staff-report-save',
        onSave: async () => {
            const result = await trpc.staffReport.saveReport.mutate({
                id: organisationReport.id,
                organisationId: organisationReport.organisationId,
                filters: organisationReport.filters,
                columns: organisationReport.columns,
                options: organisationReport.options,
            })
            setDialogContent(null)
            setOrganisationReport({
                ...organisationReport,
                ...result,
            })
            return result
        },
        loadingMessage: 'Saving report...',
        successMessage: 'Report saved successfully',
        errorMessage: 'Failed to save report',
    })

    // Rename operation
    const showRenameToast = useSaveToast({
        saveId: 'staff-report-rename',
        onSave: async () => {
            if (!nameReport || nameReport === organisationReport.name) return
            const result = await trpc.staffReport.saveReport.mutate({
                id: organisationReport.id,
                organisationId: organisationReport.organisationId,
                name: nameReport,
            })
            updateCacheItem('organisationReports', organisationReport.id, {
                name: nameReport,
                type: 'staffMember',
            })
            setPageTitle(nameReport)
            setNameReport(null)
            setDialogContent(null)
            setOrganisationReport({
                ...organisationReport,
                ...result,
            })
            return result
        },
        loadingMessage: 'Renaming report...',
        successMessage: 'Report renamed successfully',
        errorMessage: 'Failed to rename report',
    })

    // Delete operation
    const showDeleteToast = useSaveToast({
        saveId: 'staff-report-delete',
        onSave: async () => {
            const result = await trpc.staffReport.saveReport.mutate({
                id: organisationReport.id,
                organisationId: organisationReport.organisationId,
                deletedAt: new Date(),
            })
            deleteCacheItem('organisationReports', organisationReport.id)
            setDialogContent(null)
            navigate({ search: {} })
            return result
        },
        loadingMessage: 'Deleting report...',
        successMessage: 'Report deleted successfully',
        errorMessage: 'Failed to delete report',
    })

    // Set Default operation
    const showSaveDefaultToast = useSaveToast({
        saveId: 'staff-report-set-default',
        onSave: async () => {
            return await trpc.staffReport.setDefaultReport.mutate({
                id: organisationReport.id,
            })
        },
        loadingMessage: 'Setting as default...',
        successMessage: 'Set as default report',
        errorMessage: 'Failed to set as default',
    })

    const handleOpenDialog = (content: string) => {
        setNameReport(null)
        setDialogContent(content)
    }

    const csvFormatter = (
        data: Row<TData>,
        column: Column<TData, unknown>
    ): string => {
        const value = data.getValue(column?.id)
        switch (column.columnDef.meta?.type) {
            case 'progress':
            case 'progressBar':
                const { numerator, denominator } = value as Fraction
                if (numerator || denominator) {
                    return `${Formatter.progress(
                        numerator || 0
                    )} / ${Formatter.progress(denominator || 0)}`
                } else {
                    return ''
                }
            case 'date':
                return data
                    ? FormatDate(new Date(value as string), {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      })
                    : ''
            default:
                return value as string
        }
    }

    const handleExportToCSV = () => {
        const results: Array<string>[] = []
        const headers = table
            .getAllColumns()
            .filter((item) => organisationReport?.columns?.includes(item.id))
        const firstRow: Array<string> = []
        headers.forEach((header) => firstRow.push(header.columnDef.meta?.title))
        results.push(firstRow)
        // Add data
        table.getRowModel().rows.forEach((item: Row<TData>) => {
            const row: string[] = []
            headers.forEach((header) => {
                row.push(csvFormatter(item, header))
            })
            results.push(row)
        })

        download(Papa.unparse(results), `staff.csv`, 'text/csv')
    }

    const handleSetDefault = async () => {
        showSaveDefaultToast()
    }

    const handleDialogSubmit = async (dialogContent: string) => {
        switch (dialogContent) {
            case 'Save as':
                showSaveAsToast()
                break
            case 'Save':
                showSaveToast()
                break
            case 'Rename Report':
                showRenameToast()
                break
            case 'Delete Report':
                showDeleteToast()
                break
            default:
                break
        }
        setDialogContent(null)
    }
    return (
        <>
            <DataTableActions
                handleExportToCSV={handleExportToCSV}
                handleDialogSubmit={handleDialogSubmit}
                handleOpenDialog={handleOpenDialog}
                handleSetDefault={handleSetDefault}
                dialogContent={dialogContent}
                setDialogContent={setDialogContent}
                setNameReport={setNameReport}
                nameReport={nameReport}
                organisationReport={organisationReport}
                checkDisableButton={checkDisableButton}
                exportColumns={exportColumns}
            />
        </>
    )
}
