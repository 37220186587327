import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { trpc } from '@/system/trpc'
import { addMonths, subMonths, format, endOfMonth } from '@2/utils/date-fns'
import _ from 'lodash'
import { startOfMonth } from 'date-fns'
// Types for the revenue forecast data structure
type MonthlyRevenue = {
    [key: string]: number // Format: YYYY-MM-[A/P/E/S] -> revenue amount
}

type RevenueForecastReport = {
    id: string
    name: string
    organisationId: string
    createdAt: string | null
    updatedAt: string | null
    deletedAt: string | null
    history: any[]
    filters: Record<string, any>
}

type BasePhase = {
    phaseId: string
    label: string
    pastActualTotal: number
    pastProjectedTotal: number
    futureProjectedTotal: number
    futureActualTotal: number
    currentActualTotal: number
    currentProjectedTotal: number
    fee: number
}

type Phase = BasePhase & MonthlyRevenue

type BaseProject = {
    projectId: string
    label: string
    pastActualTotal: number
    pastProjectedTotal: number
    futureProjectedTotal: number
    futureActualTotal: number
    currentActualTotal: number
    currentProjectedTotal: number
    fee: number
    children: Phase[]
}

type Project = BaseProject & MonthlyRevenue

type BaseStatusGroup = {
    status: string // Status: active, prospective, onHold, archived
    pastActualTotal: number
    pastProjectedTotal: number
    futureProjectedTotal: number
    futureActualTotal: number
    currentActualTotal: number
    currentProjectedTotal: number
    fee: number
    children: Project[]
}

type StatusGroup = BaseStatusGroup & MonthlyRevenue

type ExpenseItem = {
    id: string
    name: string
} & MonthlyRevenue

type ForecastData = {
    revenue: StatusGroup[]
    overheadExpenses: ExpenseItem[]
    staffExpenses: ExpenseItem[]
}

type SelectedRow = {
    status: string
    projectId: string
    phaseId: string
} | null

// Initial state
const initialContext = {
    report: null as RevenueForecastReport | null,
    selectedRow: null as SelectedRow,
    selectedMonth: null as string | null,
    dateRange: ['', ''] as [string, string],
    data: [] as StatusGroup[],
    overheadExpenses: [] as ExpenseItem[],
    staffExpenses: [] as ExpenseItem[],
    error: null as string | null,
    isLoading: false,
    sidebar: {
        month: '',
        project: {
            id: '',
            title: '',
            fee: 0,
            expenseBudget: 0,
            hoursBudget: 0,
            revenue: 0,
            expense: 0,
            phases: [] as Array<{
                id: string
                title: string
                fee: number
                expenseBudget: number
                hoursBudget: number
                revenue: number
                expense: number
                status: string
            }>,
        },
        resourcing: {
            allocations: [] as Array<{
                staffId: string
                roleId: string
                projectId: string
                phaseId: string
                hours: number
            }>,
            staff: [] as Array<{
                id: string
                name: string
                roleId: string
                availability: number
                costRate: number
                isArchived: boolean
            }>,
            roles: [] as Array<{
                id: string
                name: string
                isArchived: boolean
            }>,
        },
    },
}

// Create store
export const revenueForecastStore = createStore({
    context: initialContext,
    on: {
        setReport: {
            report: (_, event: { report: RevenueForecastReport }) =>
                event.report,
        },
        setDateRange: {
            dateRange: (_, event: { dateRange: [string, string] }) =>
                event.dateRange,
        },
        setData: {
            data: (_, event: { data: ForecastData }) => event.data.revenue,
            overheadExpenses: (_, event: { data: ForecastData }) =>
                event.data.overheadExpenses,
            staffExpenses: (_, event: { data: ForecastData }) =>
                event.data.staffExpenses,
        },
        setError: {
            error: (_, event: { error: string | null }) => event.error,
        },
        setIsLoading: {
            isLoading: (_, event: { isLoading: boolean }) => event.isLoading,
        },
        setSelectedRow: {
            selectedRow: (_, event: { selectedRow: SelectedRow }) =>
                event.selectedRow,
        },
        setSelectedMonth: {
            selectedMonth: (_, event: { selectedMonth: string | null }) =>
                event.selectedMonth,
        },
        setSidebarProject: {
            sidebar: (
                state,
                event: { project: typeof initialContext.sidebar.project }
            ) => ({
                ...state.sidebar,
                project: event.project,
            }),
        },
        setSidebarResourcing: {
            sidebar: (
                state,
                event: { resourcing: typeof initialContext.sidebar.resourcing }
            ) => ({
                ...state.sidebar,
                resourcing: event.resourcing,
            }),
        },
        setSidebarMonth: {
            sidebar: (state, event: { month: string }) => ({
                ...state.sidebar,
                month: event.month,
            }),
        },
        updateProjectedRevenueCell: {
            data: (
                state,
                event: {
                    path: Array<string | number>
                    value: number
                    month: string
                }
            ) => {
                const { path, value, month } = event
                const monthKey = `${month}-P`

                // Get the old value by traversing the path
                let current = state.data
                let oldValue = 0
                const tempPath = [...path]
                while (tempPath.length > 0) {
                    const key = tempPath.shift()
                    if (current[key] !== undefined) {
                        current = current[key]
                    }
                }
                oldValue = Number(current[monthKey]) || 0
                const diffValue = value - oldValue

                // Helper to determine if a month is current
                const isCurrentMonth = (month: string) => {
                    return month === format(new Date(), 'yyyy-MM')
                }

                // Recalculate totals up the hierarchy
                const updateTotals = (
                    data: any,
                    pathToUpdate: Array<string | number>
                ) => {
                    if (pathToUpdate.length === 0) return

                    let current = data
                    for (let i = 0; i < pathToUpdate.length - 1; i++) {
                        current = current[pathToUpdate[i]]
                    }
                    const parent = current
                    const lastKey = pathToUpdate[pathToUpdate.length - 1]

                    if (parent) {
                        // If the parent has children (it's a project or status group),
                        // recalculate its value based on the sum of its children
                        if (parent[lastKey] && parent[lastKey].children) {
                            const children = parent[lastKey].children
                            const childrenTotal = _.sum(
                                children.map(
                                    (child: any) => Number(child[monthKey]) || 0
                                )
                            )

                            // Update the parent's value to match the sum of its children
                            const oldParentValue =
                                Number(parent[lastKey][monthKey]) || 0
                            parent[lastKey][monthKey] = childrenTotal

                            // Calculate the difference for updating totals
                            const localDiffValue =
                                childrenTotal - oldParentValue

                            // Update the appropriate total based on the month
                            if (isCurrentMonth(month)) {
                                parent[lastKey].currentProjectedTotal =
                                    (parent[lastKey].currentProjectedTotal ||
                                        0) + localDiffValue
                            } else {
                                const monthDate = new Date(month)
                                const today = new Date()
                                if (monthDate > today) {
                                    parent[lastKey].futureProjectedTotal =
                                        (parent[lastKey].futureProjectedTotal ||
                                            0) + localDiffValue
                                }
                            }
                        } else {
                            // If there are no children (it's a phase), use the original diffValue
                            parent[lastKey][monthKey] ??= 0
                            parent[lastKey][monthKey] += diffValue

                            // Update the appropriate total based on the month
                            if (isCurrentMonth(month)) {
                                parent[lastKey].currentProjectedTotal =
                                    (parent[lastKey].currentProjectedTotal ||
                                        0) + diffValue
                            } else {
                                const monthDate = new Date(month)
                                const today = new Date()
                                if (monthDate > today) {
                                    parent[lastKey].futureProjectedTotal =
                                        (parent[lastKey].futureProjectedTotal ||
                                            0) + diffValue
                                }
                            }
                        }
                    }

                    // Continue up the hierarchy
                    updateTotals(data, pathToUpdate.slice(0, -1))
                }

                // Update appropriate total based on month and then update the hierarchy
                const updateTotalForMonth = (
                    item: any,
                    diff: number = diffValue,
                    itemPath: Array<string | number> = []
                ) => {
                    // Update the item's totals
                    if (isCurrentMonth(month)) {
                        item.currentProjectedTotal =
                            (item.currentProjectedTotal || 0) + diff
                    } else {
                        const monthDate = new Date(month)
                        const today = new Date()
                        if (monthDate > today) {
                            item.futureProjectedTotal =
                                (item.futureProjectedTotal || 0) + diff
                        }
                    }

                    // If we have a path, update the hierarchy
                    if (itemPath.length > 0) {
                        updateTotals(newData, itemPath.slice(0, -1))
                    }
                }

                // If updating a project (not a phase)
                const newData = _.cloneDeep(state.data)
                if (path.length === 3) {
                    // [statusIndex, 'children', projectIndex]
                    let current = newData
                    for (let i = 0; i < path.length; i++) {
                        current = current[path[i]]
                    }

                    // Update project value first
                    current[monthKey] = value
                    updateTotalForMonth(current, diffValue, path)

                    // Then distribute to phases
                    const phases = current.children
                    const phaseTotals = _.sum(
                        phases.map((phase: any) => phase[monthKey] || 0)
                    )

                    if (phaseTotals > 0) {
                        // Distribute proportionally based on current phase values
                        phases.forEach((phase: any, phaseIndex: number) => {
                            phase[monthKey] ??= 0
                            const ratio = phase[monthKey] / phaseTotals
                            const oldPhaseValue = phase[monthKey]
                            phase[monthKey] = ratio * value
                            const phaseDiff = phase[monthKey] - oldPhaseValue
                            const phasePath = [...path, 'children', phaseIndex]
                            updateTotalForMonth(phase, phaseDiff, phasePath)
                        })
                    } else {
                        // Distribute equally among phases
                        const equalShare = value / phases.length
                        phases.forEach((phase: any, phaseIndex: number) => {
                            const oldPhaseValue = phase[monthKey] || 0
                            phase[monthKey] = equalShare
                            const phaseDiff = equalShare - oldPhaseValue
                            const phasePath = [...path, 'children', phaseIndex]
                            updateTotalForMonth(phase, phaseDiff, phasePath)
                        })
                    }
                } else {
                    // Direct phase update
                    let current = newData
                    for (let i = 0; i < path.length - 1; i++) {
                        current = current[path[i]]
                    }
                    current[path[path.length - 1]][monthKey] = value
                    updateTotalForMonth(
                        current[path[path.length - 1]],
                        diffValue,
                        path
                    )
                }

                return newData
            },
        },
        mergeFetchedData: {
            data: (state, event: { data: ForecastData }) => {
                const { revenue } = event.data
                const existingRevenue = state.data
                revenue.forEach((statusGroup) => {
                    let existingStatusGroup = existingRevenue.find(
                        (s) => s.status === statusGroup.status
                    )
                    if (existingStatusGroup) {
                        Object.keys(statusGroup).forEach((key) => {
                            if (key !== 'children') {
                                existingStatusGroup[key] = statusGroup[key]
                            } else {
                                const newChildren = statusGroup.children.filter(
                                    (p) =>
                                        !existingStatusGroup.children
                                            .map((p2) => p2.projectId)
                                            .includes(p.projectId)
                                )
                                existingStatusGroup.children = [
                                    ...existingStatusGroup.children,
                                    ...newChildren,
                                ]
                            }
                        })
                    } else {
                        existingRevenue.push(statusGroup)
                        return
                    }
                    statusGroup.children.forEach((project) => {
                        let existingProject = existingStatusGroup.children.find(
                            (p) => p.projectId === project.projectId
                        )
                        if (existingProject) {
                            Object.keys(project).forEach((key) => {
                                if (key !== 'children') {
                                    existingProject[key] = project[key]
                                } else {
                                    existingProject.children = [
                                        ...existingProject.children,
                                        ...project.children.filter(
                                            (p2) =>
                                                !existingProject.children
                                                    .map((p3) => p3.phaseId)
                                                    .includes(p2.phaseId)
                                        ),
                                    ]
                                }
                            })
                        } else {
                            existingStatusGroup.children.push(project)
                            return
                        }
                        project.children.forEach((phase) => {
                            let existingPhase = existingProject.children.find(
                                (p) => p.phaseId === phase.phaseId
                            )
                            if (existingPhase) {
                                Object.keys(phase).forEach((key) => {
                                    existingPhase[key] = phase[key]
                                })
                            } else {
                                existingProject.children.push(phase)
                            }
                        })
                    })
                })
                return existingRevenue
            },
            overheadExpenses: (state, event: { data: ForecastData }) => {
                const { overheadExpenses } = event.data
                const existingOverheadExpenses = state.overheadExpenses
                const expenseMap = new Map(
                    existingOverheadExpenses.map((expense) => [
                        expense.id,
                        expense,
                    ])
                )

                overheadExpenses.forEach((expense) => {
                    const existingExpense = expenseMap.get(expense.id)
                    if (existingExpense) {
                        Object.assign(existingExpense, expense)
                    } else {
                        existingOverheadExpenses.push(expense)
                    }
                })

                return existingOverheadExpenses
            },
            staffExpenses: (state, event: { data: ForecastData }) => {
                const { staffExpenses } = event.data
                const existingStaffExpenses = state.staffExpenses
                const expenseMap = new Map(
                    existingStaffExpenses.map((expense) => [
                        expense.id,
                        expense,
                    ])
                )

                staffExpenses.forEach((expense) => {
                    const existingExpense = expenseMap.get(expense.id)
                    if (existingExpense) {
                        Object.assign(existingExpense, expense)
                    } else {
                        existingStaffExpenses.push(expense)
                    }
                })

                return existingStaffExpenses
            },
        },
    },
})

// Selector hooks
export const useReport = () =>
    useSelector(revenueForecastStore, (state) => state.context.report)

export const useDateRange = () =>
    useSelector(revenueForecastStore, (state) => state.context.dateRange)

export const useData = () =>
    useSelector(revenueForecastStore, (state) => state.context.data)

export const useError = () =>
    useSelector(revenueForecastStore, (state) => state.context.error)

export const useIsLoading = () =>
    useSelector(revenueForecastStore, (state) => state.context.isLoading)

export const useOverheadExpenses = () =>
    useSelector(revenueForecastStore, (state) => state.context.overheadExpenses)

export const useStaffExpenses = () =>
    useSelector(revenueForecastStore, (state) => state.context.staffExpenses)

export const useSelectedRow = () =>
    useSelector(revenueForecastStore, (state) => state.context.selectedRow)

export const useSelectedMonth = () =>
    useSelector(revenueForecastStore, (state) => state.context.selectedMonth)

export const useSidebarProject = () =>
    useSelector(revenueForecastStore, (state) => state.context.sidebar.project)

export const useSidebarResourcing = () =>
    useSelector(
        revenueForecastStore,
        (state) => state.context.sidebar.resourcing
    )

export const useSidebarMonth = () =>
    useSelector(revenueForecastStore, (state) => state.context.sidebar.month)

// Action creators
export const setReport = (report: RevenueForecastReport) =>
    revenueForecastStore.send({
        type: 'setReport',
        report,
    })

export const setDateRange = (dateRange: [string, string]) =>
    revenueForecastStore.send({
        type: 'setDateRange',
        dateRange,
    })

export const setData = (data: ForecastData) =>
    revenueForecastStore.send({
        type: 'setData',
        data,
    })

export const mergeFetchedData = (data: ForecastData) =>
    revenueForecastStore.send({
        type: 'mergeFetchedData',
        data,
    })

export const setError = (error: string | null) =>
    revenueForecastStore.send({
        type: 'setError',
        error,
    })

export const setIsLoading = (isLoading: boolean) =>
    revenueForecastStore.send({
        type: 'setIsLoading',
        isLoading,
    })

export const setSelectedRow = (selectedRow: SelectedRow) =>
    revenueForecastStore.send({
        type: 'setSelectedRow',
        selectedRow,
    })

export const setSelectedMonth = (selectedMonth: string | null) =>
    revenueForecastStore.send({
        type: 'setSelectedMonth',
        selectedMonth,
    })

export const setSidebarProject = (
    project: typeof initialContext.sidebar.project
) =>
    revenueForecastStore.send({
        type: 'setSidebarProject',
        project,
    })

export const setSidebarResourcing = (
    resourcing: typeof initialContext.sidebar.resourcing
) =>
    revenueForecastStore.send({
        type: 'setSidebarResourcing',
        resourcing,
    })

export const setSidebarMonth = (month: string) =>
    revenueForecastStore.send({
        type: 'setSidebarMonth',
        month,
    })

// Helper functions for data manipulation
export const getTotalRevenue = (data: StatusGroup[], month: string): number => {
    return data.reduce((total, statusGroup) => {
        return total + (statusGroup[month] || 0)
    }, 0)
}

export const getStatusGroupRevenue = (
    statusGroup: StatusGroup,
    month: string
): number => {
    return statusGroup[month] || 0
}

export const getProjectRevenue = (project: Project, month: string): number => {
    return project[month] || 0
}

export const getPhaseRevenue = (phase: Phase, month: string): number => {
    return phase[month] || 0
}

export const getMonthlyTotals = (data: StatusGroup[]): MonthlyRevenue => {
    const totals: MonthlyRevenue = {}

    data.forEach((statusGroup) => {
        Object.entries(statusGroup)
            .filter(([key]) => key.match(/^\d{4}-\d{2}-[AP]$/))
            .forEach(([month, revenue]) => {
                totals[month] = (totals[month] || 0) + (revenue as number)
            })
    })

    return totals
}

export const getSelectedRow = () => {
    const state = revenueForecastStore.getSnapshot()
    return state.context.selectedRow
}

export const getSelectedMonth = () => {
    const state = revenueForecastStore.getSnapshot()
    return state.context.selectedMonth
}

export const getReport = () => {
    const state = revenueForecastStore.getSnapshot()
    return state.context.report
}

// Helper function to merge monthly revenues
const mergeMonthlyRevenues = (
    existing: MonthlyRevenue,
    incoming: MonthlyRevenue
): MonthlyRevenue => {
    return { ...existing, ...incoming }
}

// Helper function to merge phases
const mergePhases = (existing: Phase[], incoming: Phase[]): Phase[] => {
    const phaseMap = new Map(existing.map((phase) => [phase.id, phase]))

    incoming.forEach((phase) => {
        const existingPhase = phaseMap.get(phase.id)
        if (existingPhase) {
            const { id, name, ...monthlyRevenue } = phase
            const basePhase: BasePhase = { id, name }
            phaseMap.set(phase.id, {
                ...basePhase,
                ...mergeMonthlyRevenues(existingPhase, monthlyRevenue),
            } as Phase)
        } else {
            phaseMap.set(phase.id, phase)
        }
    })

    return Array.from(phaseMap.values())
}

// Helper function to merge projects
const mergeProjects = (existing: Project[], incoming: Project[]): Project[] => {
    const projectMap = new Map(existing.map((project) => [project.id, project]))

    incoming.forEach((project) => {
        const existingProject = projectMap.get(project.id)
        if (existingProject) {
            const { id, name, phases, ...monthlyRevenue } = project
            const baseProject: BaseProject = {
                id,
                name,
                phases: mergePhases(existingProject.phases, phases),
            }
            projectMap.set(project.id, {
                ...baseProject,
                ...mergeMonthlyRevenues(existingProject, monthlyRevenue),
            } as Project)
        } else {
            projectMap.set(project.id, project)
        }
    })

    return Array.from(projectMap.values())
}

// Helper function to merge status groups
const mergeStatusGroups = (
    existing: StatusGroup[],
    incoming: StatusGroup[]
): StatusGroup[] => {
    const statusMap = new Map(existing.map((status) => [status.id, status]))

    incoming.forEach((status) => {
        const existingStatus = statusMap.get(status.id)
        if (existingStatus) {
            const { id, projects, ...monthlyRevenue } = status
            const baseStatusGroup: BaseStatusGroup = {
                id,
                projects: mergeProjects(existingStatus.projects, projects),
            }
            statusMap.set(status.id, {
                ...baseStatusGroup,
                ...mergeMonthlyRevenues(existingStatus, monthlyRevenue),
            } as StatusGroup)
        } else {
            statusMap.set(status.id, status)
        }
    })

    return Array.from(statusMap.values())
}

// Function to fetch and merge data when date range shifts
export const fetchShiftedData = async (newDateRange: [string, string]) => {
    try {
        setIsLoading(true)
        setError(null)

        const response = await trpc.revenueForecast.getForecastData.query({
            dateRange: newDateRange,
        })

        // Get existing data from store using snapshot
        const state = revenueForecastStore.getSnapshot()
        const existingData = state.context.data

        // Merge existing and new data
        const mergedData = mergeStatusGroups(
            existingData,
            response.revenue as StatusGroup[]
        )

        // Update store with merged data and new date range
        setData({
            revenue: mergedData,
            overheadExpenses: response.overheadExpenses,
            staffExpenses: response.staffExpenses,
        } as ForecastData)
        setDateRange(newDateRange)
    } catch (error) {
        setError(
            error instanceof Error
                ? error.message
                : 'An error occurred while fetching data'
        )
    } finally {
        setIsLoading(false)
    }
}

// Initial data fetching function
export const fetchForecastData = async (
    dateRange: [string, string],
    filters: Record<string, any> = {}
) => {
    try {
        setIsLoading(true)
        setError(null)
        const response = await trpc.revenueForecast.getForecastData.query({
            dateRange,
            filters,
        })
        setData({
            revenue: response.revenue as StatusGroup[],
            overheadExpenses: response.overheadExpenses,
            staffExpenses: response.staffExpenses,
        } as ForecastData)
        setDateRange(dateRange)
    } catch (error) {
        setError(
            error instanceof Error
                ? error.message
                : 'An error occurred while fetching data'
        )
    } finally {
        setIsLoading(false)
    }
}

// Function to fetch and merge data when date range shifts
export const shiftMonths = async (direction: 'left' | 'right') => {
    const state = revenueForecastStore.getSnapshot()
    const currentDateRange = state.context.dateRange
    if (!currentDateRange[0] || !currentDateRange[1]) return

    // setIsLoading(true) // Set loading state immediately

    const startDate = new Date(currentDateRange[0])
    const endDate = new Date(currentDateRange[1])

    // Calculate new date range maintaining 12-month window
    const newStartDate = startOfMonth(
        direction === 'left' ? subMonths(startDate, 1) : addMonths(startDate, 1)
    )
    const newEndDate = endOfMonth(addMonths(newStartDate, 11))

    const newDateRange: [string, string] = [
        format(newStartDate, 'yyyy-MM-dd'),
        format(newEndDate, 'yyyy-MM-dd'),
    ]

    try {
        // setIsLoading(true)
        setError(null)

        // Fetch data for the new date range
        const response = await trpc.revenueForecast.getForecastData.query({
            dateRange: newDateRange,
            filters: getReport()?.filters,
        })

        mergeFetchedData({
            revenue: response.revenue as StatusGroup[],
            overheadExpenses: response.overheadExpenses,
            staffExpenses: response.staffExpenses,
        } as ForecastData)
        setDateRange(newDateRange)
    } catch (error) {
        setError(
            error instanceof Error
                ? error.message
                : 'An error occurred while fetching data'
        )
    } finally {
        // setIsLoading(false)
    }
}

// Function to update cell value and recalculate totals
export const updateProjectedRevenueCellValue = async ({
    status,
    projectId,
    phaseId,
    month,
    value,
}: {
    status: string
    projectId: string
    phaseId?: string
    month: string
    value: number
}) => {
    value = Number(value)
    value = isFinite(value) ? value : 0
    const state = revenueForecastStore.getSnapshot().context
    const statusIndex = _.findIndex(state.data, { status })
    if (statusIndex === -1) return

    const projectIndex = _.findIndex(state.data[statusIndex].children, {
        projectId,
    })
    if (projectIndex === -1) return

    const previousState = state.data

    try {
        if (phaseId) {
            const phaseIndex = _.findIndex(
                state.data[statusIndex].children[projectIndex].children,
                { phaseId }
            )
            if (phaseIndex === -1) return

            revenueForecastStore.send({
                type: 'updateProjectedRevenueCell',
                path: [
                    statusIndex,
                    'children',
                    projectIndex,
                    'children',
                    phaseIndex,
                ],
                value,
                month,
            })
        } else {
            // Update store first
            revenueForecastStore.send({
                type: 'updateProjectedRevenueCell',
                path: [statusIndex, 'children', projectIndex],
                value,
                month,
            })

            // Get updated phases after distribution
            const updatedPhases =
                revenueForecastStore.getSnapshot().context.data[statusIndex]
                    .children[projectIndex].children

            const updates = updatedPhases.map((phase) => ({
                month,
                status,
                projectId,
                phaseId: phase.phaseId,
                value: phase[`${month}-P`] || 0,
            }))
        }
    } catch (error) {
        // Revert changes in store if API call fails
        revenueForecastStore.send({
            type: 'setData',
            data: {
                revenue: previousState,
                overheadExpenses:
                    revenueForecastStore.getSnapshot().context.overheadExpenses,
                staffExpenses:
                    revenueForecastStore.getSnapshot().context.staffExpenses,
            },
        })

        setError(
            error instanceof Error ? error.message : 'Failed to save changes'
        )
    }
}
