import { Input } from './ui/input'
import { cn } from '../lib/utils'
import _ from 'lodash'
import { FormatNumber } from '../../Utils/Localisation/NumberFormatter'
import { Selector } from './selector'
import { SelectWithLabel } from './ui/select'

export interface DurationUnitValue {
    duration: number
    durationUnit: string
}

interface DurationUnitProps {
    value: DurationUnitValue
    className?: string
    onChange: (value: DurationUnitValue) => void
}

export const DurationUnit = (props: DurationUnitProps) => {
    const { value, onChange, className } = props

    return (
        <div className={cn('flex', className)}>
            <div className="w-[40%]">
                <Input
                    value={_.toNumber(value?.duration)}
                    onChange={(e) => {
                        const v = FormatNumber(_.toNumber(e.target.value), {
                            decimals: 2,
                        })
                        onChange({
                            ...value,
                            duration: v,
                        })
                    }}
                />
            </div>
            <div className="w-[60%]">
                <SelectWithLabel
                    value={value?.durationUnit}
                    options={[
                        { key: 'days', value: 'days', label: 'Days' },
                        { key: 'weeks', value: 'weeks', label: 'Weeks' },
                        { key: 'months', value: 'months', label: 'Months' },
                    ]}
                    onValueChange={(v) =>
                        onChange({
                            ...value,
                            durationUnit: v,
                        })
                    }
                />
            </div>
        </div>
    )
}
