import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'

export const OperationalExpensesSchema = z.object({
    id: z.string(),
    name: z.string(),
    value: z.number(),
    startDate: z.date(),
    endDate: z.date(),
    hasRepeat: z.boolean(),
    repeatQuantity: z.number(),
    repeatUnit: z.string(),
    costCentreId: z.string().nullable().optional(),
    deletedAt: z.date().nullable().optional(),
})

export type OperationalExpenses = z.infer<typeof OperationalExpensesSchema>

type OperationalExpensesContext = {
    operationalExpenses: OperationalExpenses[]
}

const initialContext: OperationalExpensesContext = {
    operationalExpenses: [],
}

export const operationalExpensesStore = createQueryStore<
    OperationalExpensesContext,
    { operationalExpenses: OperationalExpenses[] }
>(
    {
        context: initialContext,
        on: {
            setOperationalExpenses: {
                operationalExpenses: (
                    _,
                    event: { operationalExpenses: OperationalExpenses[] }
                ) => event.operationalExpenses,
            },
            addOperationalExpenses: {
                operationalExpenses: (
                    context,
                    event: { operationalExpense: OperationalExpenses }
                ) => [...context.operationalExpenses, event.operationalExpense],
            },
            updateOperationalExpenses: {
                operationalExpenses: (
                    context,
                    event: {
                        id: string
                        operationalExpense: Partial<OperationalExpenses>
                    }
                ) => {
                    const index = context.operationalExpenses.findIndex(
                        (e) => e.id === event.id
                    )
                    if (index === -1) return context.operationalExpenses

                    const updatedExpenses = [...context.operationalExpenses]
                    updatedExpenses[index] = {
                        ...updatedExpenses[index],
                        ...event.operationalExpense,
                    }
                    return updatedExpenses
                },
            },
            deleteOperationalExpenses: {
                operationalExpenses: (context, event: { id: string }) => {
                    const index = context.operationalExpenses.findIndex(
                        (e) => e.id === event.id
                    )
                    if (index === -1) return context.operationalExpenses

                    const updatedExpenses = [...context.operationalExpenses]
                    updatedExpenses[index] = {
                        ...updatedExpenses[index],
                        deletedAt: new Date(),
                    }
                    return updatedExpenses
                },
            },
        },
    },
    {
        queryKey: ['operational-expenses'],
        queryFn: async () => {
            const result =
                await trpc.operationalExpense.getOperationalExpenses.query()
            return {
                operationalExpenses: result.operationalExpenses.map(
                    (expense) => ({
                        ...expense,
                        value: Number(expense.value),
                        startDate: new Date(expense.startDate),
                        endDate: new Date(expense.endDate),
                    })
                ),
            }
        },
        mutationFn: (data) =>
            trpc.operationalExpense.saveOperationalExpenses.mutate({
                operationalExpenses: data.operationalExpenses,
            }),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'operational-expenses-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to operational expenses?',
        loadingMessage: 'Saving operational expenses...',
        successMessage: 'Operational expenses saved successfully',
        errorMessage: 'Failed to save operational expenses',
    }
)

// Export everything from the store
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = operationalExpensesStore

// Action creators
export const setOperationalExpenses = (
    operationalExpenses: OperationalExpenses[]
) =>
    operationalExpensesStore.store.send({
        type: 'setOperationalExpenses',
        operationalExpenses,
    })

export const addOperationalExpenses = (
    operationalExpense: OperationalExpenses
) =>
    operationalExpensesStore.store.send({
        type: 'addOperationalExpenses',
        operationalExpense,
    })

export const updateOperationalExpenses = (
    id: string,
    operationalExpense: Partial<OperationalExpenses>
) =>
    operationalExpensesStore.store.send({
        type: 'updateOperationalExpenses',
        id,
        operationalExpense,
    })

export const deleteOperationalExpenses = (id: string) =>
    operationalExpensesStore.store.send({
        type: 'deleteOperationalExpenses',
        id,
    })

// Custom selectors
export const useOperationalExpenses = () =>
    useSelector(
        operationalExpensesStore.store,
        (state) => state.context.operationalExpenses
    )

export const useActiveOperationalExpenses = () => {
    const expenses = useOperationalExpenses()
    return useMemo(() => expenses.filter((e) => !e?.deletedAt), [expenses])
}
