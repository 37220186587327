import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'

interface Tab {
    id: string
    label: string
    url?: string
}

// Initial state
const initialContext = {
    pageTitle: '',
    subMenu: null as string | null,
    tabs: [] as Tab[],
    showSubMenu: true,
}

// Create the store
export const layoutStore = createStore({
    context: initialContext,
    on: {
        setPageTitle: {
            pageTitle: (_, event: { title: string }) => event.title,
        },
        setSubMenu: {
            subMenu: (_, event: { menu: string | null }) => event.menu,
        },
        setTabs: {
            tabs: (_, event: { tabs: Tab[] }) => event.tabs,
        },
        setShowSubMenu: {
            showSubMenu: (_, event: { show: boolean }) => event.show,
        },
        setDefaults: {
            pageTitle: (_, event: { pageTitle: string }) => event.pageTitle,
            subMenu: (_, event: { subMenu: string | null }) => event.subMenu,
            tabs: (_, event: { tabs: Tab[] }) => event.tabs,
        },
    },
})

// Selector hooks
export const usePageTitle = () =>
    useSelector(layoutStore, (state) => state.context.pageTitle)

export const useSubMenu = () =>
    useSelector(layoutStore, (state) => state.context.subMenu)

export const useTabs = () =>
    useSelector(layoutStore, (state) => state.context.tabs)

export const useShowSubMenu = () =>
    useSelector(layoutStore, (state) => state.context.showSubMenu)

// Action creators
export const setPageTitle = (title: string) =>
    layoutStore.send({ type: 'setPageTitle', title })

export const setSubMenu = (menu: string | null) =>
    layoutStore.send({ type: 'setSubMenu', menu })

export const setTabs = (tabs: Tab[]) =>
    layoutStore.send({ type: 'setTabs', tabs })

export const setShowSubMenu = (show: boolean) =>
    layoutStore.send({ type: 'setShowSubMenu', show })

export const setLayoutDefaults = (defaults: {
    pageTitle: string
    subMenu: string | null
    showSubMenu: boolean
    tabs: Tab[]
}) => layoutStore.send({ type: 'setDefaults', ...defaults })

// Getter functions for non-reactive access
export const getLayoutState = () => layoutStore.getSnapshot().context
