import * as React from 'react'
import { cn } from '@2/lib/utils'

export interface InputProps
    extends Omit<
        React.InputHTMLAttributes<HTMLInputElement>,
        'prefix' | 'suffix' | 'value' | 'defaultValue'
    > {
    prefix?: React.ReactNode
    suffix?: React.ReactNode
    debounceMs?: number
    value?: string | number
    defaultValue?: string | number
}

const TableInput = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            type,
            suffix,
            prefix,
            onChange,
            debounceMs = 300,
            value: controlledValue,
            defaultValue,
            ...props
        },
        ref
    ) => {
        const [internalValue, setInternalValue] = React.useState<
            string | number
        >(controlledValue ?? defaultValue ?? '')

        // Update internal value when controlled value changes
        React.useEffect(() => {
            if (controlledValue !== undefined) {
                setInternalValue(controlledValue)
            }
        }, [controlledValue])

        // Create a debounced version of onChange
        const debouncedOnChange = React.useCallback(
            React.useMemo(() => {
                let timeoutId: NodeJS.Timeout | null = null
                return (value: string) => {
                    if (timeoutId) {
                        clearTimeout(timeoutId)
                    }
                    timeoutId = setTimeout(() => {
                        // Create a synthetic event with the current value
                        const syntheticEvent = {
                            target: { value },
                        } as React.ChangeEvent<HTMLInputElement>
                        onChange?.(syntheticEvent)
                    }, debounceMs)
                }
            }, [onChange, debounceMs]),
            [onChange, debounceMs]
        )

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value

            // For number inputs, only update if the value is valid
            if (['number', 'percent', 'currency'].includes(type)) {
                // Allow empty string for clearing
                if (newValue === '') {
                    setInternalValue(newValue)
                    debouncedOnChange(newValue)
                    return
                }

                // Allow valid number patterns including partial inputs
                const numberPattern = /^-?\d*\.?\d*$/
                if (numberPattern.test(newValue)) {
                    setInternalValue(newValue)
                    debouncedOnChange(newValue)
                }
                setInternalValue(newValue)
                return
            }

            // For all other types, update normally
            setInternalValue(newValue)
            debouncedOnChange(newValue)
        }

        return (
            <div className="relative flex items-center">
                {prefix && (
                    <span className="absolute left-0 text-xs text-muted-foreground">
                        {prefix}
                    </span>
                )}
                <input
                    type={type}
                    className={cn(
                        'z-1 flex w-full rounded-md border border-input bg-background px-1 py-1 text-xs ring-offset-background file:border-0 file:bg-transparent file:text-xs file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
                        suffix && 'pr-10',
                        className
                    )}
                    value={internalValue}
                    onChange={handleChange}
                    ref={ref}
                    {...props}
                />
                {suffix && (
                    <span className="absolute right-1 text-xs text-muted-foreground">
                        {suffix}
                    </span>
                )}
            </div>
        )
    }
)
TableInput.displayName = 'TableInput'

export { TableInput }
