import { createFileRoute } from '@tanstack/react-router'
import PermissionListPageHeader from '../../Pages/PermissionListPage/PermissionListPageHeader'
import PermissionListPage from '../../Pages/PermissionListPage/PermissionListPage'
import PageBody from '../../Pages/Layout/PageBody'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
    usingNewLogin,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { CenterPadPageBody } from '@2/layout/page-body'
import { setLayoutDefaults } from '@2/layout/layout-store'
export const Route = createFileRoute('/_private/permissions/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'staff-permissions', params))
            throw new Error('Permission Denied')
        setLayoutDefaults({
            pageTitle: 'Permissions',
            subMenu: 'resources',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async () => await loader(),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async () => {
    const collections = [
        {
            collection: 'permissions',
            fields: ['name', 'settings'],
        },
    ].filter(Boolean)
    return Promise.all(collections.map(fetchData))
}
function Wrapper() {
    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <PermissionListPage />
            </CenterPadPageBody>
        )
    }
    return (
        <>
            <PermissionListPageHeader />
            <PageBody>
                <PermissionListPage />
            </PageBody>
        </>
    )
}
