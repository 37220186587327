import { createFileRoute } from '@tanstack/react-router'
import ForgottenPasswordPage from '../../Pages/LoginPage/ForgottenPasswordPage'
import ErrorComponent from '../../Components/ErrorComponent'

export const Route = createFileRoute('/_public/forgotten-password')({
    component: Wrapper,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})
function Wrapper() {
    return (
        <ForgottenPasswordPage {...Route.useParams()} {...Route.useSearch()} />
    )
}
