import { z } from 'zod'
import { trpc } from '@/system/trpc'
import { createQueryStore } from '@/version2/utils/query-store'
import { useSelector } from '@xstate/store/react'

export const CostCentreSchema = z.object({
    id: z.string(),
    name: z.string().max(100),
    isBillable: z.boolean(),
    deletedAt: z.date().nullable().optional(),
})

export type CostCentre = z.infer<typeof CostCentreSchema>

type CostCentreContext = {
    costCentres: CostCentre[]
}

const initialContext: CostCentreContext = {
    costCentres: [],
}

export const costCentreStore = createQueryStore<
    CostCentreContext,
    { costCentres: CostCentre[] }
>(
    {
        context: initialContext,
        on: {
            setCostCentres: {
                costCentres: (_, event: { costCentres: CostCentre[] }) =>
                    event.costCentres,
            },
            addCostCentre: {
                costCentres: (context, event: { costCentre: CostCentre }) => [
                    ...context.costCentres,
                    event.costCentre,
                ],
            },
            updateCostCentre: {
                costCentres: (
                    context,
                    event: { id: string; costCentre: Partial<CostCentre> }
                ) => {
                    const index = context.costCentres.findIndex(
                        (c) => c.id === event.id
                    )
                    if (index === -1) return context.costCentres

                    const updatedCostCentres = [...context.costCentres]
                    updatedCostCentres[index] = {
                        ...updatedCostCentres[index],
                        ...event.costCentre,
                    }
                    return updatedCostCentres
                },
            },
            deleteCostCentre: {
                costCentres: (context, event: { id: string }) => {
                    const index = context.costCentres.findIndex(
                        (c) => c.id === event.id
                    )
                    if (index === -1) return context.costCentres

                    const updatedCostCentres = [...context.costCentres]
                    updatedCostCentres[index] = {
                        ...updatedCostCentres[index],
                        deletedAt: new Date(),
                    }
                    return updatedCostCentres
                },
            },
        },
    },
    {
        queryKey: ['cost-centres'],
        queryFn: () => trpc.costCentre.getCostCentres.query(),
        mutationFn: (data) => trpc.costCentre.saveCostCentres.mutate(data),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
    },
    {
        saveId: 'cost-centres-save',
        requireConfirmation: true,
        confirmationMessage: 'Save changes to cost centres?',
        loadingMessage: 'Saving cost centres...',
        successMessage: 'Cost centres saved successfully',
        errorMessage: 'Failed to save cost centres',
    }
)

// Export everything from the store
export const {
    useStoreData,
    useIsLoading,
    useCanUndo,
    useCanRedo,
    useUpdatedAt,
    undo,
    redo,
    save,
    revertToLastSave,
    getState,
    getHistory,
    getCurrentHistoryIndex,
    getLastSavedIndex,
    getSavedAt,
    getUpdatedAt,
} = costCentreStore

// Action creators
export const setCostCentres = (costCentres: CostCentre[]) =>
    costCentreStore.store.send({ type: 'setCostCentres', costCentres })

export const addCostCentre = (costCentre: CostCentre) =>
    costCentreStore.store.send({ type: 'addCostCentre', costCentre })

export const updateCostCentre = (id: string, costCentre: Partial<CostCentre>) =>
    costCentreStore.store.send({ type: 'updateCostCentre', id, costCentre })

export const deleteCostCentre = (id: string) =>
    costCentreStore.store.send({ type: 'deleteCostCentre', id })

export const useCostCentres = () =>
    useSelector(costCentreStore.store, (state) => state.context.costCentres)
