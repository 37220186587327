import { NumberParser } from '@internationalized/number'
import { getUserLocale } from 'get-user-locale'
import isoLangCodes from 'iso-lang-codes'
import SessionStore from '../../State/SessionStore'
import { constantCase } from 'change-case'
import { evaluate } from 'mathjs'
import { FormatNumber } from './NumberFormatter'

export const ParseNumber = (valueString) => {
    if ([null, undefined, ''].includes(valueString)) return valueString

    try {
        // Check if the string contains mathematical operators
        if (/[+\-*/()^]/.test(valueString)) {
            // Get the current locale based on organisation country
            const locale = isoLangCodes.findCountryLocales(
                constantCase(SessionStore?.organisationCountry || 'us')
            )[0]

            // Create number parser for the current locale
            const parser = new NumberParser(locale, {
                style: 'decimal',
            })

            // Regular expression to match numbers and operators
            // This regex captures numbers (possibly with decimal separators or thousands separators)
            // and operators separately
            const regex = /(\d[\d.,]*)|([-+*/()^])/g

            // Process the string to replace localized numbers with standard format
            let standardizedExpression = ''
            let lastIndex = 0
            let match

            const expression = String(valueString)

            // Use regex to find all numbers and operators
            while ((match = regex.exec(expression)) !== null) {
                // If there's text between the last match and this one, add it to result
                if (match.index > lastIndex) {
                    standardizedExpression += expression.substring(
                        lastIndex,
                        match.index
                    )
                }

                if (match[1]) {
                    // This is a number - parse it according to locale
                    try {
                        const parsedNumber = parser.parse(match[1])
                        standardizedExpression += parsedNumber
                    } catch (e) {
                        // If parsing fails, keep the original number string
                        standardizedExpression += match[1]
                    }
                } else if (match[2]) {
                    // This is an operator - keep it as is
                    standardizedExpression += match[2]
                }

                lastIndex = regex.lastIndex
            }

            // Add any remaining text after the last match
            if (lastIndex < expression.length) {
                standardizedExpression += expression.substring(lastIndex)
            }

            // Evaluate the standardized expression
            return evaluate(standardizedExpression)
        } else {
            // If there are no operators, just parse the number normally
            const parser = new NumberParser(
                isoLangCodes.findCountryLocales(
                    constantCase(SessionStore?.organisationCountry || 'us')
                )[0],
                {
                    style: 'decimal',
                }
            )
            return parser.parse(String(valueString))
        }
    } catch (e) {
        // If all else fails, return the original string
        return valueString
    }
}
