import { createFileRoute } from '@tanstack/react-router'
import SettingsPageHeader from '../../Pages/SettingsPage/SettingsPageHeader'
import SettingsPage from '../../Pages/SettingsPage/SettingsPage'
import { SettingPage as SettingPageV2 } from '../../version2/pages/settings-page/settings-page'
import PageBody from '../../Pages/Layout/PageBody'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { CenterPadPageBody } from '@2/layout/page-body'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { settingsStore } from '../../version2/pages/settings-page/settings-store'

export const Route = createFileRoute('/_private/settings')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'settings', params))
            throw new Error('Permission Denied')
        setLayoutDefaults({
            pageTitle: 'Settings',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: settingsStore.loader,
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

function Wrapper() {
    // The store now handles all the save toast logic internally
    settingsStore.useSaveHandler()

    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <SettingPageV2 />
            </CenterPadPageBody>
        )
    }

    return (
        <>
            <SettingsPageHeader label="Settings" />
            <PageBody>
                <SettingsPage />
            </PageBody>
        </>
    )
}
