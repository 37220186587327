import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

import SessionStore from '../../State/SessionStore'
import { Link, useNavigate } from '@tanstack/react-router'
import { error } from 'jquery'
import { ErrorAlert, InfoAlert, SuccessAlert } from '../../widgets'
import { canViewRoute } from '../../State/Permissions/HasPermissions'

export default observer((props) => {
    const { error } = props
    const navigate = useNavigate()
    const [status, setStatus] = useState(error ? 'error' : 'success')

    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className="flex flex-col items-center justify-center w-[350px]">
                <header className="flex items-center flex-col justify-center">
                    <div className="logo2 s75 size-[10em] flex items-center justify-center">
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                        />
                    </div>
                    <h1 style={{ fontSize: '5em' }}>coincraft</h1>
                </header>
                <div>
                    {status === 'success' && (
                        <SuccessAlert>
                            Login successful! redirecting...
                        </SuccessAlert>
                    )}
                    {status === 'error' && (
                        <>
                            <ErrorAlert>
                                <p>{`Sorry, we couldn't log you in.`}</p>
                                <p>{error}</p>
                            </ErrorAlert>{' '}
                            <Link to="/login">Back to login</Link>
                        </>
                    )}
                </div>
            </section>
        </div>
    )
})
