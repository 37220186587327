import { useEffect } from 'react'
import { RevenueForecastProjectTable } from './revenue-forecast-project-table'
import { RevenueForecastOverheadTable } from './revenue-forecast-overhead-table'
import { RevenueForecastStaffTable } from './revenue-forecast-staff-table'
import { Button } from '@2/components/ui/button'
import {
    fetchForecastData,
    useError,
    useIsLoading,
    shiftMonths,
    useData,
    useDateRange,
} from './revenue-forecast-store'
import { startOfMonth, addMonths, format } from '@2/utils/date-fns'
import { RevenueForecastChart } from './revenue-forecast-chart'
import { RevenueForecastProfitTable } from './revenue-forecast-profit-table'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/version2/components/ui/select'
import { useFilteredCacheItems } from '@/version2/cache'
import { useSearch, useNavigate } from '@tanstack/react-router'
import SessionStore from '@/State/SessionStore'
import {
    canViewRevenueForecastOperationalExpenses,
    canViewRevenueForecastStaffExpenses,
    canViewStaffPayRate,
} from '@/State/Permissions/HasPermissions'
export const RevenueForecastPage = () => {
    const isLoading = useIsLoading()
    const error = useError()
    const dateRange = useDateRange()
    const revenueForecastReports = useFilteredCacheItems(
        'revenueForecastReports'
    )
    const { report } = useSearch({ strict: false })
    const navigate = useNavigate()
    useEffect(() => {
        if (!dateRange[0] || !dateRange[1]) {
            const today = new Date()
            const startDate = startOfMonth(today)
            const endDate = addMonths(startDate, 11) // Ensure 12-month window
            fetchForecastData([
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd'),
            ])
        }
    }, [dateRange])

    if (error) {
        return (
            <div className="p-4 text-red-500">
                Error loading forecast data: {error}
            </div>
        )
    }

    const canViewStaffTable =
        canViewRevenueForecastStaffExpenses(SessionStore.user) &&
        canViewStaffPayRate(SessionStore.user)

    const canViewOverheadTable = canViewRevenueForecastOperationalExpenses(
        SessionStore.user
    )

    const canViewProfitTable = canViewStaffTable && canViewOverheadTable

    return (
        <>
            <div className="sticky left-0 pt-8">
                <div className="p-8">
                    <Select
                        value={report}
                        onValueChange={(value) => {
                            navigate({
                                search: (prev) => ({
                                    ...prev,
                                    report: value,
                                }),
                            })
                        }}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select a report" />
                        </SelectTrigger>
                        <SelectContent>
                            {revenueForecastReports
                                ?.sort((a, b) => a.name.localeCompare(b.name))
                                .map((report) => (
                                    <SelectItem
                                        key={report.id}
                                        value={report.id.toString()}
                                    >
                                        {report.name}
                                    </SelectItem>
                                ))}
                        </SelectContent>
                    </Select>
                </div>
                <RevenueForecastChart />
                <div className="flex justify-between items-center p-4">
                    <Button
                        onClick={() => shiftMonths('left')}
                        disabled={isLoading}
                        variant="outline"
                    >
                        ← Previous Month
                    </Button>
                    <div className="text-sm text-gray-600">
                        {dateRange[0] && dateRange[1]
                            ? `${format(new Date(dateRange[0]), 'MMM yyyy')} - ${format(new Date(dateRange[1]), 'MMM yyyy')}`
                            : ''}
                    </div>
                    <Button
                        onClick={() => shiftMonths('right')}
                        disabled={isLoading}
                        variant="outline"
                    >
                        Next Month →
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <div className="p-4">Loading forecast data...</div>
            ) : (
                <>
                    {canViewProfitTable && <RevenueForecastProfitTable />}
                    <RevenueForecastProjectTable />
                    {canViewStaffTable && <RevenueForecastStaffTable />}
                    {canViewOverheadTable && <RevenueForecastOverheadTable />}
                </>
            )}
        </>
    )
}
