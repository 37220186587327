import {
    canViewFlexi,
    canViewRemote,
    usingNewLogin,
} from '../../../State/Permissions/HasPermissions'
import { SelectWithLabel } from '@/version2/components/ui/select'
import { CheckboxWithLabel } from '@/version2/components/ui/checkbox'
import DomainVerification from '../../../Pages/SettingsPage/DomainVerification'
import { settingActions, useSetting } from './settings-store'

export function SettingsPageForm() {
    const settings = useSetting()
    const { autoPopulate, timeEntryAllocations } = settings

    return (
        <div className="max-w-[60em] py-[2em] px-[4px]">
            <div className="flex items-center py-[2em]">
                <span className="inline-block">Sort Phases By:</span>
                <div className="mx-[1em] w-[12em]">
                    <SelectWithLabel
                        value={settings.sortPhasesBy}
                        onValueChange={(value) => {
                            settingActions.changeSettings(value, 'sortPhasesBy')
                        }}
                        options={[
                            {
                                key: 'startDate',
                                value: 'startDate',
                                label: 'Start Date',
                            },
                            {
                                key: 'title',
                                value: 'title',
                                label: 'Title',
                            },
                        ]}
                        // defaultValue="title"
                        placeholder="Select..."
                    />
                </div>
            </div>
            <div>
                <CheckboxWithLabel
                    checked={settings.allowNoPhase}
                    onCheckedChange={(value) => {
                        settingActions.changeSettings(value, 'allowNoPhase')
                    }}
                    id="allowNoPhase"
                    label="Allow time to be logged without a selecting a phase."
                />
            </div>
            <div className="mt-[2em]">
                <CheckboxWithLabel
                    checked={settings.useTasks}
                    onCheckedChange={function (value) {
                        settingActions.changeSettings(value, 'useTasks')
                    }}
                    label="Allow time to be logged against tasks."
                    id="useTasks"
                />
            </div>
            <div className="mt-[2em]">
                <CheckboxWithLabel
                    checked={settings.allowAfterPhaseEnd}
                    onCheckedChange={(value) => {
                        settingActions.changeSettings(
                            value,
                            'allowAfterPhaseEnd'
                        )
                    }}
                    label="Allow time to be logged against a phase that has ended."
                    id="allowAfterPhaseEnd"
                />
            </div>
            <div className="mt-[3em]">
                <p>
                    Allow phases with the following statuses to be visible when
                    entering time:
                </p>
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.timeEntryStatus.includes('active')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryStatus, 'active'],
                                'timeEntryStatus'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryStatus.filter(
                                    (s) => s !== 'active'
                                ),
                                'timeEntryStatus'
                            )
                        }
                    }}
                    label="Active"
                    id="active"
                />
            </div>
            <div className="ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.timeEntryStatus.includes('prospective')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryStatus, 'prospective'],
                                'timeEntryStatus'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryStatus.filter(
                                    (s) => s !== 'prospective'
                                ),
                                'timeEntryStatus'
                            )
                        }
                    }}
                    label="Prospective"
                    id="prospective"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={settings.timeEntryStatus.includes('onHold')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryStatus, 'onHold'],
                                'timeEntryStatus'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryStatus.filter(
                                    (s) => s !== 'onHold'
                                ),
                                'timeEntryStatus'
                            )
                        }
                    }}
                    label="On Hold"
                    id="onHold"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={settings.timeEntryStatus.includes('archived')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryStatus, 'archived'],
                                'timeEntryStatus'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryStatus.filter(
                                    (s) => s !== 'archived'
                                ),
                                'timeEntryStatus'
                            )
                        }
                    }}
                    label="Archived"
                    id="archived"
                />
            </div>
            <p className="mt-[3em]">
                Allow staff to flag time entries with the following:
            </p>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.timeEntryFlags.includes('variation')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryFlags, 'variation'],
                                'timeEntryFlags'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryFlags.filter(
                                    (s) => s !== 'variation'
                                ),
                                'timeEntryFlags'
                            )
                        }
                    }}
                    label="Variation"
                    id="variation"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={settings.timeEntryFlags.includes('billable')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryFlags, 'billable'],
                                'timeEntryFlags'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryFlags.filter(
                                    (s) => s !== 'billable'
                                ),
                                'timeEntryFlags'
                            )
                        }
                    }}
                    label="Billable"
                    id="billable"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={settings.timeEntryFlags.includes('overtime')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryFlags, 'overtime'],
                                'timeEntryFlags'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryFlags.filter(
                                    (s) => s !== 'overtime'
                                ),
                                'timeEntryFlags'
                            )
                        }
                    }}
                    label="Overtime"
                    id="overtime"
                />
            </div>
            {canViewRemote() ? (
                <div style={{ marginLeft: '2em' }}>
                    <CheckboxWithLabel
                        checked={settings.timeEntryFlags.includes('remote')}
                        onCheckedChange={function (value) {
                            if (value) {
                                settingActions.changeSettings(
                                    [...settings.timeEntryFlags, 'remote'],
                                    'timeEntryFlags'
                                )
                            } else {
                                settingActions.changeSettings(
                                    settings.timeEntryFlags.filter(
                                        (s) => s !== 'remote'
                                    ),
                                    'timeEntryFlags'
                                )
                            }
                        }}
                        label="Remote"
                        id="remote"
                    />
                </div>
            ) : null}
            {canViewFlexi() ? (
                <div style={{ marginLeft: '2em' }}>
                    <CheckboxWithLabel
                        checked={settings.timeEntryFlags.includes('flexi')}
                        onCheckedChange={function (value) {
                            if (value) {
                                settingActions.changeSettings(
                                    [...settings.timeEntryFlags, 'flexi'],
                                    'timeEntryFlags'
                                )
                            } else {
                                settingActions.changeSettings(
                                    settings.timeEntryFlags.filter(
                                        (s) => s !== 'flexi'
                                    ),
                                    'timeEntryFlags'
                                )
                            }
                        }}
                        label="Flexi"
                        id="flexi"
                    />
                </div>
            ) : null}
            <p className="mt-[3em]">
                Allow staff to enter time against phases:
            </p>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.timeEntryAllocations.includes(
                        'noAllocations'
                    )}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [
                                    ...settings.timeEntryAllocations,
                                    'noAllocations',
                                ],
                                'timeEntryAllocations'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryAllocations.filter(
                                    (s) => s !== 'noAllocations'
                                ),
                                'timeEntryAllocations'
                            )
                        }
                    }}
                    label="Without budgets or allocations for that staff member."
                    id="noAllocations"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.timeEntryAllocations.includes('budgets')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [...settings.timeEntryAllocations, 'budgets'],
                                'timeEntryAllocations'
                            )
                        } else {
                            settingActions.changeSettings(
                                settings.timeEntryAllocations.filter(
                                    (s) => s !== 'budgets'
                                ),
                                'timeEntryAllocations'
                            )
                        }
                    }}
                    label="With budgets for that staff member."
                    id="budgets"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.timeEntryAllocations.includes(
                        'allocations'
                    )}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                [
                                    ...settings.timeEntryAllocations,
                                    'allocations',
                                ],
                                'timeEntryAllocations'
                            )
                        } else {
                            settingActions.changeSettings(
                                timeEntryAllocations.filter(
                                    (s) => s !== 'allocations'
                                ),
                                'timeEntryAllocations'
                            )
                        }
                    }}
                    label="With monthly allocations for that staff member."
                    id="allocations"
                />
            </div>
            <p className="mt-[3em]">Auto-populate timesheets for phases:</p>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.autoPopulate.includes('budgets')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                ['budgets', ...autoPopulate],
                                'autoPopulate'
                            )
                        } else {
                            settingActions.changeSettings(
                                autoPopulate.filter((s) => s !== 'budgets'),
                                'autoPopulate'
                            )
                        }
                    }}
                    label="With time budgeted for that staff member."
                    id="budgets-autoPopulate"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={settings.autoPopulate.includes('allocations')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeSettings(
                                ['allocations', ...autoPopulate],
                                'autoPopulate'
                            )
                        } else {
                            settingActions.changeSettings(
                                autoPopulate.filter((s) => s !== 'allocations'),
                                'autoPopulate'
                            )
                        }
                    }}
                    label="With monthly allocations assigned to that staff member."
                    id="allocations-autoPopulate"
                />
            </div>
            <div className="mt-[2em]">
                <CheckboxWithLabel
                    checked={settings.updateHoursFromRevenue}
                    onCheckedChange={function (value) {
                        settingActions.changeSettings(
                            value,
                            'updateHoursFromRevenue'
                        )
                    }}
                    label="Automatically adjust Staff Hours when editing Revenue in Forecasts"
                    id="updateHoursFromRevenue"
                />
                <CheckboxWithLabel
                    checked={settings.updateRevenueFromHours}
                    onCheckedChange={function (value) {
                        settingActions.changeSettings(
                            value,
                            'updateRevenueFromHours'
                        )
                    }}
                    label="Automatically adjust Revenue when editing Staff Hours in Forecasts"
                    id="updateRevenueFromHours"
                />
            </div>
            <div className="mt-[4em]">
                <div className="flex items-center mb-[0.5em]">
                    <SelectWithLabel
                        value={settings.autoUpdateRevenue.action}
                        onValueChange={(value) => {
                            settingActions.changeSettings(
                                {
                                    ...settings.autoUpdateRevenue,
                                    action: value,
                                },
                                'autoUpdateRevenue'
                            )
                        }}
                        options={[
                            {
                                key: 'ask',
                                value: 'ask',
                                label: 'Ask Me to',
                            },
                            {
                                key: 'automatic',
                                value: 'automatic',
                                label: 'Always',
                            },
                            {
                                key: 'never',
                                value: 'never',
                                label: 'Never',
                            },
                        ]}
                        className="flex-row-reverse gap-2"
                        defaultValue="ask"
                        placeholder="Select..."
                        label="Automatically adjust the Revenue Forecast when saving Projects and Invoices."
                    />
                </div>
                <CheckboxWithLabel
                    checked={settings.autoUpdateRevenue.adjustOnLoad}
                    onCheckedChange={function (value) {
                        settingActions.changeSettings(
                            {
                                ...settings.autoUpdateRevenue,
                                adjustOnLoad: value,
                            },
                            'autoUpdateRevenue'
                        )
                    }}
                    label="Automatically adjust Revenue when loading the Revenue Forecast"
                    id="adjustOnLoad"
                    className="my-3"
                />
                <div className="flex items-center border-t border-[#eee] p-[2em]">
                    <span className="inline-block">Distribute</span>
                    <div className="mx-[1em] w-[12em]">
                        <SelectWithLabel
                            value={settings.autoUpdateRevenue.budget}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    {
                                        ...settings.autoUpdateRevenue,
                                        budget: value,
                                    },
                                    'autoUpdateRevenue'
                                )
                            }}
                            options={[
                                {
                                    key: 'total',
                                    value: 'total',
                                    label: 'Total Budget',
                                },
                                {
                                    key: 'remaining',
                                    value: 'remaining',
                                    label: 'Remaining Budget',
                                },
                            ]}
                            // defaultValue="total"
                        />
                    </div>
                    <span className="inline-block">between</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={settings.autoUpdateRevenue.start}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    {
                                        ...settings.autoUpdateRevenue,
                                        start: value,
                                    },
                                    'autoUpdateRevenue'
                                )
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'now',
                                    value: 'now',
                                    label: 'Current Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                            // defaultValue="startDate"
                        />
                    </div>
                    <span className="inline-block">and</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={settings.autoUpdateRevenue.end}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    {
                                        ...settings.autoUpdateRevenue,
                                        end: value,
                                    },
                                    'autoUpdateRevenue'
                                )
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                            // defaultValue="startDate"
                        />
                    </div>
                </div>
            </div>
            <div className="mt-[3em]">
                <SelectWithLabel
                    value={settings.autoUpdateHours.action}
                    onValueChange={(value) => {
                        settingActions.changeSettings(
                            {
                                ...settings.autoUpdateHours,
                                action: value,
                            },
                            'autoUpdateHours'
                        )
                    }}
                    options={[
                        {
                            key: 'ask',
                            value: 'ask',
                            label: 'Ask Me to',
                        },
                        {
                            key: 'automatic',
                            value: 'automatic',
                            label: 'Always',
                        },
                        {
                            key: 'never',
                            value: 'never',
                            label: 'Never',
                        },
                    ]}
                    className="flex-row-reverse gap-2"
                    placeholder="Select..."
                    label="Automatically adjust the Resource Schedule when saving Projects and Time entries
                "
                />
                <CheckboxWithLabel
                    checked={settings.autoUpdateHours.adjustOnLoad}
                    onCheckedChange={function (value) {
                        settingActions.changeSettings(
                            {
                                ...settings.autoUpdateHours,
                                adjustOnLoad: value,
                            },
                            'autoUpdateHours'
                        )
                    }}
                    className="my-3"
                    label="Automatically adjust Staff Hours when loading the Resource Schedule"
                    id="adjustOnLoad"
                />
                <div className="flex items-center border-t border-[#eee] p-[2em]">
                    <span className="inline-block">Distribute</span>
                    <div className="mx-[1em] w-[12em]">
                        <SelectWithLabel
                            value={settings.autoUpdateHours.budget}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    {
                                        ...settings.autoUpdateHours,
                                        budget: value,
                                    },
                                    'autoUpdateHours'
                                )
                            }}
                            options={[
                                {
                                    key: 'total',
                                    value: 'total',
                                    label: 'Total Budget',
                                },
                                {
                                    key: 'remaining',
                                    value: 'remaining',
                                    label: 'Remaining Budget',
                                },
                            ]}
                        />
                    </div>
                    <span className="inline-block">between</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={settings.autoUpdateHours.start}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    {
                                        ...settings.autoUpdateHours,
                                        start: value,
                                    },
                                    'autoUpdateHours'
                                )
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'now',
                                    value: 'now',
                                    label: 'Current Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                        />
                    </div>
                    <span className="inline-block">and</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={settings.autoUpdateHours.end}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    {
                                        ...settings.autoUpdateHours,
                                        end: value,
                                    },
                                    'autoUpdateHours'
                                )
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                            defaultValue="startDate"
                        />
                    </div>
                </div>
                <div
                    style={{
                        padding: '4em 0em 1em 0em',
                    }}
                    className="flex items-center"
                >
                    <span className="inline-block">
                        Use Invoice Date Type in Reports:
                    </span>
                    <div className="mx-[1em] w-[12em]">
                        <SelectWithLabel
                            value={settings.reportInvoiceDateType}
                            onValueChange={(value) => {
                                settingActions.changeSettings(
                                    value,
                                    'reportInvoiceDateType'
                                )
                            }}
                            options={[
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'Work Completion',
                                },
                                {
                                    key: 'issueDate',
                                    value: 'issueDate',
                                    label: 'Issue Date',
                                },
                                {
                                    key: 'dueDate',
                                    value: 'dueDate',
                                    label: 'Due Date',
                                },
                            ]}
                            defaultValue="endDate"
                        />
                    </div>
                </div>
                <div
                    style={{
                        maxWidth: '60em',
                        padding: '0em 0em 2em',
                    }}
                >
                    <p style={{ marginTop: '2em' }}>When saving Invoices:</p>
                    <div
                        style={{
                            marginTop: '1em',
                            marginLeft: '2em',
                        }}
                    >
                        <CheckboxWithLabel
                            checked={settings.savingInvoices.includes(
                                'lockTime'
                            )}
                            onCheckedChange={function (value) {
                                if (value) {
                                    settingActions.changeSettings(
                                        [
                                            ...settings.savingInvoices,
                                            'lockTime',
                                        ],
                                        'savingInvoices'
                                    )
                                } else {
                                    settingActions.changeSettings(
                                        settings.savingInvoices.filter(
                                            (s) => s !== 'lockTime'
                                        ),
                                        'savingInvoices'
                                    )
                                }
                            }}
                            label="Lock time entries."
                            id="lockTime"
                        />
                    </div>
                </div>
                {usingNewLogin() ? (
                    <div
                        style={{
                            maxWidth: '60em',
                            padding: '0em 0em 2em',
                        }}
                    >
                        <p>
                            Automatically create accounts for users with the
                            following email domains:
                        </p>
                        <div style={{ padding: '1em 2em' }}>
                            <DomainVerification />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
