import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ReportCollection from '../../State/Collections/ReportCollection'
import LayoutStore from '../../State/LayoutStore'
import { caseInsensitiveContains } from '../../utils'
import {
    Dropdown3,
    Dropdown3ListItem,
    SmallDeleteButton,
    FilterTextBox,
} from '../../widgets'
import {
    operatorDefaults,
    operatorLabels,
    rangeValueDefaults,
    singleValueDefaults,
} from '../Filters'
import Inputs from '../Inputs'
import { MultiSelect } from '../MultiSelect'
import { RelativeDateSelector, Selector } from '../Selector'
import pluralize from 'pluralize'
import RenameReportModal from './RenameReportModal'
import DataStore from '../../State/DataStore'
import SaveAsReportModal from './SaveAsReportModal'
import SortableMultiselect from '../Widgets/SortableMultiselect'
import { CollectionLookup } from '../../State/CollectionLookup'
import HeaderButton from '../ui/HeaderButton'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'

export default observer(
    ({
        report,
        filterOptions = () => null,
        reportOptions = [],
        expandClick,
        expandLabel,
        selector,
    }) => {
        const [expandReportEditor, setExpandReportEditor] = useState(false)
        const navigate = useNavigate()

        return (
            <div className="flex flex-col mt-8 w-full print:hidden">
                <div className="flex items-center justify-between">
                    <div
                        className="report-selector flex items-center gap-2"
                        key={report.queryKey + 'reportheader'}
                    >
                        {'Report: '}
                        <Selector
                            className="flex-0-0-auto page-header__button"
                            style={{ marginLeft: '1em', maxWidth: '15em' }}
                            value={report}
                            options={reportOptions
                                .filter((r) => !r.deletedAt)
                                .sort((a, b) => a.name.localeCompare(b.name))}
                            searchMatcher={function (report, searchText) {
                                return caseInsensitiveContains(
                                    report.name,
                                    searchText
                                )
                            }}
                            optionLabel={function (report) {
                                return <div>{report.name}</div>
                            }}
                            onChange={(report) => {
                                navigate({
                                    search: (prev) => ({
                                        ...prev,
                                        report: report.id,
                                    }),
                                })
                            }}
                        />
                        <div className="flex gap-2 items-center">
                            <HeaderButton
                                onClick={() =>
                                    setExpandReportEditor(!expandReportEditor)
                                }
                                label={expandReportEditor ? 'Hide ' : 'Edit '}
                                rightIcon={
                                    <i
                                        className={classNames(
                                            'fa',
                                            expandReportEditor
                                                ? 'fa-angle-double-up'
                                                : 'fa-angle-double-down',
                                            'm-0'
                                        )}
                                    />
                                }
                                style={{
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                }}
                            />
                            {expandReportEditor ? (
                                <span className="flex items-center gap-1">
                                    <ReportSaveDropdown report={report} />

                                    <ReportActionsDropdown report={report} />
                                </span>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex items-center gap-2 dont-print">
                        {expandLabel && (
                            <HeaderButton
                                onClick={expandClick}
                                label={expandLabel}
                            />
                        )}
                        {selector && selector}
                    </div>
                </div>

                {expandReportEditor ? (
                    <ReportEditor
                        report={report}
                        dateRange={report.options.dateRange}
                        groupBy={report.groupBy?.map(
                            (prc) => report.columnOptionsById[prc]
                        )}
                        groupByOptions={Object.values(report.columnOptionsById)}
                        filterOptions={filterOptions}
                    />
                ) : null}
            </div>
        )
    }
)

const ReportSaveDropdown = observer(({ report }) => {
    return (
        <Dropdown3
            // isExpanded={this.props.isExpanded}
            // onToggle={this.handleToggle}
            toggleElement={
                <button className="primary flex items-center gap-1 py-2 px-4 border border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs">
                    Save
                    <i className="rw-i rw-i-caret-down" />
                </button>
            }
            contentStyle={{
                width: '14em',
                color: '#444',
                borderRadius: '0.5em',
            }}
        >
            {!report.systemReport ? (
                <Dropdown3ListItem
                    style={{ padding: '0.5em 1em', height: '2.5em' }}
                    className="report-save-dropdown__save-option"
                    onClick={() => DataStore.saveModel(report)}
                >
                    Save
                </Dropdown3ListItem>
            ) : null}
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    LayoutStore.openModal(
                        <SaveAsReportModal
                            report={report}
                            modalId={'saveAsReport'}
                        />
                    )
                }}
            >
                Save As...
            </Dropdown3ListItem>
        </Dropdown3>
    )
})

const ReportActionsDropdown = observer(({ report }) => {
    const navigate = useNavigate()
    return (
        <Dropdown3
            // isExpanded={this.props.isExpanded}
            // onToggle={this.handleToggle}
            toggleElement={
                <button className="primary flex items-center gap-1 py-2 px-4 border border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] text-xs">
                    Actions
                    <i
                        className="rw-i rw-i-caret-down"
                        style={{ marginLeft: '0.5em' }}
                    />
                </button>
            }
            contentStyle={{
                width: '18em',
                color: '#444',
                borderRadius: '0.5em',
            }}
        >
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => report.makeDefault()}
            >
                Set As Default
            </Dropdown3ListItem>
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    LayoutStore.openModal(
                        <RenameReportModal
                            report={report}
                            modalId={'renameReport'}
                        />
                    )
                }}
            >
                Rename
            </Dropdown3ListItem>
            <Dropdown3ListItem
                style={{ padding: '0.5em 1em', height: '2.5em' }}
                onClick={() => {
                    report.update({ deletedAt: new Date() })
                    DataStore.saveModel(report)
                    report.removeDefault()

                    navigate({
                        search: (prev) =>
                            _.omitBy(
                                {
                                    ...prev,
                                    report: null,
                                },
                                (v) => v === null
                            ),
                    })
                    // ReportCollection.delete(report.id)
                }}
            >
                Delete
            </Dropdown3ListItem>
        </Dropdown3>
    )
})

const ReportEditor = observer(
    ({ report, dateRange, groupBy, groupByOptions, filterOptions }) => {
        return (
            <div
                className="dont-print"
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '1em',
                    margin: '1em 0 0 0',
                }}
            >
                <div key={report.queryKey + 'reporteditor'}>
                    <div
                        style={{
                            borderBottom: 'solid 1px #ccc',
                            padding: '1em',
                        }}
                    >
                        <div>
                            {dateRange ? (
                                <div className="inline-flex flex-align-items-center report-widget__use-data-from">
                                    <label className="mx-1 text-white">
                                        Use data from:
                                    </label>
                                    <RelativeDateSelector
                                        className="mx-1 text-white"
                                        style={{
                                            marginLeft: '0.5em',
                                            marginRight: '0.5em',
                                        }}
                                        selectedDateRange={dateRange}
                                        onChange={(dateRange) =>
                                            report.updateOptions(
                                                'dateRange',
                                                dateRange
                                            )
                                        }
                                        fortnightType={
                                            report.options.fortnightType
                                        }
                                        onChangeFortnightType={(
                                            fortnightType
                                        ) =>
                                            report.updateOptions(
                                                'fortnightType',
                                                fortnightType
                                            )
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div
                            className="flex flex-align-items-center"
                            style={{
                                marginTop: '1em',
                            }}
                        >
                            <label className="mx-1 text-white">Columns:</label>
                            <SortableMultiselect
                                className="!text-sm !text-[#333]"
                                selectedOptions={report.columns
                                    .map((c) => report.columnOptionsById[c])
                                    .filter(
                                        (c) => !c.permissions || c.permissions()
                                    )}
                                options={Object.values(
                                    report.columnOptionsById
                                ).filter(
                                    (c) => !c.permissions || c.permissions()
                                )}
                                styles={{
                                    container: (provided, state) => ({
                                        ...provided,
                                        color: '#444',
                                        width: '50em',
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#444',
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        fontSize: '0.9em',
                                        color: '#444',
                                    }),
                                }}
                                onChange={(columns) =>
                                    report.updateColumns(
                                        columns.map((c) => c.id)
                                    )
                                }
                                filter={function (item, searchText) {
                                    return item.label
                                        .toLowerCase()
                                        .match(searchText.toLowerCase())
                                }}
                            />
                        </div>
                        {report.type === 'timesheet' ? (
                            <div
                                className="flex flex-align-items-center"
                                style={{
                                    marginTop: '1em',
                                }}
                            >
                                <label className="mx-1 text-white">
                                    Group by:
                                </label>
                                <SortableMultiselect
                                    className="!text-sm !text-[#333]"
                                    selectedOptions={groupBy}
                                    options={groupByOptions}
                                    styles={{
                                        container: (provided, state) => ({
                                            ...provided,
                                            color: '#444',
                                            width: '50em',
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            color: '#444',
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            fontSize: '0.9em',
                                            color: '#444',
                                        }),
                                    }}
                                    onChange={(groups) =>
                                        report.update(
                                            {
                                                groupBy: groups.map(
                                                    (g) => g.id
                                                ),
                                            },
                                            {},
                                            true
                                        )
                                    }
                                    filter={function (item, searchText) {
                                        return item.label
                                            .toLowerCase()
                                            .match(searchText.toLowerCase())
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>

                    <ReportFilterList
                        key={report.queryKey + 'filters'}
                        report={report}
                        columns={Object.values(report.columnOptionsById)}
                        filters={report.filters}
                        filterOptions={filterOptions}
                    />
                </div>
            </div>
        )
    }
)

const ReportFilterList = observer(
    ({ columns, filters, filterOptions, report }) => {
        return (
            <div style={{ padding: '1em 1.5em' }}>
                {filters.map(function (filter, filterIndex) {
                    return (
                        <DefaultFilterRow
                            key={filter.column + filterIndex}
                            filter={filter}
                            columns={columns}
                            report={report}
                            filterOptions={filterOptions}
                        />
                    )
                })}

                <div className="mt-4">
                    <button
                        className="btn btn-default add-filter-button plus-btn"
                        onClick={() => report.addFilter()}
                    >
                        + Add filter
                    </button>
                </div>
            </div>
        )
    }
)

export const ReportSearch = observer(({ report, placeholder }) => {
    return (
        <FilterTextBox
            style={{ width: '19em' }}
            value={report.searchFilter || ''}
            onChange={(val) => report.updateSearchFilter(val)}
            placeholder={placeholder}
        />
    )
})

const DefaultFilterRow = observer(
    ({ filter, filterOptions, columns, report }) => {
        const column = report.columnOptionsById[filter.column]
        return (
            <FilterRow
                filter={filter}
                columns={columns}
                report={report}
                filterValueInput={
                    <FilterInput
                        filter={filter}
                        column={column}
                        report={report}
                    />
                }
                filterOptions={filterOptions({ filter, columns, report })}
            />
        )
    }
)

const FilterInput = observer(({ filter, column, report }) => {
    const columnType = column.type
    const isRangeValue = filter.operator.includes('between')
    let value = filter.value //??
    // (isRangeValue
    //     ? rangeValueDefaults[columnType]
    //     : singleValueDefaults[columnType])
    if (!isRangeValue) {
        return (Inputs[pluralize(column.type, 2)] || Inputs[column.type])({
            value:
                value instanceof Array &&
                CollectionLookup[pluralize(column.type, 2)]
                    ? value.map(
                          (v) =>
                              CollectionLookup[pluralize(column.type, 2)]
                                  .modelsById[v]
                      )
                    : value,
            editable: true,
            onChange: (val) => {
                report.updateFilter(
                    filter,
                    'value',
                    val instanceof Array && val[0]?.id
                        ? val.map((v) => v.id)
                        : val
                )
            },
            className: 'w-full rounded !min-h-9',
        })
    }
    if (columnType === 'date') {
        return (
            <>
                <RelativeDateSelector
                    className="mx-1 !w-1/2 [&_span]:text-black"
                    style={{
                        marginLeft: '0.5em',
                        marginRight: '0.5em',
                    }}
                    selectedDateRange={value}
                    onChange={(dateRange) =>
                        report.updateFilter(filter, 'value', dateRange)
                    }
                    variant="secondary"
                />
            </>
        )
    }
    return (
        <div className="flex items-center">
            {(Inputs[pluralize(column.type, 2)] || Inputs[column.type])({
                value: value?.[0],
                editable: true,
                onChange: (val) =>
                    report.updateFilter(filter, 'value', [val, value?.[1]]),
                className: 'w-full rounded !min-h-9 p-2',
            })}
            <div className="text-white" style={{ margin: '0 0.5em' }}>
                to
            </div>
            {(Inputs[pluralize(column.type, 2)] || Inputs[column.type])({
                value: value?.[1],
                editable: true,
                onChange: (val) =>
                    report.updateFilter(filter, 'value', [value?.[0], val]),
                className: 'w-full rounded !min-h-9 p-2',
            })}
        </div>
    )
})

const FilterRow = observer(
    ({ filter, columns, report, filterValueInput, filterOptions }) => {
        const column = report.columnOptionsById[filter.column]
        return (
            <div className="filter-row py-[0.5em] border-b border-[#ccc] flex items-center">
                <div className="inline-block align-top mr-[0.5em] mt-[0.3em] flex-grow-0">
                    <Selector
                        className="filter-row__column-select"
                        value={column}
                        options={columns
                            .filter((c) => !c.permissions || c.permissions())
                            .map((c) => ({
                                value: c,
                                label: c.label || '-- Select a column --',
                            }))}
                        onChange={(col) => {
                            return report.updateFilter(filter, 'column', col.id)
                        }}
                        searchMatcher={(col, searchTerm) =>
                            col != null &&
                            caseInsensitiveContains(col.label, searchTerm)
                        }
                        style={{ minWidth: '15em' }}
                    />
                </div>

                {column != null ? (
                    <div className="flex items-center w-full">
                        <div className="flex items-center w-2/3">
                            <div
                                style={{
                                    display: 'inline-block',
                                    verticalAlign: 'top',
                                    marginRight: '0.5em',
                                }}
                            >
                                <Selector
                                    className="filter-row__operator-select"
                                    value={
                                        filter.operator ||
                                        operatorDefaults[column.type]
                                    }
                                    options={Object.keys(
                                        operatorLabels[column.type]
                                    )}
                                    onChange={(col) => {
                                        report.updateFilter(
                                            filter,
                                            'operator',
                                            col
                                        )
                                        report.updateFilter(
                                            filter,
                                            'value',
                                            null
                                        )
                                    }}
                                    optionLabel={(c) =>
                                        operatorLabels[column.type][c]
                                    }
                                    style={{ minWidth: '12em' }}
                                />
                            </div>
                            <div className="!text-sm !text-444 inline-block align-top w-full rounded-[6px]">
                                {filterValueInput}
                            </div>
                        </div>
                        <div
                            className="whitespace-nowrap ml-4"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                                marginRight: '0.5em',
                                marginTop: '0.3em',
                            }}
                        >
                            {filterOptions}
                        </div>
                    </div>
                ) : null}

                <div
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginRight: '0.5em',
                        flexGrow: 0,
                    }}
                >
                    <SmallDeleteButton
                        onClick={() => report.removeFilter(filter)}
                    />
                </div>
            </div>
        )
    }
)
