import classNames from 'classnames'
import {
    addDays,
    addWeeks,
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    getWeek,
    isWeekend,
    parse,
    startOfMonth,
    startOfWeek,
    subDays,
    subWeeks,
} from 'date-fns'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import SessionStore from '../../State/SessionStore'
import { HoursInput } from '../../widgets'
import TimesheetsDailyStore from './TimesheetsDailyStore'
import { formatMinutes, isNumber } from '../../utils'
import _ from 'lodash'
import LayoutStore from '../../State/LayoutStore'
import EditTimeEntryModal from './EditTimeEntryModal'
import TimeEntryNotesModal from './TimeEntryNotesModal'
import DataStore from '../../State/DataStore'
import {
    getPreviousDayTime,
    getPreviousWeekTime,
} from '../../Queries/previousTime'
import TimeEntryModel from '../../State/Models/TimeEntryModel'
// import Textarea from 'react-textarea-autosize'
import { TextArea } from '@2/components/ui/textarea'
import TimesheetsWeeklyStore from './TimesheetsWeeklyStore'
import BudgetProgressBar from '../../Components/BudgetProgressBar'
import RenderOnQueries from '../Layout/RenderOnQueries'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { qf } from '../../Queries/queryFormatter'
import {
    autoPopulatePhaseInTimesheets,
    canEditStaffTime,
    canEnterTimeAgainstPhase,
    hideBudgetProgress,
    shouldAutoPopulatePhasesInTimesheets,
} from '../../State/Permissions/HasPermissions'
import Time from '../../formulas/core/operations/time'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import tuple from 'immutable-tuple'
import TimeEntryAggregateCollection from '../../State/Aggregates/TimeEntryAggregateCollection'
import { queryClient } from '../../App'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ date, staffId }) => {
    const [store, setStore] = useState(TimesheetsDailyStore)
    useEffect(() => {
        store.setSearchParams({ date, staffId })
    }, [date, staffId])
    useEffect(() => {
        DataStore.setAutosave(true)
        // TimeEntryCollection.clear()
        // TimeEntryAggregateCollection.clear()
        // store.reset()
        return () => DataStore.setAutosave(false)
    }, [])
    return (
        <div className="max-w-[1040px] mx-auto mb-auto w-[1040px]">
            <TimesheetNav
                monday={store.startOfWeek}
                dayTotals={store.dailyTotals}
                selectedDate={store.date}
                store={store}
            />
            <RenderOnQueries
                queryIds={[
                    {
                        collection: 'timeEntries',
                        staleTime: 0,
                        fields: [
                            'costCentreId',
                            'projectId',
                            'phaseId',
                            'staffId',
                            'taskId',
                            'numMinutes',
                            'date',
                            'notes',
                            'isVariation',
                            'isBillable',
                            'isOvertime',
                            'isLocked',
                            'flexi',
                            'remote',
                            'deletedAt',
                            'createdAt',
                        ],
                        filters: [
                            `staffId == "${staffId || SessionStore.userId}"`,
                            `date >= ${qf(
                                startOfWeek(store.date, { weekStartsOn: 1 })
                            )}`,
                            `date <= ${qf(
                                endOfWeek(store.date, { weekStartsOn: 1 })
                            )}`,
                        ],
                    },
                    {
                        collection: 'phases',
                        fields: [
                            'jobNumber',
                            'name',
                            'projectId',
                            'isRootPhase',
                            'position',
                            ['timeEntryCount', 'phaseTimeEntries.count'],
                        ],
                        subQueries: [
                            {
                                label: 'phaseTimeEntries',
                                collection: 'timeEntries',
                                join: 'id == phaseTimeEntries.phaseId',
                                groupBy: ['phaseId'],
                                fields: [['count', 'count(id)']],
                                filters: [
                                    `date >= ${qf(
                                        startOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                    `date <= ${qf(
                                        endOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                ],
                            },
                        ],
                        filters: ['phaseTimeEntries.count > 0'],
                    },
                    {
                        collection: 'tasks',
                        fields: [
                            'name',
                            'phaseId',
                            'projectId',
                            ['timeEntryCount', 'taskTimeEntries.count'],
                        ],
                        subQueries: [
                            {
                                label: 'taskTimeEntries',
                                collection: 'timeEntries',
                                join: 'id == taskTimeEntries.taskId',
                                groupBy: ['taskId'],
                                fields: [['count', 'count(id)']],
                                filters: [
                                    `date >= ${qf(
                                        startOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                    `date <= ${qf(
                                        endOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                ],
                            },
                        ],
                        filters: ['taskTimeEntries.count > 0'],
                    },
                    shouldAutoPopulatePhasesInTimesheets()
                        ? {
                              collection: 'phases',
                              fields: [
                                  'jobNumber',
                                  'name',
                                  'projectId',
                                  'status',
                                  'startDate',
                                  'endDate',
                                  'isRootPhase',
                                  'position',
                                  [
                                      'budgetedStaffIds',
                                      'budgetedHours.staffIds',
                                  ],
                                  [
                                      'monthlyAllocatedStaffIds',
                                      'monthlyAllocations.staffIds',
                                  ],
                              ],
                              subQueries: [
                                  {
                                      collection: 'budgetedHours',
                                      join: `id == budgetedHours.phaseId`,
                                      groupBy: ['phaseId'],
                                      fields: [['staffIds', 'uniq(staffId)']],
                                      filters: [`staffId != null`],
                                  },
                                  {
                                      collection: 'monthlyAllocations',
                                      join: `id == monthlyAllocations.phaseId`,
                                      groupBy: ['phaseId'],
                                      fields: [['staffIds', 'uniq(staffId)']],
                                      filters: [
                                          `staffId != null`,
                                          `month >= ${qf(
                                              format(new Date(), 'yyyy-MM')
                                          )}`,
                                          `month <= ${qf(
                                              format(new Date(), 'yyyy-MM')
                                          )}`,
                                      ],
                                  },
                              ],
                              filters: [
                                  `(budgetedStaffIds != null and "${
                                      staffId || SessionStore.userId
                                  }" in budgetedStaffIds) or (monthlyAllocatedStaffIds != null and "${
                                      staffId || SessionStore.userId
                                  }" in monthlyAllocatedStaffIds)`,
                                  `startDate != null and startDate <= ${qf(
                                      new Date()
                                  )}`,
                                  `endDate != null and endDate >= ${qf(
                                      new Date()
                                  )}`,
                              ],
                              chain: [
                                  {
                                      collection: 'tasks',
                                      join: {
                                          tasks: 'phaseId',
                                          phases: 'id',
                                      },
                                      fields: [
                                          'name',
                                          'phaseId',
                                          'projectId',
                                          'isDefault',
                                          'isBillable',
                                          'isVariation',
                                      ],
                                  },
                              ],
                          }
                        : null,
                ].filter((q) => q)}
                loading={
                    <div style={{ padding: '8em' }}>
                        <LoadingSpinner />
                    </div>
                }
            >
                <TimesheetRows store={store} />
            </RenderOnQueries>
        </div>
    )
})

const TimesheetRows = observer(({ store }) => {
    useEffect(() => {
        if (!shouldAutoPopulatePhasesInTimesheets()) return
        store.timeEntries.forEach((te) => {
            if (
                canEnterTimeAgainstPhase(
                    store.staff || SessionStore.user,
                    te.phase
                )
            ) {
                te.update({ isLocked: false }, { trackUpdates: false })
            }
        })
        const existingPhases = new Set(Object.keys(store.rowsByPhaseId))
        const phasesToAdd = new Set()
        PhaseCollection.phases.forEach((ph) => {
            if (existingPhases.has(String(tuple(ph?.projectId, ph?.id)))) return
            if (
                autoPopulatePhaseInTimesheets(
                    store.staff || SessionStore.user,
                    ph
                ) &&
                canEnterTimeAgainstPhase(store.staff || SessionStore.user, ph)
            ) {
                phasesToAdd.add(ph)
            }
        })
        store.addExtraTime(
            [...phasesToAdd].map((ph) => {
                return TimeEntryCollection.add(
                    {
                        costCentreId: ph.project?.costCentreId,
                        projectId: ph.projectId,
                        phaseId: ph?.id,
                        staffId: store?.staffId || SessionStore.userId,
                        taskId: ph.defaultTask?.id,
                        isBillable: ph.defaultTask?.isBillable,
                        isVariation: ph.defaultTask?.isVariation,
                        date: store.date,
                        numMinutes: 0,
                    },
                    { trackUpdates: true }
                )
            })
        )
    }, [store])
    return (
        <div className="mt-8">
            {store.costCentres
                .sort((a, b) => {
                    return a?.name?.localeCompare?.(b?.name)
                })
                .map((cc) => {
                    return (
                        <>
                            <TimesheetCostCentre costCentre={cc} />
                            {(store.projectsByCostCentreId[cc?.id] || [])
                                .sort((a, b) => {
                                    const aName = a.title || ''
                                    const bName = b.title || ''
                                    return aName.localeCompare(bName)
                                })
                                .map((pr) => {
                                    return (
                                        <>
                                            <TimesheetProject project={pr} />
                                            {(
                                                store.phasesByProjectId[
                                                    pr?.id
                                                ] || []
                                            )
                                                .sort((a, b) => {
                                                    const aName = a.title || ''
                                                    const bName = b.title || ''
                                                    return aName.localeCompare(
                                                        bName
                                                    )
                                                })
                                                .map((ph) => {
                                                    return (
                                                        <div className="phase-block timesheet-widget__row">
                                                            <div className="timesheet-widget__phase-block timesheet-widget__row__inner">
                                                                <TimesheetPhase
                                                                    project={pr}
                                                                    phase={ph}
                                                                    store={
                                                                        store
                                                                    }
                                                                />
                                                                {(
                                                                    store
                                                                        .rowsByPhaseId[
                                                                        tuple(
                                                                            pr?.id,
                                                                            ph?.id
                                                                        )
                                                                    ] || []
                                                                )
                                                                    .sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) => {
                                                                            const aName =
                                                                                a
                                                                                    .task
                                                                                    ?.name ||
                                                                                ''
                                                                            const bName =
                                                                                b
                                                                                    .task
                                                                                    ?.name ||
                                                                                ''
                                                                            return aName.localeCompare(
                                                                                bName
                                                                            )
                                                                        }
                                                                    )
                                                                    .map(
                                                                        (
                                                                            row
                                                                        ) => {
                                                                            return (
                                                                                <TaskRow
                                                                                    store={
                                                                                        store
                                                                                    }
                                                                                    row={
                                                                                        row
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                    )
                                })}
                        </>
                    )
                })}
            {!store.costCentres.length && (
                <div>
                    <div>
                        <div
                            className="text-[#666666] text-3xl pb-5"
                            // style={{
                            //     fontSize: '2em',
                            //     padding: '2em 0 1em 0',
                            //     textAlign: 'left',
                            // }}
                        >
                            No Time Entries
                        </div>
                        <div
                            className="text-[#666666] leading-[2em]"
                            // style={{
                            //     textAlign: 'left',
                            //     fontSize: '1.3em',
                            //     lineHeight: '2em',
                            // }}
                        >
                            There are no time entries for this day.
                            <br />
                            Please add an entry by clicking the button below.
                        </div>
                    </div>
                </div>
            )}
            <button
                className="btn btn-lg btn-default add-timesheet-entry-button plus-btn border-[#ccc]"
                onClick={() =>
                    LayoutStore.openModal(
                        <EditTimeEntryModal
                            modalId={'addEntry'}
                            dates={[store.date]}
                            staff={store.staff}
                            store={store}
                        />
                    )
                }
            >
                + Add time entry
            </button>

            {!store.costCentres.length ? (
                <button
                    className="btn btn-lg btn-primary timesheet-widget__copy-previous-button ml-4 bg-[#428bca] m-[0.5em] text-white py-2 px-4 text-sm rounded-[6px] border border-[#357ebd] cursor-pointer hover:bg-[#428bca]/70 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={async () => {
                        const uniqueRows = await getPreviousDayTime(
                            store.staff?.id,
                            store.date
                        )
                        store.addExtraTime(
                            uniqueRows.map((r) => {
                                return TimeEntryCollection.add(
                                    {
                                        numMinutes: 0,
                                        ...r,
                                        date: store.date,
                                        staffId:
                                            store.staffId ||
                                            SessionStore.userId,
                                        date: store.daysOfWeek[0],
                                    },
                                    { trackUpdates: true }
                                )
                            })
                        )
                    }}
                >
                    Copy previous
                </button>
            ) : null}
        </div>
    )
})

const TimesheetCostCentre = observer(({ costCentre }) => {
    return (
        <div className="text-[1.7em] py-[0.5em] text-left border-b border-[#aaa] mb-[0.8em]">
            {costCentre?.name}
        </div>
    )
})

const TimesheetProject = observer(({ project }) => {
    return (
        <div
            className="project-title"
            style={{
                fontSize: '1.4em',
                margin: '1.2em 0 0.2em 0.6em',
                fontWeight: 600,
                width: '50%',
            }}
        >
            {project.title}
        </div>
    )
})

const TimesheetPhase = observer(({ project, phase, store }) => {
    return (
        <div className="timesheet-widget__phase-row flex flex-col">
            <div className="timesheet-widget__phase-title w-full">
                <div className="inline-flex w-full pb-[0.1em] items-end">
                    <button
                        className="btn btn-default timesheet-widget__add-task flex-0-0-auto"
                        onClick={() =>
                            LayoutStore.openModal(
                                <EditTimeEntryModal
                                    modalId={'addEntry'}
                                    dates={[store.date]}
                                    staff={store.staff}
                                    store={store}
                                    defaults={{
                                        costCentre: project.costCentre,
                                        project: project,
                                        phase: phase,
                                        isBillable:
                                            phase?.defaultTask?.isBillable ||
                                            true,
                                        isVariation:
                                            phase?.defaultTask?.isVariation ||
                                            false,
                                        isOvertime: false,
                                        isRemote: false,
                                        isFlexi: false,
                                    }}
                                />
                            )
                        }
                        style={{
                            padding: '0.1em 0.4em',
                            margin: '0em 1em 0 0.5em',
                            fontSize: '0.6em',
                        }}
                    >
                        <i className="fa fa-plus" style={{ margin: 0 }} />
                    </button>
                    <div
                        className="phase-block__title flex-1-1-auto"
                        style={{ minWidth: 0, flex: '0 0 auto', width: '24%' }}
                    >
                        <div className="text-base">
                            {phase?.title || 'No Phase'}
                        </div>
                    </div>
                    {!hideBudgetProgress() ? (
                        <BudgetProgressBar
                            dropdownClassName="!text-xs"
                            projects={store.projects}
                            project={project}
                            phase={phase}
                            role={store.staff.role}
                            staff={store.staff}
                            startDate={startOfMonth(store.date)}
                            endDate={endOfDay(subDays(store.date, 1))}
                            containerStyles={{
                                margin: '0 2em',
                                position: 'relative',
                                width: '70%',
                            }}
                            textStyles={{ marginLeft: '5em' }}
                            dropdownStyles={{
                                right: '1em',
                                bottom: '0.75rem',
                                height: '1.5rem',
                                maxWidth: 'max-content',
                                position: 'absolute',
                            }}
                            dropdownAbove={true}
                            extraHours={_.sum(
                                store.timeEntries
                                    .filter(
                                        (te) =>
                                            te.phaseId === phase?.id &&
                                            te.staffId == store.staff?.id
                                    )
                                    .map((te) => te.hours)
                            )}
                        />
                    ) : null}
                </div>
            </div>
            <div
                className="timesheet-widget__progress-bar timesheet-widget__middle-section"
                style={{ verticalAlign: 'bottom' }}
            >
                {/* {?.phase != null ? (
                    <PhaseProgressBar
                        projectPhaseGroup={.projectPhaseGroup}
                        phase={.phase}
                        staffMember={.staffMember}
                        monthIndex={
                            .rows.getIn([0, 'entries', 0]).monthIndex
                        }
                        loading={.budgetState !== 'loaded'}
                    />
                ) : null} */}
            </div>
        </div>
    )
})

const TaskRow = observer(({ store, row, mode }) => {
    const isHighlighted = false
    return (
        <div
            className={classNames('timesheet-widget__task-row', {
                'timesheet-widget__task-row--highlighted': isHighlighted,
            })}
        >
            <div className="timesheet-widget__task-title timesheet-widget__left-section">
                {!row.isLocked ? (
                    <button
                        className="timesheet-widget__task-delete delete-button"
                        onClick={() => {
                            row.update({ deletedAt: new Date() })
                        }}
                    >
                        <i className="fa fa-times fa-fw" />
                    </button>
                ) : null}
                {!row.isLocked ? (
                    <button
                        className="timesheet-widget__task-edit edit-button"
                        onClick={() => {
                            LayoutStore.openModal(
                                <EditTimeEntryModal
                                    modalId={'editEntry'}
                                    timeEntries={[row]}
                                    staff={store.staff}
                                    store={store}
                                />
                            )
                        }}
                    >
                        <i className="fa fa-pencil-square-o fa-fw" />
                    </button>
                ) : null}
                {canEditStaffTime(SessionStore.user) && !row.isLocked ? (
                    <button
                        className="timesheet-widget__task-lock lock-button"
                        onClick={() => {
                            row.update({ isLocked: true })
                        }}
                    >
                        <i className="fa fa-lock fa-fw" />
                    </button>
                ) : canEditStaffTime(SessionStore.user) && row.isLocked ? (
                    <button
                        className="timesheet-widget__task-lock lock-button"
                        onClick={() => {
                            row.update({ isLocked: false })
                        }}
                    >
                        <i className="fa fa-unlock fa-fw" />
                    </button>
                ) : null}
                <div className="text-[0.85em]">
                    <span style={{ marginLeft: '0.5em' }}>
                        {!row.isBillable ? (
                            <div
                                style={{ display: 'inline-block' }}
                                className="time-entry__time-options timesheet-widget__task-row__is-not-billable"
                            >
                                <span
                                    className="fa-stack"
                                    style={{
                                        fontSize: '0.7em',
                                        marginRight: '0.4em',
                                    }}
                                >
                                    <i className="fa fa-usd fa-stack-1x" />
                                    <i className="fa fa-ban fa-stack-2x" />
                                </span>
                                <div className="time-entry__time-options-hover">
                                    Non-Billable Time
                                </div>
                            </div>
                        ) : null}
                        {row.isOvertime ? (
                            <div
                                style={{ display: 'inline-block' }}
                                className="time-entry__time-options timesheet-widget__task-row__is-overtime"
                            >
                                <i
                                    className="fa fa-clock-o"
                                    style={{
                                        fontSize: '1.3em',
                                        position: 'relative',
                                        top: '0.15em',
                                    }}
                                />
                                <div className="time-entry__time-options-hover">
                                    Overtime
                                </div>
                            </div>
                        ) : null}
                        {row.isVariation ? (
                            <div
                                style={{ display: 'inline-block' }}
                                className="time-entry__time-options timesheet-widget__task-row__is-variation"
                            >
                                <i
                                    className="fa fa-code-fork"
                                    style={{
                                        fontSize: '1.3em',
                                        position: 'relative',
                                        top: '0.15em',
                                    }}
                                />
                                <div className="time-entry__time-options-hover">
                                    Variation
                                </div>
                            </div>
                        ) : null}
                        <span style={{ marginLeft: '0.5em' }}>
                            {row?.task != null ? row.task?.name : '(No task)'}
                        </span>
                    </span>
                </div>
            </div>
            <div className="timesheet-widget__task-entries timesheet-widget__middle-section">
                <DailyTimeEntry timeEntry={row} store={store} />
            </div>
        </div>
    )
})

const DailyTimeEntry = observer(({ timeEntry, store }) => {
    return (
        <div className="time-entry time-entry--daily py-1">
            <HoursInput
                value={timeEntry.numMinutes}
                onChange={(numMinutes) => {
                    timeEntry.update({ numMinutes })
                    timeEntry.attach()
                }}
                className="time-entry__input"
                isEditable={!timeEntry.isLocked}
            />
            <div className="time-entry--daily__note">
                <TextArea
                    value={timeEntry.notes || ''}
                    onChange={(e) => {
                        timeEntry.update({ notes: e.target.value })
                        timeEntry.attach()
                    }}
                    className="time-entry__note-input !my-0 inline-flex items-center justify-center !text-sm bg-white"
                    placeholder="(No Notes)"
                    disabled={timeEntry.isLocked}
                />
            </div>
        </div>
    )
})

const TimesheetNav = observer(
    ({ mode, monday, dayTotals, selectedDate, store }) => {
        const navigate = useNavigate()

        const formatTotal = (n, type) => {
            return isNumber(n) ? (
                formatMinutes(n)
            ) : n === 'loading' ? (
                <i className="fa fa-spinner fa-spin" style={{ margin: 0 }} />
            ) : (
                <i
                    className="fa fa-exclamation-triangle error-highlight-color"
                    style={{ margin: 0 }}
                    title={`Some entries for this ${type} have errors.`}
                />
            )
        }
        return (
            <div className="max-w-[1040px] bg-[#fafafa] z-[1000] pt-8 w-[1040px] sticky top-0">
                <div className="border-b border-[#aaa]">
                    <div>
                        <div
                            className="timesheet-widget__row min-w-[36em] mb-2.5 text-center"
                            style={{ fontSize: '1em' }}
                        >
                            <div className="timesheet-widget__row__inner min-[1310px]:w-[92%] w-full mx-auto">
                                <div
                                    className="flex items-start"
                                    // to align buttons
                                    style={
                                        {
                                            // marginTop: '-2.4em',
                                        }
                                    }
                                >
                                    <div className="timesheet-widget__left-section min-[1381px]:w-[30%] w-1/4">
                                        <div
                                            className="timesheets__navigation flex items-center"
                                            style={{ flex: '0 0 auto' }}
                                        >
                                            <div className="flex w-full text-center">
                                                <button
                                                    onClick={
                                                        store.shiftThisWeek
                                                    }
                                                    className="btn btn-default w-full py-1.5 px-3 text-sm text-[#333] bg-white border border-[#ccc] text-center whitespace-nowrap rounded hover:bg-[#e0e0e0]"
                                                >
                                                    Today
                                                </button>
                                            </div>
                                            <div
                                                className=""
                                                style={{ flex: '0 0 auto' }}
                                            >
                                                <button
                                                    onClick={
                                                        store.shiftPrevWeek
                                                    }
                                                    className="btn btn-default previous-week-button ml-3 py-1.5 px-3 text-[#333] bg-white border border-[#ccc] text-sm rounded"
                                                    title="Previous week"
                                                >
                                                    <i className="fa fa-chevron-left m-0" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="timesheet-widget__middle-section min-[1381px]:w-[65%] w-3/4"
                                        // style={{
                                        //     flex: '0 0 auto',
                                        //     paddingTop: '1.4em',
                                        // }}
                                    >
                                        <div className="timesheets__day-nav flex justify-around w-full">
                                            {dayTotals.map(
                                                function (dayTotal, dayIndex) {
                                                    let d1 = addDays(
                                                        monday,
                                                        dayIndex
                                                    )

                                                    let inner = (
                                                        <div className="timesheet__nav__day__date">
                                                            <div className="text-[10px] leading-[14px]">
                                                                {format(
                                                                    d1,
                                                                    'EEE'
                                                                )}
                                                            </div>
                                                            <div className="text-xl">
                                                                {format(
                                                                    d1,
                                                                    'dd'
                                                                )}
                                                            </div>
                                                        </div>
                                                    )

                                                    return (
                                                        <div
                                                            key={dayIndex}
                                                            className="flex-0-0-auto timesheet__nav__day text-center"
                                                            data-date={format(
                                                                d1,
                                                                'yyyy-MM-dd'
                                                            )}
                                                            // style={{
                                                            //     fontSize: '1.1em',
                                                            // }}
                                                        >
                                                            <button
                                                                className={classNames(
                                                                    'btn btn-default timesheets__day-nav-button border hover:m-0 hover:border-2 hover:border-gold text-sm text-[#333] bg-white rounded py-1.5 px-3 whitespace-nowrap',
                                                                    d1?.getTime?.() ===
                                                                        selectedDate?.getTime?.()
                                                                        ? 'border-2 border-gold m-0'
                                                                        : 'border-[#ccc] m-px'
                                                                )}
                                                                style={{
                                                                    fontSize:
                                                                        '1.1em',
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                                onClick={() => {
                                                                    navigate({
                                                                        search: (
                                                                            prev
                                                                        ) => ({
                                                                            ...prev,
                                                                            date: format(
                                                                                d1,
                                                                                'yyyy-MM-dd'
                                                                            ),
                                                                        }),
                                                                    })
                                                                }}
                                                            >
                                                                {inner}
                                                            </button>
                                                            <div className="timesheet-widget__hours-cell timesheet__nav__total text-xs text-[#666666] font-semibold mt-[2px]">
                                                                {formatTotal(
                                                                    dayTotal,
                                                                    'day'
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="timesheet-widget__right-section"
                                        style={{ flex: '0 0 auto' }}
                                    >
                                        <button
                                            onClick={store.shiftNextWeek}
                                            className="btn btn-default next-week-button py-1.5 px-3 text-[#333] bg-white border border-[#ccc] text-sm rounded"
                                            title="Next week"
                                        >
                                            <i className="fa fa-chevron-right m-0" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)
