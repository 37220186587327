import {
    createFileRoute,
    redirect,
    useLoaderData,
} from '@tanstack/react-router'
import AuthCallbackPage from '../../Pages/AuthCallbackPage/AuthCallbackPage'
import LoadingSpinner from '../../Components/LoadingSpinner'
import SessionStore from '../../State/SessionStore'
import { canViewRoute } from '../../State/Permissions/HasPermissions'

export const Route = createFileRoute('/_public/sign-up')({
    loader: async ({ location, context }) => {
        try {
            await SessionStore.clearCache()
            await SessionStore.signup()
        } catch (error) {
            console.log('error', error)
            throw error?.response?.data?.message || 'An error occurred'
        }
        if (!context.organization) {
            window.location.href = '/register-details'
        } else {
            window.location.href = canViewRoute(
                SessionStore.user,
                'projectList'
            )
                ? '/projects'
                : '/timesheets'
        }
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    errorComponent: ({ error, reset }) => {
        return <Wrapper error={error} />
    },
})

function Wrapper({ error }) {
    return (
        <>
            <AuthCallbackPage
                {...Route.useParams()}
                {...Route.useSearch()}
                error={error}
            />
        </>
    )
}
