import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import ProjectRateCollection from '../../State/Collections/ProjectRateCollection'
import {
    canEditProjectRates,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import StaffModel from '../../State/Models/StaffModel'
import { PhaseSelector } from '../../Components/Selector'

const rateColumns = [
    {
        id: 'date',
        label: 'Starting From',
        width: 20,
        type: 'date',
        editable: (r) => canEditProjectRates(SessionStore.user, r.project),
        value: (r) => r.date,
        onChange: (r) => (v) => r.update({ date: v }),
    },
    {
        label: 'Staff / Role',
        width: 20,
        type: 'staffOrRole',
        editable: (r) => canEditProjectRates(SessionStore.user, r.project),
        value: (p) => p.resource,
        onChange: (r) => (v) =>
            r.update({
                itemId: v.id,
                itemType: v instanceof StaffModel ? 'staff' : 'role',
            }),
    },
    {
        label: 'Phase',
        width: 20,
        type: 'phase',
        editable: (r) => canEditProjectRates(SessionStore.user, r.project),
        value: (p) => p.phase,
        onChange: (r) => (v) => {
            r.update({ phaseId: v.id })
        },
        component: ({ value, onChange, stores, editable }) => {
            const project = stores.row.rowObject.project
            return editable ? (
                <PhaseSelector
                    options={project.phases}
                    {...{ selectedPhase: value, onChange }}
                    variant="secondary"
                    className="!border-none"
                />
            ) : (
                value?.title || 'No Phase'
            )
        },
    },
    {
        label: 'Cost Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        editable: (r) =>
            canEditProjectRates(SessionStore.user, r.project) &&
            canViewStaffCostRate(SessionStore.user),
        value: (p) => p.costRate,
        onChange: (r) => (v) => r.update({ costRate: v }),
    },
    {
        label: 'Charge Out Rate',
        width: 20,
        type: 'currency',
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        editable: (r) =>
            canEditProjectRates(SessionStore.user, r.project) &&
            canViewStaffChargeOutRate(SessionStore.user),
        value: (p) => p.chargeOutRate,
        onChange: (r) => (v) => r.update({ chargeOutRate: v }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) => canEditProjectRates(SessionStore.user, r.project),
        editable: (r) => canEditProjectRates(SessionStore.user, r.project),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
    {
        id: 'createdAt',
        label: 'Created At',
        width: 18,
        type: 'date',
        editable: (r) => false,
        value: (e) => e.initiatedAt,
        visible: false,
    },
    {
        id: 'idString',
        label: 'id',
        width: 18,
        type: 'string',
        editable: (r) => false,
        value: (e) => {
            console.log(
                e.date.getTime() +
                    e.phaseId +
                    e.resource.id +
                    e.createdAt.getTime() +
                    e.initiatedAt.getTime()
            )
            return e.id
        },
        visible: false,
    },
]

export default observer(({ id }) => {
    const project = ProjectCollection.projectsById[id]
    useEffect(() => {
        ;(project?.rates || []).forEach((cli, i) => (cli.position = i))
    }, [project])
    if (!project) return null
    const [rows, setRows] = useState(
        project.rates.sort((a, b) => {
            // First, compare by date
            const dateA = new Date(a.date),
                dateB = new Date(b.date)
            if (dateA < dateB) return -1
            if (dateA > dateB) return 1

            // If dates are equal, then compare by phaseId
            if (a.phaseId < b.phaseId) return -1
            if (a.phaseId > b.phaseId) return 1

            // If phaseIds are equal, then compare by itemId
            if (a.itemId < b.itemId) return -1
            if (a.itemId > b.itemId) return 1

            // If itemIds are equal, then compare by initiatedAt
            const initiatedAtA = new Date(a.initiatedAt),
                initiatedAtB = new Date(b.initiatedAt)
            if (initiatedAtA < initiatedAtB) return -1
            if (initiatedAtA > initiatedAtB) return 1

            // If all are equal, return 0 (no sorting)
            return 0
        })
    )
    useEffect(() => {
        setRows(
            project.rates.sort((a, b) => {
                // First, compare by date
                const dateA = new Date(a.date),
                    dateB = new Date(b.date)
                if (dateA < dateB) return -1
                if (dateA > dateB) return 1

                // If dates are equal, then compare by phaseId
                if (a.phaseId < b.phaseId) return -1
                if (a.phaseId > b.phaseId) return 1

                // If phaseIds are equal, then compare by itemId
                if (a.itemId < b.itemId) return -1
                if (a.itemId > b.itemId) return 1

                // If itemIds are equal, then compare by initiatedAt
                const initiatedAtA = new Date(a.initiatedAt),
                    initiatedAtB = new Date(b.initiatedAt)
                if (initiatedAtA < initiatedAtB) return -1
                if (initiatedAtA > initiatedAtB) return 1

                // If all are equal, return 0 (no sorting)
                return 0
            })
        )
    }, [project.id])
    return (
        <div>
            <Table
                columns={rateColumns}
                rows={rows.filter((r) => !r.deletedAt)}
            />
            {canEditProjectRates(SessionStore.user, project) ? (
                <button
                    className="btn btn-default plus-btn border-[#ccc]"
                    style={{ marginTop: '2em' }}
                    onClick={() => {
                        const pr = ProjectRateCollection.add(
                            {
                                projectId: project.id,
                                phaseId: project.rootPhase.id,
                                date: project.startDate || new Date(),
                            },
                            { trackUpdates: true }
                        )
                        pr.position = project.rates.length
                        setRows([...rows, pr])
                    }}
                >
                    + Add Rate
                </button>
            ) : null}
        </div>
    )
})
