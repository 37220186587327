import { InputWithLabel } from '@/version2/components/ui/input'
import { RolePageTable } from './role-page-table'
import { staffRoleActions, useRoleData } from './role-page-store'
import { Button } from '@/version2/components/ui/button'

export function RolePage() {
    const role = useRoleData()
    const onAddNewRate = () => {
        staffRoleActions.addNewRate()
    }

    return (
        <div className="w-full">
            <div className="flex justify-between">
                <InputWithLabel
                    value={role?.name}
                    onChange={(e) =>
                        staffRoleActions.changeRoleData('name', e.target.value)
                    }
                    containerClassName="flex-row items-center gap-3 mb-4"
                    label="Role Name"
                />
                <Button variant="outline" onClick={onAddNewRate}>
                    <i className="fa fa-plus mr-1" />
                    Add Rate
                </Button>
            </div>
            <RolePageTable />
        </div>
    )
}
