import { pascalCase } from 'change-case'
import { StaffRateInterface } from '../pages/staff-page/staff.interface'
import { getStaffRates, getStaffs } from '../pages/role-page/role-page-store'
import getRateAtDate from '../../Utils/getRateAtDate'
import getRateInDateRange from '../../Utils/getRateInDateRange'
import { RoleUtils } from './role-utils'

export type RateType = 'pay' | 'overtime' | 'cost' | 'chargeOut'

const inheritRateType = (
    staff: StaffRateInterface.StaffData,
    rateType: RateType
) => {
    return staff?.[`inherit${pascalCase(rateType)}Rate`] ?? false
}

const getAvailableStaffs = (rateType: RateType) => {
    const staffs = getStaffs() ?? []
    console.log('staffs', staffs)
    const availableStaffs = staffs.filter(
        (s) =>
            StaffUtils.inheritRateType(s, rateType) &&
            !s?.deletedAt &&
            !s?.isArchived
    )
    return availableStaffs
}

const getStaffRate = (staff: StaffRateInterface.StaffData) => {
    const staffRate = getStaffRates()
    return staffRate.filter((r) => r.staffId === staff.id)
}

const getStaffRateInDateRange = (
    staff: StaffRateInterface.StaffData,
    rateType: RateType,
    dateRange: Date[],
    fromRole: boolean = false
) => {
    if (inheritRateType(staff, rateType) && !fromRole) {
        return RoleUtils.getRoleRateInDateRange(rateType, dateRange)
    }
    const rates = getStaffRate(staff)
    return getRateInDateRange(rates, rateType, dateRange)
}

const getStaffRateAtDate = (
    staff: StaffRateInterface.StaffData,
    date: Date,
    rateType: RateType,
    fromRole: boolean = false
) => {
    if (inheritRateType(staff, rateType) && !fromRole) {
        return RoleUtils.getRoleRateAtDate(rateType, date)
    }
    const rates = getStaffRate(staff)
    return getRateAtDate(rates, rateType, date)
}

export const StaffUtils = {
    getStaffRateAtDate,
    inheritRateType,
    getStaffRate,
    getStaffRateInDateRange,
    getAvailableStaffs,
}
