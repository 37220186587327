import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { toast } from '@vendor/sonner'
import { useEffect } from 'react'
import { Toaster } from '@2/components/ui/sonner'
// Define the SaveEvent interface
export interface SaveEvent {
    saveId: string
    timestamp?: number
    onSave: () => Promise<any>
    onCancel?: () => void
    requireConfirmation?: boolean
    confirmationMessage?: string
    loadingMessage?: string
    successMessage?: string
    errorMessage?: string
    showCancelButton?: boolean
    onSuccess?: () => void
    onRetry?: () => void
}

// Store context interface
interface SaveQueueContext {
    queue: SaveEvent[]
    processingEvent: SaveEvent | null
    isProcessing: boolean
    activeToasts: Set<string>
}

// Initial state
const initialContext: SaveQueueContext = {
    queue: [],
    processingEvent: null,
    isProcessing: false,
    activeToasts: new Set(),
}

// Create the store
export const saveQueueStore = createStore({
    context: initialContext,
    on: {
        addToQueue: {
            queue: (state, event: { saveEvent: SaveEvent }) => {
                const newEvent = {
                    ...event.saveEvent,
                    timestamp: event.saveEvent.timestamp || Date.now(),
                }

                // Only remove if the last item has the same ID
                const queue = [...state.queue]
                if (
                    queue.length > 0 &&
                    queue[queue.length - 1].saveId === newEvent.saveId
                ) {
                    queue.pop()
                }

                // Add the new event to the end
                return [...queue, newEvent]
            },
        },
        removeFromQueue: {
            queue: (state, event: { saveId: string }) =>
                state.queue.filter((item) => item.saveId !== event.saveId),
        },
        startProcessing: {
            isProcessing: () => true,
            processingEvent: (state) => {
                if (state.queue.length === 0) return null

                // Sort by timestamp to process in order
                const sortedQueue = [...state.queue].sort(
                    (a, b) => (a.timestamp || 0) - (b.timestamp || 0)
                )

                return sortedQueue[0]
            },
            queue: (state) => {
                const sortedQueue = [...state.queue].sort(
                    (a, b) => (a.timestamp || 0) - (b.timestamp || 0)
                )
                return sortedQueue.slice(1)
            },
        },
        finishProcessing: {
            isProcessing: () => false,
            processingEvent: () => null,
        },
        addActiveToast: {
            activeToasts: (state, event: { toastId: string }) =>
                new Set([...Array.from(state.activeToasts), event.toastId]),
        },
        removeActiveToast: {
            activeToasts: (state, event: { toastId: string }) => {
                const newSet = new Set(state.activeToasts)
                newSet.delete(event.toastId)
                return newSet
            },
        },
        reset: {
            queue: () => [],
            processingEvent: () => null,
            isProcessing: () => false,
            activeToasts: () => new Set(),
        },
    },
})

// Action creators
export const addToQueue = (saveEvent: SaveEvent) => {
    saveQueueStore.send({
        type: 'addToQueue',
        saveEvent: {
            ...saveEvent,
            timestamp: saveEvent.timestamp || Date.now(),
        },
    })

    // Start processing if not already processing
    const state = getSaveState()
    if (!state.isProcessing) {
        processNext()
    }
}

export const removeFromQueue = (saveId: string) =>
    saveQueueStore.send({ type: 'removeFromQueue', saveId })

export const startProcessing = () =>
    saveQueueStore.send({ type: 'startProcessing' })

export const finishProcessing = () =>
    saveQueueStore.send({ type: 'finishProcessing' })

export const addActiveToast = (toastId: string) =>
    saveQueueStore.send({ type: 'addActiveToast', toastId })

export const removeActiveToast = (toastId: string) =>
    saveQueueStore.send({ type: 'removeActiveToast', toastId })

export const resetSaveQueue = () => saveQueueStore.send({ type: 'reset' })

// Get the current state
export const getSaveState = () => saveQueueStore.getSnapshot().context

// Process the next save event in the queue
export const processNext = async () => {
    const state = getSaveState()

    if (state.isProcessing || state.queue.length === 0) {
        return
    }

    startProcessing()

    const currentState = getSaveState()
    const currentEvent = currentState.processingEvent

    if (!currentEvent) {
        finishProcessing()
        return
    }

    // If confirmation is required, show confirmation toast
    if (currentEvent.requireConfirmation) {
        const toastId = Math.random().toString(36).substr(2, 9)
        addActiveToast(toastId)

        toast(currentEvent.confirmationMessage || 'Confirm save?', {
            id: toastId,
            action: {
                label: 'Save',
                onClick: () => {
                    removeActiveToast(toastId)
                    executeCurrentSave()
                },
            },
            cancel: currentEvent.showCancelButton
                ? {
                      label: 'Cancel',
                      onClick: () => {
                          if (currentEvent.onCancel) {
                              currentEvent.onCancel()
                          }

                          removeFromQueue(currentEvent.saveId)
                          removeActiveToast(toastId)
                          finishProcessing()

                          // Process next after a short delay
                          setTimeout(processNext, 50)
                      },
                  }
                : undefined,
            onDismiss: () => {
                if (currentEvent.onCancel) {
                    currentEvent.onCancel()
                }

                removeFromQueue(currentEvent.saveId)
                removeActiveToast(toastId)
                finishProcessing()

                // Process next after a short delay
                setTimeout(processNext, 50)
            },
        })
    } else {
        // Execute the save directly if no confirmation needed
        executeCurrentSave()
    }
}

// Execute the current save operation
const executeCurrentSave = async () => {
    const state = getSaveState()
    const currentEvent = state.processingEvent

    if (!currentEvent) {
        finishProcessing()
        return
    }

    // Show loading toast
    const toastId = Math.random().toString(36).substr(2, 9)
    addActiveToast(toastId)

    toast.loading(currentEvent.loadingMessage || 'Saving...', { id: toastId })

    try {
        // Call the save function
        await currentEvent.onSave()

        // Show success toast
        toast.success(currentEvent.successMessage || 'Saved successfully!', {
            id: toastId,
        })

        // Call onSuccess callback if provided
        if (currentEvent.onSuccess) {
            currentEvent.onSuccess()
        }
    } catch (error) {
        // Show error toast with retry option
        toast.error(
            currentEvent.errorMessage || 'Failed to save. Please try again.',
            {
                id: toastId,
                action: {
                    label: 'Retry',
                    onClick: () => {
                        if (currentEvent.onRetry) {
                            currentEvent.onRetry()
                        }

                        // Add back to queue
                        addToQueue(currentEvent)
                    },
                },
            }
        )
    } finally {
        removeActiveToast(toastId)
        finishProcessing()

        // Process next after a short delay
        setTimeout(processNext, 50)
    }
}

// Hook to check if there are any save operations in progress
export const useHasSaveOperations = () =>
    useSelector(
        saveQueueStore,
        (state) => state.context.queue.length > 0 || state.context.isProcessing
    )

// Hook to get the current queue
export const useSaveQueue = () =>
    useSelector(saveQueueStore, (state) => state.context.queue)

// Hook to get processing status
export const useIsSaveProcessing = () =>
    useSelector(saveQueueStore, (state) => state.context.isProcessing)

// Hook to get the currently processing event
export const useCurrentSaveEvent = () =>
    useSelector(saveQueueStore, (state) => state.context.processingEvent)

// Hook to add a save event to the queue
export const useSaveQueueAction = () => {
    return (saveEvent: SaveEvent) => {
        addToQueue({
            ...saveEvent,
            saveId: saveEvent.saveId,
            timestamp: Date.now(),
        })
    }
}

// Component to automatically process the queue
export const SaveQueueProcessor = ({
    toasterProps = {
        position: 'top-center',
        visibleToasts: 3,
    },
}) => {
    const queue = useSaveQueue()
    const isProcessing = useIsSaveProcessing()

    useEffect(() => {
        if (queue.length > 0 && !isProcessing) {
            processNext()
        }
    }, [queue, isProcessing])

    return <Toaster {...toasterProps} />
}
