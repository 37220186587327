import { DataTableColumnHeader } from '../data-table-column-header'
import { CellType, renderCell } from './render-cell'
import _ from 'lodash'
import { sortingFns } from './sorting-fns'
import { FilterType, generateFilterOptions } from './filters'
import { Input } from '@2/components/ui/input'
import { Row, Column } from '@tanstack/react-table'

interface GenerateColumnDefProps<
    TData = any,
    TKey extends keyof TData = keyof TData,
    TValue = TData[TKey],
> {
    key?: TKey | (Record<never, any> & string)
    type?: CellType
    title?: string
    permissions?: (row: Row<TData>) => boolean
    size?: number
    aggregationFn?: any
    formatter?: (v: any) => any
    cell?: (row: Row<TData>) => any
    filterOptions?: any
    editable?: boolean | ((row: Row<TData>) => boolean)
    onChange?: (row: Row<TData>, value: TValue) => void
    onClick?: (v: Row<TData>) => void
    onFocus?: (v: Row<TData>) => void
    onHideColumn?: (column: string) => void
    cellClassName?: (row: Row<TData>) => string
    cellStyle?: (row: Row<TData>) => React.CSSProperties
    customValue?: (value: TData, row: Row<TData>, col: Column<TData>) => any
    filterType?: FilterType
}

export function generateColumnDef<
    TData = any,
    TKey extends keyof TData = keyof TData,
>({
    key = '',
    type = 'default',
    title = '',
    permissions = (row) => true,
    size = 160,
    aggregationFn = null,
    formatter = (v) => v,
    cell = null,
    filterOptions = null,
    editable = false,
    onChange = (v) => {},
    onClick = (v) => {},
    onFocus = (v) => {},
    onHideColumn = (column: string) => {},
    cellClassName = (row) => null,
    cellStyle = (row) => null,
    customValue = null,
    filterType,
}: GenerateColumnDefProps<TData, TKey>) {
    return {
        accessorKey: key,
        ...(customValue && {
            accessorFn: customValue,
        }),
        header: (header) => {
            return (
                <DataTableColumnHeader
                    column={header.column}
                    title={title}
                    onHideColumn={onHideColumn}
                />
            )
        },
        cell: ({ getValue, row, column }) => {
            const value = getValue()
            return renderCell({
                value: value,
                row,
                column,
                editable:
                    typeof editable === 'function' ? editable(row) : editable,
                onClick,
                onFocus,
                onChange,
                cell,
            })
        },
        size: size,
        minSize: size * 0.8,
        maxSize: size * 1.2,
        meta: {
            title,
            type,
            permissions: permissions,
            filterOptions:
                filterOptions ||
                generateFilterOptions(filterType || (type as FilterType)),
            formatter: formatter,
            cellClassName: cellClassName,
            cellStyle: cellStyle,
        },
        sortingFn: sortingFns[type],
        aggregationFn: aggregationFn || 'none',
    }
}

export function createGenerateColumnDef<
    TData = any,
    TKey extends keyof TData = keyof TData,
>(defaultProps: GenerateColumnDefProps<TData, TKey>) {
    return (props: GenerateColumnDefProps<TData, TKey>) =>
        generateColumnDef({ ...defaultProps, ...props })
}
