import { Button } from '@2/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@2/components/ui/tabs'
import {
    PanelLeftIcon,
    MoreVertical,
    PlusCircle,
    PencilRuler,
    LineChart,
    Clock,
    PanelRightIcon,
} from 'lucide-react'
import { SearchBox } from './search-box' // Import the new SearchBox component
import { useRouter, useNavigate, useSearch } from '@tanstack/react-router'
import {
    usePageTitle,
    useTabs,
    useShowSubMenu,
    setShowSubMenu,
} from './layout-store'

export const TopBar = () => {
    const { latestLocation } = useRouter()
    const navigate = useNavigate()
    const pageTitle = usePageTitle()
    const tabs = useTabs()
    const showSubMenu = useShowSubMenu()
    const activeTab = tabs?.length
        ? latestLocation?.search?.tab ||
          tabs.find((t) => t.url === latestLocation?.pathname)?.id ||
          tabs[0]?.id
        : null

    return (
        <div className="w-full bg-background">
            <div className="border-b">
                <div className="flex items-center justify-between px-6 mx-auto">
                    <div className="">
                        <div className="font-semibold text-lg flex items-center space-x-2 my-4  print:text-[1rem]">
                            <Button
                                className="print:hidden text-sm"
                                variant="outline"
                                size="icon"
                                onClick={() => setShowSubMenu(!showSubMenu)}
                            >
                                <PanelLeftIcon className="h-4 w-4" />
                            </Button>
                            <span>{pageTitle}</span>
                        </div>

                        {tabs?.length ? (
                            <div className="flex justify-center -mt-2">
                                <Tabs
                                    value={activeTab}
                                    className="inline-block"
                                    onValueChange={(value) => {
                                        const tab = tabs.find(
                                            (tab) => tab.id === value
                                        )
                                        if (tab?.url) {
                                            navigate({
                                                to: tab.url,
                                            })
                                        } else {
                                            navigate({
                                                to: latestLocation.pathname,
                                                search: (prev) => ({
                                                    ...prev,
                                                    tab: value,
                                                }),
                                            })
                                        }
                                    }}
                                >
                                    <TabsList className="w-full justify-center">
                                        {tabs.map((tab) => (
                                            <TabsTrigger
                                                key={tab.id}
                                                value={tab.id}
                                            >
                                                {tab.label}
                                            </TabsTrigger>
                                        ))}
                                    </TabsList>
                                </Tabs>
                            </div>
                        ) : null}
                    </div>

                    <div className="flex flex-1 justify-end space-x-2 py-4 print:hidden content-stretch items-center">
                        <div className="mx-2 flex-1 max-w-2xl">
                            <SearchBox />
                        </div>

                        <Button
                            variant="outline"
                            className="flex items-center space-x-2"
                        >
                            <PlusCircle className="h-4 w-4" />
                            <span>Create</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
