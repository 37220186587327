import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { ErrorAlert, SuccessAlert } from '../../widgets'
import * as Sentry from '@sentry/react'
import { makeRequest } from '../../Queries/makeRequest'
import { useNavigate } from '@tanstack/react-router'

export default observer((props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState(props.email || '')
    const [resetState, setResetState] = useState('idle')
    return (
        <div className="flex items-center justify-center text-center w-full h-full">
            <section className="flex flex-col items-center justify-center w-[350px]">
                <header className="flex items-center flex-col justify-center">
                    <div className="logo2 s75 size-[10em] flex items-center justify-center">
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                        />
                    </div>
                    <h1 style={{ fontSize: '5em' }}>coincraft</h1>
                </header>
                {resetState === 'success' ? (
                    <SuccessAlert className="body">
                        We've sent instructions to reset your password to your
                        email address. Please check your email, and spam folder
                        if you can't find it.
                    </SuccessAlert>
                ) : (
                    <div className="body w-full">
                        <h4
                            style={{
                                margin: '0em -2em 1em',
                            }}
                        >
                            Set a New Password
                        </h4>
                        <p>
                            Please enter your email address and we will email
                            you instructions on how to set a new password.
                        </p>
                        <form
                            name="forgottenPasswordForm"
                            className="no-margin mt-8"
                        >
                            <fieldset>
                                <div className="form-group no-margin">
                                    <div className="input-group input-group-lg">
                                        <span className="input-group-addon">
                                            <i className="eicon-user"></i>
                                        </span>
                                        <input
                                            value={email || ''}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            type="email"
                                            className="form-control input-lg h-10 py-2 input-base w-full focus:outline-none hover:border-[#618fb0]"
                                            placeholder="Your Email"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        {resetState === 'error' ? (
                            <ErrorAlert style={{ marginTop: 15 }}>
                                Sorry, we couldn't find an email with this
                                e-mail address. Please try again.
                            </ErrorAlert>
                        ) : null}
                        <div className="form-actions mt-5">
                            <button
                                onClick={() => {
                                    setResetState('pending')
                                    makeRequest({
                                        path:
                                            process.env.REACT_APP_SERVER_URL +
                                            '/api/v1.5/user/send-password-reset',
                                        method: 'post',
                                        data: {
                                            email: email,
                                        },
                                    })
                                        .then((response) => {
                                            setResetState('success')
                                        })
                                        .catch((error) => {
                                            Sentry.captureException(error)
                                            setResetState('error')
                                        })
                                }}
                                className="btn btn-block btn-lg btn-primary w-full py-2.5"
                            >
                                Email New Password Instructions
                            </button>
                            <div className="forgot py-4 mt-3.5">
                                <a
                                    className="forgot underline text-[1.2em] text-[#808080] text-center"
                                    href={`login?email=${email}`}
                                    onClick={() =>
                                        navigate({
                                            to: `/login?email=${email}`,
                                        })
                                    }
                                >
                                    Sign Into Your Account
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    )
})
