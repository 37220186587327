import { pascalCase } from 'change-case'
import {
    getRoleData,
    getRoleRates,
    getStaffs,
} from '../pages/role-page/role-page-store'
import getRateAtDate from '../../Utils/getRateAtDate'
import getRateInDateRange from '../../Utils/getRateInDateRange'
import { StaffUtils } from './staff-utils'
import _ from 'lodash'

export type RateType = 'pay' | 'overtime' | 'cost' | 'chargeOut'

const isAvgRateType = (rateType: RateType) => {
    const role = getRoleData()
    return role?.['avg' + pascalCase(rateType) + 'Rate'] ?? false
}

const getRoleRateInDateRange = (
    rateType: RateType,
    dateRange: Date[],
    fromStaff: boolean = false
) => {
    const staffs = getStaffs() ?? []
    const roleRates = getRoleRates() ?? []
    const availableStaffs = staffs.filter(
        (s) => !StaffUtils.inheritRateType(s, rateType)
    )
    if (availableStaffs.length && isAvgRateType(rateType) && !fromStaff) {
        return _.mean(
            availableStaffs
                .filter((s) => !s.isArchived)
                .map((s) =>
                    StaffUtils.getStaffRateInDateRange(s, rateType, dateRange)
                )
                .map((r) => _.toNumber(r))
        )
    }

    return getRateInDateRange(roleRates, rateType, dateRange)
}

const getRoleRateAtDate = (
    rateType: RateType,
    date: Date,
    fromStaff: boolean = false
) => {
    const staffs = getStaffs() ?? []
    const roleRates = getRoleRates() ?? []
    const availableStaffs = staffs.filter(
        (s) => !StaffUtils.inheritRateType(s, rateType) && !s.isArchived
    )
    if (availableStaffs.length && isAvgRateType(rateType) && !fromStaff) {
        const listRate = availableStaffs
            .map((s) => StaffUtils.getStaffRateAtDate(s, date, rateType))
            .filter((r) => r)
            .map((r) => _.toNumber(r))
        const rate = _.mean(listRate)
        return rate
    }

    return getRateAtDate(roleRates, rateType, date)
}

export const RoleUtils = {
    getRoleRateInDateRange,
    getRoleRateAtDate,
    isAvgRateType,
}
