import _ from 'lodash'
import React from 'react'
import Formatter from '../../Components/Formatter'
import {
    canViewProjectDates,
    canViewProjectExpenseBudgets,
    canViewProjectStaffCost,
    canViewProjectFees,
    canViewProjectInvoices,
    canViewProjectExpenses,
    canViewStaffAllocations,
    canViewProjectStaffPay,
    canViewProjectStaffChargeOut,
    canViewCostCentres,
    canViewContacts,
    canViewProjectNotes,
    canViewRevenueTargets,
    canViewPrimaryContact,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { addYears, format } from 'date-fns'
import { Link } from '@tanstack/react-router'
import ProjectCollection from '../../State/Collections/ProjectCollection'

export const ProjectReportColumns = (report) => ({
    name: {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 26,
        value: (row) => row.name,
        component: ({ value, group, stores }) => {
            const { row, table } = stores
            if (row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {row.rowObject.children?.length ? (
                        <Link to={`/projects/${row.rowObject.projectId}`}>
                            {value}
                        </Link>
                    ) : (
                        value
                    )}
                </div>
            )
        },
    },
    project: {
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 26,
        value: (row) => row.project,
        format: (name) => {
            return name
        },
    },
    jobCode: {
        id: 'jobCode',
        label: 'Job code',
        type: 'text',
        width: 13,
        value: (row) => row.jobCode,
    },
    startDate: {
        id: 'startDate',
        label: 'Start date',
        type: 'date',
        width: 13,
        aggregate: 'min',
        value: (row) => {
            return row.startDate ? new Date(row.startDate) : null
        },
        permissions: (row) =>
            canViewProjectDates(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    endDate: {
        id: 'endDate',
        label: 'End date',
        type: 'date',
        width: 13,
        aggregate: 'max',
        value: (row) => {
            return row.endDate ? new Date(row.endDate) : null
        },
        permissions: (row) =>
            canViewProjectDates(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    fee: {
        id: 'fee',
        label: 'Fee',
        type: 'currency',
        width: 13,
        value: (row) => row.fee,
        permissions: (row) =>
            canViewProjectFees(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    expenseBudget: {
        id: 'expenseBudget',
        label: 'Expense Budget',
        type: 'currency',
        width: 13,
        value: (row) => row.expenseBudget,
        permissions: (row) =>
            canViewProjectExpenseBudgets(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost centre',
        type: 'costCentre',
        width: 13,
        value: (row) => row.costCentre,
        format: (name) => {
            return name
        },
        permissions: (row) => canViewCostCentres(SessionStore.user),
    },
    contact: {
        id: 'contact',
        label: 'Contact',
        type: 'contact',
        width: 13,
        value: (row) => row.contact,
        format: (name) => {
            return name
        },
        permissions: (row) => canViewContacts(SessionStore.user),
    },
    invoiceContact: {
        id: 'invoiceContact',
        label: 'Primary Contact',
        type: 'contact',
        width: 13,
        value: (row) => row.invoiceContact,
        format: (name) => {
            return name
        },
        permissions: (row) =>
            canViewContacts(SessionStore.user) && canViewPrimaryContact(),
    },
    owner: {
        id: 'owner',
        label: 'Project Owner',
        type: 'staffMember',
        width: 15,
        value: (row) => row.owner,
        format: (name) => {
            return name
        },
    },
    status: {
        id: 'status',
        label: 'Status',
        type: 'status',
        width: 13,
        value: (row) => row.status,
    },
    remainingFee: {
        id: 'remainingFee',
        label: 'Remaining fee',
        type: 'currency',
        width: 13,
        value: (row) => row.remainingFee,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    remainingExpenseProjectBudget: {
        id: 'remainingExpenseProjectBudget',
        label: 'Remaining Expense Budget',
        type: 'currency',
        width: 13,
        value: (row) => row.remainingExpenseProjectBudget,
        permissions: (row) =>
            ProjectReportColumns(report).expenseBudget.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
    },
    latestEvent: {
        id: 'latestEvent',
        label: 'Latest Event',
        type: 'date',
        width: 13,
        value: (row) => {
            return row.latestEvent ? new Date(row.latestEvent) : null
        },
    },
    latestNote: {
        id: 'latestNote',
        label: 'Latest Note',
        type: 'text',
        width: 35,
        value: (row) => row.latestNote,
        permissions: (row) =>
            canViewProjectNotes(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    currentPhases: {
        id: 'currentPhases',
        label: 'Current Phases',
        type: 'text',
        width: 13,
        value: (row) => row.currentPhases,
    },
    activePhases: {
        id: 'activePhases',
        label: 'Active Phases',
        type: 'text',
        width: 13,
        value: (row) => row.activePhases,
    },
    prospectivePhases: {
        id: 'prospectivePhases',
        label: 'Prospective Phases',
        type: 'text',
        width: 13,
        value: (row) => row.prospectivePhases,
    },
    staffMembers: {
        id: 'staffMembers',
        label: 'Staff members assigned',
        type: 'staffs',
        width: 13,
        value: (row) => row.staffMembers,
    },
    progress: {
        id: 'progress',
        label: 'Progress',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.progress,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
    },
    revenue: {
        id: 'revenue',
        label: 'Revenue',
        type: 'currency',
        width: 13,
        value: (row) => row.revenue,
        permissions: (row) =>
            canViewProjectInvoices(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    revenueWithVariations: {
        id: 'revenueWithVariations',
        label: 'Revenue (Variations)',
        type: 'currency',
        width: 13,
        value: (row) => row.revenueWithVariations,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
    },
    revenueWithVariationsReimbursements: {
        id: 'revenueWithVariationsReimbursements',
        label: 'Revenue (Variations + Reimbursements)',
        type: 'currency',
        width: 13,
        value: (row) => row.revenueWithVariationsReimbursements,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
    },
    recordedHours: {
        id: 'recordedHours',
        label: 'Recorded Hours',
        type: 'number',
        width: 13,
        value: (row) => row.recordedHours,
    },
    recordedHoursVariation: {
        id: 'recordedHoursVariation',
        label: 'Recorded Hours (Variation)',
        type: 'number',
        width: 13,
        value: (row) => row.recordedHoursVariation,
    },
    hoursBudgeted: {
        id: 'hoursBudgeted',
        label: 'Hours budgeted',
        type: 'number',
        width: 13,
        value: (row) => row.hoursBudgeted,
    },
    hoursAllocated: {
        id: 'hoursAllocated',
        label: 'Hours allocated',
        type: 'number',
        width: 13,
        value: (row) => row.hoursAllocated,
        permissions: (row) =>
            canViewStaffAllocations(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    expenses: {
        id: 'expenses',
        label: 'Expenses (cost)',
        type: 'currency',
        width: 13,
        value: (row) => row.expenses,
        permissions: (row) =>
            canViewProjectStaffCost(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    projectExpenses: {
        id: 'projectExpenses',
        label: 'Project Expenses',
        type: 'currency',
        width: 13,
        value: (row) => row.projectExpenses,
        permissions: (row) =>
            canViewProjectExpenses(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    billableProjectExpenses: {
        id: 'billableProjectExpenses',
        label: 'Billable Project Expenses',
        type: 'currency',
        width: 13,
        value: (row) => row.billableProjectExpenses,
        permissions: (row) =>
            canViewProjectExpenses(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    allProjectExpenses: {
        id: 'allProjectExpenses',
        label: 'All Project Expenses',
        type: 'currency',
        width: 13,
        value: (row) => row.allProjectExpenses,
        permissions: (row) =>
            canViewProjectExpenses(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    expensesVariation: {
        id: 'expensesVariation',
        label: 'Expenses (cost) + Variation Cost',
        type: 'currency',
        width: 13,
        value: (row) => row.expensesVariation,
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row),
    },
    expensesVariationProject: {
        id: 'expensesVariationProject',
        label: 'Expenses (cost) + Variation Cost + Project Expenses',
        type: 'currency',
        width: 13,
        value: (row) => row.expensesVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
    },
    expensesProject: {
        id: 'expensesProject',
        label: 'Expenses (cost) + Project Expenses',
        type: 'currency',
        width: 13,
        value: (row) => row.expensesProject,
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
    },
    labourExpense: {
        id: 'labourExpense',
        label: 'Labour expense',
        type: 'currency',
        width: 13,
        value: (row) => row.labourExpense,
        permissions: (row) =>
            canViewProjectStaffPay(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    chargeOut: {
        id: 'chargeOut',
        label: 'Charge-out',
        type: 'currency',
        width: 13,
        value: (row) => row.chargeOut,
        permissions: (row) =>
            canViewProjectStaffChargeOut(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    chargeOutVariation: {
        id: 'chargeOutVariation',
        label: 'Charge-out (Variations)',
        type: 'currency',
        width: 13,
        value: (row) => row.chargeOutVariation,
        permissions: (row) =>
            ProjectReportColumns(report).chargeOut.permissions(row),
    },
    chargeOutVariationProject: {
        id: 'chargeOutVariationProject',
        label: 'Charge-out (Variations + Project)',
        type: 'currency',
        width: 13,
        value: (row) => row.chargeOutVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).chargeOut.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
    },
    actualVsBudgetedHours: {
        id: 'actualVsBudgetedHours',
        label: 'Actual / Budgeted Hours',
        type: 'progressBar',
        width: 24,
        value: (row) => row.actualVsBudgetedHours,
    },
    actualVsBudgetedHoursVariation: {
        id: 'actualVsBudgetedHoursVariation',
        label: 'Actual (Variation) / Budgeted Hours',
        type: 'progressBar',
        width: 24,
        value: (row) => row.actualVsBudgetedHoursVariation,
    },
    actualProjectVsBudgetedExpenses: {
        id: 'actualProjectVsBudgetedExpenses',
        label: 'Actual (Cost + Project) / Budgeted Expenses',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.actualProjectVsBudgetedExpenses,
        permissions: (row) =>
            ProjectReportColumns(report).expensesProject.permissions(row) &&
            ProjectReportColumns(report).expenseBudget.permissions(row),
    },
    actualVsBudgetedExpenses: {
        id: 'actualVsBudgetedExpenses',
        label: 'Actual (Cost) / Budgeted Expenses',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.actualVsBudgetedExpenses,
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).expenseBudget.permissions(row),
    },
    actualVariationVsBudgetedExpenses: {
        id: 'actualVariationVsBudgetedExpenses',
        label: 'Actual (Cost) / Budgeted Expenses',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.actualVariationVsBudgetedExpenses,
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariation.permissions(row) &&
            ProjectReportColumns(report).expenseBudget.permissions(row),
    },
    actualVariationProjectVsBudgetedExpenses: {
        id: 'actualVariationProjectVsBudgetedExpenses',
        label: 'Actual (Cost + Variation + Project) / Budgeted Expenses',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.actualVariationProjectVsBudgetedExpenses,
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ) && ProjectReportColumns(report).expenseBudget.permissions(row),
    },
    revenueVsFee: {
        id: 'revenueVsFee',
        label: 'Revenue / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.revenueVsFee,
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    revenueVariationVsFee: {
        id: 'revenueVariationVsFee',
        label: 'Revenue (Variations) / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.revenueVariationVsFee,
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    revenueVsChargeOut: {
        id: 'revenueVsChargeOut',
        label: 'Revenue / Charge Out',
        type: 'progressBar',
        width: 24,
        value: (row) => row.revenueVsChargeOut,
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).chargeOut.permissions(row),
    },
    percentRevenueVsFee: {
        id: 'percentRevenueVsFee',
        label: 'Percent Revenue / Fee',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.percentRevenueVsFee,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    costProjectVsFee: {
        id: 'costProjectVsFee',
        label: 'Expenses (Cost + Project) / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.costProjectVsFee,
        permissions: (row) =>
            ProjectReportColumns(report).expensesProject.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        format: (v) => Formatter.currency(v, { decimals: 0 }),
    },
    costVsFee: {
        id: 'costVsFee',
        label: 'Expense (cost) / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.costVsFee,
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        format: (v) => Formatter.currency(v, { decimals: 0 }),
    },
    payVsFee: {
        id: 'payVsFee',
        label: 'Expense (labour) / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.payVsFee,
        permissions: (row) =>
            ProjectReportColumns(report).labourExpense.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        format: (v) => Formatter.currency(v, { decimals: 0 }),
    },
    chargeOutVsFee: {
        id: 'chargeOutVsFee',
        label: 'Charge Out / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.chargeOutVsFee,
        permissions: (row) =>
            ProjectReportColumns(report).chargeOut.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        format: (v) => Formatter.currency(v, { decimals: 0 }),
    },
    chargeOutVariationVsFee: {
        id: 'chargeOutVariationVsFee',
        label: 'Charge Out (Variations) / Fee',
        type: 'progressBar',
        width: 24,
        value: (row) => row.chargeOutVariationVsFee,
        permissions: (row) =>
            ProjectReportColumns(report).chargeOutVariation.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        format: (v) => Formatter.currency(v, { decimals: 0 }),
    },
    percentOfTotalHours: {
        id: 'percentOfTotalHours',
        label: 'Percentage of total hours',
        type: 'percent',
        width: 13,
        value: (row) => row.percentOfTotalHours,
    },
    percentOfTotalRevenue: {
        id: 'percentOfTotalRevenue',
        label: 'Percentage of total revenue',
        type: 'percent',
        width: 13,
        value: (row) => row.percentOfTotalRevenue,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
    },
    percentOfTotalExpenses: {
        id: 'percentOfTotalExpenses',
        label: 'Percentage of total expenses (cost)',
        type: 'percent',
        width: 13,
        value: (row) => row.percentOfTotalExpenses,
        permissions: (row) =>
            ProjectReportColumns(report).expensesProject.permissions(row),
    },
    percentOfTotalExpensesLabour: {
        id: 'percentOfTotalExpensesLabour',
        label: 'Percentage of total expenses (labour)',
        type: 'percent',
        width: 13,
        value: (row) => row.percentOfTotalExpensesLabour,
        permissions: (row) =>
            ProjectReportColumns(report).labourExpense.permissions(row),
    },
    profit: {
        id: 'profit',
        label: 'Profit (cost + project expenses)',
        type: 'currency',
        width: 13,
        value: (row) => row.profit,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
    },
    profitCost: {
        id: 'profitCost',
        label: 'Profit (cost)',
        type: 'currency',
        width: 13,
        value: (row) => row.profitCost,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
    },
    profitLabour: {
        id: 'profitLabour',
        label: 'Profit (labour)',
        type: 'currency',
        width: 13,
        value: (row) => row.profitLabour,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).labourExpense.permissions(row),
    },
    profitFee: {
        id: 'profitFee',
        label: 'Profit (fee)',
        type: 'currency',
        width: 13,
        value: (row) => row.profitFee,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
    },
    profitFeeVariation: {
        id: 'profitFeeVariation',
        label: 'Profit (fee, variation & project expenses)',
        type: 'currency',
        width: 13,
        value: (row) => row.profitFeeVariation,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
    },
    profitCostVariationProject: {
        id: 'profitCostVariationProject',
        label: 'Profit (cost, variation & project expenses)',
        type: 'currency',
        width: 13,
        value: (row) => row.profitCostVariationProject,
        permissions: (row) =>
            ProjectReportColumns(
                report
            ).revenueWithVariationsReimbursements.permissions(row) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
    },
    profitMargin: {
        id: 'profitMargin',
        label: 'Profit margin (cost + project expenses)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.profitMargin,
        permissions: (row) =>
            ProjectReportColumns(report).profit.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    profitMarginCost: {
        id: 'profitMarginCost',
        label: 'Profit margin (cost)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.profitMarginCost,
        permissions: (row) =>
            ProjectReportColumns(report).profitCost.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    profitMarginLabour: {
        id: 'profitMarginLabour',
        label: 'Profit margin (labour)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.profitMarginLabour,
        permissions: (row) =>
            ProjectReportColumns(report).profitLabour.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    profitMarginFee: {
        id: 'profitMarginFee',
        label: 'Profit margin (fee)',
        type: 'percent',
        width: 13,
        value: (row) => row.profitMarginFee,
        permissions: (row) =>
            ProjectReportColumns(report).profitFee.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
    },
    profitMarginFeeVariation: {
        id: 'profitMarginFeeVariation',
        label: 'Profit margin (fee, variation & project expenses)',
        type: 'percent',
        width: 13,
        value: (row) => row.profitMarginFeeVariation,
        permissions: (row) =>
            ProjectReportColumns(report).profitFeeVariation.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
    },
    netMultiplier: {
        id: 'netMultiplier',
        label: 'Net Multiplier',
        type: 'number',
        width: 13,
        aggregate: 'avg',
        value: (row) => row.netMultiplier,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
    },
    markup: {
        id: 'markup',
        label: 'Markup (cost + project expenses)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.markup,
        permissions: (row) =>
            ProjectReportColumns(report).profit.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
    },
    markupCost: {
        id: 'markupCost',
        label: 'Markup (cost)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.markupCost,
        permissions: (row) =>
            ProjectReportColumns(report).profitCost.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
    },
    markupLabour: {
        id: 'markupLabour',
        label: 'Markup (labour)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.markupLabour,
        permissions: (row) =>
            ProjectReportColumns(report).profitLabour.permissions(row) &&
            ProjectReportColumns(report).labourExpense.permissions(row),
    },
    revenuePerHour: {
        id: 'revenuePerHour',
        label: 'Revenue per hour',
        type: 'currency',
        width: 13,
        value: (row) => row.revenuePerHour,
        aggregate: 'avg',
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
    },
    revenueVariationPerHour: {
        id: 'revenueVariationPerHour',
        label: 'Revenue per hour (variations)',
        type: 'currency',
        width: 13,
        value: (row) => row.revenueVariationPerHour,
        permissions: (row) =>
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
    },
    revenueVariationReimbursementPerHour: {
        id: 'revenueVariationReimbursementPerHour',
        label: 'Revenue per hour (variations + reimbursements)',
        type: 'currency',
        width: 13,
        value: (row) => row.revenueVariationReimbursementPerHour,
        permissions: (row) =>
            ProjectReportColumns(
                report
            ).revenueWithVariationsReimbursements.permissions(row),
    },
    completionDate: {
        id: 'completionDate',
        label: 'Completion date',
        type: 'date',
        width: 13,
        value: (row) => {
            return row.completionDate ? new Date(row.completionDate) : null
        },
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
    },
    latestInvoicedPhases: {
        id: 'latestInvoicedPhases',
        label: 'Latest Invoiced Phases',
        type: 'text',
        width: 15,
        value: (row) => row.latestInvoicedPhases,
        permissions: (row) => canViewProjectInvoices(SessionStore.user, row),
    },
    latestRevenueDate: {
        id: 'latestRevenueDate',
        label: 'Latest Revenue Date',
        type: 'date',
        width: 13,
        value: (row) => {
            return row.latestRevenueDate
                ? new Date(row.latestRevenueDate)
                : null
        },
        permissions: (row) => canViewProjectInvoices(SessionStore.user, row),
    },
    latestRevenueAmount: {
        id: 'latestRevenueAmount',
        label: 'Latest Revenue Amount',
        type: 'currency',
        width: 13,
        value: (row) => {
            return row.latestRevenueAmount
        },
        permissions: (row) => canViewProjectInvoices(SessionStore.user, row),
    },
    latestTimeEntryDate: {
        id: 'latestTimeEntryDate',
        label: 'Latest Time Entry Date',
        type: 'date',
        width: 13,
        value: (row) => {
            return row.latestTimeEntryDate
                ? new Date(row.latestTimeEntryDate)
                : null
        },
    },
    latestTimeEntryDateInRange: {
        id: 'latestTimeEntryDateInRange',
        label: 'Latest Time Entry Date In Range',
        type: 'date',
        width: 13,
        value: (row) => {
            return row.latestTimeEntryDateInRange
                ? new Date(row.latestTimeEntryDateInRange)
                : null
        },
    },
    projectedHours: {
        id: 'projectedHours',
        label: 'Projected Hours',
        type: 'number',
        width: 13,
        value: (row) => row.projectedHours,
        permissions: (row) =>
            ProjectReportColumns(report).futureHours.permissions(row),
    },
    futureHours: {
        id: 'futureHours',
        label: 'Future Hours',
        type: 'number',
        width: 13,
        value: (row) => row.futureHours,
        permissions: (row) =>
            canViewStaffAllocations(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    futureLabour: {
        id: 'futureLabour',
        label: 'Future Labour Expense',
        type: 'currency',
        width: 13,
        value: (row) => row.futureLabour,
        permissions: (row) =>
            canViewStaffAllocations(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ) &&
            canViewProjectStaffPay(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    futureCost: {
        id: 'futureCost',
        label: 'Future Expense',
        type: 'currency',
        width: 13,
        value: (row) => row.futureCost,
        permissions: (row) =>
            canViewStaffAllocations(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    futureChargeOut: {
        id: 'futureChargeOut',
        label: 'Future Charge Out',
        type: 'currency',
        width: 13,
        value: (row) => row.futureChargeOut,
        permissions: (row) =>
            canViewStaffAllocations(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ) &&
            canViewProjectStaffChargeOut(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    futureRevenue: {
        id: 'futureRevenue',
        label: 'Future Revenue',
        type: 'currency',
        width: 13,
        value: (row) => row.futureRevenue,
        permissions: (row) =>
            canViewRevenueTargets(
                SessionStore.user,
                ProjectCollection.projectsById[row?.projectId]
            ),
    },
    futureWeeklyCost: {
        id: 'futureWeeklyCost',
        label: 'Future Weekly Expense',
        type: 'currency',
        width: 13,
        value: (row) => row.futureWeeklyCost,
        permissions: (row) =>
            ProjectReportColumns(report).futureCost?.permissions?.(row) &&
            ProjectReportColumns(report).expenses?.permissions?.(row),
    },
    projectedRevenue: {
        id: 'projectedRevenue',
        label: 'Projected Revenue',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedRevenue,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).futureRevenue.permissions(row),
    },
    projectedExpense: {
        id: 'projectedExpense',
        label: 'Projected Expense (Cost)',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedExpense,
        permissions: (row) =>
            ProjectReportColumns(report).futureCost.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
    },
    projectedLabourExpense: {
        id: 'projectedLabourExpense',
        label: 'Projected Labour Expense',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedLabourExpense,
        permissions: (row) =>
            ProjectReportColumns(report).futureLabour.permissions(row) &&
            ProjectReportColumns(report).labourExpense.permissions(row),
    },
    projectedChargeOut: {
        id: 'projectedChargeOut',
        label: 'Projected Charge Out',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedChargeOut,
        permissions: (row) =>
            ProjectReportColumns(report).futureChargeOut.permissions(row) &&
            ProjectReportColumns(report).chargeOut.permissions(row),
    },
    projectedProfit: {
        id: 'projectedProfit',
        label: 'Projected Profit',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedProfit,
        permissions: (row) =>
            ProjectReportColumns(report).projectedRevenue.permissions(row) &&
            ProjectReportColumns(report).projectedExpense.permissions(row),
    },
    projectedProfitMargin: {
        id: 'projectedProfitMargin',
        label: 'Projected Profit Margin',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.projectedProfitMargin,
        permissions: (row) =>
            ProjectReportColumns(report).projectedProfit.permissions(row) &&
            ProjectReportColumns(report).projectedRevenue.permissions(row),
    },
    futureWeeklyHours: {
        id: 'futureWeeklyHours',
        label: 'Future Weekly Hours',
        type: 'number',
        width: 13,
        value: (row) => row.futureWeeklyHours,
        permissions: (row) =>
            ProjectReportColumns(report).futureHours.permissions(row),
    },
    projectedWeeklyHours: {
        id: 'projectedWeeklyHours',
        label: 'Projected Weekly Hours',
        type: 'number',
        width: 13,
        value: (row) => row.projectedWeeklyHours,
        permissions: (row) =>
            ProjectReportColumns(report).futureHours.permissions(row),
    },
    projectedWeeklyExpense: {
        id: 'projectedWeeklyExpense',
        label: 'Projected Weekly Expense',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedWeeklyExpense,
        permissions: (row) =>
            ProjectReportColumns(report).futureCost.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
    },
    projectedWeeklyProfit: {
        id: 'projectedWeeklyProfit',
        label: 'Projected Weekly Profit',
        type: 'currency',
        width: 13,
        value: (row) => row.projectedWeeklyProfit,
        permissions: (row) =>
            ProjectReportColumns(report).projectedRevenue.permissions(row) &&
            ProjectReportColumns(report).projectedWeeklyExpense.permissions(
                row
            ),
    },
    projectedWeeklyProfitMargin: {
        id: 'projectedWeeklyProfitMargin',
        label: 'Projected Weekly Profit Margin',
        type: 'percent',
        width: 13,
        value: (row) => row.projectedWeeklyProfitMargin,
        permissions: (row) =>
            ProjectReportColumns(report).projectedWeeklyProfit.permissions(
                row
            ) && ProjectReportColumns(report).projectedRevenue.permissions(row),
    },
    profitVariationProject: {
        id: 'profitVariationProject',
        label: 'Profit (Cost + Variation + Project)',
        type: 'currency',
        width: 13,
        value: (row) => row.profitVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).revenueWithVariations.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
    },
    profitMarginVariationProject: {
        id: 'profitMarginVariationProject',
        label: 'Profit Margin (Cost + Variation + Project)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.profitMarginVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
    },
    markupVariationProject: {
        id: 'markupVariationProject',
        label: 'Markup (Cost + Variation + Project)',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        value: (row) => row.markupVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
    },
    chargeOutVsRevenueVariation: {
        id: 'chargeOutVsRevenueVariation',
        label: 'Charge Out / Revenue (Variation)',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.chargeOutVsRevenueVariation,
        permissions: (row) =>
            ProjectReportColumns(report).chargeOutVariation.permissions(row) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
    },
    expenseVsRevenueVariation: {
        id: 'expenseVsRevenueVariation',
        label: 'Expenses / Revenue (Variation)',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.expenseVsRevenueVariation,
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariation.permissions(row) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
    },
    expenseVsRevenueVariationProject: {
        id: 'expenseVsRevenueVariationProject',
        label: 'Expenses / Revenue (Cost + Variation + Project)',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.expenseVsRevenueVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
    },
    revenueVsExpenseVariationProject: {
        id: 'revenueVsExpenseVariationProject',
        label: 'Revenue / Expenses (Cost + Variation + Project)',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.revenueVsExpenseVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).revenueWithVariations.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
    },
    profitVsRevenueVariationProject: {
        id: 'profitVsRevenueVariationProject',
        label: 'Profit / Revenue (Cost + Variation + Project)',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.profitVsRevenueVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
    },
    profitVsExpenseVariationProject: {
        id: 'profitVsExpenseVariationProject',
        label: 'Profit / Expenses (Cost + Variation + Project)',
        type: 'progressBar',
        format: (v) => Formatter.currency(v, { decimals: 0 }),
        width: 24,
        value: (row) => row.profitVsExpenseVariationProject,
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
    },
    serviceFee: {
        id: 'serviceFee',
        label: 'Service Fee',
        type: 'currency',
        width: 13,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
        value: (row) => row.serviceFee,
    },
    serviceProfit: {
        id: 'serviceProfit',
        label: 'Service Profit',
        type: 'currency',
        width: 13,
        permissions: (row) =>
            ProjectReportColumns(report).serviceFee.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
        value: (row) => row.serviceProfit,
    },
    serviceProfitMargin: {
        id: 'serviceProfitMargin',
        label: 'Service Profit Margin',
        type: 'percent',
        aggregate: 'avg',
        width: 13,
        permissions: (row) =>
            ProjectReportColumns(report).serviceProfit.permissions(row) &&
            ProjectReportColumns(report).serviceFee.permissions(row),
        value: (row) => row.serviceProfitMargin,
    },
})
